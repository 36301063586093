import {useEffect, useState} from "react";
import {default as styles} from "./MortgageType.module.scss";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import BackButton from "components/molecules/BackButton/BackButton";
import Message from "components/atoms/Message/Message";
import {equalIgnoreCase, toLowerCaseOrEmpty} from "components/utils/stringUtil";
import {useDispatch, useSelector} from "react-redux";
import {
  getConsolidatedMortgageProviderName,
  getIsLocked,
  getLoanTypeConsolated,
  getMortgageDetailsOrEmpty,
} from "store/selectors/basicInfo";
import {LoanType} from "types/enums/loanType";
import {
  MortgageTypeFormData,
  submitMortgageType,
  navigatePreviousStep,
  initialize,
} from "./MortgageType.action";
import {getFixedT} from "util/languageUtil";
import {useNavigate} from "react-router-dom";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import classNames from "classnames";
import StepContent from "components/organisms/StepContent/StepContent";
import Form from "components/atoms/Form/Form";
import {InputAdornment, MenuItem, Typography} from "@mui/material";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./MortgageType.schema";
import MoneyInputControlled from "components/organisms/Form/MoneyInputControlled/MoneyInputControlled";
import DatePickerControlled from "components/organisms/Form/DatePickerControlled/DatePickerControlled";
import {moneyOrZero} from "components/utils/moneyUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import {getIsNavigationMenuEnabled} from "store/selectors/configurations";
import {Province} from "types/enums/province";

const fixedT = getFixedT("mortgageInfoSteps.mortgageType");

export const LOAN_TYPES: Record<
  LoanType,
  {
    displayName: string;
    value: string;
    trackingEventBack: TrackingEventType;
    trackingEventNext: TrackingEventType;
  }
> = {
  [LoanType.PREAPPROVAL]: {
    displayName: "Pre-Approval",
    value: "PREAPPROVAL",
    trackingEventBack: TrackingEventType.mortgageTypePreApprovalClickBack,
    trackingEventNext: TrackingEventType.mortgageTypePreApprovalClickNext,
  },
  [LoanType.NEW_PURCHASE]: {
    displayName: "New Purchase",
    value: "NEW_PURCHASE",
    trackingEventBack: TrackingEventType.mortgageTypeNewPurchaseClickBack,
    trackingEventNext: TrackingEventType.mortgageTypeNewPurchaseClickNext,
  },
  [LoanType.RENEWAL]: {
    displayName: "Renewal",
    value: "RENEWAL",
    trackingEventBack: TrackingEventType.mortgageTypeRenewalClickBack,
    trackingEventNext: TrackingEventType.mortgageTypeRenewalClickNext,
  },
};

export const PURCHASE_TYPES = {
  FIRST_HOME_PURCHASE: "First Home Purchase",
  NEW_HOME_PURCHASE: "New Home Purchase",
  RENTAL_HOME_PURCHASE: "Rental Home Purchase",
  VACATION_HOME_PURCHASE: "Vacation Home Purchase",
};

export const MORTGAGE_TYPE = {
  ALL_TYPES: "All Types",
  FIXED_CLOSED: "Fixed Closed",
  FIXED_OPEN: "Fixed Open",
  VARIABLE_CLOSED: "Variable Closed",
};

const MortgageType = ({nextPage = () => { }, className = ""}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locked = useSelector(getIsLocked);
  const loanType = useSelector<any, LoanType | undefined>(getLoanTypeConsolated);
  const isNavigationMenuEnabled = useSelector(getIsNavigationMenuEnabled);
  const mortgageDetails = useSelector(getMortgageDetailsOrEmpty);

  const [formError, setFormError] = useState<string>();

  const formHook = useForm<MortgageTypeFormData>({
    defaultValues: {
      city: mortgageDetails?.city || "",
      downPayment: moneyOrZero(mortgageDetails?.downPayment?.amount),
      downPaymentPercent: mortgageDetails?.downPaymentPercent,
      downPaymentPercentMultipled100: Math.round((mortgageDetails.downPaymentPercent || 0) * 100),
      remainingAmount: moneyOrZero(mortgageDetails?.remainingAmount?.amount),
      remainingMonths: mortgageDetails?.remainingMonths,
      estimatedPropertyValue: moneyOrZero(mortgageDetails?.estimatedPropertyValue?.amount),
      purchasePrice: moneyOrZero(mortgageDetails?.purchasePrice?.amount),
      loanType: loanType,
      closingDate: mortgageDetails?.closingDate,
      renewalDate: mortgageDetails?.renewalDate,
      province: mortgageDetails.province,
    },
    resolver: yupResolver(getSchema()),
  });

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  const minDate = dayjs();
  const maxDate = dayjs().add(6, "month");

  const providerName = useSelector(getConsolidatedMortgageProviderName);
  const mortgageProviderName = toLowerCaseOrEmpty(providerName);

  function onBack() {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventBack);
    }
    dispatch(navigatePreviousStep({navigate}));
  }

  async function onSubmit(values: MortgageTypeFormData) {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventNext);
    }
    dispatch(
      submitMortgageType({
        onSuccess: nextPage,
        ...values,
        closingDate: values?.closingDate,
      })
    );
  }

  useEffect(() => {
    dispatch(initialize());
  }, []);

  return (
    <StepContent
      className={classNames(styles.root, className)}
      headerText={fixedT("title", {context: loanType})}
      backButton={<BackButton onClick={onBack} />}
      nextButton={
        <SubmitButton onClick={formHook.handleSubmit(onSubmit)} text={fixedT("forwardButton")} />
      }
    >
      <Form onEnter={formHook.handleSubmit(onSubmit)}>
        {equalIgnoreCase(loanType || "", LoanType.NEW_PURCHASE) && (
          <div className={styles.options}>
            <div className={styles.field}>
              <MoneyInputControlled
                formHook={formHook}
                name="purchasePrice.amount"
                label={fixedT("newPurchase.purchasePrice")}
                trackingEvent={TrackingEventType.mortgageTypeNewPurchaseFillPurchasePrice}
                disabled={locked}
                required={LoanType.NEW_PURCHASE === loanType}
              />
            </div>
            <div className={styles.field}>
              <MoneyInputControlled
                formHook={formHook}
                name="downPayment.amount"
                label={fixedT("newPurchase.downPaymentSize")}
                trackingEvent={TrackingEventType.mortgageTypeNewPurchaseFillDownPaymentSize}
                disabled={locked}
                required={LoanType.NEW_PURCHASE === loanType}
              />
            </div>
            <div className={styles.closingDate}>
              <DatePickerControlled
                formHook={formHook}
                name="closingDate"
                label={fixedT("newPurchase.closingDate")}
                trackingEvent={TrackingEventType.mortgageTypeNewPurchaseFillClosingDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>

            <div className={styles.explanation}>
              <Typography className={styles.title} align="left" variant="h3">
                {fixedT("newPurchase.explanation1.title")}
              </Typography>
              <Typography className={styles.details} variant="subtitle1">
                {fixedT("newPurchase.explanation1.details")}
              </Typography>
            </div>
            <div className={styles.explanation}>
              <Typography className={styles.title} align="left" variant="h3">
                {fixedT("newPurchase.explanation2.title")}
              </Typography>
              <Typography className={styles.details} variant="subtitle1">
                {fixedT("newPurchase.explanation2.details")}
              </Typography>
            </div>
          </div>
        )}
        {equalIgnoreCase(loanType || "", LoanType.RENEWAL) && (
          <div className={styles.options}>
            <div className={styles.field}>
              <MoneyInputControlled
                formHook={formHook}
                name="remainingAmount.amount"
                label={fixedT("renewal.mortgageBalance")}
                trackingEvent={TrackingEventType.mortgageTypeRenewalFillMortgageBalance}
                disabled={locked}
                required={LoanType.RENEWAL === loanType}
              />
            </div>
            <div className={styles.field}>
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="remainingMonths"
                required={LoanType.RENEWAL === loanType}
                label={fixedT("renewal.amortizationRemaining")}
                trackingEvent={TrackingEventType.mortgageTypeRenewalFillAmortizationRemaining}
                inputProps={{maxLength: 3}}
                disabled={locked}
                type="number"
              />
            </div>
            <div className={styles.field}>
              <MoneyInputControlled
                formHook={formHook}
                name="estimatedPropertyValue.amount"
                label={fixedT("renewal.estimatedPropertyValue")}
                trackingEvent={TrackingEventType.mortgageTypeRenewalFillEstimatedPropertyValue}
                disabled={locked}
                required={LoanType.RENEWAL === loanType}
              />
            </div>
            <div className={styles.renewalDate}>
              <DatePickerControlled
                formHook={formHook}
                name="renewalDate"
                label={fixedT("renewal.renewalDate")}
                trackingEvent={TrackingEventType.mortgageTypeRenewalFillRenewalDate}
                minDate={minDate}
                maxDate={maxDate}
                required
              />
            </div>
            <div className={styles.explanation}>
              <Typography className={styles.title} align="center" variant="h3">
                {fixedT("renewal.explanation.title")}
              </Typography>
              <Typography className={styles.details} variant="subtitle1">
                {fixedT("renewal.explanation.details")}
              </Typography>
            </div>
          </div>
        )}
        {equalIgnoreCase(loanType || "", LoanType.PREAPPROVAL) && (
          <div className={styles.options}>
            <Typography className={styles.enterLabel} variant="h3">
              {fixedT("preapproval.enterLabel")}
            </Typography>
            <div>
              <div className={styles.row}>
                <div className={styles.formGroup}>
                  <TextFieldControlled
                    fullWidth
                    formHook={formHook}
                    name="city"
                    label={fixedT("preapproval.purchaseCity")}
                    trackingEvent={TrackingEventType.mortgageTypePreApprovalFillPurchaseCity}
                    disabled={locked}
                  />
                  <TextFieldControlled
                    select
                    className={styles.purchaseProvince}
                    fullWidth
                    formHook={formHook}
                    name="province"
                    label={fixedT("preapproval.purchaseProvince")}
                    trackingEvent={TrackingEventType.mortgageTypePreApprovalFillPurchaseCity}
                    disabled={locked}>
                    {Object.keys(Province)
                      .filter((v) => v != "NULL") // There is Province.NULL, which should be excluded.
                      .map((province) => (
                        <MenuItem key={province} value={province}>
                          {fixedT(`preapproval.province.${province}`)}
                        </MenuItem>
                      ))}
                  </TextFieldControlled>

                </div>
              </div>

              <div className={classNames(styles.row, styles.downPaymentGroup)}>
                <div className={styles.formGroup}>
                  <MoneyInputControlled
                    formHook={formHook}
                    name="downPayment.amount"
                    disabled={locked}
                    required
                    label={fixedT("preapproval.downPaymentAmount")}
                    trackingEvent={TrackingEventType.mortgageTypePreApprovalFillDownPaymentAmount}
                  />
                </div>
                <div className={styles.formGroup}>
                  <TextFieldControlled
                    formHook={formHook}
                    name="downPaymentPercentMultipled100"
                    disabled={locked}
                    label={fixedT("preapproval.downPaymentPercentage")}
                    trackingEvent={
                      TrackingEventType.mortgageTypePreApprovalFillDownPaymentPercentage
                    }
                    required
                    valueAsFloat
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <p>%</p>
                        </InputAdornment>
                      ),
                    }}
                    type={"number"}
                  />
                </div>
              </div>
            </div>

            <div className={styles.explanation}>
              <div className={styles.title}>{fixedT("preapproval.explanation1.title")}</div>
              <br />
              <div className={styles.details}>
                {isNavigationMenuEnabled
                  ? fixedT("preapproval.explanation1.details")
                  : fixedT("preapproval.explanation1.detailsNoNavigation")}
              </div>
            </div>
            <div className={styles.explanation}>
              <div className={styles.title}>{fixedT("preapproval.explanation2.title")}</div>
              <br />
              <div className={styles.details}>{fixedT("preapproval.explanation2.details")}</div>
            </div>
          </div>
        )}
      </Form>
      <Message message={formError} />
      <Typography className={styles.subtext} variant="body1">
        {isNavigationMenuEnabled
          ? fixedT("under20Subtext", {context: mortgageProviderName})
          : fixedT("under20SubtextNoNavigation", {context: mortgageProviderName})}
      </Typography>
    </StepContent>
  );
};

export default MortgageType;
