import {useState} from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import {default as styles} from "./CreditScorePage.module.scss";
import BackButton from "components/molecules/BackButton/BackButton";
import Message from "components/atoms/Message/Message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import IconDialogButton from "components/molecules/IconDialogButton/IconDialogButton";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getBasicInfo, getBasicInfoOrEmpty, getIsLocked} from "store/selectors/basicInfo";
import {CreditScoreType} from "types/enums/creditScoreType";
import {submit, navigatePreviousStep, SubmitCreditScorePagePayload} from "./CreditScorePage.action";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import StepContent from "components/organisms/StepContent/StepContent";
import classNames from "classnames";
import Typography from "@mui/material/Typography/Typography";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import DatePickerControlled from "components/organisms/Form/DatePickerControlled/DatePickerControlled";
import Form from "components/atoms/Form/Form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./CreditScorePage.schema";
import {getApplicantMeta} from "store/selectors/applicantMeta";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {ConfigurationKey} from "types/configurations";

const fixedT = getFixedT("basicInfoSteps.creditScorePage");

interface Props {
  className?: () => any;
  prevPage?: () => any;
  nextPage?: () => any;
}

const CreditScorePage: React.FC<Props> = ({
  className = "",
  prevPage = undefined,
  nextPage = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const basicInfo = useSelector(getBasicInfo);
  const applicantMeta = useSelector(getApplicantMeta);
  const existingCreditScore =
    useSelector(getBasicInfoOrEmpty).creditScore || applicantMeta.creditScore;
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitSelfReportedCreditScore
  );
  const isSelfReportedCreditEnabled: boolean = useSelector(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
    )
  );
  const [fieldError, setFieldError] = useState<string>();
  const [creditScore, setCreditScore] = useState(existingCreditScore);

  const formHook = useForm<SubmitCreditScorePagePayload>({
    defaultValues: {
      dateOfBirth: basicInfo?.dateOfBirth || applicantMeta.dateOfBirth,
    },
    resolver: yupResolver(getSchema()),
  });

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  const minDate = dayjs().subtract(73, "year");
  const maxDate = dayjs(new Date()).subtract(18, "year");

  function validated() {
    if (!creditScore) {
      setFieldError(fixedT("messages.missing_creditScore")!);
      return false;
    }
    setFieldError(undefined);
    return true;
  }

  function onChangeScore(newCreditScore: CreditScoreType): void {
    if (locked) {
      setFieldError(fixedT("messages.appLocked")!);
    } else {
      setCreditScore(newCreditScore);
    }
  }

  async function onSubmit(values: SubmitCreditScorePagePayload) {
    if (validated()) {
      trackEvent(TrackingEventType.creditPageClickNextButton);
      dispatch(
        submit({
          creditScore,
          dateOfBirth: new Date(values?.dateOfBirth!).toISOString().split("T")[0], // QM only accepts it in this format; yyyy-mm-dd,
          navigate,
        })
      ) && nextPage();
    }
  }

  return (
    <StepContent
      className={classNames(styles.root, className)}
      headerText={!isSelfReportedCreditEnabled ? fixedT("header") : fixedT("headerPreSignup")}
      backButton={
        !isSelfReportedCreditEnabled ? (
          // If this page is after sign up,
          //    let the component dynamically handle routing
          <BackButton
            onClick={() => {
              trackEvent(TrackingEventType.creditPageClickBackButton);
              dispatch(navigatePreviousStep({navigate}));
            }}
          />
        ) : isSelfReportedCreditEnabled && prevPage ? (
          // If this page is before sign up and there is an assigned previous page
          //    let the Router handle routing
          <BackButton
            onClick={() => {
              trackEvent(TrackingEventType.creditPageClickBackButton);
              prevPage();
            }}
          />
        ) : undefined
        // If this page is before sign up and there is no assigned previous page
        //    it is the first page and no back button is rendered
      }
      nextButton={
        <SubmitButton
          isEnabled={!isPending}
          onClick={formHook.handleSubmit(onSubmit)}
          text={
            (!isPending ? (
              fixedT("forwardButton")
            ) : (
              <FontAwesomeIcon icon={faSyncAlt} spin={true} />
            )) as string
          }
        />
      }
    >
      <Form onEnter={() => formHook.handleSubmit(onSubmit)}>
        <Typography className={styles.header} variant="prompt">
          {fixedT("subtitle")}
        </Typography>
        <div className={styles.options}>
          {Object.values(CreditScoreType).map((creditScoreTypeValue, index) => (
            <IconDialogButton
              className={styles.iconDialogButton}
              textClassName={styles.text}
              key={`${creditScoreTypeValue}-${index}`}
              text={`${fixedT("creditScore", {context: creditScoreTypeValue})}`}
              selected={creditScore === creditScoreTypeValue}
              onClick={() => {
                trackEvent(TrackingEventType.creditPageSelectCreditScore);
                onChangeScore(creditScoreTypeValue);
              }}
            />
          ))}
        </div>
        <div className={styles.dateOfBirth}>
          <DatePickerControlled
            formHook={formHook}
            name="dateOfBirth"
            label={fixedT("inputDateOfBirth")}
            minDate={minDate}
            maxDate={maxDate}
            trackingEvent={TrackingEventType.creditPageChangeBirthDate}
            disabled={locked}
          />
        </div>
      </Form>
      <Message message={fieldError} />
      <Message message={failure} />
    </StepContent>
  );
};

export default CreditScorePage;
