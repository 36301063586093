import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import {root} from "./Modal.module.scss";

interface ModalProps {
  className?: string;
  modalParent?: HTMLElement;
  children?: JSX.Element;
  onCloseRequest?: () => void;
  closeOnBackdropClick?: boolean;
  closeOnOutsideClick?: boolean;
}

const Modal = ({
  className = "",
  modalParent = document.body,
  children = <div />,
  onCloseRequest = () => {},
  closeOnBackdropClick = true,
  closeOnOutsideClick = true,
  ...otherProperties
}: ModalProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null),
    modal = document.createElement("div"),
    modalContents = React.cloneElement(children, {requestModalClose: onCloseRequest});
  if (contentRef.current === null) contentRef.current = modal;

  function cleanupModal() {
    onCloseRequest();
    modalParent.removeChild(modal);
    document.removeEventListener("mousedown", handleClickOutside);
    document.body.style.removeProperty("overflow");
  }

  function handleClickOutside(clickEvent: MouseEvent): void {
    if (closeOnBackdropClick && clickEvent.target === modal) {
      cleanupModal();
    } else if (
      closeOnOutsideClick &&
      !modal.contains((clickEvent as never as React.BaseSyntheticEvent<MouseEvent>).target)
    ) {
      cleanupModal();
    }
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    modal.className = `${root} ${className}`;
    modalParent.appendChild(modal);
    document.addEventListener("mousedown", handleClickOutside);
    return cleanupModal;
  }, [contentRef]);

  return ReactDOM.createPortal(modalContents, contentRef.current);
};

export default Modal;
