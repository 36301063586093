import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {ResidentialMortgageDetailsResponse} from "types/dto/residentialMortgageDetailsResponse";
import {WithNavigate, WithOnSuccess} from "types/ui";

const namespace = "PropertyInformation";

export interface PropertyInformationFormData
  extends Partial<ResidentialMortgageDetailsResponse>,
    WithOnSuccess,
    WithNavigate {}

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const submitPropertyInforation = createAction<PropertyInformationFormData>(
  actionTypeOf(namespace, "submit")
);

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
