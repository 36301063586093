import {MIXPANEL_DEBOUNCE_WAIT_MS} from "appConstants";
import {debounce} from "lodash";
import mixpanel from "mixpanel-browser";
import {TrackingEventType} from "types/enums/trackingEventType";
import memoizeOne from "memoize-one";

export const isKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (e.key === "Enter") {
    return true;
  }
};

export const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>, callback: () => void) => {
  if (isKeyDown(e)) {
    callback();
  }
};

export const trackEvent = (event: TrackingEventType, data?: string) => {
  try {
    mixpanel.track(event, {
      ...(data && {data: data}),
    });
  } catch (error: any) {
    console.error(`Error tracking event: ${event}. ${error.message}`);
  }
};

export const trackEventDebounced = debounce(trackEvent, MIXPANEL_DEBOUNCE_WAIT_MS);

export const trackEventMemoized = memoizeOne(trackEventDebounced);

export function preventDefaultBehaviour(event: any) {
  event.preventDefault();
  event.stopPropagation();
}
