import React from "react";
import {root} from "./NavigationSection.module.scss";

interface NavigationSectionProps {
  children?: React.ReactNode;
}

const NavigationSection = ({children}: NavigationSectionProps) => {
  return <div className={root}>{children}</div>;
};

export default NavigationSection;
