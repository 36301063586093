import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFixedT} from "util/languageUtil";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import Message from "components/atoms/Message/Message";
import {useNavigate} from "react-router-dom";
import {registerPrimaryUser, SignUpFormData} from "./SignUpForm.action";
import {getIsAnyOperationPending, getOperationFailure} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {Typography, Link, Modal} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {default as styles} from "./SignUpForm.module.scss";
import Form from "components/atoms/Form/Form";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import {fetchPolicyApi} from "api/basicInfo";
import AcceptLegalInfo from "components/templates/AcceptLegalInfo/AcceptLegalInfo";
import {getSelectedProviderName, getWhitelabelId} from "store/selectors/whitelabel";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";
import {useForm} from "react-hook-form";
import {getSchema} from "./SignUpForm.schema";
import {yupResolver} from "@hookform/resolvers/yup";
import ChipControlled from "components/organisms/Form/ChipControlled/ChipControlled";
import {getNavigationError} from "store/selectors/navigationController";
import {removeNavigationError} from "components/molecules/NavigationBar/NavigationBar.action";
import CheckboxControlled from "components/organisms/Form/CheckboxControlled/CheckboxControlled";
import {getConsolidatedMortgageProviderId} from "store/selectors/basicInfo";

const fixedT = getFixedT("components.organisms.SignUpForm");

export const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPending = useSelector(getIsAnyOperationPending)(OperationType.registerPrimaryUser);
  const failureError = useSelector(getOperationFailure)(OperationType.registerPrimaryUser);
  const navigationError = useSelector(getNavigationError);
  const whitelabelId = useSelector(getWhitelabelId);
  const organizationName = useSelector(getSelectedProviderName);
  const providerId = useSelector(getConsolidatedMortgageProviderId);

  const formHook = useForm<SignUpFormData>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      applicationType: undefined,
      phoneNumber: "",
    },
    resolver: yupResolver(getSchema()),
  });

  const [policyContent, setPolicyContent] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);

  const handleClose = () => setModal(false);

  async function handleOpen() {
    const {data: privacyPolicy} = await fetchPolicyApi(whitelabelId);
    setPolicyContent(privacyPolicy);
    setModal(true);
  }

  const APPLICATION_TYPE = {
    SINGLE_APPLICANT: {
      label: fixedT("singleApplicant"),
      value: ResidentialApplicationType.SINGLE_APPLICANT,
      icon: <PersonIcon />,
    },
    JOINT_APPLICANT: {
      label: fixedT("jointApplicant"),
      value: ResidentialApplicationType.JOINT_APPLICANT,
      icon: <PeopleAltIcon />,
    },
  };

  const getOptions = (map: any) => Object.keys(map).map((key) => map[key]);

  const onSubmit = (values: SignUpFormData) => {
    dispatch(removeNavigationError());
    trackEvent(TrackingEventType.signUpPageSubmit);
    dispatch(registerPrimaryUser({...values, navigate}));
  };
  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  useEffect(() => {
    if (navigationError) {
      setError(navigationError ?? "");
    }
    if (!providerId) {
      setError(fixedT("error", {context: "missingProductProvider"}));
    } else if (failureError) {
      if (failureError?.status && failureError.status != 200) {
        setError(fixedT("error", {context: failureError?.status}));
      }
    }
  }, [navigationError, failureError]);

  return (
    <div className={styles.root}>
      <Typography variant="h1" align="left">
        {fixedT("header")}
      </Typography>
      <Message message={error} className={styles.error} />
      <Typography variant="subtitle1" dangerouslySetInnerHTML={{__html: fixedT("subheader")}} />
      <Form className={styles.fields} onEnter={formHook.handleSubmit(onSubmit)}>
        <div className={styles.twoColumns}>
          <div className={styles.col}>
            <TextFieldControlled
              fullWidth
              formHook={formHook}
              name="firstName"
              type="text"
              label={fixedT("fields.firstName.label")}
              placeholder={fixedT("fields.firstName.placeholder")}
              autoComplete="firstName"
              disabled={isPending}
              required
              trackingEvent={TrackingEventType.signUpPageEditEmail}
            />
          </div>
          <div className={styles.col}>
            <TextFieldControlled
              fullWidth
              formHook={formHook}
              name="lastName"
              type="text"
              label={fixedT("fields.lastName.label")}
              placeholder={fixedT("fields.lastName.placeholder")}
              autoComplete="lastName"
              disabled={isPending}
              required
              trackingEvent={TrackingEventType.signUpPageEditEmail}
            />
          </div>
        </div>
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="email"
          type="email"
          label={fixedT("fields.email.label")}
          placeholder={fixedT("fields.email.placeholder")}
          autoComplete="email"
          disabled={isPending}
          required
          trackingEvent={TrackingEventType.signUpPageEditEmail}
        />
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="phoneNumber"
          type="tel"
          label={fixedT("fields.phoneNumber.label")}
          placeholder={fixedT("fields.phoneNumber.placeholder")}
          autoComplete="phoneNumber"
          required
          trackingEvent={TrackingEventType.signUpPageEditPhoneNo}
        />
        <ChipControlled
          formHook={formHook}
          name="applicationType"
          color="primary"
          options={getOptions(APPLICATION_TYPE)}
        />
        <div className={styles.fineprint}>
          <Typography variant="body2">
            {fixedT("termsAndConditions")}{" "}
            <Link variant="body2" onClick={handleOpen}>
              {fixedT("termsAndConditionsLink")}
            </Link>
          </Typography>
        </div>
        <div className={styles.checkboxContainer}>
          {organizationName && (
            <>
              <div className={styles.checkboxWrapper}>
                <CheckboxControlled formHook={formHook} name="emailOptIn" />
              </div>

              <Typography variant="body2" className={styles.typography} gutterBottom>
                {fixedT("emailOptIn", {name: organizationName})}
              </Typography>
            </>
          )}
        </div>
      </Form>

      <Modal open={modal} onClose={handleClose}>
        <AcceptLegalInfo title={fixedT("policyTitle")} onClose={handleClose}>
          {policyContent || fixedT("errorPolicy")}
        </AcceptLegalInfo>
      </Modal>

      <NavigationSection>
        <SubmitButton
          isSubmitting={isPending}
          isEnabled={!isPending}
          onClick={formHook.handleSubmit(onSubmit)}
          text={fixedT("forwardButton")}
        />
      </NavigationSection>
    </div>
  );
};
