import {createSelector} from "@reduxjs/toolkit";
import {State} from "types/store";
import {
  NavigationState,
  NavigationHistory,
  StepStatusRecord,
  StepStatusState,
} from "types/NavigationController";
import {
  hasSelfReportedAssetsOrLiabilities,
  hasPulledBankingData,
  hasCompletedMortgageInfo,
  hasSelfReportedCreditScore,
  hasPulledCreditData,
} from "util/routeUtil";
import {getBasicInfo, getBasicInfoOrEmpty, getIsDirectLender, getIsPrimary} from "./basicInfo";
import {
  getOnlyDocumentUploadRequirements,
  getIsAllDocumentUploadHidden,
  getIsAnyDocumentUploadRequirementsEnabled,
  getIsProviderConfigurationValueEnabled,
} from "./providerconfigurations";
import {Configuration, ConfigurationKey} from "types/configurations";
import {getHasUserSelectedProvider} from "./whitelabel";
import {NavStep, NavStepKey, StepsWithoutSelectProductType} from "types/enums/navStep";
import {isConfigurationValueTrue} from "util/configUtil";
import {getHasUser} from "./user";
import {RouteUrl} from "types/route";

export const getNavigationController = (rootState: State): NavigationState =>
  rootState.navigationController;

export const getNavigationHistory = (rootState: State): NavigationHistory =>
  rootState.navigationController?.history;

export const getNavigationStepStatus = (rootState: State): StepStatusRecord =>
  rootState.navigationController?.stepStatus;

export const getHasVisitedUrl = createSelector(
  getNavigationHistory,
  (navigationHistory: any) =>
    (url: string): boolean =>
      (navigationHistory ?? []).includes(url)
);

export const getCurrentStep = createSelector(
  getNavigationController,
  (navigationController: NavigationState) => navigationController.currentStep
);

export const getNextUrl = createSelector(
  getNavigationController,
  (navigationController: NavigationState) => navigationController.nextUrl
);

export const getHasVisitedAnyUrls = createSelector(
  getNavigationHistory,
  (navigationHistory: any) => (urls: string[]) =>
    !!urls.find((url) => navigationHistory.includes(url))
);

export const getHasCompletedStep = createSelector(
  getNavigationStepStatus,
  (stepStatus: StepStatusRecord) =>
    (step: NavStep): boolean =>
      stepStatus[step].completed
);

export const getNavigationError = createSelector(
  getNavigationController,
  (navigationState: NavigationState): string | undefined => navigationState.error
);

export const getAllRequiredIncompleteSteps = createSelector(
  getNavigationStepStatus,
  (stepStatus: {[x in string]: StepStatusState}): StepsWithoutSelectProductType[] =>
    Object.keys(NavStep)
      .filter((item) => NavStep.SELECT_PRODUCT !== item)
      .filter((item) => !stepStatus[item].hidden)
      .filter((item) => !stepStatus[item].optional)
      .filter((item) => !stepStatus[item].completed) as StepsWithoutSelectProductType[]
);

export const getAllOptionalIncompleteSteps = createSelector(
  getNavigationStepStatus,
  (stepStatus: {[x in string]: StepStatusState}): StepsWithoutSelectProductType[] =>
    Object.keys(NavStep)
      .filter((item) => NavStep.SELECT_PRODUCT !== item)
      .filter((item) => !stepStatus[item].hidden)
      .filter((item) => stepStatus[item].optional)
      .filter((item) => !stepStatus[item].completed) as StepsWithoutSelectProductType[]
);

export const getHasAllRequiredStepsCompleted = createSelector(
  getAllRequiredIncompleteSteps,
  (allRequiredIncompleteSteps: NavStepKey[]): boolean => !allRequiredIncompleteSteps.length
);

export const getHasCompletedWelcome = createSelector(
  getHasUserSelectedProvider,
  getHasUser,
  (hasUserSelectedProvider: boolean, hasUser: boolean): boolean =>
    hasUserSelectedProvider && hasUser
);

export const getHasCompletedPersonalProfile = createSelector(
  getBasicInfo,
  (basicInfo): boolean => !!basicInfo?.employmentHistory && !!basicInfo?.residentialAddress
);

export const getHasCompletedIncomeVerification = createSelector(
  getBasicInfoOrEmpty,
  getIsDirectLender,
  (basicInfo, isDirectLender): boolean =>
    isDirectLender ? hasSelfReportedAssetsOrLiabilities(basicInfo) : hasPulledBankingData(basicInfo)
);

export const getHasCompletedCreditVerification = createSelector(
  getBasicInfoOrEmpty,
  getIsDirectLender,
  (basicInfo, isDirectLender): boolean =>
    isDirectLender ? hasSelfReportedCreditScore(basicInfo) : hasPulledCreditData(basicInfo)
);

export const getHasCompletedUploadDocuments = createSelector(
  getBasicInfo,
  getOnlyDocumentUploadRequirements,
  (basicInfo, docUploadReq): boolean => {
    if (basicInfo) {
      const configToInputMap: Map<ConfigurationKey, boolean> = new Map();
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_T4,
        basicInfo.uploadedT4
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_NOA,
        basicInfo.uploadedNOA
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_PAYSTUB,
        basicInfo.uploadedPayStub
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_BANK_STATEMENT,
        basicInfo.uploadedBankStatement
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_MLS,
        basicInfo.uploadedMLS
      );
      configToInputMap.set(
        ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_CLOSING_DOCUMENT,
        basicInfo.uploadedClosingDocument
      );
      const isAllRequiredDocsUploaded = !docUploadReq?.find((configuration: Configuration) => {
        return isConfigurationValueTrue(configuration) && !configToInputMap.get(configuration.key);
      });
      const isAtleastOneDocUploaded =
        basicInfo.uploadedT4 ||
        basicInfo.uploadedNOA ||
        basicInfo.uploadedPayStub ||
        basicInfo.uploadedBankStatement ||
        basicInfo.uploadedMLS ||
        basicInfo.uploadedClosingDocument;
      return isAllRequiredDocsUploaded && isAtleastOneDocUploaded;
    }
    return false;
  }
);

export const getHasCompletedMortgageInformation = createSelector(
  getBasicInfoOrEmpty,
  (basicInfo): boolean =>
    !!basicInfo?.mortgageDetails?.loanType && hasCompletedMortgageInfo(basicInfo)
);

export const getIsBankingOptional = getIsDirectLender;

export const getHasSelectedProduct = createSelector(
  getBasicInfo,
  (basicInfo): boolean => !!basicInfo?.recordIsLocked
);

export const getCompleteStatus = createSelector(
  getHasCompletedWelcome,
  getHasCompletedPersonalProfile,
  getHasCompletedIncomeVerification,
  getHasCompletedCreditVerification,
  getHasCompletedUploadDocuments,
  getHasCompletedMortgageInformation,
  getHasSelectedProduct,
  (
    hasCompletedWelcome,
    hasCompletedPersonalProfile,
    hasCompletedIncomeVerification,
    hasCompletedCreditVerification,
    hasCompletedUploadDocuments,
    hasCompletedMortgageInformation,
    hasSelectedLender
  ): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: hasCompletedWelcome,
      [NavStep.PERSONAL_PROFILE]: hasCompletedPersonalProfile,
      [NavStep.BANKING]: hasCompletedIncomeVerification,
      [NavStep.CREDIT]: hasCompletedCreditVerification,
      [NavStep.DOCUMENTS]: hasCompletedUploadDocuments,
      [NavStep.MORTGAGE_INFORMATION]: hasCompletedMortgageInformation,
      [NavStep.SELECT_PRODUCT]: hasSelectedLender,
    };
  }
);

export const getDataFilledOutStatus = createSelector(
  getHasCompletedWelcome,
  getHasCompletedPersonalProfile,
  getHasCompletedIncomeVerification,
  getHasCompletedCreditVerification,
  getHasCompletedUploadDocuments,
  getHasCompletedMortgageInformation,
  getHasSelectedProduct,
  (
    hasCompletedWelcome,
    hasCompletedPersonalProfile,
    hasCompletedIncomeVerification,
    hasCompletedCreditVerification,
    hasCompletedUploadDocuments,
    hasCompletedMortgageInformation,
    hasSelectedLender
  ): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: hasCompletedWelcome,
      [NavStep.PERSONAL_PROFILE]: hasCompletedPersonalProfile,
      [NavStep.BANKING]: hasCompletedIncomeVerification,
      [NavStep.CREDIT]: hasCompletedCreditVerification,
      [NavStep.DOCUMENTS]: hasCompletedUploadDocuments,
      [NavStep.MORTGAGE_INFORMATION]: hasCompletedMortgageInformation,
      [NavStep.SELECT_PRODUCT]: hasSelectedLender,
    };
  }
);

export const getOptionalStatus = createSelector(
  getIsBankingOptional,
  getIsAnyDocumentUploadRequirementsEnabled,
  (isBankingOptional, isDocumentUploadRequired): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: false,
      [NavStep.PERSONAL_PROFILE]: false,
      [NavStep.BANKING]: isBankingOptional,
      [NavStep.CREDIT]: false,
      [NavStep.DOCUMENTS]: !isDocumentUploadRequired,
      [NavStep.MORTGAGE_INFORMATION]: false,
      [NavStep.SELECT_PRODUCT]: false,
    };
  }
);

export const getHiddenStatus = createSelector(
  getIsAllDocumentUploadHidden,
  (isAllDocumentUploadHidden): Record<NavStep, boolean> => {
    return {
      [NavStep.WELCOME]: false,
      [NavStep.PERSONAL_PROFILE]: false,
      [NavStep.BANKING]: false,
      [NavStep.CREDIT]: false,
      [NavStep.DOCUMENTS]: isAllDocumentUploadHidden,
      [NavStep.MORTGAGE_INFORMATION]: false,
      [NavStep.SELECT_PRODUCT]: false,
    };
  }
);

export const getStepStatusWelcome = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  (completeStatus, optionalStatus, hasVisitedUrls): StepStatusState => {
    return {
      hidden: false,
      optional: optionalStatus[NavStep.WELCOME],
      completed: completeStatus[NavStep.WELCOME],
      visited: hasVisitedUrls([RouteUrl.LANDING_PAGE_URL, RouteUrl.SIGN_UP]),
    };
  }
);

export const getStepStatusPersonalProfile = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  (completeStatus, optionalStatus, hasVisitedUrls): StepStatusState => {
    return {
      hidden: false,
      optional: optionalStatus[NavStep.PERSONAL_PROFILE],
      completed: completeStatus[NavStep.PERSONAL_PROFILE],
      visited: hasVisitedUrls([
        RouteUrl.BASIC_INFO_URL_EMPLOYMENT,
        RouteUrl.BASIC_INFO_URL_ADDRESS,
      ]),
    };
  }
);

export const getStepStatusBanking = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  (completeStatus, optionalStatus, hasVisitedUrls): StepStatusState => {
    let visited = [
      RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT,
      RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO,
    ];
    if (
      getIsProviderConfigurationValueEnabled(
        ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
      )
    ) {
      visited = [RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO];
    }
    return {
      hidden: false,
      optional: optionalStatus[NavStep.BANKING],
      completed: completeStatus[NavStep.BANKING],
      visited: hasVisitedUrls(visited),
    };
  }
);
export const getStepStatusCredit = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedAnyUrls,
  (completeStatus, optionalStatus, hasVisitedUrls): StepStatusState => {
    let visited = [
      RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT,
      RouteUrl.BASIC_INFO_URL_SIN_FORM,
    ];
    if (
      getIsProviderConfigurationValueEnabled(
        ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
      )
    ) {
      visited = [RouteUrl.BASIC_INFO_URL_SIN_FORM];
    }
    return {
      hidden: false,
      optional: optionalStatus[NavStep.CREDIT],
      completed: completeStatus[NavStep.CREDIT],
      visited: hasVisitedUrls(visited),
    };
  }
);
export const getStepStatusUploadDocuments = createSelector(
  getIsAllDocumentUploadHidden,
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedUrl,
  (isAllDocumentUploadHidden, completeStatus, optionalStatus, hasVisitedUrl): StepStatusState => {
    return {
      hidden: isAllDocumentUploadHidden,
      optional: optionalStatus[NavStep.DOCUMENTS],
      completed: completeStatus[NavStep.DOCUMENTS],
      visited: hasVisitedUrl(RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS),
    };
  }
);
export const getStepStatusMortgageInformation = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedUrl,
  getIsPrimary,
  (completeStatus, optionalStatus, hasVisitedUrl, isPrimary): StepStatusState => {
    return {
      hidden: !isPrimary,
      optional: optionalStatus[NavStep.MORTGAGE_INFORMATION],
      completed: completeStatus[NavStep.MORTGAGE_INFORMATION],
      visited: hasVisitedUrl(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION),
    };
  }
);

export const getStepStatusSelectProduct = createSelector(
  getCompleteStatus,
  getOptionalStatus,
  getHasVisitedUrl,
  getIsPrimary,
  (completeStatus, optionalStatus, hasVisitedUrl, isPrimary): StepStatusState => {
    return {
      hidden: !isPrimary,
      optional: optionalStatus[NavStep.SELECT_PRODUCT],
      completed: completeStatus[NavStep.SELECT_PRODUCT],
      visited: hasVisitedUrl(RouteUrl.PRODUCTS_SELECT_URL),
    };
  }
);
