type KT = string | number | symbol;

export function allMatch<K extends KT, V>(map: Record<K, V>, value: V): boolean {
  return Array.from(Object.values(map))?.every((entry) => entry === value);
}

export function anyMatch<K extends KT, V>(map: Record<K, V>, value: V): boolean {
  return Array.from(Object.values(map))?.some((entry) => entry === value);
}

export function anyMatchCollection<K extends KT, V>(map: Record<K, V>, values: V[]): boolean {
  return Array.from(Object.values(map))?.some((entry) => values.includes(entry as V));
}
