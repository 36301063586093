import authClient, {storeTokens} from "components/utils/OktaAuth/OktaAuth";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {authorizationCodeCallback, signOut} from "store/actions/auth.action";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {
  fetchAuthorizedUserOperation,
  fetchProviderConfigurationsOperation,
  fetchThemeOperation,
  fetchUserApiOperation,
  signOutOperation,
} from "store/operation/operations";
import {
  getConsolidatedMortgageProviderId,
  getBasicInfoOrEmpty,
  getBrokerCode,
} from "store/selectors/basicInfo";
import {getHasUserAccountAuthorized, getUserId} from "store/selectors/user";
import {WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {getNextStepUrl} from "util/routeUtil";
import {onInitialize as initializeNavbar} from "components/molecules/NavigationBar/NavigationBar.saga";
export function* onSignOut() {
  // clears all local user data: okta, auth state, user account
  yield call(signOutOperation.saga);
}

export function* onAuthorizationCodeCallback({payload: {navigate}}: WithPayload<WithNavigate>) {
  if (authClient.isLoginRedirect()) {
    try {
      yield call(storeTokens);
    } catch (e) {
      navigate && navigate(RouteUrl.SIGN_IN);
      return;
    }

    // if BE stores where user were, we can redirect properly
    yield call(fetchAuthorizedUserOperation.saga);
    yield call(fetchUserApiOperation.saga);
    const userId: string = yield select(getUserId);
    yield call(fetchBasicInfoByUserIdOperation.saga, userId);
    const brokerCode: string = yield select(getBrokerCode);
    yield call(fetchThemeOperation.saga, brokerCode);
    const mortgageProviderId: string = yield select(getConsolidatedMortgageProviderId);
    yield call(fetchProviderConfigurationsOperation.saga, mortgageProviderId);
    const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getBasicInfoOrEmpty);
    yield call(initializeNavbar);

    navigate(getNextStepUrl(basicInfo));
    return;
  }
  const hasUserAccountAuthorized: boolean = yield select(getHasUserAccountAuthorized);
  if (!hasUserAccountAuthorized) {
    navigate && navigate(RouteUrl.SIGN_IN);
  }
}

export default function* authSaga() {
  yield takeEvery(signOut, onSignOut);
  yield takeEvery(authorizationCodeCallback, onAuthorizationCodeCallback);
}
