import {equalIgnoreCase} from "components/utils/stringUtil";
import {call, select, takeEvery} from "redux-saga/effects";
import {submitPropertyInformationOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {
  getLoanTypeConsolated,
  getPotentialAddressMatchesOrEmpty,
  getShouldRetryAvm,
} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {PropertyAddressDto} from "types/dto/propertyAddressDto";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType} from "types/enums/loanType";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {
  initialize,
  PropertyInformationFormData,
  submitPropertyInforation,
  navigatePreviousStep,
} from "./PropertyInformation.action";

function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const loanType: LoanType = yield select(getLoanTypeConsolated);
  if (equalIgnoreCase(LoanType.PREAPPROVAL, loanType)) {
    console.warn("Redirecting to a proper page, for loanType=" + loanType);
    navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION);
  }
}

function* onSubmit({
  payload: {onSuccess, navigate, ...mortgageDetails},
}: WithPayload<PropertyInformationFormData>) {
  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitPropertyInformationOperation.saga,
    userId,
    basicInfoRequest,
    Step.PROPERTY_INFOR
  );
  const potentialAddressMatches: PropertyAddressDto[] = yield select(
    getPotentialAddressMatchesOrEmpty
  );
  const retry: boolean = yield select(getShouldRetryAvm);

  if (retry) {
    if (potentialAddressMatches.length > 0) {
      navigate && navigate(RouteUrl.UNRESOLVED_AVM_URL);
    }
    return;
  }

  if (Operation.isSuccess(result)) {
    onSuccess && onSuccess();
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION);
}

export default function* propertyInformationSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitPropertyInforation, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
