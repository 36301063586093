import {default as styles} from "./SINForm.module.scss";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import BackButton from "components/molecules/BackButton/BackButton";
import TextInput from "components/atoms/TextInput/TextInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import EquifaxLogo from "assets/images/Iconography/equifax.svg";
import Message from "components/atoms/Message/Message";
import {useDispatch, useSelector} from "react-redux";
import {getHasFeatureSin} from "store/selectors/theme";
import {
  getBasicInfo,
  getCreditReportFailedAttempts,
  getIsLocked,
  getIsSinRequired,
  getNamesConsolidated,
} from "store/selectors/basicInfo";
import {SINFormData, submit, navigatePreviousStep} from "./SINForm.action";
import {
  getIsAppInitializing,
  getOperationFailure,
  getOperationResult,
} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {useNavigate} from "react-router-dom";
import {trimOrEmpty} from "components/utils/stringUtil";
import {getFixedT} from "util/languageUtil";
import {RouteUrl} from "types/route";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {trackEvent, trackEventMemoized} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import Form from "components/atoms/Form/Form";
import StepContent from "components/organisms/StepContent/StepContent";
import Typography from "@mui/material/Typography/Typography";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./SINForm.schema";
import DatePickerControlled from "components/organisms/Form/DatePickerControlled/DatePickerControlled";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";

const fixedT = getFixedT("basicInfoSteps.sinForm");

const SINForm = ({className = ""}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const basicInfo = useSelector(getBasicInfo);
  const isAppInitializing = useSelector(getIsAppInitializing);
  const {isPending} = useSelector(getOperationResult)(OperationType.submitLegalNamesSin);
  const failureError = useSelector(getOperationFailure)(OperationType.submitLegalNamesSin);

  const locked = useSelector(getIsLocked);
  const creditReportFailedAttempts = useSelector(getCreditReportFailedAttempts);

  const hasSinFeature = useSelector(getHasFeatureSin);
  const SINRequired = useSelector(getIsSinRequired);
  const {firstName, lastName} = useSelector(getNamesConsolidated);
  const [isSkipButton, setIsSkipButton] = useState(false);

  const [formData, setFormData] = useState<SINFormData>({
    sinNumbers: ["", "", ""],
  });

  const formHook = useForm<SINFormData>({
    defaultValues: {
      legalFirstName: firstName || "",
      legalLastName: lastName || "",
      dateOfBirth: basicInfo?.dateOfBirth || "",
      sinRequired: SINRequired,
    },
    resolver: yupResolver(getSchema()),
  });

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  const [fieldError, setFieldError] = useState<string>();
  const minDate = dayjs().subtract(73, "year");
  const maxDate = dayjs(new Date()).subtract(18, "year");

  function validated() {
    const {sinNumbers} = formData;
    if (SINRequired && !(sinNumbers?.map(trimOrEmpty).join("").length === 9)) {
      setFieldError(fixedT("messages.missing", {context: "sinNumbers"})!);
      return false;
    }
    setFieldError("");
    return true;
  }

  async function submitForm(values: SINFormData) {
    if (isSkipButton) {
      trackEvent(TrackingEventType.eqClickSkipButton);
      navigate(RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS);
      return;
    }
    const isValid = validated();
    trackEvent(TrackingEventType.eqClickNextButton, `Valid info for SIN form: ${isValid}`);
    if (isValid) {
      dispatch(
        submit({
          ...formData,
          ...values,
          dateOfBirth: new Date(values?.dateOfBirth!).toISOString().split("T")[0], // QM only accepts it in this format; yyyy-mm-dd,
          navigate,
        })
      );
    }
  }

  useEffect(() => {
    // Failed to pull credit report once, allow one retry.
    if (creditReportFailedAttempts == 1 || failureError?.status == 400) {
      setFieldError(fixedT("badInputError")!);
    }

    if (failureError?.status == 500) {
      setIsSkipButton(true);
      setFieldError(fixedT("unknownError")!);
    }
  }, [failureError, creditReportFailedAttempts]);

  function handleAutoFocus(event: any): void {
    if (event?.target?.value?.length === 3) {
      event.preventDefault();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      const elem = form.elements[index + 1];
      if (elem) {
        elem.focus();
      } else {
        console.warn("Element not found");
      }
    }
  }

  function renderPersonalInformation() {
    return (
      <div className={styles.options}>
        <div className={styles.fields}>
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            label={fixedT("optionsFirstName")}
            name="legalFirstName"
            required
            disabled={locked}
            type={"text"}
          />
        </div>
        <div className={styles.fields}>
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            label={fixedT("optionsLastName")}
            name="legalLastName"
            required
            disabled={locked}
            type={"text"}
          />
        </div>
        <div className={styles.dateOfBirth}>
          <DatePickerControlled
            formHook={formHook}
            name="dateOfBirth"
            label={fixedT("inputDateOfBirth")}
            minDate={minDate}
            maxDate={maxDate}
            trackingEvent={TrackingEventType.eqChangeBirthDate}
            disabled={locked}
          />
        </div>
      </div>
    );
  }

  function renderSinNumbers() {
    if (!hasSinFeature) {
      return null;
    }

    //SINRequired means BE has no sin number, user need to provide them. Otherwise, display ***
    return (
      <div className={styles.options}>
        {/* <div className={styles.promptClass}>{fixedT("optionsSinNumber")}</div> */}
        <Typography className={styles.prompt} align="center" variant="h5">
          {fixedT("optionsSinNumber")}
        </Typography>
        <div className={styles.horizontalFields}>
          <TextInput
            className={styles.sinInput}
            disabled={!SINRequired || locked}
            placeholder={"---"}
            type={!SINRequired ? "password" : "number"}
            maxLength={3}
            onChange={(event: any) => {
              if (event?.key !== "Enter") {
                trackEventMemoized(TrackingEventType.eqChangeSIN);
                setFormData({
                  ...formData,
                  sinNumbers: [
                    event.target.value,
                    formData.sinNumbers![1],
                    formData.sinNumbers![2],
                  ],
                });
                handleAutoFocus(event);
              }
            }}
            defaultValue={SINRequired ? formData.sinNumbers![0] : "***"}
          />
          <TextInput
            className={styles.sinInput}
            disabled={!SINRequired || locked}
            placeholder={"---"}
            type={!SINRequired ? "password" : "number"}
            maxLength={3}
            onChange={(event) => {
              trackEventMemoized(TrackingEventType.eqChangeSIN);
              setFormData({
                ...formData,
                sinNumbers: [formData.sinNumbers![0], event.target.value, formData.sinNumbers![2]],
              });
              handleAutoFocus(event);
            }}
            defaultValue={SINRequired ? formData.sinNumbers![1] : "***"}
          />
          <TextInput
            className={styles.sinInput}
            disabled={!SINRequired || locked}
            placeholder={"---"}
            type={!SINRequired ? "password" : "number"}
            maxLength={3}
            onChange={(event) => {
              trackEventMemoized(TrackingEventType.eqChangeSIN);
              setFormData({
                ...formData,
                sinNumbers: [formData.sinNumbers![0], formData.sinNumbers![1], event.target.value],
              });
              handleAutoFocus(event);
            }}
            defaultValue={SINRequired ? formData.sinNumbers![2] : "***"}
          />
        </div>
      </div>
    );
  }

  const buttonText = isSkipButton ? fixedT("skipButton") : fixedT("forwardButton");

  return isAppInitializing ? null : (
    <StepContent
      className={classNames(styles.root, className)}
      headerText={fixedT("header")}
      backButton={
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.eqClickBackButton);
            dispatch(navigatePreviousStep({navigate}));
          }}
        />
      }
      nextButton={
        <SubmitButton
          isSubmitting={isPending}
          isEnabled={!isPending}
          onClick={formHook.handleSubmit(submitForm)}
          text={
            (!isPending ? buttonText : <FontAwesomeIcon icon={faSyncAlt} spin={true} />) as string
          }
        />
      }
    >
      <Form onEnter={() => formHook.handleSubmit(submitForm)}>
        <Typography className={styles.header} variant="h3" align="center">
          {fixedT("prompt")}
        </Typography>
        {renderPersonalInformation()}
        {renderSinNumbers()}
        <img src={EquifaxLogo} className={styles.logo} />
        <div className={styles.disclaimer}>{fixedT("disclaimer")}</div>
      </Form>
      <Message message={fieldError} />
    </StepContent>
  );
};

export default SINForm;
