import {createReducer} from "@reduxjs/toolkit";
import {signOutOperation} from "store/operation/operations";
import {
  fetchPrivacyPolicyOperation,
  fetchTermsOfServicePolicyOperation,
} from "store/operation/policy";
import {PolicyType} from "types/enums/policyType";
import {PolicyState} from "types/policy";

const initialState: PolicyState = {
  [PolicyType.PRIVACY]: null,
  [PolicyType.TERMS_OF_SERVICE]: null,
};

const policyReducer = createReducer<PolicyState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.success, () => initialState)
    .addCase(fetchPrivacyPolicyOperation.success, (state, {payload: policyCotent}) => ({
      ...state,
      [PolicyType.PRIVACY]: policyCotent,
    }))
    .addCase(fetchTermsOfServicePolicyOperation.success, (state, {payload: policyCotent}) => ({
      ...state,
      [PolicyType.TERMS_OF_SERVICE]: policyCotent,
    }))
);

export default policyReducer;
