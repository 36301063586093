import {Autocomplete, AutocompleteProps, TextField} from "@mui/material";
import {BaseSyntheticEvent} from "react";
import {Controller, FieldValues, UseFormReturn} from "react-hook-form";
import {Option} from "types/option";
import {TrackingEventType} from "types/enums/trackingEventType";
import {WithChildren} from "types/ui";
import {trackEventMemoized} from "util/eventUtil";

interface AutocompleteControlledProps<T extends FieldValues = any, C = any>
  extends Pick<
      AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined>,
      "disabled" | "placeholder" | "defaultValue" | "fullWidth" | "getOptionLabel"
    >,
    WithChildren {
  formHook: UseFormReturn<T, C>;
  options: Option[];
  name: string;
  required?: boolean;
  label?: string;
  triggerOnChange?: boolean; // React-hook-form workaround for dropdown change not updating the validation/UI
  trackingEvent?: TrackingEventType;
}

const AutocompleteControlled = ({
  formHook,
  triggerOnChange = false,
  trackingEvent,
  // MUI props
  name,
  options,
  label,
  placeholder,
  defaultValue = "",
  disabled = false,
  required = false,
  fullWidth = false,
  getOptionLabel,
}: AutocompleteControlledProps) => {
  const {control, trigger, getFieldState, formState} = formHook;
  const {error, invalid, isTouched} = getFieldState(name);

  const handleChange = (field: any, value: string, reason?: string) => {
    if (reason === "clear" || reason === "input" || (reason === "reset" && value !== undefined)) {
      const selectedOption = options.find(
        (option) => option.label === value || option.value === value
      );
      if (trackingEvent) trackEventMemoized(trackingEvent);

      field.onChange(selectedOption?.value || null);
      defaultValue = selectedOption?.label || null;
    }
  };

  const areOptionsEqual = (a: any, b: any) => {
    return (a?.label === b?.label && a?.value === b?.value) || a?.label === b;
  };

  const shouldDisplayError =
    (formState.submitCount > 0 && Boolean(error?.message)) ||
    (isTouched && invalid && formState.isDirty);
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({field}) => (
        <Autocomplete
          fullWidth
          options={options}
          value={defaultValue || null}
          onInputChange={(event: BaseSyntheticEvent, value: string, reason: string) => {
            handleChange(field, value, reason);
          }}
          onChange={(event: BaseSyntheticEvent, value, reason) => {
            handleChange(field, value, reason);
            if (triggerOnChange) {
              trigger();
            }
          }}
          isOptionEqualToValue={areOptionsEqual}
          getOptionLabel={getOptionLabel}
          disabled={disabled}
          renderInput={(params: object) => (
            <TextField
              {...field}
              value={field.value === null || field.value === undefined ? defaultValue : field.value}
              type={"text"}
              label={label}
              fullWidth={fullWidth}
              placeholder={placeholder}
              helperText={shouldDisplayError ? error?.message : ""}
              error={shouldDisplayError}
              onBlur={() => {
                field.onBlur();
                trigger();
              }}
              disabled={disabled}
              required={required}
              {...params}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteControlled;
