export enum IncomeType {
  SALARY = "SALARY",
  HOURLY = "HOURLY",
  HOURLY_AND_COMMISSIONS = "HOURLY_AND_COMMISSIONS",
  COMMISSIONS = "COMMISSIONS",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  PENSION = "PENSION",
  RENTAL_INCOME = "RENTAL_INCOME",
  OTHER = "OTHER",
}
