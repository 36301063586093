import {WithDataError} from "types/api";
import {Theme} from "types/theme";
import {normalizedFetchApi} from "./util";
import {camelCaseObject} from "components/utils/objectUtil";

const baseUrl = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/theme`;

export const fetchThemeApi = async (brokerCode?: string): Promise<WithDataError<Theme>> => {
  let response: WithDataError<Theme>;
  if (brokerCode) {
    response = await normalizedFetchApi(() =>
      fetch(`${baseUrl}/host/${location.host}?brokerCode=${brokerCode}`)
    );
  } else {
    response = await normalizedFetchApi(() => fetch(`${baseUrl}/host/${location.host}`));
  }
  response.data = camelCaseObject(response.data);
  return response;
};
