import {default as styles} from "./PreBankingScreen.module.scss";
import SecurityImage from "assets/images/PreBankingScreen/security.svg";
import QuestionMark from "assets/images/PreBankingScreen/question-mark.svg";
import Collaboration from "assets/images/PreBankingScreen/collaboration.svg";
import BackButton from "components/molecules/BackButton/BackButton";
import {useDispatch, useSelector} from "react-redux";
import {submitPreBankingScreen, navigatePreviousStep} from "./PreBankingScreen.action";
import {useNavigate} from "react-router-dom";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {getWhitelabelToken} from "store/selectors/whitelabel";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {getFixedT} from "util/languageUtil";
import classNames from "classnames";
import StepContent from "components/organisms/StepContent/StepContent";
import Typography from "@mui/material/Typography/Typography";

const fixedT = getFixedT("basicInfoSteps.preBankingScreen");

const PreBankingScreen = ({className = ""}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getWhitelabelToken);

  return (
    <StepContent
      className={classNames(styles.root, className)}
      headerText={fixedT("header")}
      backButton={
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.preBankingClickBackButton);
            dispatch(navigatePreviousStep({navigate}));
          }}
        />
      }
      nextButton={
        <SubmitButton
          className={styles.submitButton}
          onClick={() => {
            trackEvent(TrackingEventType.preBankingClickNextButton);
            dispatch(submitPreBankingScreen({navigate}));
          }}
          text={fixedT("forwardButton")}
        />
      }
    >
      <div className={styles.entriesContainer}>
        <Typography variant="h2" textAlign="center">
          {fixedT("prompt")}
        </Typography>
        <div className={styles.entries}>
          <div className={styles.entry}>
            <FailoverImage
              className={styles.icon}
              src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/security.svg`}
              failoverSrc={SecurityImage}
            />
            <div>{fixedT("entry1")}</div>
          </div>
          <div className={styles.entry}>
            <FailoverImage
              className={styles.icon}
              src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/collaboration.svg`}
              failoverSrc={QuestionMark}
            />
            <div>{fixedT("entry2")}</div>
          </div>
          <div className={styles.entry}>
            <FailoverImage
              className={styles.icon}
              src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/question_mark.svg`}
              failoverSrc={Collaboration}
            />
            <div>{fixedT("entry3")}</div>
          </div>
          <div className={styles.disclaimer}>{fixedT("disclaimer")}</div>
        </div>
      </div>
    </StepContent>
  );
};

export default PreBankingScreen;
