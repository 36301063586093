import {useEffect, useState} from "react";
import {default as styles} from "./EmploymentInfo.module.scss";
import Message from "components/atoms/Message/Message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {EmploymentLength} from "types/enums/employmentLength";
import {useDispatch, useSelector} from "react-redux";
import {getBasicInfoOrEmpty, getIsLocked} from "store/selectors/basicInfo";
import {acceptPrivacy, EmploymentInfoFormData, submitEmployment} from "./EmploymentInfo.action";
import {useNavigate} from "react-router-dom";
import {moneyOrZero} from "components/utils/moneyUtil";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import {IndustryType} from "types/enums/industryType";
import {EmploymentType} from "types/enums/employmentType";
import {IncomeType} from "types/enums/incomeType";
import {OccupationType} from "types/enums/occupationType";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import StepContent from "components/organisms/StepContent/StepContent";
import Form from "components/atoms/Form/Form";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./EmploymentInfo.schema";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";
import MoneyInputControlled from "components/organisms/Form/MoneyInputControlled/MoneyInputControlled";
import AutocompleteControlled from "components/organisms/Form/AutocompleteControlled/AutocompleteControlled";
import Modal from "components/molecules/Modal/Modal";
import ConsentInfo from "components/templates/ConsentInfo/ConsentInfo";

const getDropdownOptions = (map: any) => Object.keys(map).map((key) => map[key]);

const EmploymentInfo = ({className = ""}) => {
  const fixedT = getFixedT("basicInfoSteps.employmentInfo");

  const INDUSTRY_TYPE = {
    BANKING_OR_FINANCE: {
      label: fixedT("industryTypeBanking"),
      value: IndustryType.BANKING_OR_FINANCE,
    },
    CONSTRUCTION: {
      label: fixedT("industryTypeConstruction"),
      value: IndustryType.CONSTRUCTION,
    },
    EDUCATION: {
      label: fixedT("industryTypeEducation"),
      value: IndustryType.EDUCATION,
    },
    FARMING_OR_NATURAL_RESOURCES: {
      label: fixedT("industryTypeFarming"),
      value: IndustryType.FARMING_OR_NATURAL_RESOURCES,
    },
    GOVERNMENT: {
      label: fixedT("industryTypeGovernment"),
      value: IndustryType.GOVERNMENT,
    },
    HEALTH: {
      label: fixedT("industryTypeHealth"),
      value: IndustryType.HEALTH,
    },
    HIGH_TECH: {
      label: fixedT("industryTypeHighTech"),
      value: IndustryType.HIGH_TECH,
    },
    LEISURE_OR_ENTERTAINMENT: {
      label: fixedT("industryTypeLeisure"),
      value: IndustryType.LEISURE_OR_ENTERTAINMENT,
    },
    MANUFACTURING: {
      label: fixedT("industryTypeManufacturing"),
      value: IndustryType.MANUFACTURING,
    },
    RETAIL_SALES: {
      label: fixedT("industryTypeRetailSales"),
      value: IndustryType.RETAIL_SALES,
    },
    SERVICES: {
      label: fixedT("industryTypeServices"),
      value: IndustryType.SERVICES,
    },
    TRANSPORTATION: {
      label: fixedT("industryTypeTransportation"),
      value: IndustryType.TRANSPORTATION,
    },
    VARIES: {
      label: fixedT("industryTypeVaries"),
      value: IndustryType.VARIES,
    },
    OTHER: {
      label: fixedT("industryTypeOther"),
      value: IndustryType.OTHER,
    },
  };

  const EMPLOYMENT_TYPE = {
    FULL_TIME: {
      label: fixedT("employmentTypeFullTime"),
      value: EmploymentType.FULL_TIME,
    },
    SELF_EMPLOYED: {
      label: fixedT("employmentTypeSelfEmployed"),
      value: EmploymentType.SELF_EMPLOYED,
    },
    PART_TIME: {
      label: fixedT("employmentTypePartTime"),
      value: EmploymentType.PART_TIME,
    },
  };

  const EMPLOYMENT_LENGTH = {
    FEWER_THAN_2_YEARS: {
      label: fixedT("employmentLength", {context: "FEWER_THAN_2_YEARS"}),
      value: EmploymentLength.FEWER_THAN_2_YEARS,
    },
    BETWEEN_2_AND_5_YEARS: {
      label: fixedT("employmentLength", {context: "BETWEEN_2_AND_5_YEARS"}),
      value: EmploymentLength.BETWEEN_2_AND_5_YEARS,
    },
    MORE_THAN_5_YEARS: {
      label: fixedT("employmentLength", {context: "MORE_THAN_5_YEARS"}),
      value: EmploymentLength.MORE_THAN_5_YEARS,
    },
  };

  const INCOME_TYPE = {
    SALARY: {
      label: fixedT("incomeTypeSalary"),
      value: IncomeType.SALARY,
    },
    HOURLY: {
      label: fixedT("incomeTypeHourly"),
      value: IncomeType.HOURLY,
    },
    HOURLY_AND_COMMISSIONS: {
      label: fixedT("incomeTypeHourlyCommissions"),
      value: IncomeType.HOURLY_AND_COMMISSIONS,
    },
    COMMISSIONS: {
      label: fixedT("incomeTypeCommissions"),
      value: IncomeType.COMMISSIONS,
    },
    SELF_EMPLOYED: {
      label: fixedT("incomeTypeSelfEmployed"),
      value: IncomeType.SELF_EMPLOYED,
    },
    PENSION: {
      label: fixedT("incomeTypePension"),
      value: IncomeType.PENSION,
    },
    RENTAL_INCOME: {
      label: fixedT("incomeTypeRentalIncome"),
      value: IncomeType.RENTAL_INCOME,
    },
    OTHER: {
      label: fixedT("incomeTypeOther"),
      value: IncomeType.OTHER,
    },
  };

  const OCCUPATION_TYPE = {
    MANAGER: {
      label: fixedT("OccupationTypeManager"),
      value: OccupationType.MANAGER,
    },
    CLERICAL: {
      label: fixedT("OccupationTypeClerical"),
      value: OccupationType.CLERICAL,
    },
    LABOURER_OR_TRADESPERSON: {
      label: fixedT("OccupationTypeLabourerTradesperson"),
      value: OccupationType.LABOURER_OR_TRADESPERSON,
    },
    RETIRED: {
      label: fixedT("OccupationTypeRetired"),
      value: OccupationType.RETIRED,
    },
    PROFESSIONAL: {
      label: fixedT("OccupationTypeProfessional"),
      value: OccupationType.PROFESSIONAL,
    },
    SELF_EMPLOYED: {
      label: fixedT("OccupationTypeSelfEmployed"),
      value: OccupationType.SELF_EMPLOYED,
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicInfo = useSelector(getBasicInfoOrEmpty);
  const locked = useSelector(getIsLocked);

  const [modal, setModal] = useState<any | undefined>();
  const [error, setError] = useState<any>();
  const {isPending, failure: submissionFailure} = useSelector(getOperationResult)(
    OperationType.submitEmploymentInfo
  );

  const formHook = useForm<EmploymentInfoFormData>({
    defaultValues: {
      ...basicInfo?.employmentHistory,
      grossIncome: moneyOrZero(basicInfo?.grossIncome?.amount),
    },
    resolver: yupResolver(getSchema()),
  });

  async function getConsentInfo(): Promise<any> {
    setModal(
      <Modal
        onCloseRequest={() => setModal(undefined)}
        closeOnBackdropClick={false}
        closeOnOutsideClick={false}
      >
        <ConsentInfo
          handleAgree={() => {
            trackEvent(TrackingEventType.personalProfileConsentAgree);
            dispatch(acceptPrivacy({onSuccess: () => setModal(undefined)}));
          }}
        />
      </Modal>
    );
  }

  useEffect(() => {
    if (!basicInfo?.acceptedPrivacyPolicyAt) {
      getConsentInfo();
    }
  }, [basicInfo?.acceptedPrivacyPolicyAt]);

  async function onSubmit(values: EmploymentInfoFormData): Promise<void> {
    trackEvent(TrackingEventType.employmentInfoClickNextButton);

    dispatch(
      submitEmployment({
        ...values,
        navigate,
      })
    );
  }

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  return (
    <StepContent
      className={classNames(styles.root, className)}
      headerText={fixedT("header")}
      nextButton={
        <SubmitButton
          isSubmitting={isPending}
          isEnabled={!isPending}
          onClick={formHook.handleSubmit(onSubmit)}
          text={
            (!isPending ? (
              fixedT("forwardButton")
            ) : (
              <FontAwesomeIcon icon={faSyncAlt} spin={true} />
            )) as string
          }
        />
      }
    >
      <Form className={styles.content} onEnter={() => formHook.handleSubmit(onSubmit)}>
        <TextFieldControlled
          fullWidth
          required
          formHook={formHook}
          name="employer"
          label={fixedT("optionsLabelEmployer")}
          disabled={locked}
          trackingEvent={TrackingEventType.employmentInfoEditEmployer}
        />
        <TextFieldControlled
          fullWidth
          required
          formHook={formHook}
          name="jobTitle"
          label={fixedT("optionsLabelJobTitle")}
          disabled={locked}
          trackingEvent={TrackingEventType.employmentInfoEditJobTitle}
        />
        <AutocompleteControlled
          fullWidth
          required
          triggerOnChange
          name="industryType"
          disabled={locked}
          options={getDropdownOptions(INDUSTRY_TYPE)}
          label={fixedT("optionsLabelIndustryType")}
          defaultValue={
            INDUSTRY_TYPE[formHook.getValues().industryType! as IndustryType]?.label || ""
          }
          formHook={formHook}
        />

        <MoneyInputControlled
          required
          formHook={formHook}
          name="grossIncome.amount"
          label={fixedT("optionsLabelGrossIncome")}
          disabled={locked}
          className={styles.grossIncome}
          trackingEvent={TrackingEventType.employmentInfoEditGrossIncome}
        />
        <AutocompleteControlled
          fullWidth
          required
          triggerOnChange
          name="occupationType"
          disabled={locked}
          options={getDropdownOptions(OCCUPATION_TYPE)}
          defaultValue={
            OCCUPATION_TYPE[formHook?.getValues().occupationType! as OccupationType]?.label || ""
          }
          label={fixedT("optionsLabelOccupationType")}
          formHook={formHook}
        />
        <AutocompleteControlled
          fullWidth
          required
          triggerOnChange
          name="incomeType"
          disabled={locked}
          options={getDropdownOptions(INCOME_TYPE)}
          defaultValue={INCOME_TYPE[formHook?.getValues().incomeType! as IncomeType]?.label || ""}
          label={fixedT("optionsLabelIncomeType")}
          formHook={formHook}
        />
        <AutocompleteControlled
          fullWidth
          required
          triggerOnChange
          name="employmentType"
          disabled={locked}
          options={getDropdownOptions(EMPLOYMENT_TYPE)}
          defaultValue={EMPLOYMENT_TYPE[formHook?.getValues().employmentType!]?.label || ""}
          label={fixedT("optionsLabelEmploymentType")}
          formHook={formHook}
        />
        <AutocompleteControlled
          fullWidth
          required
          triggerOnChange
          name="duration"
          disabled={locked}
          options={getDropdownOptions(EMPLOYMENT_LENGTH)}
          defaultValue={
            EMPLOYMENT_LENGTH[formHook?.getValues().duration! as EmploymentLength]?.label || ""
          }
          label={fixedT("optionsLabelDuration")}
          formHook={formHook}
        />
        {formHook?.getValues("duration") == EmploymentLength.FEWER_THAN_2_YEARS && (
          <>
            <TextFieldControlled
              fullWidth
              required
              formHook={formHook}
              name="previousEmployerName"
              label={fixedT("optionsLabelPreviousEmployer")}
              disabled={locked}
              trackingEvent={TrackingEventType.employmentInfoEditPreviousEmployer}
              defaultValue={formHook?.getValues().previousEmployerName || ""}
            />
            <AutocompleteControlled
              fullWidth
              required
              triggerOnChange
              name="previousEmploymentLength"
              disabled={locked}
              options={getDropdownOptions(EMPLOYMENT_LENGTH)}
              defaultValue={
                EMPLOYMENT_LENGTH[
                  formHook?.getValues().previousEmploymentLength! as EmploymentLength
                ]?.label || ""
              }
              label={fixedT("optionsLabelPreviousTime")}
              formHook={formHook}
            />
          </>
        )}
      </Form>
      <Message message={error} />
      <Message message={submissionFailure} />
      {modal}
    </StepContent>
  );
};

export default EmploymentInfo;
