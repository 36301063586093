import {Province} from "types/enums/province";

export function toProvince(provinceOrCode?: string): Province | undefined {
  const provinceUpper = provinceOrCode?.toUpperCase();
  switch (provinceUpper) {
    case "ONTARIO":
    case "ON":
      return Province.ONTARIO;
    case "QUEBEC":
    case "QUÉBEC":
    case "QC":
      return Province.QUEBEC;
    case "NS":
    case "NOVA SCOTIA":
      return Province.NOVA_SCOTIA;
    case "NB":
    case "NEW BRUNSWICK":
      return Province.NEW_BRUNSWICK;
    case "MB":
    case "MANITOBA":
      return Province.MANITOBA;
    case "BC":
    case "BRITISH COLUMBIA":
      return Province.BRITISH_COLUMBIA;
    case "PE":
    case "PRINCE EDWARD ISLAND":
      return Province.PRINCE_EDWARD_ISLAND;
    case "SK":
    case "SASKATCHEWAN":
      return Province.SASKATCHEWAN;
    case "AB":
    case "ALBERTA":
      return Province.ALBERTA;
    case "NL":
    case "NEWFOUNDLAND AND LABRADOR":
      return Province.NEWFOUNDLAND_AND_LABRADOR;
    case "NT":
    case "NORTHWEST TERRITORIES":
      return Province.NORTHWEST_TERRITORIES;
    case "YT":
    case "YUKON":
      return Province.YUKON;
    case "NU":
    case "NUNAVUT":
      return Province.NUNAVUT;
    default:
      console.warn("Unknown province", provinceOrCode);
      return undefined;
  }
}
