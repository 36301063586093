import {Province} from "./enums/province";

export const allProvinces: Province[] = Object.values(Province).sort();

export const PROVINCE_LIST = [
  Province.ONTARIO,
  Province.QUEBEC,
  Province.NOVA_SCOTIA,
  Province.NEW_BRUNSWICK,
  Province.MANITOBA,
  Province.BRITISH_COLUMBIA,
  Province.PRINCE_EDWARD_ISLAND,
  Province.SASKATCHEWAN,
  Province.ALBERTA,
  Province.NEWFOUNDLAND_AND_LABRADOR,
  Province.NORTHWEST_TERRITORIES,
  Province.YUKON,
  Province.NUNAVUT,
];

export const ProvinceCode: Record<Province, string> = {
  [Province.ALBERTA]: "AB",
  [Province.BRITISH_COLUMBIA]: "BC",
  [Province.MANITOBA]: "MB",
  [Province.NEWFOUNDLAND_AND_LABRADOR]: "NL",
  [Province.NEW_BRUNSWICK]: "NB",
  [Province.NOVA_SCOTIA]: "NS",
  [Province.NUNAVUT]: "NU",
  [Province.NORTHWEST_TERRITORIES]: "NT",
  [Province.ONTARIO]: "ON",
  [Province.PRINCE_EDWARD_ISLAND]: "PE",
  [Province.QUEBEC]: "QC",
  [Province.SASKATCHEWAN]: "SK",
  [Province.YUKON]: "YT",
  [Province.NULL]: "", // The default for Province in forms. This is so google autocomplete will properly update. We will never send this
};
