import {ApiError} from "./ApiError";
import {WritableError} from "./operation";

export type ErrorType = ApiError | Error | WritableError | string | any;

export const UNKNOWN_ERROR = "Unknown error";

export const UNKNOWN_API_ERROR = "Unknown api error";

export const UNKNOWN_FETCH_API_ERROR = "Unknown fetch api error";

export const UNKNOWN_AXIOS_API_ERROR = "Unknown axios api error";

export const UNKNOWN_AUTH_ERROR = "Unknown auth error";
