import LogoBox from "components/atoms/LogoBox/LogoBox";
import React from "react";
import {root, selected as selectedClass} from "./LenderDisplay.module.scss";
import {classNames} from "../../utils/StringFormatter/StringFormatter";

interface LenderDisplayProps {
  iconPath?: string;
  backgroundColor?: string;
  organizationName?: string;
  productName?: string;
  isEnabled?: boolean;
  selected?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

const LenderDisplay = ({
  iconPath = "",
  backgroundColor = "128, 128, 128",
  organizationName = "",
  productName = "",
  isEnabled = true,
  selected = false,
  onClick = (e?: React.MouseEvent<HTMLDivElement>) => {},
  ...otherProperties
}: LenderDisplayProps) => {
  let onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13) {
      onClick();
    }
  };

  return (
    <div
      className={classNames(root, selected ? selectedClass : "not-selected")}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (isEnabled) onClick(e);
      }}
      disabled={!isEnabled}
      onKeyDown={onKeyDownHandler}
      tabIndex={0}
      {...otherProperties}
    >
      <LogoBox
        iconPath={iconPath}
        backgroundColor={backgroundColor}
        fallbackText={productName || organizationName}
      />
    </div>
  );
};

export default LenderDisplay;
