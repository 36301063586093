import {WithNavigate} from "./../../../types/ui";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {LanguageType} from "types/enums/languageType";

const namespace: string = "LanguagesToggle";

export interface ChangeLanguagePayload extends WithNavigate {
  locale: string | LanguageType;
}

export const changeLanguage = createAction<ChangeLanguagePayload>(
  actionTypeOf(namespace, "changeLanguage")
);
