import {PINCH_MORTGAGE_PROVIDER} from "appConstants";
import {likeNull, lowerAndUnderscoreRemoved} from "components/utils/stringUtil";
import {nullable, QueryParamKeys, UTM} from "types/basic";

export function getUtm(): UTM {
  return {
    [QueryParamKeys.BROKER_CODE]: localStorage.getItem(QueryParamKeys.BROKER_CODE),
    [QueryParamKeys.UTM_CAMPAIGN]: localStorage.getItem(QueryParamKeys.UTM_CAMPAIGN),
    [QueryParamKeys.UTM_CONTENT]: localStorage.getItem(QueryParamKeys.UTM_CONTENT),
    [QueryParamKeys.UTM_MEDIUM]: localStorage.getItem(QueryParamKeys.UTM_MEDIUM),
    [QueryParamKeys.UTM_SOURCE]: localStorage.getItem(QueryParamKeys.UTM_SOURCE),
    [QueryParamKeys.UTM_TERM]: localStorage.getItem(QueryParamKeys.UTM_TERM),
    [QueryParamKeys.INTEGRATION_USER_ID]: localStorage.getItem(QueryParamKeys.INTEGRATION_USER_ID),
  };
}

export function extractSupportedQueryParams(
  urlSearchQueryString: string
): Record<QueryParamKeys, string> {
  const lowerKeyParams: Record<string, string> = {};
  const params = new URLSearchParams(urlSearchQueryString);
  params.forEach((value, key) => {
    lowerKeyParams[lowerAndUnderscoreRemoved(key)] = value;
  });

  return Object.values(QueryParamKeys).reduce(
    (prevMap: Record<string, string>, supportedKey: QueryParamKeys) => {
      prevMap[supportedKey] =
        lowerKeyParams[lowerAndUnderscoreRemoved(supportedKey)] || prevMap[supportedKey];
      return prevMap;
    },
    {}
  );
}

export function storeUtm(): void {
  try {
    const extractedParams: Record<string, string> = extractSupportedQueryParams(
      window.location.search
    );

    for (let key in extractedParams) {
      if (extractedParams[key] != null) {
        // only apply when value found
        localStorage.setItem(key, extractedParams[key]);
      }
    }
  } catch (e) {
    console.error("Error occurred when storing utm: ", e);
  }
}

export function getIntegrationId(): nullable<string> {
  const integrationUserId = localStorage.getItem(QueryParamKeys.INTEGRATION_USER_ID);
  return likeNull(integrationUserId) ? null : integrationUserId;
}

export function setLocalMortgageProvider(mortgageProviderId: string): void {
  // todo: use redux, then deprecate this
  localStorage.setItem(PINCH_MORTGAGE_PROVIDER, mortgageProviderId);
}

export function getReferralCode(): nullable<string> {
  return localStorage.getItem(QueryParamKeys.REFERRAL_CODE);
}

export function getSelectedProvince(): nullable<string> {
  return localStorage.getItem(QueryParamKeys.SELECTED_PROVINCE);
}

export function getReferrer(): nullable<string> {
  return localStorage.getItem(QueryParamKeys.REFERRER);
}

export function storeReferrer(referrer: nullable<string>): void {
  let ref = referrer || document.referrer;

  // Only store external referrers. Ignore referrers from our own app (e.g. from sign in page)
  try {
    const referrerUrl = new URL(ref);
    if (referrerUrl.origin !== window.location.origin) {
      localStorage.setItem(QueryParamKeys.REFERRER, ref);
    }
  } catch (e) {
    return;
  }
}

export function hasVisited(): boolean {
  return localStorage.getItem("hasVisited") != null;
}

export function setHasVisited(val: boolean) {
  if (val) {
    localStorage.setItem("hasVisited", "true");
  } else {
    localStorage.removeItem("hasVisited");
  }
}
