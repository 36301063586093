import React from "react";
import i18next from "i18next";
import {root} from "./BackButton.module.scss";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

interface ButtonProps {
  id?: string;
  children?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BackButton = ({
  onClick,
  children = i18next.t("components.molecules.backButtonBody")!,
  id = "",
}: ButtonProps) => {
  const navigate = useNavigate();

  function defaultBackBehavior(event: any) {
    event?.stopPropagation?.();
    event?.preventDefault?.();
    navigate(-1);
  }

  return (
    <Button
      id={id}
      variant="outlined"
      size="medium"
      onClick={onClick || defaultBackBehavior}
      className={root}
    >
      {children}
    </Button>
  );
};

export default BackButton;
