import {fetchQualificationStatusApi, fetchLendersApi} from "api/qualification";
import {ResidentialApplicationQualificationsResponse} from "types/dto/residentialApplicationQualificationsResponse";
import {OperationType} from "types/operation";
import {Operation} from "./Operation";
import {ApplicationStatusResponse} from "types/dto/ApplicationStatusResponse";

export const fetchQualificationStatusOperation = new Operation<ApplicationStatusResponse>(
  OperationType.fetchQualificationStatus,
  fetchQualificationStatusApi
);

export const fetchLendersOperation = new Operation<ResidentialApplicationQualificationsResponse[]>(
  OperationType.fetchLenders,
  fetchLendersApi
);
