import {put, select, takeEvery} from "redux-saga/effects";
import {WithPayload} from "types/basic";
import {
  HandleNewUrl,
  NavigateStep,
  handleNewUrl,
  navigateStep,
  removeNavigationError,
  initializeStatus,
  storeCurrentStep,
  storeNavigationError,
  storeUrl,
  storeStepStatusChange,
  calculateStepStatusChange,
  storeNextUrl,
} from "./NavigationBar.action";
import {
  getHasCompletedMortgageInformation,
  getCurrentStep,
  getHasCompletedStep,
  getStepStatusWelcome,
  getStepStatusBanking,
  getStepStatusCredit,
  getStepStatusMortgageInformation,
  getStepStatusPersonalProfile,
  getStepStatusSelectProduct,
  getStepStatusUploadDocuments,
} from "store/selectors/navigationController";
import {GenericFunction} from "types/functions";
import {logEmpty} from "components/utils/log";
import {MORTGAGE_PROVIDER_NOT_REQUIRED_URLS, RouteUrl} from "types/route";
import {NavStep} from "types/enums/navStep";
import {getHasUserSelectedProvider} from "store/selectors/whitelabel";
import {getHasUser} from "store/selectors/user";
import {StepStatusState} from "types/NavigationController";
import {getHasUserAcceptedDataWarning} from "store/selectors/applicantMeta";
import {
  acceptMissingDataWarning,
  openUserAcceptedDataWarningModal,
} from "pages/BasicInfoSteps/SelfReportedAssetsLiabilities/SelfReportedAssetsLiabilities.action";

export function* onInitialize() {
  yield put(removeNavigationError());
  yield put(
    initializeStatus({
      [NavStep.WELCOME]: (yield select(getStepStatusWelcome)) as StepStatusState,
      [NavStep.PERSONAL_PROFILE]: (yield select(getStepStatusPersonalProfile)) as StepStatusState,
      [NavStep.BANKING]: (yield select(getStepStatusBanking)) as StepStatusState,
      [NavStep.CREDIT]: (yield select(getStepStatusCredit)) as StepStatusState,
      [NavStep.DOCUMENTS]: (yield select(getStepStatusUploadDocuments)) as StepStatusState,
      [NavStep.MORTGAGE_INFORMATION]: (yield select(
        getStepStatusMortgageInformation
      )) as StepStatusState,
      [NavStep.SELECT_PRODUCT]: (yield select(getStepStatusSelectProduct)) as StepStatusState,
    })
  );
}

export function* onChangeNavigatationStep({
  payload: {url, navigate, providerNotChosenError, userNotCreatedError},
}: WithPayload<NavigateStep>): any {
  logEmpty(navigate);
  const hasCompletedWelcomeStep: boolean = ((yield select(getHasCompletedStep)) as GenericFunction)(
    NavStep.WELCOME
  );
  const hasUserSelectedProvider: boolean = yield select(getHasUserSelectedProvider);
  const hasUser: boolean = yield select(getHasUser);
  const currentUrl = window.location.pathname;
  const hasUserAcceptedDataWarning: boolean = yield select(getHasUserAcceptedDataWarning);

  if (hasCompletedWelcomeStep || MORTGAGE_PROVIDER_NOT_REQUIRED_URLS.includes(url as any)) {
    if (
      currentUrl === RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT &&
      !hasUserAcceptedDataWarning
    ) {
      yield put(acceptMissingDataWarning(false));
      yield put(openUserAcceptedDataWarningModal(true));
      yield put(storeNextUrl(url));
    } else {
      navigate(url); // let them navigate to page
    }
  } else if (!hasUserSelectedProvider) {
    console.warn("Provider needs to be selected");
    yield put(storeNavigationError(providerNotChosenError));
    currentUrl !== RouteUrl.LANDING_PAGE_URL && navigate(RouteUrl.LANDING_PAGE_URL);
  } else if (!hasUser) {
    console.warn("User account needs to be created");
    yield put(storeNavigationError(userNotCreatedError));
    currentUrl !== RouteUrl.SIGN_UP && navigate(RouteUrl.SIGN_UP);
  } else {
    console.warn("Redirecting to landing page to complete welcome step!");
    navigate(RouteUrl.LANDING_PAGE_URL);
  }
}

export function* onChangeUrl({payload: {step, url}}: WithPayload<HandleNewUrl>) {
  const previousStep: NavStep = yield select(getCurrentStep);
  // checks step completion when user changes steps
  if (previousStep !== step) {
    yield put(storeCurrentStep(step));
    yield put(calculateStepStatusChange(previousStep));
  }

  yield put(storeUrl(url));
}

export function* onUpdateStepStatus({payload: step}: WithPayload<NavStep>) {
  switch (step) {
    case NavStep.WELCOME:
      const stepStatusWelcome: StepStatusState = yield select(getStepStatusWelcome);
      yield put(storeStepStatusChange({step, ...stepStatusWelcome}));
      break;
    case NavStep.PERSONAL_PROFILE:
      const stepStatusPersonalProfile: StepStatusState = yield select(getStepStatusPersonalProfile);
      yield put(storeStepStatusChange({step, ...stepStatusPersonalProfile}));
      break;
    case NavStep.BANKING:
      const stepStatusBanking: StepStatusState = yield select(getStepStatusBanking);
      yield put(storeStepStatusChange({step, ...stepStatusBanking}));
      break;
    case NavStep.CREDIT:
      const stepStatusCredit: StepStatusState = yield select(getStepStatusCredit);
      yield put(storeStepStatusChange({step, ...stepStatusCredit}));
      break;
    case NavStep.DOCUMENTS:
      const stepStatusUploadDocuments: StepStatusState = yield select(getStepStatusUploadDocuments);
      yield put(storeStepStatusChange({step, ...stepStatusUploadDocuments}));
      break;
    case NavStep.MORTGAGE_INFORMATION:
      const stepStatusMortgageInformation: StepStatusState = yield select(
        getStepStatusMortgageInformation
      );
      yield put(storeStepStatusChange({step, ...stepStatusMortgageInformation}));
      getHasCompletedMortgageInformation;
      break;
    case NavStep.SELECT_PRODUCT:
      const stepStatusSelectProduct: StepStatusState = yield select(getStepStatusSelectProduct);
      yield put(storeStepStatusChange({step, ...stepStatusSelectProduct}));
      break;
    default:
      console.warn("Step is undefined step=", step);
  }
}

export default function* navigationHistorySaga() {
  yield takeEvery(navigateStep, onChangeNavigatationStep);
  yield takeEvery(handleNewUrl, onChangeUrl);
  yield takeEvery(calculateStepStatusChange, onUpdateStepStatus);
}
