import {createSelector} from "@reduxjs/toolkit";
import {State} from "types/store";
import {UserState} from "types/dto/user";
import {getIsAuthorized, getAuthUserGivenName} from "store/selectors/auth";
import {toLanguageType} from "util/languageUtil";

export const get = (rootState: State): UserState => rootState.user || null;

export const getUser = createSelector(get, (user) => user);

export const getHasUser = createSelector(get, (user) => !!user);

export const getUserId = createSelector(getUser, (user) => user?.publicId);

// may or may not be authed
export const getHasUserAccount = createSelector(getUser, (user) => !!user?.publicId);

export const getHasUnverifiedUserAccount = createSelector(
  getIsAuthorized,
  getHasUserAccount,
  (isAuthorized, hasUserAccount): boolean => !isAuthorized && hasUserAccount
);

export const getHasUserAccountAuthorized = createSelector(
  getIsAuthorized,
  getHasUserAccount,
  (isAuthorized, hasUserAccount): boolean => isAuthorized && hasUserAccount
);

export const getPreferredLanguage = createSelector(getUser, (user) => toLanguageType(user?.languagePreference));

export const getFirstName = createSelector(getUser, (user) => user?.firstName);

export const getLastName = createSelector(getUser, (user) => user?.lastName);

export const getEmail = createSelector(getUser, (user) => user?.email);

export const getFirstNameConsolidated = createSelector(
  getAuthUserGivenName,
  getFirstName,
  (authUserGivenName, userFirstName) => authUserGivenName || userFirstName
);
