import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EN_CA from "assets/lang/en.json";
import FR_CA from "assets/lang/fr.json";
import {LanguageType} from "types/enums/languageType";
import {maybe} from "types/basic";
import PluralRules from "intl-pluralrules/plural-rules";
import {GenericFunction} from "types/functions";
import {isProduction} from "./envUtil";

type SupportedLocal = "en-CA" | "fr-CA";

const FALLBACK_LANGUAGE = LanguageType.EN_CA;

const LocalTypeMap: Record<LanguageType, SupportedLocal> = {
  [LanguageType.EN_CA]: "en-CA",
  [LanguageType.FR_CA]: "fr-CA",
};

export const CIMODE = "cimode";

export const LanguageTypeCaption: Record<LanguageType, string> = {
  [LanguageType.EN_CA]: "EN",
  [LanguageType.FR_CA]: "FR",
};

export const allLanguageTypes: LanguageType[] = Object.values(LanguageType).sort();
export const LANGUAGE_LOOKUP = "locale";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // https://github.com/i18next/i18next-browser-languageDetector
  .init({
    compatibilityJSON: "v3",
    fallbackLng: FALLBACK_LANGUAGE,
    debug: !isProduction(),
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: LANGUAGE_LOOKUP,
      lookupLocalStorage: LANGUAGE_LOOKUP,
      caches: ["localStorage"],
      excludeCacheFor: [CIMODE],
    },
    resources: {
      [LanguageType.EN_CA]: {
        translation: EN_CA,
      },
      [LanguageType.FR_CA]: {
        translation: FR_CA,
      },
    },
  });

export const isFrench = (language: string) => language === LanguageType.FR_CA;

export const isEnglish = (language: string) => language === LanguageType.EN_CA;

export const isValidLanguageType = (locale: string | null): boolean => {
  if (!locale) {
    return false;
  }
  return Object.values(LanguageType).includes(locale as LanguageType);
};

const LanguageTypeMap: Record<string, LanguageType> = {
  [LanguageType.EN_CA.toLowerCase()]: LanguageType.EN_CA,
  "en-ca": LanguageType.EN_CA,
  en: LanguageType.EN_CA,
  [LanguageType.FR_CA.toLowerCase()]: LanguageType.FR_CA,
  "fr-ca": LanguageType.FR_CA,
  fr: LanguageType.FR_CA,
};

export const toLanguageType = (locale: maybe<string>): LanguageType | undefined => {
  if (!locale) {
    return undefined;
  }
  const lang = LanguageTypeMap[String(locale).toLowerCase()];
  return lang;
};

export const toLanguageTypeOrDefault = (
  locale: maybe<string>,
  defaultLang: LanguageType = LanguageType.EN_CA
): LanguageType => {
  return toLanguageType(locale) || defaultLang;
};

export function getFixedT<R = any>(namespace: string): GenericFunction<any, R> {
  return i18next.getFixedT(null, null, namespace);
}

export const toLocalType = (language: LanguageType): SupportedLocal => {
  return LocalTypeMap[language];
};

export const getLocalOrFallback = (): SupportedLocal =>
  toLocalType((i18next.language as LanguageType) || FALLBACK_LANGUAGE);

export const getPluralFormatRule = (count: number): Intl.LDMLPluralRule =>
  new PluralRules(LanguageType.EN_CA).select(count);

export function toLanguageCode(lang: string): string {
  if (lang === LanguageType.FR_CA) {
    return "fr";
  }
  return "en";
}
