import {AppBar, Toolbar, IconButton, Box} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CompanyLogo from "components/atoms/CompanyLogo/CompanyLogo";
import LanguagesToggle from "components/molecules/LanguagesToggle/LanguagesToggle";
import {DRAWER_WIDTH} from "appConstants";

interface Props {
  handleDrawerToggle: () => void;
  isMobileView: boolean;
}

export default function NavigationBarTop({handleDrawerToggle, isMobileView}: Props) {
  return (
    <AppBar
      position="fixed"
      elevation={3}
      sx={{
        width: {sm: `calc(100% - ${DRAWER_WIDTH}px)`},
        ml: {sm: `${DRAWER_WIDTH}px`},
        boxShadow: {sm: "unset"},
        backgroundColor: "white",
      }}
    >
      <Toolbar sx={{justifyContent: {xs: "space-between", sm: "flex-end"}}}>
        {isMobileView && (
          <>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2}}
            >
              <MenuIcon />
            </IconButton>
            <Box>
              <CompanyLogo />
            </Box>
          </>
        )}
        <LanguagesToggle />
      </Toolbar>
    </AppBar>
  );
}
