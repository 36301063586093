import {default as styles} from "./LoadingHouseAnimation.module.scss";
import Message from "components/atoms/Message/Message";
import {useEffect} from "react";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import HomeScanner from "assets/images/Loading/home-scanner.gif";
import {useSelector} from "react-redux";
import {getWhitelabelToken} from "store/selectors/whitelabel";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import {Typography} from "@mui/material";
import classNames from "classnames";
import StepContent from "components/organisms/StepContent/StepContent";

const fixedT = getFixedT("mortgageInfoSteps.detailsComplete");

const LoadingHouseAnimation = ({className = ""}) => {
  const token = useSelector(getWhitelabelToken);
  const {failure} = useSelector(getOperationResult)(OperationType.fetchQualificationStatus);

  return (
    <StepContent
      className={classNames(styles.root, className)}
      headerText={""}
      backButton={<></>}
      nextButton={<></>}
    >
      <Message message={failure} />
      <div className={styles.loadingGifContainer}>
        <FailoverImage
          src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/mortgage_info_screen/loading.gif`}
          failoverSrc={HomeScanner}
          className={styles.loadingGif}
        />
      </div>
      <Typography align="center" variant="h1">
        {fixedT("message")}
      </Typography>
    </StepContent>
  );
};

export default LoadingHouseAnimation;
