import {createSelector} from "@reduxjs/toolkit";
import {AuthState} from "types/auth";
import {State} from "types/store";

export const get = (rootState: State): AuthState => rootState.auth || null;

export const getAuthUser = createSelector(get, (auth) => auth.user);

export const getIsAuthorized = createSelector(getAuthUser, (user) => !!user && !!user.name);

export const getAuthUserEmail = createSelector(getAuthUser, (user) => user?.email);

export const getAuthUserName = createSelector(getAuthUser, (user) => user?.name);

export const getAuthUserGivenName = createSelector(getAuthUser, (user) => user?.given_name);

export const getRecoveryTokenTransaction = createSelector(get, (auth) => auth.recoveryTransaction);

export const getProfileFromRecovery = createSelector(
  getRecoveryTokenTransaction,
  (auth) => auth?.user?.profile
);

export const getStateTokenFromRecovery = createSelector(
  getRecoveryTokenTransaction,
  (auth) => auth?.data?.stateToken
);

export const getEmailFromRecovery = createSelector(
  getProfileFromRecovery,
  (profile) => profile?.login
);
