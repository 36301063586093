import {call, select, takeEvery} from "redux-saga/effects";
import {submitLegalNamesSinOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getCreditReportError, getCreditReportFailedAttempts} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {SINFormData, submit, navigatePreviousStep} from "./SINForm.action";
import {WithNavigate} from "types/ui";

function* onSubmit({payload}: WithPayload<SINFormData>) {
  const userId: string = yield select(getUserId);
  const {legalFirstName, legalLastName, sinNumbers, navigate, dateOfBirth} = payload;
  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    legalFirstName,
    legalLastName,
    sinNo: sinNumbers!.join(""),
    dateOfBirth,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitLegalNamesSinOperation.saga,
    userId,
    basicInfoRequest,
    Step.LEGAL_NAMES_SIN
  );

  if (Operation.isSuccess(result)) {
    const creditReportFailedAttempts: number = yield select(getCreditReportFailedAttempts);
    const creditReportError: string = yield select(getCreditReportError);
    if (creditReportError && creditReportFailedAttempts == 1) {
      return;
    }
    navigate(RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS);
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO);
}

export default function* sinFormSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
