import {useState, useEffect} from "react";
import i18next from "i18next";
import {default as styles} from "./SelectLenders.module.scss";
import LenderBox from "../../../components/molecules/LenderBox/LenderBox";
import LenderInfo from "components/templates/LenderInfo/LenderInfo";
import Modal from "components/molecules/Modal/Modal";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {initialize, navigatePreviousStep, submit} from "./SelectLenders.action";
import {getLenders} from "store/selectors/qualification";
import {ResidentialApplicationQualificationsResponse} from "types/dto/residentialApplicationQualificationsResponse";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getIsAuthorized} from "store/selectors/auth";
import {formatCurrencyAmountOrElse} from "util/currencyUtil";
import {getIconDisplayMapper} from "store/selectors/configurations";
import {monthToYear} from "util/timeUtil";
import {formatAsPercentage} from "util/numberUtil";
import Page from "pages/Page/Page";
import Typography from "@mui/material/Typography/Typography";
import {Trans} from "react-i18next";
import {getFixedT} from "util/languageUtil";
import {RouteUrl} from "types/route";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import BackButton from "components/molecules/BackButton/BackButton";
import MissingStepsChecklist from "components/organisms/MissingStepsChecklist/MissingStepsChecklist";

const t = getFixedT("selectLenders");

const SelectLenders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lenders = useSelector(getLenders);
  const {failure} = useSelector(getOperationResult)(OperationType.fetchLenders);
  const [modal, setModal] = useState<any>();
  const isAuthorized = useSelector(getIsAuthorized);
  const iconDisplayMapper = useSelector(getIconDisplayMapper);

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  function renderLenders() {
    return lenders?.map((lender: ResidentialApplicationQualificationsResponse, index) => {
      const {icon, background} = iconDisplayMapper(lender);
      return (
        <LenderBox
          key={`lender-option-${index}-${lender.productPublicId}`}
          rate={formatAsPercentage(lender?.interestRate * 100)}
          mortgagePrincipal={formatCurrencyAmountOrElse(lender?.mortgagePrincipal?.amount)}
          icon={icon}
          backgroundColor={background}
          name={lender.productName}
          termInYears={monthToYear(lender.termInMonths)}
          monthlyPayment={formatCurrencyAmountOrElse(lender?.monthlyCost?.amount)}
          onClick={() => {
            trackEvent(TrackingEventType.lenderClickSeeMore);
            setModal(
              <Modal
                className={styles.selectLendersModal}
                closeOnBackdropClick={false}
                closeOnOutsideClick={false}
                onCloseRequest={() => setModal(undefined)}
              >
                <LenderInfo
                  backgroundColor={background}
                  icon={icon}
                  lenderInformation={lender}
                  onSubmit={(lender) => dispatch(submit({lender, navigate}))}
                />
              </Modal>
            );
          }}
        />
      );
    });
  }

  return (
    <Page>
      <div className={styles.root}>
        <Typography variant="h1" textAlign="center">
          {i18next.t("selectLenders.header", {context: isAuthorized ? "" : "unauthorized"})}
        </Typography>

        {!isAuthorized && (
          <div className={styles.explanation}>
            <Trans>
              {t("explanation", {
                context: "unauthorized",
              })}
              <Link to={RouteUrl.RESEND_EMAIL_URL}>{t("linkText")}</Link>
            </Trans>
          </div>
        )}

        {!!lenders && (
          <div className={styles.lenders}>
            <div className={styles.explanation}>{t("explanation")}</div>
            {renderLenders()}
          </div>
        )}

        {!lenders && <MissingStepsChecklist />}
      </div>
      {failure && (
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.selectLendersClickBack);
            dispatch(navigatePreviousStep({navigate}));
          }}
        />
      )}
      {modal}
    </Page>
  );
};

export default SelectLenders;
