import {createSelector} from "@reduxjs/toolkit";
import {BACKGROUND_COLOR_IMAGE_DEFAULT} from "appConstants";
import {maybe} from "types/basic";
import {Configuration, ConfigurationKey, ConfigurationsState} from "types/configurations";
import {ProductSummary} from "types/dto/productSummary";
import {IconDisplay} from "types/iconDisplay";
import {State} from "types/store";
import {isConfigurationValueTrue} from "util/configUtil";
import {toLanguageType} from "util/languageUtil";

export const get = (rootState: State): ConfigurationsState => rootState.whiteLabelConfigurations;

export const getIsNavigationMenuEnabled = createSelector(get, (configState) => {
  return configState?.some(
    (configuration: Configuration) =>
      configuration?.key === ConfigurationKey.WHITELABEL_NAVIGATION_MENU &&
      isConfigurationValueTrue(configuration)
  );
});

export const getIsPurchaseTimeframePageActive = createSelector(get, (configState) => {
  return configState?.some(
    (configuration: Configuration) =>
      configuration?.key === ConfigurationKey.WHITELABEL_ACTIVE_PAGES_PURCHASE_TIMEFRAME &&
      isConfigurationValueTrue(configuration)
  );
});

export const getLanguage = createSelector(get, (configState) => {
  return toLanguageType(
    configState?.find(
      (configuration: Configuration) =>
        configuration?.key === ConfigurationKey.ORGANIZATION_LANGUAGE
    )?.value
  );
});

export const getOrganizationProductStyleOverride = createSelector(get, (configrations) => {
  return configrations?.find(
    (config: Configuration) => config?.key === ConfigurationKey.ORGANIZATION_PRODUCT_STYLE_OVERRIDE
  );
});

export const getIsOrganizationProductStyleOverride = createSelector(
  getOrganizationProductStyleOverride,
  (config) => config?.value === "true"
);

export const getIconDisplayMapper = createSelector(
  getIsOrganizationProductStyleOverride,
  (isUsingProductStyle) =>
    (productSummary: maybe<ProductSummary>): IconDisplay => {
      let icon, background, text;
      if (isUsingProductStyle) {
        icon = productSummary?.productIconFileName;
        background = productSummary?.productBackgroundColor;
        text = productSummary?.productName;
      } else {
        icon = productSummary?.organizationIconFileName;
        background = productSummary?.organizationBackgroundColor;
        text = productSummary?.organizationName;
      }

      return {
        icon: icon ? `${window._env_.REACT_APP_IMGIX_DOMAIN}/${icon}` : undefined,
        background: background || BACKGROUND_COLOR_IMAGE_DEFAULT,
        text: text || undefined,
      };
    }
);

export const getIsRateDisplayed = createSelector(get, (configState) => {
  return configState?.some(
    (configuration: Configuration) =>
      configuration?.key === ConfigurationKey.WHITELABEL_LANDING_PAGE_SHOW_RATE &&
      isConfigurationValueTrue(configuration)
  );
});
