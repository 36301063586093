import {useEffect, useRef, useState} from "react";
import {
  root,
  submitButton,
  googleAddressInput,
  provincePostalCodeContainer,
  province as provinceStyle,
  postalCode as postalCodeStyle,
} from "./PropertyInformation.module.scss";
import BackButton from "components/molecules/BackButton/BackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Message from "components/atoms/Message/Message";
import i18next from "i18next";
import {Province} from "types/enums/province";
import {PROVINCE_LIST} from "types/province";
import {useDispatch, useSelector} from "react-redux";
import {
  getIsLocked,
  getLoanTypeConsolated,
  getMortgageDetailsOrEmpty,
  getShouldRetryAvm,
} from "store/selectors/basicInfo";
import {ResidentialMortgageDetailsResponse} from "types/dto/residentialMortgageDetailsResponse";
import {
  submitPropertyInforation,
  navigatePreviousStep,
  PropertyInformationFormData,
  initialize,
} from "./PropertyInformation.action";
import {PropertyType} from "types/enums/propertyType";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {useNavigate} from "react-router-dom";
import {LoanType} from "types/enums/loanType";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {InputAdornment, MenuItem, TextField} from "@mui/material";
import StepContent from "components/organisms/StepContent/StepContent";
import Form from "components/atoms/Form/Form";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./PropertyInformation.schema";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";
import MoneyInputControlled from "components/organisms/Form/MoneyInputControlled/MoneyInputControlled";
import {moneyOrZero} from "components/utils/moneyUtil";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {Autocomplete, useLoadScript} from "@react-google-maps/api";
import PoweredByGoogle from "assets/images/GoogleLogo/PoweredByGoogle.png";
import {toPropertyAddressDtoFromGoogleAddress} from "util/addressUtil";
import {googlePlacesOptions, googleLibraries} from "util/googlePlacesUtil";

const fixedT = i18next.getFixedT(null, null, "propertyInformation");

type FormError = Record<
  keyof Pick<
    ResidentialMortgageDetailsResponse,
    | "city"
    | "province"
    | "streetNo"
    | "streetName"
    | "postCode"
    | "squareFootage"
    | "condoFees"
    | "heatingCosts"
    | "propertyTaxes"
  >,
  string | number | undefined
>;

export const LOAN_TYPES: Record<
  any,
  {trackingEventBack: TrackingEventType; trackingEventNext: TrackingEventType}
> = {
  [LoanType.NEW_PURCHASE]: {
    trackingEventBack: TrackingEventType.propertyInformationNewPurchaseClickBack,
    trackingEventNext: TrackingEventType.propertyInformationNewPurchaseClickNext,
  },
  [LoanType.RENEWAL]: {
    trackingEventBack: TrackingEventType.propertyInformationRenewalClickBack,
    trackingEventNext: TrackingEventType.propertyInformationRenewalClickNext,
  },
};

const PropertyInformation = ({nextPage = () => {}, className = ""}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locked = useSelector(getIsLocked);
  const mortgageDetails = useSelector(getMortgageDetailsOrEmpty);
  const loanType = useSelector<any, LoanType | undefined>(getLoanTypeConsolated);
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitPropertyInformation
  );
  const avmRetry = useSelector(getShouldRetryAvm);
  const [fieldError, setFieldError] = useState<string>();
  const {isLoaded: isGoogleLoaded, loadError: googleError} = useLoadScript({
    googleMapsApiKey: window._env_.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: googleLibraries,
  });
  const googleAutocompeteRef = useRef<google.maps.places.Autocomplete>();

  const formHook = useForm<PropertyInformationFormData>({
    defaultValues: {
      ...mortgageDetails,
      loanType: loanType,
      condoFees: moneyOrZero(mortgageDetails?.condoFees?.amount),
      heatingCosts: moneyOrZero(mortgageDetails?.heatingCosts?.amount),
      propertyTaxes: moneyOrZero(mortgageDetails?.propertyTaxes?.amount),
      streetNo: mortgageDetails.streetNo! ?? "",
      unitNo: mortgageDetails.unitNo! ?? "",
      streetName: mortgageDetails.streetName! ?? "",
      city: mortgageDetails.city! ?? "",
      province: mortgageDetails.province ?? Province.NULL,
      postCode: mortgageDetails.postCode! ?? "",
    },
    resolver: yupResolver(getSchema()),
  });

  async function onSubmit(values: PropertyInformationFormData) {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventNext);
    }
    dispatch(
      submitPropertyInforation({
        onSuccess: nextPage,
        navigate,
        ...values,
      })
    );
  }

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  function onBack() {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventBack);
    }
    dispatch(navigatePreviousStep({navigate}));
  }

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  useEffect(() => {
    if (avmRetry) {
      setFieldError(fixedT("badInputError")!);
    }
  }, [avmRetry]);

  return (
    <StepContent
      className={classNames(root, className)}
      headerText={fixedT("title")}
      backButton={<BackButton onClick={onBack} />}
      nextButton={
        <SubmitButton
          className={submitButton}
          onClick={formHook.handleSubmit(onSubmit)}
          isEnabled={!isPending}
          text={
            (!isPending ? (
              fixedT("form.submit")
            ) : (
              <FontAwesomeIcon icon={faSyncAlt} spin={true} />
            )) as string
          }
        />
      }
    >
      <Form onEnter={() => formHook.handleSubmit(onSubmit)}>
        {isGoogleLoaded && !googleError && (
          <Autocomplete
            onLoad={(ref) => {
              googleAutocompeteRef.current = ref;
            }}
            options={googlePlacesOptions}
            onPlaceChanged={() => {
              if (!googleAutocompeteRef.current) {
                console.error("Google Autocomplete is not mounted!");
                return;
              }
              const place = googleAutocompeteRef.current.getPlace();

              if (place?.address_components) {
                const addressDto = toPropertyAddressDtoFromGoogleAddress(place?.address_components);
                const {streetNo, unitNo, streetName, city, province, postCode} = addressDto;
                formHook.setValue("streetNo", streetNo);
                formHook.setValue("unitNo", unitNo);
                formHook.setValue("streetName", streetName);
                formHook.setValue("city", city);
                formHook.setValue("province", province);
                formHook.setValue("postCode", postCode);
                formHook.trigger();
              }
            }}
          >
            <TextField
              className={googleAddressInput}
              label={fixedT("form.fields.googleAddress.label")}
              placeholder={fixedT("form.fields.googleAddress.placeholder")!}
              type="text"
              disabled={locked}
            ></TextField>
          </Autocomplete>
        )}
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          label={fixedT("form.streetNumber")}
          name="streetNo"
          required
          disabled={locked}
          type={"number"}
        />
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          label={fixedT("form.suiteNumber")}
          name="unitNo"
          disabled={locked}
          type={"text"}
          defaultValue={mortgageDetails.unitNo}
        />
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          label={fixedT("form.streetName")}
          name="streetName"
          required
          disabled={locked}
          type={"text"}
          defaultValue={mortgageDetails.streetName}
        />
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          label={fixedT("form.city")}
          name="city"
          required
          disabled={locked}
          type={"text"}
          defaultValue={mortgageDetails.city}
        />
        <div className={provincePostalCodeContainer}>
          <div className={provinceStyle}>
            <TextFieldControlled
              fullWidth
              formHook={formHook}
              label={fixedT("form.province")}
              name="province"
              required
              select
              defaultValue={mortgageDetails.province}
            >
              {PROVINCE_LIST.map((province: Province, index: any) => (
                <MenuItem key={`${province}-${index}`} value={province} tabIndex={0}>
                  {fixedT(`form.provinces.${province}`)}
                </MenuItem>
              ))}
            </TextFieldControlled>
          </div>
          <div className={postalCodeStyle}>
            <TextFieldControlled
              fullWidth
              formHook={formHook}
              label={fixedT("form.postalCode")}
              name="postCode"
              required
              disabled={locked}
            />
          </div>
        </div>
        {isGoogleLoaded && !googleError && (
          <div>
            <img src={PoweredByGoogle} alt="Powered By Google" />
          </div>
        )}
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          defaultValue={mortgageDetails?.propertyType || ""}
          label={fixedT("form.propertyType")}
          name="propertyType"
          disabled={locked}
          required
          select
        >
          <MenuItem value={PropertyType.FREEHOLD}>
            {fixedT("form.propertyTypeOptions", {context: PropertyType.FREEHOLD})}
          </MenuItem>
          <MenuItem value={PropertyType.CONDO}>
            {fixedT("form.propertyTypeOptions", {context: PropertyType.CONDO})}
          </MenuItem>
        </TextFieldControlled>
        {formHook.getValues("propertyType") === PropertyType.CONDO && (
          <MoneyInputControlled
            formHook={formHook}
            label={fixedT("form.monthlyCondoFees")}
            name="condoFees.amount"
            required={PropertyType.CONDO === formHook.getValues("propertyType")}
            disabled={locked}
            defaultValue={mortgageDetails?.condoFees?.amount}
          />
        )}
        <MoneyInputControlled
          formHook={formHook}
          required={LoanType.NEW_PURCHASE !== loanType}
          label={fixedT("form.annualPropertyTaxes")}
          name={"propertyTaxes.amount"}
          disabled={locked}
          defaultValue={mortgageDetails?.propertyTaxes?.amount}
        />
        <MoneyInputControlled
          formHook={formHook}
          required={LoanType.NEW_PURCHASE !== loanType}
          label={fixedT("form.monthlyHeatingCosts")}
          name="heatingCosts.amount"
          disabled={locked}
          defaultValue={mortgageDetails?.heatingCosts?.amount}
        />
        <TextFieldControlled
          inputProps={{inputMode: "numeric", pattern: "[0-9]*", maxLength: 6}}
          type="number"
          fullWidth
          formHook={formHook}
          required
          label={fixedT("form.squareFootage")}
          name="squareFootage"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <p>sq.ft</p>
              </InputAdornment>
            ),
          }}
          disabled={locked}
          defaultValue={mortgageDetails?.squareFootage as number}
        />
      </Form>
      <Message message={failure} />
      <Message message={fieldError} />
    </StepContent>
  );
};

export default PropertyInformation;
