import LoginForm from "components/organisms/LoginForm/LoginForm";
import Page from "pages/Page/Page";

const SignInPage = () => {
  return (
    <Page>
      <LoginForm />
    </Page>
  );
};

export default SignInPage;
