import {createAction} from "@reduxjs/toolkit";
import {MomentObjectOutput} from "moment";
import {actionTypeOf} from "store/actions/util";
import {maybe} from "types/basic";
import {ResidentialMortgageDetailsResponse} from "types/dto/residentialMortgageDetailsResponse";
import {WithNavigate, WithOnSuccess} from "types/ui";

const namespace = "MortgageType";
export interface MortgageTypeFormData
  extends Partial<ResidentialMortgageDetailsResponse>,
    Partial<MomentObjectOutput>,
    WithNavigate,
    WithOnSuccess {
  // preapproval only
  downPaymentPercentMultipled100?: maybe<number>;
}

export const initialize = createAction(actionTypeOf(namespace, "initialize"));

export const submitMortgageType = createAction<MortgageTypeFormData>(
  actionTypeOf(namespace, "submit")
);

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
