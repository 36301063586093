import {useState, useEffect} from "react";
import {
  root,
  icon as iconClass,
  prompt as promptClass,
  tooltip,
  options,
} from "./SetPasswordForm.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import Message from "components/atoms/Message/Message";
import {Navigate, useNavigate} from "react-router-dom";
import {RouteUrl} from "types/route";
import {useDispatch, useSelector} from "react-redux";
import {getProfileFromRecovery} from "store/selectors/auth";
import {SetPasswordFormPayload, initialize, submit} from "./SetPasswordForm.action";
import {
  getAllOperationFailures,
  getIsAnyOperationPending,
  getIsOperationFailure,
  getIsOperationNotStarted,
  getOperationFailure,
} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import {getWhiteLabelLogoUrl, getWhitelabelToken} from "store/selectors/whitelabel";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {HttpStatusCode} from "axios";
import {FormLabel, TextField, Typography} from "@mui/material";
import SubmitButton from "../SubmitButton/SubmitButton";
import {useForm} from "react-hook-form";
import TextFieldControlled from "../Form/TextFieldControlled/TextFieldControlled";
import Form from "components/atoms/Form/Form";
import {getSchema} from "./SetPasswordForm.schema";
import {yupResolver} from "@hookform/resolvers/yup";

const SetPasswordForm = () => {
  const dispatch = useDispatch();
  const [inputError, setInputError] = useState<string>("");
  const isActivationNotStarted = useSelector(getIsOperationNotStarted)(
    OperationType.verifyRecoveryToken
  );
  const profileFromActivation = useSelector(getProfileFromRecovery);
  const token = useSelector(getWhitelabelToken);
  const logoImgUrl = useSelector(getWhiteLabelLogoUrl);
  const failures = useSelector(getAllOperationFailures)(
    OperationType.verifyRecoveryToken,
    OperationType.setPassword
  );
  const isAnyPending = useSelector(getIsAnyOperationPending)(
    OperationType.verifyRecoveryToken,
    OperationType.setPassword
  );
  const isActivationFailure = useSelector(getIsOperationFailure)(OperationType.verifyRecoveryToken);
  const setPasswordOperationFailure = useSelector(getOperationFailure)(OperationType.setPassword);

  const t = getFixedT("components.organisms.setPasswordForm");
  const navigate = useNavigate();

  const formHook = useForm<SetPasswordFormPayload>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = (values: SetPasswordFormPayload) => {
    dispatch(submit({...values, navigate}));
  };

  useEffect(() => {
    if (setPasswordOperationFailure) {
      setInputError(t("passwordInvalid"));
    }

    if (setPasswordOperationFailure?.status === HttpStatusCode.Conflict) {
      setInputError(t("passwordReused"));
    }
  }, [setPasswordOperationFailure]);

  useEffect(() => {
    if (isActivationNotStarted) {
      const token = new URLSearchParams(window.location.search).get("token")!;
      dispatch(initialize(token));
    }
  }, [isActivationNotStarted]);

  if (isActivationFailure) {
    return <Navigate to={RouteUrl.RESEND_EMAIL_URL} />;
  }

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  return (
    <div className={root}>
      <FailoverImage
        className={iconClass}
        src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/activate_account_screen/hero.svg`}
        failoverSrc={logoImgUrl}
      />
      <div className={promptClass}>
        <Typography variant="subtitle1" gutterBottom>
          {t("prompt")}
        </Typography>
        <Typography className={tooltip} variant="h5" style={{fontWeight: "normal"}} gutterBottom>
          {t("tooltip")}
        </Typography>
      </div>
      <Message message={inputError} />
      {failures?.map(({type, failure}, index) => (
        <Message key={type} message={t(`messages.backendErrors.${type}`)} />
      ))}
      <Form className={options} onEnter={formHook.handleSubmit(onSubmit)}>
        <FormLabel htmlFor="first-name">{t("firstName")}</FormLabel>
        <TextField
          fullWidth
          name="firstName"
          value={profileFromActivation?.firstName}
          type="text"
          disabled
        />
        <FormLabel htmlFor="last-name">{t("lastName")}</FormLabel>
        <TextField
          fullWidth
          name="lastName"
          type="text"
          value={profileFromActivation?.lastName}
          disabled
        />
        <FormLabel htmlFor="email">{t("email")}</FormLabel>
        <TextField
          fullWidth
          name="email"
          type="text"
          value={profileFromActivation?.login}
          disabled
        />
        <FormLabel aria-label="password" htmlFor="password">
          {t("password")}
        </FormLabel>
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="password"
          type="password"
          disabled={isAnyPending}
          autoComplete="new-password"
        />
        <FormLabel aria-label="confirmPassword" htmlFor="confirmPassword">
          {t("confirmPassword")}
        </FormLabel>
        <TextFieldControlled
          formHook={formHook}
          fullWidth
          name="confirmPassword"
          type="password"
          disabled={isAnyPending}
        />
      </Form>
      <SubmitButton
        isEnabled={
          !isAnyPending &&
          !!formHook.getValues("password") &&
          formHook.getValues("password") === formHook.getValues("confirmPassword")
        }
        onClick={formHook.handleSubmit(onSubmit)}
        text={!isAnyPending ? t("forwardButton") : <FontAwesomeIcon icon={faSyncAlt} spin={true} />}
      />
    </div>
  );
};

export default SetPasswordForm;
