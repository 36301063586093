import {call, put, select, takeEvery} from "redux-saga/effects";
import {getUserId} from "store/selectors/user";
import {WithNavigate} from "types/ui";
import {SubmitLenderPayload, navigatePreviousStep, submit} from "./SelectLenders.action";
import {setLenderOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getIsAuthorized} from "store/selectors/auth";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {RouteUrl} from "types/route";
import {calculateStepStatusChange} from "components/molecules/NavigationBar/NavigationBar.action";
import {NavStep} from "types/enums/navStep";

function* onSubmit({payload: {navigate, lender}}: WithPayload<SubmitLenderPayload>) {
  const isAuthorized: boolean = yield select(getIsAuthorized);
  if (!isAuthorized) {
    navigate && navigate(RouteUrl.SIGN_IN);
  }

  const userId: string = yield select(getUserId);

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    setLenderOperation.saga,
    userId,
    lender.productPublicId
  );
  if (Operation.isSuccess(result)) {
    yield put(calculateStepStatusChange(NavStep.SELECT_PRODUCT));
    navigate(RouteUrl.APPLICATION_COMPLETE_URL);
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION);
}

export default function* selectLendersSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
