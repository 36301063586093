import {logEmpty} from "components/utils/log";
import {call, select, takeEvery} from "redux-saga/effects";
import {fetchProviderConfigurationsOperation} from "store/operation/operations";
import {getMortgageProviderIdFromApp} from "store/selectors/basicInfo";
import {getHasUserAccount} from "store/selectors/user";
import {WithPayload} from "types/basic";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {initialize, submit} from "./SelectProductProviderPage.action";

function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  logEmpty(navigate, "navigate");
  const hasAccount: boolean = yield select(getHasUserAccount);

  // Use the mortgage provider stored in account, for both verified and non-verified user
  if (hasAccount) {
    const mortgageProviderInApp: string = yield select(getMortgageProviderIdFromApp);
    if (mortgageProviderInApp) {
      console.info(
        "Redirecting away from landing page due to mortgageProvider found in app: " +
          mortgageProviderInApp
      );
      yield call(fetchProviderConfigurationsOperation.saga, mortgageProviderInApp);
      navigate && navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT);
      return;
    }
  }
}

export function* onSubmit({payload: mortgageProviderId}: WithPayload<string>) {
  yield call(fetchProviderConfigurationsOperation.saga, mortgageProviderId);
}

export default function* selectProductProviderPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submit, onSubmit);
}
