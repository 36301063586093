import i18next from "i18next";
import {
  root,
  container,
  consentItem,
  consentItems,
  icon,
  disclaimer,
  submitButton,
} from "./ConsentInfo.module.scss";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import BackButton from "components/molecules/BackButton/BackButton";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {useNavigate} from "react-router-dom";
import Citizen from "assets/images/BasicInfoScreen/citizen.svg";
import Bankruptcy from "assets/images/BasicInfoScreen/bankruptcy.svg";
import BuiltHome from "assets/images/BasicInfoScreen/built_home.svg";
import AgeOfMajority from "assets/images/BasicInfoScreen/age_of_majority.svg";
import {useSelector} from "react-redux";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {getWhitelabelToken} from "store/selectors/whitelabel";

interface ConsentInfoProps {
  handleAgree?: () => void;
}

const ConsentInfo = ({handleAgree = () => {}}: ConsentInfoProps) => {
  const navigate = useNavigate();
  const t = i18next.getFixedT(null, null, "components.templates.consentInfo");

  const token = useSelector(getWhitelabelToken);

  return (
    <div className={root}>
      <div className={container}>
        <h2>{t("header")}</h2>
        <div className={consentItems}>
          <div className={consentItem}>
            <div className={icon}>
              <FailoverImage
                className={"icon"}
                src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/citizen.svg`}
                failoverSrc={Citizen}
              />
            </div>
            <div>{t("consentItems.0")}</div>
          </div>
          <div className={consentItem}>
            <div className={icon}>
              <FailoverImage
                className={"icon"}
                src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/bankruptcy.svg`}
                failoverSrc={Bankruptcy}
              />
            </div>

            <div>{t("consentItems.1")}</div>
          </div>
          <div className={consentItem}>
            <div className={icon}>
              <FailoverImage
                className={"icon"}
                src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/built_home.svg`}
                failoverSrc={BuiltHome}
              />
            </div>
            <div>{t("consentItems.2")}</div>
          </div>
          <div className={consentItem}>
            <div className={icon}>
              <FailoverImage
                className={"icon"}
                src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/basic_info_screen/age_of_majority.svg`}
                failoverSrc={AgeOfMajority}
              />
            </div>

            <div>{t("consentItems.3")}</div>
          </div>
        </div>
        <div className={disclaimer}>{t("disclaimer")}</div>
      </div>

      <NavigationSection>
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.personalProfileConsentDisagree);
            navigate("/declined-terms");
          }}
        >
          {t("backButton")!}
        </BackButton>
        <SubmitButton className={submitButton} onClick={handleAgree} text={t("forwardButton")} />
      </NavigationSection>
    </div>
  );
};

export default ConsentInfo;
