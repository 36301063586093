import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {PropertyAddressDto} from "types/dto/propertyAddressDto";
import {WithNavigate} from "types/ui";

const namespace = "UnresolvedAVMPage";

export type SubmitUnresolvedAVMPagePayload = WithNavigate & Partial<PropertyAddressDto>;

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const submit = createAction<SubmitUnresolvedAVMPagePayload>(
  actionTypeOf(namespace, "submit")
);
