import {call, select, takeEvery} from "redux-saga/effects";
import {inviteSecondaryApplicantOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getIsSecondary} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {
  initialize,
  submitInviteSecondary,
  SubmitInviteSecondaryPayload,
} from "./InviteSecondaryApplicant.action";

function* onInitialize({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;

  const isSeconary: boolean = yield select(getIsSecondary);
  if (isSeconary) {
    console.info("Redirecting to BASIC_INFO_URL_EMPLOYMENT, due to current user being secondary");
    navigate && navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT);
  }
}

function* onSubmit({payload}: WithPayload<SubmitInviteSecondaryPayload>) {
  const {navigate, ...userRequest} = payload;
  const primaryUserId: string = yield select(getUserId);

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    inviteSecondaryApplicantOperation.saga,
    primaryUserId,
    userRequest
  );
  if (Operation.isSuccess(result)) {
    navigate && navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT);
  }
}

export default function* inviteSecondaryApplicantSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitInviteSecondary, onSubmit);
}
