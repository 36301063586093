import {toISODateString} from "components/utils/dateUtil";
import {equalIgnoreCase} from "components/utils/stringUtil";
import {call, select, takeEvery} from "redux-saga/effects";
import {submitMortgageTypeOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getLoanTypeConsolated} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType} from "types/enums/loanType";
import {Step} from "types/enums/step";
import {
  MortgageTypeFormData,
  submitMortgageType,
  navigatePreviousStep,
} from "./MortgageType.action";
import {WithNavigate} from "types/ui";
import {RouteUrl} from "types/route";

function* onSubmit({payload}: WithPayload<MortgageTypeFormData>) {
  const userId: string = yield select(getUserId);
  const loanType: string = yield select(getLoanTypeConsolated);

  let {onSuccess, downPaymentPercentMultipled100, ...mortgageDetails} = payload;

  if (equalIgnoreCase(LoanType.PREAPPROVAL, loanType)) {
    mortgageDetails = {
      ...mortgageDetails,
      downPaymentPercent: downPaymentPercentMultipled100! / 100,
    };
  }

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitMortgageTypeOperation.saga,
    userId,
    basicInfoRequest,
    Step.MORTGAGE_TYPE
  );
  if (Operation.isSuccess(result)) {
    onSuccess && onSuccess();
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  const loanType: string = yield select(getLoanTypeConsolated);
  if (equalIgnoreCase(LoanType.PREAPPROVAL, loanType)) {
    navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION);
  } else if (
    equalIgnoreCase(LoanType.RENEWAL, loanType) ||
    equalIgnoreCase(LoanType.NEW_PURCHASE, loanType)
  ) {
    navigate(RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO);
  }
}

export default function* mortgageTypeSaga() {
  yield takeEvery(submitMortgageType, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
