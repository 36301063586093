import {useTheme} from "@mui/material";
import {useSelector} from "react-redux";
import useOnResize from "components/hooks/useOnResize/useOnResize";
import {getHeaderLink} from "store/selectors/theme";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import {default as styles} from "./CompanyLogo.module.scss";
import {getWhiteLabelLogoUrl} from "store/selectors/whitelabel";

interface CompanyLogoProps {}

const CompanyLogo = ({}: CompanyLogoProps) => {
  const theme = useTheme();
  const {isScreenMobileWidth} = useOnResize(theme.breakpoints);
  const logoImgUrl = useSelector(getWhiteLabelLogoUrl);
  const headerLink = useSelector(getHeaderLink);

  return (
    <div className={isScreenMobileWidth ? styles.rootMobile : styles.root}>
      <img
        src={logoImgUrl}
        onClick={() => {
          if (headerLink) {
            trackEvent(TrackingEventType.clickHomePage);
            window.location.href = headerLink;
          }
        }}
      />
    </div>
  );
};

export default CompanyLogo;
