import {useLocation} from "react-router-dom";
import i18next from "i18next";
import keys from "assets/images/misc/keys.png";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteUrl} from "types/route";
import {root, errorPageContent, errorPageButton} from "./ErrorPage.module.scss";
import {WritableError} from "types/operation";
import {ErrorType, UNKNOWN_ERROR} from "types/error";
import {MessageStatus} from "types/MessageStatus";
import Page from "pages/Page/Page";

export interface LocationStateInterface {
  error: ErrorType;
}

const getMessageAndStatus = (error: ErrorType): MessageStatus => {
  let message: string = UNKNOWN_ERROR;
  let status: number | undefined = undefined;

  if (error) {
    if (typeof error === "string") {
      message = error;
    } else if (typeof error === "object") {
      // WritableError | ApiError
      const writableError = error as WritableError;
      if (typeof writableError?.message === "string") {
        message = writableError?.message;
      } else {
        message = writableError.message.message!;
      }

      message = message || UNKNOWN_ERROR;
      status = writableError.status;
    }
  }

  return [message, status];
};

const ErrorPage = () => {
  const location = useLocation();
  const redirectState: ErrorType = location?.state;
  const [message, status] = getMessageAndStatus(redirectState);
  const title = [status, "Error"].filter(Boolean).join(" ");

  return (
    <Page className={root}>
      <div className={errorPageContent}>
        <div>
          <h3>{title}</h3>
          <div>{message}</div>
          <a className={errorPageButton} href={RouteUrl.LANDING_PAGE_URL}>
            <FontAwesomeIcon icon={faArrowLeft} />
            {i18next.t("errorPage.buttonBack")}
          </a>
        </div>
        <div>
          <img src={keys} alt="keys" />
        </div>
      </div>
    </Page>
  );
};

export default ErrorPage;
