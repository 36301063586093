import {useEffect} from "react";
import {root, fields, forgotPassword} from "./LoginForm.module.scss";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getIsOperationPending, getOperationFailure} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {initialize, login, LoginFormPayload} from "./LoginForm.action";
import {getFixedT} from "util/languageUtil";
import SubmitButton from "../SubmitButton/SubmitButton";
import Message from "components/atoms/Message/Message";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import {Typography} from "@mui/material";
import Form from "components/atoms/Form/Form";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import TextFieldControlled from "../Form/TextFieldControlled/TextFieldControlled";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {getSchema} from "./LoginForm.schema";

const fixedT = getFixedT("components.organisms.loginForm");

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSignInPending = useSelector(getIsOperationPending)(OperationType.signIn);
  const signInFailure = useSelector(getOperationFailure)(OperationType.signIn);

  const formHook = useForm<LoginFormPayload>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = (values: LoginFormPayload) => {
    trackEvent(TrackingEventType.signInClickContinueButton);
    dispatch(login({...values, navigate}));
  };

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  return (
    <div className={root}>
      <Typography align="left" variant="h1">
        {fixedT("header")}
      </Typography>

      <div onClick={() => trackEvent(TrackingEventType.signInClickCreateAccount)}>
        <Typography
          variant="subtitle1"
          dangerouslySetInnerHTML={{__html: fixedT("subheader")}}
        ></Typography>
      </div>
      <Form className={fields} onEnter={formHook.handleSubmit(onSubmit)}>
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="email"
          type="email"
          label={fixedT("fields.email.label")}
          placeholder={fixedT("fields.email.placeholder")!}
          trackingEvent={TrackingEventType.signInEditEmail}
          disabled={isSignInPending}
          autoComplete={"current-user"}
          required
        />

        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="password"
          type="password"
          label={fixedT("fields.password.label")}
          placeholder={fixedT("fields.password.placeholder")!}
          trackingEvent={TrackingEventType.signInEditPassword}
          disabled={isSignInPending}
          autoComplete={"current-password"}
          required
        />
      </Form>
      <Typography
        onClick={() => trackEvent(TrackingEventType.signInClickResetPassword)}
        className={forgotPassword}
        dangerouslySetInnerHTML={{__html: fixedT("forgetPassword")}}
      />
      <Message message={signInFailure?.message} />
      <NavigationSection>
        <SubmitButton
          isSubmitting={isSignInPending}
          isEnabled={!isSignInPending}
          onClick={formHook.handleSubmit(onSubmit)}
          text={fixedT("forwardButton")}
        />
      </NavigationSection>
    </div>
  );
};

export default LoginForm;
