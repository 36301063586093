import {createSelector} from "@reduxjs/toolkit";
import {AVM_MAX_RETRIES} from "appConstants";
import {BasicInfoState} from "types/basicInfo";
import {Bank} from "types/dto/bank";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {ResidentialMortgageDetailsResponse} from "types/dto/residentialMortgageDetailsResponse";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import {State} from "types/store";
import {getUtm} from "util/localStorageUtil";
import {getHasFeatureSin} from "./theme";
import {getUser} from "./user";
import {getProviders, getSelectedProviderId} from "./whitelabel";
import {QueryParamKeys} from "types/basic";
import {LoanType} from "types/enums/loanType";

export const getBasicInfo = (rootState: State): BasicInfoState => rootState.basicInfo;

export const getBasicInfoOrEmpty = (rootState: State): ResidentialApplicationBasicInfoResponse =>
  rootState.basicInfo || ({} as ResidentialApplicationBasicInfoResponse);

export const getAppId = createSelector(getBasicInfo, (basicInfo) => basicInfo?.applicationPublicId);

export const getApplicantId = createSelector(
  getBasicInfo,
  (basicInfo) => basicInfo?.applicantDetailsPublicId
);

export const getIsPrimary = createSelector(getBasicInfo, (basicInfo) =>
  Boolean(basicInfo?.isPrimaryApplicant)
);

export const getIsSecondary = createSelector(
  getBasicInfo,
  (basicInfo) => Boolean(basicInfo) && Boolean(!basicInfo?.isPrimaryApplicant)
);

export const getIsDirectLender = createSelector(
  getBasicInfo,
  (basicInfo: BasicInfoState) => Boolean(basicInfo?.isDirectLender) && false // TODO: ENG-4137 - Remove the false, which was added so CMLS shows the broker flow
);

export const getAcceptedPrivacyPolicy = createSelector(
  getBasicInfo,
  (info) => !!info?.acceptedPrivacyPolicyAt
);

export const getAcceptedTermsOfService = createSelector(
  getBasicInfo,
  (info) => !!info?.acceptedTermsOfServiceAt
);

export const getNumOfBanks = createSelector(getBasicInfo, (info) => {
  return (
    Number(Boolean(info?.firstBankLoginId)) +
    Number(Boolean(info?.secondBankLoginId)) +
    Number(Boolean(info?.thirdBankLoginId))
  );
});

export const getIsFirstBankRequestDone = createSelector(getBasicInfo, (info) => {
  return info?.isFirstBankRequestDone;
});

export const getFirstBankFinancialDataId = createSelector(getBasicInfo, (info) => {
  return info?.firstBankFinancialDataPublicId;
});

export const getIsSecondBankRequestDone = createSelector(getBasicInfo, (info) => {
  return info?.isSecondBankRequestDone;
});

export const getSecondBankFinancialDataId = createSelector(getBasicInfo, (info) => {
  return info?.secondBankFinancialDataPublicId;
});

export const getIsThirdBankRequestDone = createSelector(getBasicInfo, (info) => {
  return info?.isThirdBankRequestDone;
});

export const getThirdBankFinancialDataId = createSelector(getBasicInfo, (info) => {
  return info?.thirdBankFinancialDataPublicId;
});

export const getIsBankRequestLoading = createSelector(
  getIsFirstBankRequestDone,
  getFirstBankFinancialDataId,
  getIsSecondBankRequestDone,
  getSecondBankFinancialDataId,
  getIsThirdBankRequestDone,
  getThirdBankFinancialDataId,
  (
    isFirstBankRequestDone,
    firstBankFinancialDataId,
    isSecondBankRequestDone,
    secondBankFinancialDataId,
    isThirdBankRequestDone,
    thirdBankFinancialDataId
  ) =>
    (!isFirstBankRequestDone && Boolean(firstBankFinancialDataId)) ||
    (!isSecondBankRequestDone && Boolean(secondBankFinancialDataId)) ||
    (!isThirdBankRequestDone && Boolean(thirdBankFinancialDataId))
);

export const getHasBank = createSelector(getNumOfBanks, (numOfBanks) => numOfBanks > 0);

export const getCreditReportError = createSelector(getBasicInfo, (info) => info?.creditReportError);

export const getCreditReportFailedAttempts = createSelector(
  getBasicInfo,
  (info) => info?.creditReportFailedAttempts
);

export const getIsLocked = createSelector(getBasicInfo, (info) => Boolean(info?.recordIsLocked));

export const getCanAddBank = createSelector(
  getBasicInfo,
  getIsLocked,
  getNumOfBanks,
  (info, isLocked, numOfBanks) => {
    return Boolean(info) && !isLocked && numOfBanks < 3;
  }
);

export const getBanks = createSelector(getBasicInfo, (info) => {
  return [
    {
      loginId: info?.firstBankLoginId,
      financialDataId: info?.firstBankFinancialDataPublicId,
      error: info?.firstBankRequestError,
      isDone: info?.isFirstBankRequestDone,
    },
    {
      loginId: info?.secondBankLoginId,
      financialDataId: info?.secondBankFinancialDataPublicId,
      error: info?.secondBankRequestError,
      isDone: info?.isSecondBankRequestDone,
    },
    {
      loginId: info?.thirdBankLoginId,
      financialDataId: info?.thirdBankFinancialDataPublicId,
      error: info?.thirdBankRequestError,
      isDone: info?.isThirdBankRequestDone,
    },
  ].filter((bank) => Boolean(bank.financialDataId)) as Bank[];
});

export const getIsSinRequired = createSelector(
  getHasFeatureSin,
  getBasicInfo,
  (hasFeatureSinInTheme, basicInfo) => hasFeatureSinInTheme && Boolean(basicInfo?.sinRequired)
);

export const getNamesConsolidated = createSelector(getUser, getBasicInfo, (user, basicInfo) => {
  return {
    firstName: basicInfo?.legalFirstName || user?.firstName,
    lastName: basicInfo?.legalLastName || user?.lastName,
  };
});

export const getMortgageDetails = createSelector(getBasicInfo, (info) => info?.mortgageDetails);

export const getMortgageProviderIdFromApp = createSelector(
  getMortgageDetails,
  (mortgageDetails) => mortgageDetails?.mortgageProvider
);

export const getConsolidatedMortgageProviderId = createSelector(
  getMortgageProviderIdFromApp,
  getSelectedProviderId,
  (providerInApp, providerInWhitelabelState) => providerInApp || providerInWhitelabelState
);

export const getConsolidatedMortgageProvider = createSelector(
  getConsolidatedMortgageProviderId,
  getProviders,
  (providerId, allProviders) =>
    allProviders?.find((provider) => provider?.financialInstitutionPublicId === providerId)
);

export const getConsolidatedMortgageProviderName = createSelector(
  getConsolidatedMortgageProvider,
  (provider) => provider?.organizationName
);

export const getConsolidatedMortgageProviderEmail = createSelector(
  getConsolidatedMortgageProvider,
  (provider) => provider?.financialInstitutionPrimaryEmail
);

export const getConsolidatedMortgageProviderPhoneNumber = createSelector(
  getConsolidatedMortgageProvider,
  (provider) => provider?.financialInstitutionPhoneNumber
);

export const getMortgageDetailsOrEmpty = createSelector(
  getBasicInfo,
  (info) => info?.mortgageDetails || ({} as ResidentialMortgageDetailsResponse)
);

export const getPurchaseOrRenewalPropertyAddress = createSelector(
  getMortgageDetails,
  (mortgageDetails) => {
    if (!mortgageDetails) {
      return undefined;
    }
    return {
      unitNo: mortgageDetails.unitNo,
      streetNo: mortgageDetails.streetNo,
      streetName: mortgageDetails.streetName,
      city: mortgageDetails.city,
      province: mortgageDetails.province,
      postCode: mortgageDetails.postCode,
    };
  }
);

export const getLoanTypeConsolated = createSelector(
  getMortgageDetails,
  (mortgageDetails): LoanType | undefined => mortgageDetails?.loanType
);

export const getPotentialAddressMatches = createSelector(
  getBasicInfo,
  (info) => info?.potentialAddressMatches
);

export const getPotentialAddressMatchesOrEmpty = createSelector(
  getPotentialAddressMatches,
  (matches) => matches || []
);

export const getHasAvm = createSelector(getBasicInfo, (info) => Boolean(info?.avmResultPublicId));

export const getAVMFailed = createSelector(getBasicInfo, (info) => info?.avmFailed || false);

export const getShouldRetryAvm = createSelector(
  getBasicInfo,
  (info) => info?.avmFailed && (info?.avmFailedAttempts || 0) < AVM_MAX_RETRIES
);

export const getApplicationType = createSelector(getBasicInfo, (info) => info?.applicationType);

export const getIsJointApp = createSelector(
  getApplicationType,
  (appType) => ResidentialApplicationType.JOINT_APPLICANT === appType
);

export const getIsPrimaryAndHasSecondary = createSelector(
  getBasicInfo,
  (info) => Boolean(info?.isPrimaryApplicant) && Boolean(info?.secondaryUserEmail)
);

export const getBrokerCode = createSelector(
  getBasicInfo,
  (info) => info?.brokerCode || getUtm()[QueryParamKeys.BROKER_CODE]
);

export const getContactInfo = createSelector(
  getConsolidatedMortgageProviderEmail,
  getConsolidatedMortgageProviderPhoneNumber,
  (email, phoneNumber) => {
    return {
      email: email,
      phoneNumber: phoneNumber,
    };
  }
);
