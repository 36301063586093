import React from "react";
import {root, disabled} from "./Button.module.scss";
import {classNames} from "../../utils/StringFormatter/StringFormatter";
import {preventDefaultPropagation} from "../Form/Form";

interface ButtonProps {
  isEnabled?: boolean;
  children?: string | React.ReactElement | any;
  fillColor?: string;
  textColor?: string;
  className?: string;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: string;
}

/**
 * @component ValueButton
 * @constructor
 * @description
 * A wrapper component for the base ValueButton component in React.
 * @param {Boolean} isEnabled
 * Whether this button should be allowed to click on or not.
 * Will fade the opacity down to 50%.
 * @param {React.Component|String} [children='Unlabelled']
 * The wrapped contents of the Component's elemental tag.
 * @param {(
 *   'primary-thematic-colour-100'|
 *   'primary-thematic-colour-200'|
 *   'secondary-thematic-colour-100'|
 *   'tertiary-thematic-colour-100'|
 *   'primary-background-colour-100'
 * )} [fillColor='primary-background-colour-100']
 * The color of which the background of the button should be.
 * @param {(
 *   'primary-thematic-colour-100'|
 *   'primary-thematic-colour-200'|
 *   'secondary-thematic-colour-100'|
 *   'tertiary-thematic-colour-100'|
 *   'primary-background-colour-100'
 * )} [textColor='primary-background-colour-100']
 * The color of which the foreground text of the button should be.
 * @param {String} [className]
 * Any additional classes that should be added to the button element.
 * @param {String} [id]
 * Any identified to assign to this ValueButton component output.
 * @param {*} [otherProperties]
 * All other properties will be scoped to the ValueButton parent.
 * @param {('button', 'submit', 'reset')} [type='button']
 * The type that this button should take.
 * @param {Function} [onClick]
 * The callback function to call with a Synthetic event when the button is clicked upon.
 * @returns {React.Component}
 * The ValueButton component to be rendered.
 */
const Button = ({
  isEnabled = true,
  children = "",
  fillColor = "primary-thematic-colour-100",
  textColor = "primary-background-colour-100",
  className = "",
  id = "",
  onClick = (e: React.MouseEvent<HTMLButtonElement>) => {},
  type = "button",
}: ButtonProps) => {
  const handleOnClick = (event: any) => {
    preventDefaultPropagation(event);
    onClick(event);
  };
  return (
    <button
      id={id}
      className={classNames(
        root,
        className,
        `fill-${fillColor}`,
        `text-${textColor}`,
        isEnabled ? "enabled" : disabled
      )}
      onClick={(e) => handleOnClick(e)}
      disabled={!isEnabled}
    >
      {children}
    </button>
  );
};

export default Button;
