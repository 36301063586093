import {UUID} from "types/uuid";

export enum EntityType {
  ORGANIZATION = "ORGANIZATION",
  THEME = "THEME",
  PRODUCT_PROVIDER = "PRODUCT_PROVIDER",
  FEATURE = "FEATURE",
  WHITELABEL = "WHITELABEL",
}

export enum ConfigurationValueType {
  STRING = "STRING",
  BOOLEAN = "BOOLEAN",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
}

export interface Configuration {
  id: UUID;
  name: string;
  description: string;
  entity: EntityType;
  key: ConfigurationKey;
  value: string;
  defaultValue: string;
  valueType: ConfigurationValueType;
  isEnabled: boolean;
  isMandatory: boolean;
}

export type ConfigurationsState = Configuration[] | null;

export enum ConfigurationKey {
  ORGANIZATION_COLLABORATION = "organization.collaboration",
  ORGANIZATION_LANGUAGE = "organization.language",
  ORGANIZATION_PRODUCT_STYLE_OVERRIDE = "organization.product.style.override",
  PRODUCT_PROVIDER_DEFAULT_FEE_CONDO = "product_provider.default.fee.condo",
  ORGANIZATION_DOCUMENTS_REQUIRED_T4 = "organization.documents.required.t4",
  ORGANIZATION_DOCUMENTS_HIDDEN_T4 = "organization.documents.hidden.t4",
  ORGANIZATION_DOCUMENTS_REQUIRED_NOA = "organization.documents.required.noa",
  ORGANIZATION_DOCUMENTS_HIDDEN_NOA = "organization.documents.hidden.noa",
  ORGANIZATION_DOCUMENTS_REQUIRED_PAYSTUB = "organization.documents.required.paystub",
  ORGANIZATION_DOCUMENTS_HIDDEN_PAYSTUB = "organization.documents.hidden.paystub",
  ORGANIZATION_DOCUMENTS_REQUIRED_BANK_STATEMENT = "organization.documents.required.bank_statement",
  ORGANIZATION_DOCUMENTS_HIDDEN_BANK_STATEMENT = "organization.documents.hidden.bank_statement",
  ORGANIZATION_DOCUMENTS_REQUIRED_MLS = "organization.documents.required.mls",
  ORGANIZATION_DOCUMENTS_HIDDEN_MLS = "organization.documents.hidden.mls",
  ORGANIZATION_DOCUMENTS_REQUIRED_CLOSING_DOCUMENT = "organization.documents.required.closing_document",
  ORGANIZATION_DOCUMENTS_HIDDEN_CLOSING_DOCUMENT = "organization.documents.hidden.closing_document",
  WHITELABEL_NAVIGATION_MENU = "whitelabel.navigation.menu",
  WHITELABEL_LANDING_PAGE_SHOW_RATE = "whitelabel.landing_page.show_rate",
  WHITELABEL_ACTIVE_PAGES_PURCHASE_TIMEFRAME = "whitelabel.active_pages.purchase_timeframe",
  PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES = "product_provider.active_pages.self_reported_assets_liabilities",
  PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT = "product_provider.active_pages.self_reported_credit",
  PRODUCT_PROVIDER_ACTIVE_PAGES_BROKERAGE_OR_BANKING = "product_provider.active_pages.brokerage_or_banking",
}
