import {useState} from "react";
import {root, notLoaded} from "./FailoverImage.module.scss";
import {classNames} from "../../utils/StringFormatter/StringFormatter";

interface FailoverImageProps {
  src?: string;
  failoverSrc?: string;
  className?: string;
}

const FailoverImage = ({src = "", failoverSrc = "", className = ""}: FailoverImageProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <>
      <img
        className={classNames(root, className, loaded ? "loaded" : notLoaded)}
        src={src}
        onLoad={() => setLoaded(true)}
      />
      {!loaded && <img className={`failover-image-component ${className}`} src={failoverSrc} />}
    </>
  );
};

export default FailoverImage;
