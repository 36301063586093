import {classNames} from "components/utils/stringUtil";
import {ErrorResponse} from "types/operation";
import {MessageType} from "types/ui";
import {default as styles} from "./Message.module.scss";
import {useTranslation} from "react-i18next";

interface Props {
  type?: MessageType;
  className?: string;
  message?: string | ErrorResponse | any;
}

const Message = ({type, className, message}: Props) => {

  const {t} = useTranslation();

  if (!message) {
    return null;
  }

  let messageText = "";

  if (typeof message === "object") {
    // Need to implement proper UI message display here, or in component
    messageText = message?.message ? String(message?.message) : t("errors.generalMessage")!;

    if (
      message?.errors?.length > 0 &&
      message?.errors[0]?.field === "loanType" &&
      message?.errors[0]?.reason === "NULL"
    ) {
      messageText = t("errors.loanTypeNull");
    }

    console.warn("Error object", message);
  } else {
    messageText = String(message);
  }

  return (
    <div className={classNames(type === MessageType.info ? styles.info : styles.error, className)}>
      {messageText}
    </div>
  );
};

export default Message;
