import {root, statusContents, descriptions as descriptionsClass} from "./StatusScreen.module.scss";
import {useSelector} from "react-redux";
import {getWhitelabelToken} from "store/selectors/whitelabel";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import BackButton from "components/molecules/BackButton/BackButton";
import {getFixedT} from "util/languageUtil";
import {Typography} from "@mui/material";
import Page from "pages/Page/Page";

const fixedT = getFixedT("StatusScreen");

interface StatusScreenProps {
  descriptions?: string[];
  title?: string;
  onBack?: () => void;
  backButtonText?: string;
}

const StatusScreen = ({
  title,
  descriptions = [],
  onBack,
  backButtonText = fixedT("backButton")!,
}: StatusScreenProps) => {
  const token = useSelector(getWhitelabelToken);

  return (
    <Page className={root}>
      <div
        className={statusContents}
        style={{
          backgroundImage: `url(${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/status_screen_background.svg?)`,
        }}
      >
        {title && (
          <Typography align="center" variant="h1">
            {title}
          </Typography>
        )}
        <div className={descriptionsClass}>
          {descriptions.map((description, index) => (
            <div key={`status-screen-content-message-${index}`}>{description}</div>
          ))}
        </div>
        <NavigationSection>
          {onBack && <BackButton onClick={onBack}>{backButtonText}</BackButton>}
        </NavigationSection>
      </div>
    </Page>
  );
};

export default StatusScreen;
