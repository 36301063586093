import Page from "pages/Page/Page";
import {SignUpForm} from "./SignUpForm/SignUpForm";

const SignUpPage = () => {
  return (
    <Page className="sign-up-page">
      <SignUpForm />
    </Page>
  );
};

export default SignUpPage;
