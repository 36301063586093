import {Controller, FieldValues, UseFormReturn} from "react-hook-form";
import {WithChildren} from "types/ui";
import {InputAdornment, TextField, TextFieldProps} from "@mui/material";
import {TrackingEventType} from "types/enums/trackingEventType";
import {NumericFormat} from "react-number-format";
import {trackEventMemoized} from "util/eventUtil";
import {LanguageType} from "types/enums/languageType";
import {noop} from "lodash";
import {useTranslation} from "react-i18next";

interface MoneyInputControlledProps<T extends FieldValues = any, C = any>
  extends Pick<
      TextFieldProps,
      "name" | "disabled" | "required" | "label" | "placeholder" | "defaultValue"
    >,
    WithChildren {
  name: string;
  className?: string;
  onChange?: (e: number | null | undefined) => void;
  maxLength?: number | null;
  nullable?: boolean;
  formHook: UseFormReturn<T, C>;
  triggerOnChange?: boolean; // React-hook-form workaround for dropdown change not updating the validation/UI
  trackingEvent?: TrackingEventType;
}

const MoneyInputControlled = ({
  name,
  className,
  disabled,
  onChange = noop,
  label,
  defaultValue = null,
  triggerOnChange = false,
  placeholder,
  maxLength = 10,
  nullable = false,
  formHook,
  required = false,
  trackingEvent,
  ...otherProperties
}: MoneyInputControlledProps) => {
  const {control, trigger, getFieldState, formState} = formHook;
  const {error, invalid, isTouched} = getFieldState(name);
  const {i18n} = useTranslation();

  const shouldDisplayError =
    (formState.submitCount > 0 && Boolean(error?.message)) ||
    (isTouched && invalid && formState.dirtyFields);

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({field}) => (
        <NumericFormat
          thousandSeparator={i18n.language === LanguageType.FR_CA ? " " : ","}
          decimalSeparator={i18n.language === LanguageType.FR_CA ? "," : "."}
          decimalScale={2}
          fixedDecimalScale={false}
          valueIsNumericString
          allowNegative={false}
          customInput={TextField}
          // Pass other props to the TextField
          className={className}
          {...field}
          disabled={disabled}
          required={required}
          helperText={shouldDisplayError ? error?.message : ""}
          error={!!shouldDisplayError}
          placeholder={placeholder}
          label={label}
          onValueChange={(values, sourceInfo) => {
            let value: string | any = values.value;
            if (trackingEvent) trackEventMemoized(trackingEvent);
            value = parseFloat(value);
            field.onChange(value);
            if (triggerOnChange) {
              trigger();
            }
          }}
          onChange={noop} // Required. Do not remove this
          onBlur={() => {
            field.onBlur();
            trigger();
          }}
          InputProps={{
            startAdornment:
              i18n.language === LanguageType.EN_CA ? (
                <InputAdornment position="start">
                  <p>$</p>
                </InputAdornment>
              ) : undefined,
            endAdornment:
              i18n.language === LanguageType.FR_CA ? (
                <InputAdornment position="end">
                  <p>$</p>
                </InputAdornment>
              ) : undefined,
          }}
        />
      )}
    />
  );
};

export default MoneyInputControlled;
