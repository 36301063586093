import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {WithNavigate} from "types/ui";

const namespace = "SelfReportedAssetsLiabilities";

export interface SelfReportedAssetsLiabilitiesFormData extends WithNavigate {
  savings?: number;
  chequing?: number;
  rrsp?: number;
  tfsa?: number;
  otherAssets?: OtherEntry[];
  creditCard?: number;
  carPayment?: number;
  mortgage?: number;
  loans?: number;
  otherLiabilities?: OtherEntry[];
}

export interface OtherEntry {
  amount?: number;
  description?: string;
}

export const submit = createAction<SelfReportedAssetsLiabilitiesFormData>(
  actionTypeOf(namespace, "submit")
);

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);

export const acceptMissingDataWarning = createAction<boolean>(
  actionTypeOf(namespace, "acceptMissingDataWarning")
);

export const openUserAcceptedDataWarningModal = createAction<boolean>(
  actionTypeOf(namespace, "openUserAcceptedDataWarningModal")
);

export const saveAssetsLiabilities = createAction<
  [SelfReportedAssetDto[], SelfReportedLiabilityDto[]]
>(actionTypeOf(namespace, "saveAssetsLiabilities"));
