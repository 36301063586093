import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/index";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "basicInfo",
    "user",
    "whitelabel",
    "navigationController",
    "applicantMeta",
  ],
};

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
  devTools: (window)._env_.REACT_APP_REDUX_DEV_TOOLS_ENABLED === "true",
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export default store;
