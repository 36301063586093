import {WithDataError} from "types/api";
import {Configuration} from "types/configurations";
import {UUID} from "types/uuid";
import {normalizedFetchApi} from "./util";

const baseUrl = `${
  (window)._env_.REACT_APP_PINCH_SERVICE_API_HOST
}/configurations`;

export const fetchConfigurationsApi = async (
  entityId: UUID
): Promise<WithDataError<Configuration[]>> =>
  normalizedFetchApi(() => fetch(`${baseUrl}/entity/${entityId}`));
