import React from "react";
import {
  root,
  logoSection,
  headerLink as headerLinkClass,
  logoContainer,
  childrenSection,
  headerText as headerTextClass,
} from "./Header.module.scss";
import CompanyLogo from "../../atoms/CompanyLogo/CompanyLogo";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {getHeaderLink, getHeaderText} from "store/selectors/theme";
import {WithClassName} from "types/ui";
import {classNames} from "components/utils/stringUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import {useTranslation} from "react-i18next";

interface HeaderWithLogoProps extends WithClassName {
  children?: React.ReactNode;
}

const HeaderWithLogo = ({children, className}: HeaderWithLogoProps) => {
  const {i18n} = useTranslation();
  const headerText = useSelector(getHeaderText)(i18n.language);
  const headerLink = useSelector(getHeaderLink);

  return (
    <div className={classNames(root, className)}>
      <div className={logoSection}>
        {headerLink && (
          <span
            className={headerLinkClass}
            onClick={() => {
              trackEvent(TrackingEventType.clickHomePage);
              window.location.href = headerLink;
            }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />{" "}
            <span className={headerTextClass}>{headerText}</span>
          </span>
        )}
        <span className={logoContainer}>
          <CompanyLogo />
        </span>
      </div>
      <div className={childrenSection}>{children}</div>
    </div>
  );
};

export default HeaderWithLogo;
