import {call, select, takeEvery, takeLatest} from "redux-saga/effects";
import {acceptPrivacyOperation, submitPersonalProfileOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getHasUserAccount, getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {PolicyType} from "types/enums/policyType";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {WithNavigate, WithOnSuccess} from "types/ui";
import {acceptPrivacy, initialize, PersonalProfileFormData, submit} from "./PersonalProfile.action";

function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const hasUser: boolean = yield select(getHasUserAccount);
  if (!hasUser) {
    console.warn("User was not found. Redirecting to sign in...");
    navigate(RouteUrl.SIGN_IN, {replace: true});
  }
}

function* onAcceptPrivacy({payload: {onSuccess}}: WithPayload<WithOnSuccess>) {
  const userId: string = yield select(getUserId);
  const payload: Partial<ResidentialApplicationBasicInfoRequest> = {
    policyType: PolicyType.PRIVACY,
  };
  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    acceptPrivacyOperation.saga,
    userId,
    payload,
    Step.ACCEPT_POLICY
  );
  if (Operation.isSuccess(result)) {
    onSuccess && onSuccess();
  }
}

function* onSubmit({payload}: WithPayload<PersonalProfileFormData>) {
  const userId: string = yield select(getUserId);
  const {dateOfBirth, phoneNo, navigate} = payload;

  const requestPayload: Partial<ResidentialApplicationBasicInfoRequest> = {
    dateOfBirth: dateOfBirth,
    phoneNo: phoneNo,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitPersonalProfileOperation.saga,
    userId,
    requestPayload,
    Step.PERSONAL_PROFILE
  );
  if (Operation.isSuccess(result)) {
    navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT);
  }
}

export default function* personalProfileSaga() {
  yield takeLatest(initialize, onInitialize);
  yield takeEvery(acceptPrivacy, onAcceptPrivacy);
  yield takeEvery(submit, onSubmit);
}
