import _ from "lodash";
import {maybe} from "types/basic";

/**
 * Transforms a string to use camel case.
 * @param {String} input
 * @returns String input as camel case.
 */
export function snakeToCamelCase(input = '') {
  return _.camelCase(input);
}


/**
 * Transforms an array of objects keys names to use camel case.
 * @param {Array{}} input An array of objects.
 * @returns Array with objects keys as camel case.
 */
export function snakeToCamelCaseArr(input = []) { //TODO add recurse param option
  return input?.map((obj) => _.mapKeys(obj, (value, key) => _.camelCase(key as never as string)));
}

/**
 * Transforms an object of objects keys names to use camel case.
 * @param {Object{}} input An Object of objects.
 * @returns Object with objects keys as camel case.
 */
export function snakeToCamelCaseObj(input: {[key: string]: any} = {}) {
  const formattedObject: {[key: string]: any} = {};
  for (let key in input) {
    formattedObject[snakeToCamelCase(key)] = input[key];
  }
  return formattedObject;
}

/**
 * Transforms a string to start case e.g. foo_bar => Foo Bar
 */
export function toStartCase(input = "") {
  return _.startCase(input?.toLowerCase());
}

export function classNames(...names: maybe<string>[]): string {
  if (!names) {
    return "";
  }
  return names
    .map((str) => (str || "").trim())
    .filter(Boolean)
    .join(" ");
}
