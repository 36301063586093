import React from "react";
import {WithChildren, WithClassName} from "types/ui";
import {root} from "./MortgageInfoStep.module.scss";

interface Props extends WithChildren, WithClassName {
  id?: string;
}

const MortgageInfoStep = React.forwardRef(({children = <>

    </>, className = "", id = undefined}: Props, ref) => {
  return (
    <div id={id} className={`${root} ${className}`} ref={ref as React.MutableRefObject<any>}>
      {children}
    </div>
  );
});

export default MortgageInfoStep;
