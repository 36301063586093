import {WithNavigate} from "./../../../types/ui";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {InviteSecondaryUserRequest} from "types/dto/inviteSecondaryUserRequest";

const namespace = "InviteSecondaryApplicant";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export type SubmitInviteSecondaryPayload = WithNavigate & InviteSecondaryUserRequest;

export const submitInviteSecondary = createAction<SubmitInviteSecondaryPayload>(
  actionTypeOf(namespace, "submitInviteSecondary")
);
