import {default as styles} from "./AddressInfo.module.scss";
import BackButton from "components/molecules/BackButton/BackButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {YearsAtAddressLength} from "types/enums/yearsAtAddressLength";
import {useDispatch, useSelector} from "react-redux";
import {getBasicInfo, getIsLocked} from "store/selectors/basicInfo";
import {useNavigate} from "react-router-dom";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {AddressInfoFormData, submitAddressInfo, navigatePreviousStep} from "./AddressInfo.action";
import {getFixedT} from "util/languageUtil";
import {MenuItem, TextField, Typography} from "@mui/material";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./AddressInfo.schema";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import Message from "components/atoms/Message/Message";
import StepContent from "components/organisms/StepContent/StepContent";
import Form from "components/atoms/Form/Form";
import {useRef} from "react";
import {Autocomplete, useLoadScript} from "@react-google-maps/api";
import {Province} from "types/enums/province";
import {PROVINCE_LIST} from "types/province";
import {toPropertyAddressDtoFromGoogleAddress} from "util/addressUtil";
import {OwnershipStatus} from "types/enums/ownershipStatus";
import PoweredByGoogle from "assets/images/GoogleLogo/PoweredByGoogle.png";
import {googlePlacesOptions, googleLibraries} from "util/googlePlacesUtil";

const timeAtAddressOptions = [
  YearsAtAddressLength.FEWER_THAN_2_YEARS,
  YearsAtAddressLength.BETWEEN_2_AND_5_YEARS,
  YearsAtAddressLength.MORE_THAN_5_YEARS,
];

const fixedT = getFixedT("basicInfoSteps.addressInfo");

const AddressInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicInfo = useSelector(getBasicInfo);
  const locked = useSelector(getIsLocked);
  const {isPending, failure} = useSelector(getOperationResult)(OperationType.submitAddressInfo);
  const {isLoaded: isGoogleLoaded, loadError: googleError} = useLoadScript({
    googleMapsApiKey: window._env_.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: googleLibraries,
  });
  const googleAutocompeteCurrentRef = useRef<google.maps.places.Autocomplete>();
  const googleAutocompetePreviousRef = useRef<google.maps.places.Autocomplete>();

  const formHook = useForm<AddressInfoFormData>({
    defaultValues: {
      timeAtAddress: basicInfo?.timeAtAddress,
      ownershipStatus: basicInfo?.ownershipStatus,
      residentialAddress: {
        unitNo: basicInfo?.residentialAddress?.unitNo ?? "",
        streetNo: basicInfo?.residentialAddress?.streetNo ?? "",
        streetName: basicInfo?.residentialAddress?.streetName ?? "",
        city: basicInfo?.residentialAddress?.city ?? "",
        province: basicInfo?.residentialAddress?.province ?? Province.NULL,
        postCode: basicInfo?.residentialAddress?.postCode ?? "",
      },
      previousResidentialAddress: {
        unitNo: basicInfo?.previousResidentialAddress?.unitNo ?? "",
        streetNo: basicInfo?.previousResidentialAddress?.streetNo ?? "",
        streetName: basicInfo?.previousResidentialAddress?.streetName ?? "",
        city: basicInfo?.previousResidentialAddress?.city ?? "",
        province: basicInfo?.previousResidentialAddress?.province ?? Province.NULL,
        postCode: basicInfo?.previousResidentialAddress?.postCode ?? "",
      },
    },
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = (values: AddressInfoFormData) => {
    trackEvent(TrackingEventType.addressInfoClickNextButton);
    dispatch(
      submitAddressInfo({
        ...values,
        navigate,
      })
    );
  };

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  if (googleError) {
    console.error("Google map api error", googleError);
  }

  return (
    <StepContent
      className={styles.root}
      headerText={fixedT("header")}
      backButton={
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.addressInfoClickBackButton);
            dispatch(navigatePreviousStep({navigate}));
          }}
        />
      }
      nextButton={
        <SubmitButton
          isSubmitting={isPending}
          isEnabled={!isPending}
          onClick={formHook.handleSubmit(onSubmit)}
          text={
            (!isPending ? (
              fixedT("forwardButton")
            ) : (
              <FontAwesomeIcon icon={faSyncAlt} spin={true} />
            )) as string
          }
        />
      }
    >
      <Form className={styles.content} onEnter={() => formHook.handleSubmit(onSubmit)}>
        <div className={styles.options}>
          {isGoogleLoaded && !googleError && (
            <Autocomplete
              onLoad={(ref) => {
                googleAutocompeteCurrentRef.current = ref;
              }}
              options={googlePlacesOptions}
              onPlaceChanged={() => {
                if (!googleAutocompeteCurrentRef.current) {
                  console.error("Google Autocomplete is not mounted!");
                  return;
                }
                const place = googleAutocompeteCurrentRef.current.getPlace();

                if (place?.address_components) {
                  const addressDto = toPropertyAddressDtoFromGoogleAddress(
                    place?.address_components
                  );
                  formHook.setValue("residentialAddress", addressDto);
                  formHook.trigger();
                }
              }}
            >
              <TextField
                className={styles.googleAddressInput}
                label={fixedT("fields.googleAddressCurrent.label")}
                disabled={locked}
                placeholder={fixedT("fields.googleAddressCurrent.placeholder")}
                type="text"
              ></TextField>
            </Autocomplete>
          )}
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="residentialAddress.streetNo"
            type="number"
            label={fixedT("labelStreetNumber")}
            disabled={locked}
            required
            trackingEvent={TrackingEventType.addressInfoChangeCurrentStreetNo}
          />
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="residentialAddress.unitNo"
            label={fixedT("labelSuiteNumber")}
            disabled={locked}
            trackingEvent={TrackingEventType.addressInfoChangeCurrentSuiteNo}
          />
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="residentialAddress.streetName"
            label={fixedT("labelStreetName")}
            disabled={locked}
            required
            trackingEvent={TrackingEventType.addressInfoChangeCurrentStreetName}
          />
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="residentialAddress.city"
            label={fixedT("labelCity")}
            disabled={locked}
            required
            trackingEvent={TrackingEventType.addressInfoChangeCurrentCity}
          />
          <div className={styles.provincePostalCodeContainer}>
            <div className={styles.province}>
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="residentialAddress.province"
                select
                label={fixedT("labelProvince")}
                disabled={locked}
                required
                trackingEvent={TrackingEventType.addressInfoChangeCurrentProvince}
              >
                {PROVINCE_LIST.map((province: Province) => (
                  <MenuItem
                    disabled={locked}
                    key={`residentialAddress-${province}`}
                    value={province}
                    tabIndex={0}
                  >
                    {fixedT(`provinces.${province}`)}
                  </MenuItem>
                ))}
              </TextFieldControlled>
            </div>
            <div className={styles.postalCode}>
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="residentialAddress.postCode"
                label={fixedT("labelPostalCode")}
                disabled={locked}
                placeholder={"--- ---"}
                required
                trackingEvent={TrackingEventType.addressInfoChangeCurrentPostalCode}
              />
            </div>
          </div>
          {isGoogleLoaded && !googleError && (
            <div>
              <img src={PoweredByGoogle} alt="Powered By Google" />
            </div>
          )}
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="ownershipStatus"
            label={fixedT("labelOwnershipStatus")}
            disabled={locked}
            select
            required
            trackingEvent={TrackingEventType.addressInfoChangeCurrentOwnershipStatus}
          >
            <MenuItem disabled={locked} value={OwnershipStatus.RENTER} tabIndex={0}>
              {fixedT("ownershipStatus", {context: OwnershipStatus.RENTER})}
            </MenuItem>
            <MenuItem disabled={locked} value={OwnershipStatus.OWNER} tabIndex={0}>
              {fixedT("ownershipStatus", {context: OwnershipStatus.OWNER})}
            </MenuItem>
          </TextFieldControlled>
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="timeAtAddress"
            label={fixedT("labelTimeAtAddress")}
            triggerOnChange
            disabled={locked}
            select
            required
            trackingEvent={TrackingEventType.addressInfoChangeCurrentTimeAtAddress}
          >
            {timeAtAddressOptions.map((timeAtAddr) => (
              <MenuItem disabled={locked} key={timeAtAddr} value={timeAtAddr} tabIndex={0}>
                {fixedT("timeAtAddress", {context: timeAtAddr})}
              </MenuItem>
            ))}
          </TextFieldControlled>
          {YearsAtAddressLength.FEWER_THAN_2_YEARS === formHook.getValues("timeAtAddress") && (
            <>
              <Typography variant="h5" className={styles.previousAddressMessage}>
                {fixedT("previousAddressMessage")}
              </Typography>

              {isGoogleLoaded && !googleError && (
                <Autocomplete
                  onLoad={(ref) => {
                    googleAutocompetePreviousRef.current = ref;
                  }}
                  options={googlePlacesOptions}
                  onPlaceChanged={() => {
                    if (!googleAutocompetePreviousRef.current) {
                      console.error("Google Autocomplete is not mounted!");
                      return;
                    }
                    const place = googleAutocompetePreviousRef.current.getPlace();

                    if (place?.address_components) {
                      const addressDto = toPropertyAddressDtoFromGoogleAddress(
                        place?.address_components
                      );
                      formHook.setValue("previousResidentialAddress", addressDto);
                      formHook.trigger();
                    }
                  }}
                >
                  <TextField
                    className={styles.googleAddressInput}
                    label={fixedT("fields.googleAddressPrevious.label")}
                    disabled={locked}
                    placeholder={fixedT("fields.googleAddressPrevious.placeholder")}
                    type="text"
                  ></TextField>
                </Autocomplete>
              )}
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="previousResidentialAddress.streetNo"
                type="number"
                label={fixedT("labelStreetNumber")}
                disabled={locked}
                required
                trackingEvent={TrackingEventType.addressInfoChangePreviousStreetNo}
              />

              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="previousResidentialAddress.unitNo"
                label={fixedT("labelSuiteNumber")}
                disabled={locked}
                trackingEvent={TrackingEventType.addressInfoChangePreviousSuiteNo}
              />
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="previousResidentialAddress.streetName"
                label={fixedT("labelStreetName")}
                disabled={locked}
                required
                trackingEvent={TrackingEventType.addressInfoChangePreviousStreetName}
              />

              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="previousResidentialAddress.city"
                label={fixedT("labelCity")}
                disabled={locked}
                required
                trackingEvent={TrackingEventType.addressInfoChangePreviousCity}
              />
              <div className={styles.provincePostalCodeContainer}>
                <div className={styles.province}>
                  <TextFieldControlled
                    fullWidth
                    formHook={formHook}
                    name="previousResidentialAddress.province"
                    select
                    label={fixedT("labelProvince")}
                    disabled={locked}
                    required
                    trackingEvent={TrackingEventType.addressInfoChangePreviousProvince}
                  >
                    {PROVINCE_LIST.map((province) => (
                      <MenuItem
                        key={`previousResidentialAddress-${province}`}
                        value={province}
                        tabIndex={0}
                        disabled={locked}
                      >
                        {fixedT(`provinces.${province}`)}
                      </MenuItem>
                    ))}
                  </TextFieldControlled>
                </div>
                <div className={styles.province}>
                  <TextFieldControlled
                    fullWidth
                    formHook={formHook}
                    name="previousResidentialAddress.postCode"
                    label={fixedT("labelPostalCode")}
                    disabled={locked}
                    placeholder={"--- ---"}
                    required
                    trackingEvent={TrackingEventType.addressInfoChangePreviousPostalCode}
                  />
                </div>
              </div>
              {isGoogleLoaded && !googleError && (
                <div>
                  <img src={PoweredByGoogle} alt="Powered By Google" />
                </div>
              )}
            </>
          )}
        </div>
      </Form>
      <Message message={failure} />
    </StepContent>
  );
};

export default AddressInfo;
