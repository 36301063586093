import { optional } from "types/basic";

export class OrderedMap<V = any> {

  private readonly keys: string[] = [];
  private readonly map: Record<string, V> = {};

  with(key: string, value: V): OrderedMap<V> {
    if (this.map.hasOwnProperty(key)) {
      throw new Error(`Duplicated key is not allowed: ${key}`);
    }
    this.keys.push(key);
    this.map[key] = value;
    return this;
  }

  get(key: string, defaultValue: optional<V> = undefined): optional<V> {
    if (!this.map.hasOwnProperty(key)) {
      return defaultValue;
    }
    return this.map[key];
  }

  getOrFirstValue(key: string): optional<V> {
    return this.get(key, this.getFirstValue());
  }

  getKeyAfter(key: string, deltaSteps: number = 1, defaultKey: optional<string> = undefined): optional<string> {
    if (!this.map.hasOwnProperty(key)) {
      return defaultKey;
    }
    const currentIndex = this.keys.indexOf(key);
    if (currentIndex < 0) {
      return defaultKey;
    }
    const nextIndex = currentIndex + deltaSteps;
    if (nextIndex >= this.keys.length) {
      return defaultKey;
    }
    return this.keys[nextIndex];
  }

  getValueAfter(key: string, deltaSteps: number = 1, defaultValue: optional<V> = undefined): optional<V> {
    const nextKey = this.getKeyAfter(key, deltaSteps);
    if (!nextKey) {
      return defaultValue;
    }
    return this.map[nextKey];
  }

  contains(key: string): boolean {
    return this.map.hasOwnProperty(key);
  }

  getFirstKey(): string {
    return this.keys[0];
  }

  getLastKey(): string {
    return this.keys[this.keys.length-1];
  }

  getFirstValue(): V {
    return this.map[this.getFirstKey()];
  }

  getLastValue(): V {
    return this.map[this.getLastKey()];
  }

  getIndexOf(key: string): number {
    return this.keys.indexOf(key);
  }
  
  getSize(): number {
    return this.keys.length;
  }
}
