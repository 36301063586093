import {maybe} from "types/basic";
import {logNumberNegative} from "./log";

export function distinct<T>(list: T[], keyProvider: (item: T) => string | number): T[] {
  if (!list) {
    return [];
  }
  const distinctList: T[] = [];
  const set = new Set();

  list.forEach((item: T) => {
    const key = keyProvider(item);
    if (!set.has(key)) {
      set.add(key);
      distinctList.push(item);
    }
  });

  return distinctList;
}

export function countBy<T>(list: T[], countedIf: (item: T) => boolean): number {
  if (!list || list.length == 0) {
    return 0;
  }

  let counter = 0;
  list.forEach((item) => {
    if (countedIf(item)) {
      counter++;
    }
  });
  return counter;
}

export function allNotBlank(...items: any[]): boolean {
  for (let item of items) {
    if (item == null) {
      return false;
    }
    if (typeof item === "string") {
      if (!item.trim()) {
        return false;
      }
    }
  }
  return true;
}

export function arrayOrEmpty<T = any>(arrayOnly: T[]): T[] {
  if (!arrayOnly || !Array.isArray(arrayOnly)) {
    console.warn("Fallback to empty array, as it's not an array:", arrayOnly);
    return [];
  }
  return arrayOnly;
}

export function arrayIgnoreEmpty(...items: maybe<string>[]): string[] {
  return items
    .filter(Boolean)
    .map((str) => String(str).trim())
    .filter(Boolean);
}

export function assembleNoEmpty(...items: maybe<string>[]): string[] | undefined {
  const arr = arrayIgnoreEmpty(...items);
  return arr.length > 0 ? arr : undefined;
}

export function concatNoEmpty(array1?: any[], array2?: any[]): any[] | undefined {
  const arr1 = array1 ? arrayIgnoreEmpty(...array1) : [];
  const arr2 = array2 ? arrayIgnoreEmpty(...array2) : [];

  const combined = [...arr1, ...arr2];
  if (combined.length === 0) {
    return undefined;
  }
  return combined;
}

export function hasNull(...items: any[]): boolean {
  // has null or undefined
  for (let item of items) {
    if (item == null) {
      return true;
    }
  }
  return false;
}

export function removeAtIndex<T>(arr: T[], index: number = 0): T[] {
  logNumberNegative(index, "index");
  const copy = [...arr];
  copy.splice(index, 1);
  return copy;
}

export function updateArrayItem<T>(arr: T[], index: number = 0, itemUpdator: (item: T) => T): T[] {
  const copy = [...arr];
  const targetItem = copy[index];
  if (targetItem == undefined) {
    console.error(`Abort updating due to target item not found by index: index=${index}`);
    return copy;
  }
  copy[index] = itemUpdator(targetItem);
  return copy;
}

export function safeArray<T = any>(arr: T[] | any): T[] {
  if (arr === undefined || arr === null) {
    return [];
  }
  if (typeof arr !== "object" || !Array?.isArray(arr)) {
    // Treat it as a single item. So it'll be an array object anyway
    return [arr] as T[];
  }

  return arr;
}
