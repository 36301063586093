import {WithNavigate} from "./../../../types/ui";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithOnSuccess} from "types/ui";

const namespace = "PersonalProfile";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const acceptPrivacy = createAction<WithOnSuccess>(actionTypeOf(namespace, "acceptPrivacy"));

export interface PersonalProfileFormData extends WithNavigate {
  dateOfBirth?: Date | string;
  phoneNo?: string;
}

export const submit = createAction<PersonalProfileFormData>(actionTypeOf(namespace, "submit"));
