export function isTruthy<T>(value: T): boolean {
  return !!value ? true : false;
}

export function isFalsy<T>(value: T): boolean {
  return !isTruthy(value);
}

export function isNull<T>(value: T): boolean {
  return value === null;
}

export function isNullOrUndefined<T>(value: T): boolean {
  return value === null || value === undefined;
}

export function nonNull<T>(value: T): boolean {
  return value != null;
}

export function ifNullThenThrowError<T>(value: T, message: string = "Value is null"): void {
  if (isNull(value)) {
    throw new Error(message);
  }
}

export function likeTrue(value: boolean | string | null | undefined): boolean {
  if (value === true || value === "true") {
    return true;
  }
  return false;
}
