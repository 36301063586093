export enum Province {
  ONTARIO = "ONTARIO",
  QUEBEC = "QUEBEC",
  NOVA_SCOTIA = "NOVA_SCOTIA",
  NEW_BRUNSWICK = "NEW_BRUNSWICK",
  MANITOBA = "MANITOBA",
  BRITISH_COLUMBIA = "BRITISH_COLUMBIA",
  PRINCE_EDWARD_ISLAND = "PRINCE_EDWARD_ISLAND",
  SASKATCHEWAN = "SASKATCHEWAN",
  ALBERTA = "ALBERTA",
  NEWFOUNDLAND_AND_LABRADOR = "NEWFOUNDLAND_AND_LABRADOR",
  NORTHWEST_TERRITORIES = "NORTHWEST_TERRITORIES",
  YUKON = "YUKON",
  NUNAVUT = "NUNAVUT",
  NULL = "", // The default for Province in forms. This is so google autocomplete will properly update. We will never send this
}
