import {maybe, optional} from "types/basic";

export const toUpperCase = (text?: string): optional<string> => {
  if (!text) {
    return undefined;
  }
  return String(text).toUpperCase();
};

export const toUpperCaseOrEmpty = (text?: string): string => {
  if (!text) {
    return "";
  }
  return String(text).toUpperCase();
};

export const toLowerCaseOrEmpty = (text?: string): string => {
  if (!text) {
    return "";
  }
  return String(text).toLowerCase();
};

export function likeNull(str: string | undefined | null): boolean {
  if (!str) {
    return true;
  }
  const safeStr = String(str).toLowerCase();
  if (safeStr === "null" || safeStr === "undefined") {
    return true;
  }
  return false;
}

export function semicolonJoin(...words: string[]): string {
  if (!words || !words.length) {
    return "";
  }
  return words
    .filter((word) => word != null)
    .map((word) => word.trim())
    .join("; ");
}

export function equalIgnoreCase(word1: string, word2: string): boolean {
  if (word1 == null || word2 == null) {
    return false;
  }

  return String(word1).toLowerCase() === String(word2).toLowerCase();
}

export function differentIgnoreCase(word1: string, word2: string): boolean {
  return !equalIgnoreCase(word1, word2);
}

export function lowerAndUnderscoreRemoved(word?: string): string {
  if (word == null) {
    return "";
  }
  return String(word).replace(/_/g, "").toLowerCase();
}

export function lowerAlphaNumeric(word?: string): string {
  if (word == null) {
    return "";
  }
  return String(word)
    .replace(/[^a-zA-Z0-9]/g, "")
    .toLowerCase();
}

export function trimOrEmpty(str: maybe<string>): string {
  return str ? String(str).trim() : "";
}

export function classNames(...names: maybe<string>[]): string {
  if (!names) {
    return "";
  }
  return names.map(trimOrEmpty).filter(Boolean).join(" ");
}

export function isEmail(email: maybe<string>): boolean {
  return (
    !!email &&
    typeof email === "string" &&
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      email
    )
  );
}

export function notEmail(email: maybe<string>): boolean {
  return !isEmail(email);
}

export function notValidPhoneNumber(phoneNo: maybe<string>): boolean {
  return !isValidPhoneNumber(phoneNo);
}

export function isValidPhoneNumber(phoneNo: maybe<string>): boolean {
  return (
    !!phoneNo &&
    new RegExp("^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$").test(phoneNo)
  );
}

export function isValidPostalCode(postCode: maybe<string>): boolean {
  return (
    !!postCode &&
    new RegExp(
      /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
    ).test(postCode)
  );
}

export function isBlank(value?: string | any): boolean {
  return value === null || value === undefined || !String(value).trim();
}

export function notBlank(value?: string | any): boolean {
  return !isBlank(value);
}

export const formatAsPhoneNo = (number: string): string | null => {
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
