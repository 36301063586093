import {call, retry, select, takeEvery} from "redux-saga/effects";
import {
  fetchLendersOperation,
  fetchQualificationStatusOperation,
} from "store/operation/qualification";
import {getUserId} from "store/selectors/user";
import {WithPayload} from "types/basic";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {initialize} from "./CalculateQualification.action";
import {logEmpty} from "components/utils/log";
import {
  getIsJointApp,
  getIsLocked,
  getPotentialAddressMatchesOrEmpty,
} from "store/selectors/basicInfo";
import {PropertyAddressDto} from "types/dto/propertyAddressDto";
import {ONE_SECOND} from "components/utils/dateUtil";
import {
  hasFailureStatus,
  hasIncompletePrimaryOrSecondaryData,
  hasIncompleteSecondaryData,
  hasPendingData,
  hasUnresolvedMortgageInformationWithoutMatches,
  hasUnresolvedMortgageInformationWithMatches,
  isAllDataPresent,
} from "./CalculateQualification.util";
import {ApplicationStatusResponse} from "types/dto/ApplicationStatusResponse";
import {ResidentialApplicationQualificationsResponse} from "types/dto/residentialApplicationQualificationsResponse";
import {ApiError} from "types/ApiError";

const MAX_TRIES = 3;

const selectUserIdAndCallSaga = function* (saga: any): any {
  const userId: string = yield select(getUserId);
  return yield call(saga, userId);
};

export const fetchAppDataStatus = selectUserIdAndCallSaga.bind(
  null,
  fetchQualificationStatusOperation.saga
);
export const fetchProducts = selectUserIdAndCallSaga.bind(null, fetchLendersOperation.saga);

const navigateIfLocked = function* (navigate: any) {
  const isLocked: boolean = yield select(getIsLocked);
  if (isLocked) {
    navigate(RouteUrl.APPLICATION_COMPLETE_URL);
  }
};

export function* navigateOnStatus({payload: {navigate}}: WithPayload<WithNavigate>) {
  logEmpty(navigate, "navigate");

  const statusResult: ApplicationStatusResponse = yield call(fetchAppDataStatus);
  const potentialAddressMatches: PropertyAddressDto[] = yield select(
    getPotentialAddressMatchesOrEmpty
  );
  if (hasIncompletePrimaryOrSecondaryData(statusResult)) {
    const isJointApp: boolean = yield select(getIsJointApp);
    const isMissingSecondaryApplicantData = hasIncompleteSecondaryData(isJointApp, statusResult);
    navigate(RouteUrl.PRODUCTS_SELECT_URL, {state: {isMissingSecondaryApplicantData}});
  }
  if (hasUnresolvedMortgageInformationWithMatches(statusResult, potentialAddressMatches)) {
    navigate(RouteUrl.UNRESOLVED_AVM_URL);
  }
  if (hasUnresolvedMortgageInformationWithoutMatches(statusResult, potentialAddressMatches)) {
    console.info("Redirect due to no AVM and no potential address matches.");
    navigate(RouteUrl.PRODUCTS_UNAVAILABLE_URL);
  }
  if (hasPendingData(statusResult)) {
    console.info("Took too long to pull AVM information");
    navigate(RouteUrl.PRODUCTS_UNAVAILABLE_URL);
  }
  if (hasFailureStatus(statusResult)) {
    navigate(RouteUrl.PRODUCTS_UNAVAILABLE_URL);
  }
  if (isAllDataPresent(statusResult)) {
    yield call(navigateOnProducts, {payload: {navigate}});
  }
}

export function* fetchStatusAndthrowPending() {
  const apiResponse: ApplicationStatusResponse = yield call(fetchAppDataStatus);
  if (hasPendingData(apiResponse)) {
    throw new Error(`${apiResponse}`); // try again
  }
  return apiResponse;
}

export function* navigateOnProducts({payload: {navigate}}: WithPayload<WithNavigate>) {
  logEmpty(navigate, "navigate");
  const lenders: ResidentialApplicationQualificationsResponse[] = yield call(fetchProducts);
  const isError = lenders instanceof ApiError;
  const hasNoProducts = lenders?.length === 0;

  if (isError || hasNoProducts) {
    isError && console.error(lenders);
    console.info(
      `Redirecting to ${RouteUrl.PRODUCTS_UNAVAILABLE_URL}, due to no lenders available for the app`
    );
    navigate(RouteUrl.PRODUCTS_UNAVAILABLE_URL);
  } else {
    navigate(RouteUrl.PRODUCTS_SELECT_URL);
  }
}

export function* onInitialize(action: WithPayload<WithNavigate>) {
  yield call(navigateIfLocked, action);
  yield retry(MAX_TRIES, ONE_SECOND, fetchStatusAndthrowPending);
  yield call(navigateOnStatus, action);
}

export default function* selectLendersSaga() {
  yield takeEvery(initialize, onInitialize);
}
