import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {MessageStatus} from "types/MessageStatus";
import {WritableError} from "types/operation";
import {WithNavigate} from "types/ui";

const namespace: string = "Root";

export interface ErrorPayload extends WithNavigate {
  message?: MessageStatus | any;
  error?: WritableError;
}

export const initialize = createAction<Required<WithNavigate>>(
  actionTypeOf(namespace, "initialize")
);

export const error = createAction<ErrorPayload>(actionTypeOf(namespace, "error"));

export const submit = createAction<string>(actionTypeOf(namespace, "submit"));
