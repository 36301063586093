import {MouseEventHandler} from "react";
import {default as styles} from "./SubmitButton.module.scss";
import Button from "@mui/material/Button";
import {classNames} from "components/utils/StringFormatter/StringFormatter";
import i18next from "i18next";

interface Props {
  isEnabled?: boolean;
  isSubmitting?: boolean;
  text: string;
  onClick: MouseEventHandler;
  className?: string;
  size?: string;
}

const SubmitButton = ({isSubmitting, isEnabled = true, onClick, text, className}: Props) => {
  return (
    <Button
      disabled={!isEnabled}
      onClick={onClick}
      type="submit"
      variant="contained"
      size="medium"
      className={classNames(styles.root, className)}
    >
      {text || i18next.t("components.molecules.submitButtonBody")}
    </Button>
  );
};

export default SubmitButton;
