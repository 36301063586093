import React from "react";
import {
  root,
  value,
  label,
  productHighlight as productHighlightClass,
  updated,
  logoBox,
  labelValue,
  numericalInformation,
  interestRate,
  fixed,
  monthlyPayment,
  mortgagePrincipal,
  bottom,
  text,
  buttons,
  keyFeatures,
  feature,
  unverified,
  cancelButton,
  getMortgageButton,
  actions,
} from "./LenderInfo.module.scss";
import Button from "components/atoms/Button/Button";
import moment from "moment";
import {ResidentialApplicationQualificationsResponse} from "types/dto/residentialApplicationQualificationsResponse";
import {useSelector} from "react-redux";
import {getIsAuthorized} from "store/selectors/auth";
import {formatCurrencyAmountOrElse} from "util/currencyUtil";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import Message from "components/atoms/Message/Message";
import LogoBox from "components/atoms/LogoBox/LogoBox";
import TextEditor from "components/molecules/TextEditor/TextEditor";
import {getPluralFormatRule, isEnglish} from "util/languageUtil";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {useTranslation} from "react-i18next";
import {InterestType} from "types/enums/interestType";

interface Props {
  lenderInformation: ResidentialApplicationQualificationsResponse;
  backgroundColor?: string;
  icon?: any;
  requestModalClose?: () => void;
  onSubmit?: (arg0: ResidentialApplicationQualificationsResponse) => void;
}

const LenderInfo: React.FC<Props> = ({
  backgroundColor = "128, 128, 128",
  lenderInformation,
  icon,
  requestModalClose = () => { },
  onSubmit = () => { },
}) => {
  const isAuthorized = useSelector(getIsAuthorized);
  const {isPending, failure} = useSelector(getOperationResult)(OperationType.setLender);
  const {t, i18n} = useTranslation("", {keyPrefix: "components.templates.lenderInfo"});

  return (
    <div className={root}>
      <Message message={failure} />
      <div className={updated}>
        {lenderInformation?.updatedAt && t("updated")} -{" "}
        {moment(lenderInformation?.updatedAt).format("LL")}
      </div>
      <LogoBox
        className={logoBox}
        backgroundColor={backgroundColor}
        iconPath={icon}
        fallbackText={lenderInformation.productName}
      />
      <div className={numericalInformation}>
        <div className={interestRate}>
          <div className={labelValue}>
            <div className={label}>{t("labels.lowestRate")}</div>
            {lenderInformation?.annualPercentageRate === null ? (
              <div className={value}>{(lenderInformation?.interestRate * 100).toFixed(2)}%</div>
            ) : (
              <div className={value}>
                {(lenderInformation?.interestRate * 100).toFixed(2)}% /{" "}
                {(lenderInformation?.annualPercentageRate * 100).toFixed(2)}%{" "}
                {t("labels.lowestRateUnit")}
              </div>
            )}
          </div>
        </div>
        <div className={monthlyPayment}>
          <div className={labelValue}>
            <div className={label}>{t("labels.monthlyPayment")}</div>
            <div className={value}>
              {formatCurrencyAmountOrElse(lenderInformation?.monthlyCost?.amount)}
            </div>
          </div>
        </div>
        <div className={fixed}>
          <div className={labelValue}>
            {lenderInformation.interestType === InterestType.FIXED_RATE && <div className={label}>{t("labels.fixed")}</div>}
            {lenderInformation.interestType === InterestType.VARIABLE_RATE && <div className={label}>{t("labels.variable")}</div>}
            <div className={value}>
              {t("year", {count: lenderInformation?.termInMonths / 12, context: getPluralFormatRule(lenderInformation?.termInMonths / 12)})}
            </div>
          </div>
        </div>
        <div className={mortgagePrincipal}>
          <div className={labelValue}>
            <div className={label}>{t("labels.mortgagePrincipal")}</div>
            <div className={value}>
              {formatCurrencyAmountOrElse(lenderInformation?.mortgagePrincipal?.amount)}
            </div>
          </div>
        </div>
        {(isEnglish(i18n.language)
          ? lenderInformation?.productHighlightsList
          : lenderInformation?.productHighlightsFrList ?? []
        )?.map((productHighlight, index) => (
          <div className={productHighlightClass} key={index}>
            <div className={label}>{productHighlight}</div>
          </div>
        ))}
      </div>
      <div className={bottom}>
        <div className={text}>
          <div className={keyFeatures}>
            <div className={feature}>
              <TextEditor
                value={
                  isEnglish(i18n.language)
                    ? lenderInformation?.lenderDetails
                    : lenderInformation?.lenderDetailsFr
                }
              />
            </div>
          </div>
        </div>
        <div className={actions}>
          <div className={buttons}>
            <Button
              onClick={() => {
                trackEvent(TrackingEventType.lenderClickSubmit);
                onSubmit(lenderInformation);
              }}
              fillColor={"primary-thematic-colour-100"}
              textColor={"primary-background-colour-100"}
              className={getMortgageButton}
              isEnabled={!isPending && isAuthorized}
            >
              {t("forwardButton")}
            </Button>
            <Button
              onClick={() => {
                trackEvent(TrackingEventType.lenderClickBack);
                requestModalClose();
              }}
              fillColor={"primary-thematic-colour-200"}
              textColor={"primary-background-colour-100"}
              className={cancelButton}
              isEnabled={!isPending}
            >
              {t("backButton")}
            </Button>
          </div>
          {!isAuthorized && (
            <div className={unverified} dangerouslySetInnerHTML={{__html: t("unverified")}}></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LenderInfo;
