import React, {useRef} from "react";
import {root} from "./DropdownList.module.scss";

interface DropdownListProps {
  requestDropdownClose?: () => void;
  onValueSelected?: (value: string) => void;
  children?: React.ReactNode;
  className?: string | number;
}

const DropdownList = ({
  requestDropdownClose = () => {},
  onValueSelected = () => {},
  children = <></>,
  className = root,
}: DropdownListProps) => {
  const elementalReference: React.MutableRefObject<any> = useRef();

  function registerElement(element: any) {
    if (elementalReference.current) {
      elementalReference.current.removeEventListener("mousedown", handleValueClick);
      elementalReference.current.removeEventListener("keydown", handleEnterKey);
      document.removeEventListener("keydown", handleTabKey);
    }

    elementalReference.current = element;

    if (elementalReference.current) {
      elementalReference.current.addEventListener("mousedown", handleValueClick);
      elementalReference.current.addEventListener("keydown", handleEnterKey);
      document.addEventListener("keydown", handleTabKey);
    }
  }

  function handleTabKey(keyEvent: KeyboardEvent) {
    if (keyEvent.key === "Tab") {
      setTimeout(() => {
        if (!elementalReference.current.parentNode.parentNode.contains(document.activeElement)) {
          requestDropdownClose();
        }
      }, 10);
    }
  }

  function handleEnterKey(keyEvent: KeyboardEvent) {
    if (keyEvent.key === "Enter") {
      const containedByElementalReference = elementalReference.current.contains(keyEvent.target);
      const eventTarget = (keyEvent as never as React.SyntheticEvent).target;
      const value = (eventTarget as HTMLElement).getAttribute("value");
      keyEvent.stopPropagation();
      keyEvent.preventDefault();

      if (value === null) {
        if (!containedByElementalReference) {
          keyEvent.stopPropagation();
        }
      } else if (containedByElementalReference) {
        onValueSelected(value);
        requestDropdownClose();
      }
    }
  }

  function handleValueClick(clickEvent: MouseEvent) {
    const eventTarget = (clickEvent as never as React.SyntheticEvent<MouseEvent>).target;
    const value = (eventTarget as HTMLElement).getAttribute("value");
    const containedByElementalReference = elementalReference.current.contains(clickEvent.target);

    if (value === null) {
      if (!containedByElementalReference) {
        clickEvent.stopPropagation();
      }
    } else if (containedByElementalReference) {
      onValueSelected(value);
      requestDropdownClose();
    }
  }

  return (
    <div className={className as string} ref={registerElement}>
      {children}
    </div>
  );
};

export default DropdownList;
