import {default as styles} from "./Footer.module.scss";
import {useSelector} from "react-redux";
import {getFooterTextImage, getIsMortgageProviderLogoEnabled} from "store/selectors/theme";
import {getSelectedProvider} from "store/selectors/whitelabel";
import classNames from "classnames";
import {State} from "types/store";
import {ProductSummary} from "types/dto/productSummary";
import {RouteUrl} from "types/route";
import {useTranslation} from "react-i18next";
import {isCurrentUrlAnyOf} from "components/utils/urlUtil";
import {LanguageType} from "types/enums/languageType";

const Footer = ({isKeyboardOpen}: {isKeyboardOpen?: boolean}) => {
  const provider = useSelector<State, ProductSummary | undefined>(getSelectedProvider);
  const {i18n} = useTranslation();
  const {image} = useSelector(getFooterTextImage)(i18n.language, provider?.organizationName);
  const isMortgageProviderLogoEnabled = useSelector(getIsMortgageProviderLogoEnabled);

  // Do not display a footer if there's nothing to display.
  if (
    (!provider?.financialInstitutionComplianceText ||
      !provider?.financialInstitutionComplianceTextFr) &&
    isMortgageProviderLogoEnabled &&
    !image &&
    !isCurrentUrlAnyOf([RouteUrl.LANDING_PAGE_URL, RouteUrl.SELECT_PRODUCT_PROVIDER_URL])
  ) {
    return <></>;
  }

  return (
    <div className={classNames(styles.root, {[styles.keyboardOpen]: isKeyboardOpen})}>
      {isMortgageProviderLogoEnabled &&
        !isCurrentUrlAnyOf([RouteUrl.LANDING_PAGE_URL, RouteUrl.SELECT_PRODUCT_PROVIDER_URL]) && (
          <img
            src={
              provider?.financialInstitutionLogo
                ? `${window._env_.REACT_APP_IMGIX_DOMAIN}/${provider.financialInstitutionLogo}?auto=format`
                : image
            }
          ></img>
        )}
      <p className={styles.footerTextComponent}>
        {i18n.language === LanguageType.EN_CA
          ? provider?.financialInstitutionComplianceText
          : provider?.financialInstitutionComplianceTextFr}
      </p>
    </div>
  );
};

export default Footer;
