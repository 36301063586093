import {PropertyAddressDto} from "types/dto/propertyAddressDto";
import {toProvince} from "./provinceUtil";

export function toPropertyAddressDtoFromGoogleAddress(
  googleAddressComponents: google.maps.GeocoderAddressComponent[]
): PropertyAddressDto {
  if (!googleAddressComponents) {
    return {};
  }

  const streetNo = googleAddressComponents.find((item) =>
    item.types.includes("street_number")
  )?.long_name;

  const dto: PropertyAddressDto = {
    streetNo: streetNo == undefined ? undefined : Number(streetNo),
    unitNo:
      googleAddressComponents.find((item) => item.types.includes("subpremise"))?.long_name ?? "",
    streetName:
      googleAddressComponents.find((item) => item.types.includes("route"))?.long_name ?? "",
    city: googleAddressComponents.find((item) => item.types.includes("locality"))?.long_name ?? "",
    province: toProvince(
      googleAddressComponents.find((item) => item.types.includes("administrative_area_level_1"))
        ?.long_name ?? ""
    ),
    postCode:
      googleAddressComponents.find((item) => item.types.includes("postal_code"))?.long_name ?? "",
  };
  return dto;
}
