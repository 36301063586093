export function either<T = any>(value1: T, value2: T): T {
  return value1 != null ? value1 : value2;
}

export const noopPreventDefault = (event: any) => {
  event?.stopPropagation();
  event?.preventDefault();
};

export const noopPreventDefaultIfEnter = (event: any) => {
  if (event?.key === "Enter") {
    event?.stopPropagation();
    event?.preventDefault();
  }
};
