import * as yup from "yup";
import {useTranslation} from "react-i18next";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {
    keyPrefix: "components.organisms.setPasswordForm",
  });

  return yup.object().shape({
    password: yup.string().test("passwordValidation", fixedT("passwordInvalid")!, function (value) {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      return passwordRegex.test(value!);
    }),
    confirmPassword: yup
      .string()
      .test("passwordMatch", fixedT("passwordMismatch")!, function (value) {
        return value === this.parent.password;
      }),
  });
};
