export enum TrackingEventType {
  bankingAddBank = "Banking - Click Add Bank Button",
  bankingClickNextButton = "Banking - Click Next Button",
  bankingClickBackButton = "Banking - Click Back Button",
  clickLogin = "Click Login",
  clickResentVerification = "Click Resent Verification",
  clickHomePage = "Click Home Page",
  flinksStepClickBackButton = "Flinks Step - Click Back Button",
  assetsLiabilitiesChangeAssetAmount = "Assets/Liabilities - Change Asset Amount",
  assetsLiabilitiesAddAsset = "Assets/Liabilities - Click Add Assets",
  assetsLiabilitiesRemoveAssetButton = "Assets/Liabilities - Click Remove Asset Button",
  assetsLiabilitiesChangeLiabilityAmount = "Assets/Liabilities - Change Liability Amount",
  assetsLiabilitiesAddLiability = "Assets/Liabilities - Click Add Liability",
  assetsLiabilitiesRemoveLiabilityButton = "Assets/Liabilities - Click Remove Liability Button",
  assetsLiabilitiesClickNextButton = "Assets/Liabilities - Click Next Button",
  assetsLiabilitiesClickBackButton = "Assets/Liabilities - Click Back Button",
  assetsLiabilitiesClickSkipButton = "Assets/Liabilities - Click Modal Skip Button",
  selectLanguage = "Select Language",
  signInEditEmail = "Sign In Page - Change Email",
  signInEditPassword = "Sign In Page - Change Password",
  signInClickCreateAccount = "Sign In Page - Click Create Account",
  signInClickResetPassword = "Sign In Page - Click Reset Password",
  signInClickContinueButton = "Sign In Page - Click Continue Button",
  signUpPageEditFirstName = "Sign Up Page - Change first name",
  signUpPageEditLastName = "Sign Up Page - Change last name",
  signUpPageEditEmail = "Sign Up Page - Change email",
  signUpPageEditPhoneNo = "Sign Up Page - Change phone number",
  signUpPageSubmit = "Sign Up Page - Submit sign up form",
  signUpPageChooseApplicationType = "Sign Up Page - Choose application type",
  inviteSecondaryApplicantEditFirstName = "Invite Secondary Applicant - Change first name",
  inviteSecondaryApplicantEditLastName = "Invite Secondary Applicant - Change last name",
  inviteSecondaryApplicantEditEmail = "Invite Secondary Applicant - Change email",
  inviteSecondaryApplicantEditRelationshipType = "Invite Secondary Applicant - Change relationship type",
  inviteSecondaryApplicantSubmit = "Invite Secondary Applicant - Click Continue Button",
  secondarySignUpPageEditFirstName = "Secondary Sign Up Page - Change first name",
  secondarySignUpPageEditLastName = "Secondary Sign Up Page - Change last name",
  secondarySignUpPageEditEmail = "Secondary Sign Up Page - Change email",
  secondarySignUpPageEditPhoneNo = "Secondary Sign Up Page - Change phone number",
  secondarySignUpPageSubmit = "Secondary Sign Up Page - Submit sign up form",
  personalProfileConsentAgree = "Disclosure - Click on agree button for disclosure",
  personalProfileConsentDisagree = "Disclosure - Click on disagree button for disclosure",
  personalProfilePrivacyPolicyRead = "Privacy Policy - Scroll to the bottom",
  personalProfilePrivacyPolicyDisagree = "Privacy Policy - Click on disagree button for privacy policy",
  personalProfilePrivacyPolicyAgree = "Privacy Policy - Click on agree button for privacy policy",
  personalProfileSubmit = "Personal Profile - Click Next",
  personalProfileClickFirstName = "Personal Profile - Click First Name",
  personalProfileClickLastName = "Personal Profile - Click Last Name",
  personalProfileChangePhoneNo = "Personal Profile - Change Phone Number",
  liabilitiesChangeDependents = "Liabilities - Change Number of Dependents",
  liabilitiesClickChildSupportYes = "Liabilities - Click Yes for Child Support",
  liabilitiesClickChildSupportNo = "Liabilities - Click No for Child Support",
  liabilitiesClickRemoveLiability = "Liabilities - Click Remove Liability",
  liabilitiesClickAddLiability = "Liabilities - Click Add Liability",
  liabilitiesClickBackButton = "Liabilities - Click Back Button",
  liabilitiesClickNextButton = "Liabilities - Click Next Button",
  liabilitiesChangeLiabilityType = "Liabilities - Change Liability Type",
  liabilitiesChangeLiabilityPurpose = "Liabilities - Change Liability Purpose",
  liabilitiesChangeLiabilityMonthlyPaymentAmount = "Liabilities - Change Liability Monthly Payment Amount",
  liabilitiesChangeLiabilityBalance = "Liabilities - Change Liability Balance",
  eqChangeFirstName = "Equifax - Change First Name",
  eqChangeLastName = "Equifax - Change Last Name",
  eqChangeSIN = "Equifax - Change SIN",
  eqChangeBirthDate = "Equifax - Change Birth Date",
  eqClickBackButton = "Equifax - Click Back Button",
  eqClickSkipButton = "Equifax - Click Skip Button",
  eqClickNextButton = "Equifax - Click Next Button",
  preBankingClickBackButton = "Pre-Banking - Click Back Button",
  preBankingClickNextButton = "Pre-Banking - Click Next Button",
  employmentInfoEditEmployer = "Employment-Info - Change Employer",
  employmentInfoEditJobTitle = "Employment-Info - Change Job Title",
  employmentInfoEditIndustryType = "Employment-Info - Change Industry Type",
  employmentInfoEditOccupationType = "Employment-Info - Change Occupation Type",
  employmentInfoEditIncomeType = "Employment-Info - Change Income Type",
  employmentInfoEditEmploymentType = "Employment-Info - Change Employment Type",
  employmentInfoEditDurationOfEmployment = "Employment-Info - Change Duration of Employment",
  employmentInfoEditPreviousEmployer = "Employment-Info - Change Previous Employer",
  employmentInfoEditTimeAtPreviousJob = "Employment-Info - Change Time at Previous Job",
  employmentInfoEditGrossIncome = "Employment-Info - Change Gross Income",
  employmentInfoClickBackButton = "Employment-Info - Click Back Button",
  employmentInfoClickNextButton = "Employment-Info - Click Next Button",
  providerPageSelectBank = "Provider Page - Select Bank",
  providerPageSelectMortgageLender = "Provider Page - Select Mortgage Lender",
  addressInfoChangeCurrentStreetNo = "Address Info - Change Current Street Number",
  addressInfoChangeCurrentSuiteNo = "Address Info - Change Current Suite Number",
  addressInfoChangeCurrentStreetName = "Address Info - Change Current Street Name",
  addressInfoChangeCurrentCity = "Address Info - Change Current City",
  addressInfoChangeCurrentProvince = "Address Info - Change Current Province",
  addressInfoChangeCurrentPostalCode = "Address Info - Change Current Postal Code",
  addressInfoChangeCurrentOwnershipStatus = "Address Info - Change Current Ownership Status",
  addressInfoChangeCurrentTimeAtAddress = "Address Info - Change Current Time At Address",
  addressInfoChangePreviousSuiteNo = "Address Info - Change Previous Suite Number",
  addressInfoChangePreviousStreetNo = "Address Info - Change Previous Street Number",
  addressInfoChangePreviousStreetName = "Address Info - Change Previous Street Name",
  addressInfoChangePreviousCity = "Address Info - Change Previous City",
  addressInfoChangePreviousProvince = "Address Info - Change Previous Province",
  addressInfoChangePreviousPostalCode = "Address Info - Change Previous Postal Code",
  addressInfoClickBackButton = "Address Info - Click Back Button",
  addressInfoClickNextButton = "Address Info - Click Next Button",
  creditPageClickBackButton = "Credit Score - Click Back Button",
  creditPageClickNextButton = "Credit Score - Click Next Button",
  creditPageSelectCreditScore = "Credit Score - Select Credit Score",
  creditPageChangeBirthDate = "Credit Score - Change Birth Date",
  uploadDocumentsClickT4 = "Upload Documents - Click T4",
  uploadDocumentsClickNOA = "Upload Documents - Click NOA",
  uploadDocumentsClickPayStub = "Upload Documents - Click Pay Stub",
  uploadDocumentsClickBankStatement = "Upload Documents - Click Bank Statement",
  uploadDocumentsClickMLS = "Upload Documents - Click MLS",
  uploadDocumentsClickClosingDocument = "Upload Documents - Click Closing Document",
  uploadDocumentsClickBackButton = "Upload Documents - Click Back Button",
  uploadDocumentsClickNextButton = "Upload Documents - Click Next Button",
  lenderClickSeeMore = "Lender Card - Click See More Button",
  lenderClickSubmit = "Lender Card - Click Submit Button",
  lenderClickBack = "Lender Card - Click Back Button",
  lenderNoQualificationClickEdit = "No Qualification - Click Edit Button",
  mortgageSelectionClickPreApproval = "Mortgage Selection - Click 'Review Rate' Button (Pre-Approval)",
  mortgageSelectionClickNewPurchase = "Mortgage Selection - Click 'Add Property' Button (New-Purchase)",
  mortgageSelectionClickRenewal = "Mortgage Selection - Click 'Renew or Switch' Button (Renewal)",
  mortgageSelectionClickBack = "Mortgage Selection - Click Back Button",
  mortgageTypePreApprovalFillPurchaseCity = "Mortgage Type/Pre-Approval - Fill Purchase City",
  mortgageTypePreApprovalFillDownPaymentAmount = "Mortgage Type/Pre-Approval - Fill Down Payment Amount",
  mortgageTypePreApprovalFillDownPaymentPercentage = "Mortgage Type/Pre-Approval - Fill Down Payment Percentage",
  mortgageTypePreApprovalClickHelp = "Mortgage Type/Pre-Approval - Click 'I Need Help with my Downpayment Percentage'", // Removed, keeping in case we bring it back
  mortgageTypePreApprovalClickBack = "Mortgage Type/Pre-Approval - Click Back Button",
  mortgageTypePreApprovalClickNext = "Mortgage Type/Pre-Approval - Click Next Button",
  mortgageTypeNewPurchaseFillPurchasePrice = "Mortgage Type/New Purchase - Fill Purchase Price",
  mortgageTypeNewPurchaseFillDownPaymentSize = "Mortgage Type/New Purchase - Fill Down Payment Size",
  mortgageTypeNewPurchaseFillClosingDate = "Mortgage Type/New Purchase - Fill Closing Date",
  mortgageTypeNewPurchaseClickBack = "Mortgage Type/New Purchase - Click Back Button",
  mortgageTypeNewPurchaseClickNext = "Mortgage Type/New Purchase - Click Next Button",
  mortgageTypeRenewalFillMortgageBalance = "Mortgage Type/Renewal - Fill Mortgage Balance",
  mortgageTypeRenewalFillAmortizationRemaining = "Mortgage Type/Renewal - Fill Amortization Remaining",
  mortgageTypeRenewalFillEstimatedPropertyValue = "Mortgage Type/Renewal - Fill Estimated Property Value",
  mortgageTypeRenewalFillRenewalDate = "Mortgage Type/Renewal - Fill Renewal Date",
  mortgageTypeRenewalClickBack = "Mortgage Type/Renewal - Click Back Button",
  mortgageTypeRenewalClickNext = "Mortgage Type/Renewal - Click Next Button",
  propertyInformationNewPurchaseClickBack = "Property Information/New Purchase - Click Back Button",
  propertyInformationNewPurchaseClickNext = "Property Information/New Purchase - Click Next Button",
  propertyInformationRenewalClickBack = "Property Information/Renewal - Click Back Button",
  propertyInformationRenewalClickNext = "Property Information/Renewal - Click Next Button",
  selectLendersClickBack = "Select Lenders - Click Back Button",
  purchaseTimeframePageWithin30Days = "Purchase Timeframe - Click 'Within 30 days'",
  purchaseTimeframePage2to3Months = "Purchase Timeframe - Click '2-3 months'",
  purchaseTimeframePage3to6Months = "Purchase Timeframe - Click '3-6 months'",
  purchaseTimeframePageMoreThan6Months = "Purchase Timeframe - Click 'More than 6 months'",
}
