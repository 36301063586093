import {WhiteLabelDetailsDto} from "types/WhiteLabelDetailsDto";
import {WithDataError} from "types/api";
import {WhiteLabelProductGroupDto} from "types/dto/WhiteLabelProductGroupDto";
import {UUID} from "types/uuid";
import {normalizedFetchApi} from "./util";

const baseUrl = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/whiteLabels`;

export const fetchWhiteLabelApi = async (
  whitelabelNameOrPlatformInTheme: string
): Promise<WithDataError<WhiteLabelDetailsDto>> => {
  return normalizedFetchApi(() => fetch(`${baseUrl}/name/${whitelabelNameOrPlatformInTheme}`)); // We can't switch from name since that's how we create a theme
};

export const fetchMortgageProvidersApi = async (
  whitelabelId: UUID,
  mortgageProviderId: UUID | undefined
): Promise<WithDataError<WhiteLabelProductGroupDto>> => {
  if (mortgageProviderId) {
    return normalizedFetchApi(() => fetch(`${baseUrl}/products/${whitelabelId}?mortgageProviderId=${mortgageProviderId}`));
  }

  return normalizedFetchApi(() => fetch(`${baseUrl}/products/${whitelabelId}`));
}
