export enum IndustryType {
  BANKING_OR_FINANCE = "BANKING_OR_FINANCE",
  CONSTRUCTION = "CONSTRUCTION",
  EDUCATION = "EDUCATION",
  FARMING_OR_NATURAL_RESOURCES = "FARMING_OR_NATURAL_RESOURCES",
  GOVERNMENT = "GOVERNMENT",
  HEALTH = "HEALTH",
  HIGH_TECH = "HIGH_TECH",
  LEISURE_OR_ENTERTAINMENT = "LEISURE_OR_ENTERTAINMENT",
  MANUFACTURING = "MANUFACTURING",
  RETAIL_SALES = "RETAIL_SALES",
  SERVICES = "SERVICES",
  TRANSPORTATION = "TRANSPORTATION",
  VARIES = "VARIES",
  OTHER = "OTHER",
}
