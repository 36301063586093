import StatusScreen from "components/templates/StatusScreen/StatusScreen";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getFixedT} from "util/languageUtil";
import {edit} from "./NoLendersPage.action";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {getContactInfo} from "store/selectors/basicInfo";

const fixedT = getFixedT("NoLendersPage");

const NoLendersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {email} = useSelector(getContactInfo);

  return (
    <StatusScreen
      title={fixedT("title")!}
      descriptions={fixedT("descriptions", {email, returnObjects: true}) as string[]}
      backButtonText={fixedT("backButton")!}
      onBack={() => {
        trackEvent(TrackingEventType.lenderNoQualificationClickEdit);
        dispatch(edit({navigate}));
      }}
    />
  );
};

export default NoLendersPage;
