import {isValidDate} from "components/utils/dateUtil";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

function isValidAge(date: Date) {
  const diff = Date.now() - date.getTime();
  const age = new Date(diff);
  return age.getUTCFullYear() - 1970 >= 18;
}

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "basicInfoSteps.sinForm"});

  return yup.object().shape({
    legalFirstName: yup
      .string()
      .trim()
      .required(fixedT("messages.missing", {context: "legalFirstName"})!),
    legalLastName: yup
      .string()
      .trim()
      .required(fixedT("messages.missing", {context: "legalLastName"})!),
    dateOfBirth: yup
      .string()
      .trim()
      .required(fixedT("invalidBirthDate")!)
      .test({
        name: "dateOfBirth",
        test(dateOfBirth, ctx) {
          const date = new Date(dateOfBirth);
          if (isValidDate(date)) return true;
          return ctx.createError({
            path: "dateOfBirth",
            message: fixedT("invalidBirthDate")!,
          });
        },
      })
      .test({
        name: "dateOfBirth",
        test(dateOfBirth, ctx) {
          if (isValidAge(new Date(dateOfBirth))) return true;
          return ctx.createError({
            path: "dateOfBirth",
            message: fixedT("invalidUnderage")!,
          });
        },
      }),
  });
}
