import {PropertyAddressDto} from "types/dto/propertyAddressDto";
import {root, textSection, selected as selectedClass} from "./AddressBox.module.scss";
import {getFixedT} from "util/languageUtil";

const t = getFixedT("addressBox");

interface AddressBoxProps {
  address?: PropertyAddressDto;
  handleClick?: () => void;
  selected?: boolean;
}

const AddressBox = ({address, handleClick = () => {}, selected = false}: AddressBoxProps) => {
  if (!address) {
    return null;
  }

  return (
    <div
      className={`
        ${root}
        ${selected ? selectedClass : "not-selected"}
      `}
      onClick={handleClick}
      tabIndex={0}
    >
      <div className={textSection}>
        {address.unitNo && <span>{address.unitNo} - </span>}
        {address.streetNo} {address.streetName} Street, {address.city},{" "}
        {t(`provinces.${address.province}`)}, {address.postCode}
      </div>
    </div>
  );
};
export default AddressBox;
