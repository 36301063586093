import {isValidPhoneNumber, isEmail} from "components/utils/stringUtil";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "components.organisms.SignUpForm"});

  return yup.object().shape({
    firstName: yup.string().trim().required(fixedT("messages.missingFirstName")!),
    lastName: yup.string().trim().required(fixedT("messages.missingLastName")!),
    email: yup
      .string()
      .trim()
      .required(fixedT("messages.invalidEmail")!)
      .test({
        name: "email",
        test(email, ctx) {
          if (isEmail(email)) return true;
          return ctx.createError({
            path: "email",
            message: fixedT("messages.invalidEmail")!,
          });
        },
      }),
    phoneNumber: yup
      .string()
      .trim()
      .required(fixedT("messages.invalidPhoneNumber")!)
      .test({
        name: "phoneNumber",
        test(phoneNumber, ctx) {
          if (isValidPhoneNumber(phoneNumber)) return true;
          return ctx.createError({
            path: "phoneNumber",
            message: fixedT("messages.invalidPhoneNumber")!,
          });
        },
      }),
    applicationType: yup
      .mixed<ResidentialApplicationType>()
      .required(fixedT("messages.missingApplicationType")!),
    emailOptIn: yup.boolean().notRequired(),
  });
};
