import React, {useState, useRef, ReactNode} from "react";
import {root} from "./DisclaimerBox.module.scss";

interface DisclaimerBoxProps {
  children?: ReactNode;
  onDisclaimerRead?: () => void;
}

const DisclaimerBox = ({children = <></>, onDisclaimerRead = () => {}}: DisclaimerBoxProps) => {
  const [disclaimerRead, setDisclaimerRead] = useState(false),
    elementalReference: React.MutableRefObject<any> = useRef();
  function registerElement(element: any) {
    if (elementalReference.current) {
      elementalReference.current.removeEventListener("scroll", checkDisclaimerRead);
      elementalReference.current.removeEventListener("mouseover", checkDisclaimerRead);
    }
    elementalReference.current = element;
    if (elementalReference.current) {
      checkDisclaimerRead();
      elementalReference.current.addEventListener("mouseover", checkDisclaimerRead);
      elementalReference.current.addEventListener("scroll", checkDisclaimerRead);
    }
  }

  function checkDisclaimerRead() {
    if (
      elementalReference.current.clientHeight > 1 &&
      elementalReference.current.scrollHeight -
        elementalReference.current.scrollTop -
        elementalReference.current.clientHeight < 5
    ) {
      setDisclaimerRead(true);
      onDisclaimerRead();
    }
  }

  return (
    <div className={root} ref={registerElement}>
      {children}
    </div>
  );
};

export default DisclaimerBox;
