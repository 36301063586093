import {useState} from "react";
import i18next from "i18next";
import {root, header, options, resendButton} from "./ResendEmailForm.module.scss";
import Message from "components/atoms/Message/Message";
import RegistrationComplete from "components/organisms/RegistrationComplete/RegistrationComplete";
import {useSelector} from "react-redux";
import {resetPasswordApi} from "api/auth";
import SubmitButton from "../SubmitButton/SubmitButton";
import {getFixedT} from "util/languageUtil";
import Typography from "@mui/material/Typography/Typography";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./ResentEmailForm.schema";
import TextFieldControlled from "../Form/TextFieldControlled/TextFieldControlled";
import Form from "components/atoms/Form/Form";
import {getWhitelabelId} from "store/selectors/whitelabel";

const fixedT = getFixedT("components.organisms.resendEmailForm");
interface ResendEmailFormPayload {
  email: string;
  confirmEmail: string;
}

const ResendEmailForm = () => {
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const whiteLabelId = useSelector(getWhitelabelId);

  const formHook = useForm<ResendEmailFormPayload>({
    defaultValues: {
      email: "",
      confirmEmail: "",
    },
    resolver: yupResolver(getSchema()),
  });

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  async function submitEmail(values: ResendEmailFormPayload) {
    setError("");
    setSubmitting(true);

    const {error} = await resetPasswordApi(
      whiteLabelId!,
      values.email,
      location.host,
      i18next.language
    );

    setSubmitting(false);
    if (error) {
      console.error("resetPasswordApi failed", error);
      setError(fixedT("error")!);
      return;
    }
    setSentEmail(true);
  }

  if (sentEmail) {
    return (
      <div className={root}>
        <RegistrationComplete />
      </div>
    );
  }
  return (
    <Form className={root} onEnter={formHook.handleSubmit(submitEmail)}>
      <Typography className={header} variant="h1">
        {fixedT("header")}
      </Typography>
      <Message message={error} />
      <div className={options}>
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="email"
          type="email"
          label={fixedT("fields.email.label")}
          placeholder={fixedT("fields.email.placeholder")!}
          disabled={submitting}
          autoComplete={"email"}
          required
        />
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="confirmEmail"
          type="email"
          label={fixedT("fields.confirmEmail.label")}
          placeholder={fixedT("fields.confirmEmail.placeholder")!}
          disabled={submitting}
          autoComplete={"email"}
          required
        />
      </div>
      <div className={resendButton}>
        <SubmitButton
          isSubmitting={submitting}
          isEnabled={
            !!formHook.getValues("email") &&
            formHook.getValues("email") === formHook.getValues("confirmEmail")
          }
          onClick={formHook.handleSubmit(submitEmail)}
          text={fixedT("forwardButton")}
        />
      </div>
    </Form>
  );
};

export default ResendEmailForm;
