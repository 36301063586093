import {takeEvery} from "redux-saga/effects";
import {WithPayload} from "types/basic";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {submitPreBankingScreen, navigatePreviousStep} from "./PreBankingScreen.action";

function* onSubmit({payload: {navigate}}: WithPayload<WithNavigate>) {
  yield navigate(RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO);
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(RouteUrl.BASIC_INFO_URL_ADDRESS);
}

export default function* preBankingScreenSaga() {
  yield takeEvery(submitPreBankingScreen, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
