import ListItemIcon from "@mui/material/ListItemIcon";
import {faPhone, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {List, ListItemButton, ListItemText, Collapse, ListItem, Tooltip} from "@mui/material";
import {signOut} from "store/actions/auth.action";
import {TrackingEventType} from "types/enums/trackingEventType";
import {RouteUrl} from "types/route";
import {trackEvent} from "util/eventUtil";
import {navigateStep} from "../NavigationBar/NavigationBar.action";
import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getContactInfo} from "store/selectors/basicInfo";
import {getHasUserAccount} from "store/selectors/user";
import {useTranslation} from "react-i18next";
import {getHasUserSelectedProvider} from "store/selectors/whitelabel";

export default function NavigationBarActionSection() {
  const {t} = useTranslation();

  const [contactToggleOpen, setContactToggleOpen] = useState(false);

  const hasUserAccount = useSelector(getHasUserAccount);
  const {email, phoneNumber} = useSelector(getContactInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasSelectedProvider = useSelector(getHasUserSelectedProvider);

  return (
    <List>
      {hasUserAccount ? (
        <ListItemButton
          onClick={() => {
            dispatch(signOut());
          }}
        >
          <ListItemText primary={t("navigationBar.buttons.signOut")} />
        </ListItemButton>
      ) : (
        <ListItemButton
          onClick={() => {
            trackEvent(TrackingEventType.clickLogin);
            dispatch(
              navigateStep({
                url: RouteUrl.SIGN_IN,
                navigate,
                providerNotChosenError: t("errors.providerNotChosen"),
                userNotCreatedError: t("errors.userNotCreated"),
              })
            );
          }}
        >
          <ListItemText primary={t("navigationBar.buttons.signIn")} />
        </ListItemButton>
      )}
      {hasSelectedProvider && (
        <>
          <ListItemButton onClick={() => setContactToggleOpen(!contactToggleOpen)}>
            <ListItemText primary={t("navigationBar.toggle.contactToggle")} />
            {contactToggleOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={contactToggleOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem sx={{gap: "10px"}}>
                <ListItemIcon sx={{minWidth: "unset"}}>
                  <FontAwesomeIcon icon={faPhone} />
                </ListItemIcon>
                <ListItemText
                  primary={phoneNumber}
                  sx={{
                    wordWrap: "break-word",
                  }}
                />
              </ListItem>
              <ListItem sx={{gap: "10px"}}>
                <ListItemIcon sx={{minWidth: "unset"}}>
                  <FontAwesomeIcon icon={faMailBulk} />
                </ListItemIcon>
                <Tooltip title={email}>
                  <ListItemText
                    primary={t("navigationBar.emailUs")}
                    onClick={(e) => {
                      window.location.href = `mailto:${email}`;
                      e.preventDefault();
                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </ListItem>
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}
