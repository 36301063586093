import {Typography} from "@mui/material";
import {default as styles} from "./StepContent.module.scss";
import classNames from "classnames";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import {WithChildren, WithClassName} from "types/ui";

interface Props extends WithChildren, WithClassName {
  headerText: string;
  backButton?: JSX.Element;
  nextButton: JSX.Element;
}

const StepContent = ({
  headerText,
  children,
  backButton = <></>,
  nextButton = <></>,
  className,
}: Props) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Typography className={styles.header} variant="h1" textAlign="center">
        {headerText}
      </Typography>
      <div className={styles.content}>{children}</div>
      <NavigationSection>
        {backButton}
        {nextButton}
      </NavigationSection>
    </div>
  );
};

export default StepContent;
