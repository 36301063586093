import {SagaIterator} from "redux-saga";
import {call, takeEvery, select} from "redux-saga/effects";
import i18next from "i18next";

import {getUserId} from "store/selectors/user";
import {changeLanguageOperation} from "store/operation/operations";
import {changeLanguage, ChangeLanguagePayload} from "./LanguagesToggle.action";
import {WithPayload} from "types/basic";
import {LanguageTypeCaption, toLanguageTypeOrDefault} from "util/languageUtil";
import {LanguageType} from "types/enums/languageType";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";

export function changeLocalLanguage(locale: LanguageType) {
  trackEvent(TrackingEventType.selectLanguage, LanguageTypeCaption[locale]);

  i18next.changeLanguage(locale);
}

export function* onChangeLanguage({
  payload: {locale},
}: WithPayload<ChangeLanguagePayload>): SagaIterator {
  const lang = toLanguageTypeOrDefault(locale);

  if (!lang || lang === i18next.language) {
    return;
  }

  const userId = yield select(getUserId);

  // update user account if applicable
  if (userId && locale) {
    yield call(changeLanguageOperation.saga, lang, userId);
  }

  yield call(changeLocalLanguage, lang);
}

export default function* languagesToggleSaga() {
  yield takeEvery(changeLanguage, onChangeLanguage);
}
