import {classNames} from "components/utils/stringUtil";
import React from "react";
import {WithClassName} from "types/ui";

import {root} from "./TwoFieldsContainer.module.scss";

interface Props extends WithClassName {
  left: React.ReactNode;
  right: React.ReactNode;
}

const TwoFieldsContainer = ({left, right, className}: Props) => {
  return (
    <div className={classNames(root, className)}>
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
};

export default TwoFieldsContainer;
