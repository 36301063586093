import {useEffect, useState} from "react";
import {default as styles} from "./AddBankStep.module.scss";
import BackButton from "components/molecules/BackButton/BackButton";
import Button from "components/atoms/Button/Button";
import {useNavigate} from "react-router-dom";
import Message from "../../../components/atoms/Message/Message";
import {useDispatch, useSelector} from "react-redux";
import {getBanks, getCanAddBank} from "store/selectors/basicInfo";
import {RouteUrl} from "types/route";
import {getIsAppInitializing, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {initialize, navigatePreviousStep} from "./AddBankStep.action";
import {getFixedT} from "util/languageUtil";
import {MessageType} from "types/ui";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import StepContent from "components/organisms/StepContent/StepContent";
import Typography from "@mui/material/Typography/Typography";
import {storeNavigationError} from "components/molecules/NavigationBar/NavigationBar.action";

const fixedT = getFixedT("basicInfoSteps.addBankStep");

const AddBankStep = ({className = ""}) => {
  // Display added num of banks if any; Display add bank button if applicable.
  // request backend for adding new bank from flinks-screen, on initializing, with loginId, inistitution, provided by flinks-screen's redirect url
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const canAddBank = useSelector(getCanAddBank);
  const banks = useSelector(getBanks);

  const isAppLoading = useSelector(getIsAppInitializing);
  const {failure} = useSelector(getOperationResult)(OperationType.submitNewBank);
  const [isPending, setIsPending] = useState(false);

  function displayBankMessage(financialDataId?: string, error?: string, isDone?: boolean): string {
    if (error) {
      return fixedT("bankError");
    }
    if (financialDataId && isDone) {
      return fixedT("bankAdded");
    }
    return fixedT("bankPending");
  }

  useEffect(() => {
    if (banks.length > 0 && !banks[banks.length - 1].isDone) {
      setIsPending(true);
    }
  }, [banks]);

  function renderExistingBanksAndNewBankEntries() {
    return isAppLoading ? null : (
      <div className={`${styles.entries} ${className}`}>
        {banks
          .map(({loginId, financialDataId, error, isDone}, index) => {
            if (!loginId) {
              return null;
            }
            if (error) {
              dispatch(storeNavigationError(fixedT("bankError")));
            }
            return (
              <div className={styles.bankingInformation} key={`added-bank-${index}`}>
                <span>{displayBankMessage(financialDataId, error, isDone)}</span>
                {!error && isDone && Boolean(financialDataId) && (
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="15.5" cy="15.5" r="14.5" stroke="currentcolor" strokeWidth="2" />
                    <line
                      x1="22.0073"
                      y1="10.4034"
                      x2="13.403"
                      y2="21.4473"
                      stroke="currentcolor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <line
                      x1="13.3108"
                      y1="21.1026"
                      x2="9.93666"
                      y2="17.4128"
                      stroke="currentcolor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                )}
              </div>
            );
          })
          .filter(Boolean)}
        {canAddBank && (
          <Button
            onClick={() => {
              trackEvent(TrackingEventType.bankingAddBank);
              navigate(RouteUrl.BASIC_INFO_URL_FLINKS_SCREEN);
            }}
            className={styles.addBankButton}
          >
            {fixedT("addBankButton")}
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15.5" cy="15.5" r="14.5" stroke="currentcolor" strokeWidth="2" />
              <line
                x1="16"
                y1="8"
                x2="16"
                y2="22"
                stroke="currentcolor"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <line
                x1="23"
                y1="15"
                x2="9"
                y2="15"
                stroke="currentcolor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        )}
      </div>
    );
  }

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  // add a bank -> flinks-screen, and will be redirected back here when completed
  return (
    <StepContent
      headerText={fixedT("header1")}
      backButton={
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.bankingClickBackButton);
            dispatch(navigatePreviousStep({navigate}));
          }}
        />
      }
      nextButton={
        <SubmitButton
          className={styles.submitButton}
          onClick={() => {
            trackEvent(TrackingEventType.bankingClickNextButton);
            navigate(RouteUrl.BASIC_INFO_URL_SIN_FORM);
          }}
          text={fixedT("forwardButton")}
        />
      }
    >
      <div className={styles.root}>
        <Typography variant="h3" className={styles.headers}>
          {fixedT("header2")}
        </Typography>
        {isPending && <Message type={MessageType.info} message={fixedT("messages.pending")} />}
        {renderExistingBanksAndNewBankEntries()}
        <Message message={failure} />
      </div>
    </StepContent>
  );
};

export default AddBankStep;
