import {all} from "redux-saga/effects";
import loginFormSaga from "components/organisms/LoginForm/LoginForm.saga";
import setPasswordSaga from "components/organisms/SetPasswordForm/SetPasswordForm.saga";
import userProfileBadgeSaga from "components/organisms/UserProfileBadge/UserProfileBadge.saga";
import languagesToggleSaga from "components/molecules/LanguagesToggle/LanguagesToggle.saga";
import rootComponentSaga from "pages/Root/Root.saga";
import personalProfileSaga from "pages/BasicInfoSteps/PersonalProfile/PersonalProfile.saga";
import employmentInfoSaga from "pages/BasicInfoSteps/EmploymentInfo/EmploymentInfo.saga";
import addressInfoSaga from "pages/BasicInfoSteps/AddressInfo/AddressInfo.saga";
import addBankStepSaga from "pages/BasicInfoSteps/AddBankStep/AddBankStep.saga";
import sinFormSaga from "pages/BasicInfoSteps/SINForm/SINForm.saga";
import mortgageSelectionSaga from "pages/MortgageInfoSteps/MortgageSelection/MortgageSelection.saga";
import mortgageTypeSaga from "pages/MortgageInfoSteps/MortgageType/MortgageType.saga";
import propertyInformationSaga from "pages/MortgageInfoSteps/PropertyInformation/PropertyInformation.saga";
import selectLendersSaga from "pages/MortgageRatesPages/SelectLenders/SelectLenders.saga";
import unresolvedAVMPageSaga from "pages/UnresolvedAVMPage/UnresolvedAVMPage.saga";
import selfReportedIncomeAssetsSaga from "pages/BasicInfoSteps/SelfReportedAssetsLiabilities/SelfReportedAssetsLiabilities.saga";
import uploadDocumentsSaga from "pages/BasicInfoSteps/UploadDocuments/UploadDocuments.saga";
import creditScorePageSaga from "pages/BasicInfoSteps/CreditScorePage/CreditScorePage.saga";
import inviteSecondaryApplicantSaga from "pages/BasicInfoSteps/InviteSecondaryApplicant/InviteSecondaryApplicant.saga";
import selectProductProviderPageSaga from "pages/SelectProductProviderPage/SelectProductProviderPage.saga";
import secondarySignUpFormSaga from "../../components/organisms/SecondarySignUpForm/SecondarySignUpForm.saga";
import signUpFormSaga from "pages/SignUpPage/SignUpForm/SignUpForm.saga";
import noLendersPageSaga from "pages/MortgageRatesPages/NoLendersPage/NoLendersPage.saga";
import authSaga from "./auth.saga";
import navigationHistorySaga from "components/molecules/NavigationBar/NavigationBar.saga";
import preBankingScreenSaga from "pages/BasicInfoSteps/PreBankingScreen/PreBankingScreen.saga";
import qualificationSaga from "pages/MortgageRatesPages/CalculateQualification/CalculateQualification.saga";

function* rootSaga() {
  yield all([
    authSaga(),
    rootComponentSaga(),
    loginFormSaga(),
    setPasswordSaga(),
    userProfileBadgeSaga(),
    languagesToggleSaga(),
    signUpFormSaga(),
    secondarySignUpFormSaga(),
    personalProfileSaga(),
    employmentInfoSaga(),
    addressInfoSaga(),
    preBankingScreenSaga(),
    addBankStepSaga(),
    sinFormSaga(),
    mortgageSelectionSaga(),
    mortgageTypeSaga(),
    propertyInformationSaga(),
    selectLendersSaga(),
    qualificationSaga(),
    unresolvedAVMPageSaga(),
    selfReportedIncomeAssetsSaga(),
    uploadDocumentsSaga(),
    creditScorePageSaga(),
    inviteSecondaryApplicantSaga(),
    selectProductProviderPageSaga(),
    noLendersPageSaga(),
    navigationHistorySaga(),
  ]);
}

export default rootSaga;
