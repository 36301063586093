import {useState, useEffect, useRef} from "react";
import {Outlet} from "react-router-dom";
import LoadingAnimation from "components/atoms/LoadingAnimation/LoadingAnimation";
import MortgageInfoStep from "components/templates/MortgageInfoStep/MortgageInfoStep";
import MortgageType from "pages/MortgageInfoSteps/MortgageType/MortgageType";
import PropertyInformation from "pages/MortgageInfoSteps/PropertyInformation/PropertyInformation";
import MortgageSelection from "pages/MortgageInfoSteps/MortgageSelection/MortgageSelection";
import {useSelector} from "react-redux";
import {getIsAppInitializing} from "store/selectors/operation";
import {RouteUrl} from "types/route";
import Page from "pages/Page/Page";
import SelectLenders from "pages/MortgageRatesPages/SelectLenders/SelectLenders";

const MortgageInfoPage = () => {
  const isAppInitializing = useSelector(getIsAppInitializing);
  const MORTGAGE_INFO_ELEMENTS: any = {
    [RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION]: MortgageSelection,
    [RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO]: PropertyInformation,
    [RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE]: MortgageType,
    [RouteUrl.PRODUCTS_SELECT_URL]: SelectLenders,
  };
  const [pageIndex, setPageIndex] = useState(-1);
  const [animationClass, setAnimationClass] = useState("next-page");
  const containingElementReference: React.MutableRefObject<any> = useRef();

  useEffect(() => {
    if (containingElementReference.current) containingElementReference.current.scrollTop = 0;
  }, [pageIndex]);

  function getNavAnimationClass(targetPageIndex: number) {
    if (targetPageIndex === pageIndex) return;
    if (pageIndex < targetPageIndex) return setAnimationClass("next-page");
    return setAnimationClass("previous-page");
  }

  return (
    <Page>
      {isAppInitializing && <LoadingAnimation />}
      {!isAppInitializing && (
        <MortgageInfoStep ref={containingElementReference}>
          <Outlet />
        </MortgageInfoStep>
      )}
    </Page>
  );
};

export default MortgageInfoPage;
