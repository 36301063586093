import {classNames} from "components/utils/stringUtil";
import React from "react";
import {root, disabled as disabledClass} from "./TextInput.module.scss";

interface TextInputProps {
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string | undefined;
  defaultValue?: string | number | undefined | null;
  className?: string;
  id?: string;
  value?: string | number;
  disable?: boolean;
  autoComplete?: string;
  [anyKey: string]: any;
}

const TextInput = ({
  type = "text",
  onChange = (e) => {},
  onBlur = (e) => {},
  placeholder = undefined,
  className,
  disable = false,
  defaultValue = undefined,
  ...otherProperties
}: TextInputProps) => {
  return (
    <input
      className={classNames(root, disable ? disabledClass : "", className)}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      placeholder={placeholder}
      defaultValue={defaultValue}
      {...(otherProperties as any)}
    />
  );
};

export default TextInput;
