import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import { ResidentialApplicationQualificationsResponse } from "types/dto/residentialApplicationQualificationsResponse";
import {WithNavigate} from "types/ui";

const namespace = "SelectLenders";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);

export const fetchApplicationStatus = createAction<WithNavigate>(
  actionTypeOf(namespace, "fetchApplicationStatus")
);

export interface SubmitLenderPayload extends WithNavigate {
  lender: ResidentialApplicationQualificationsResponse;
}

export const submit = createAction<SubmitLenderPayload>(actionTypeOf(namespace, "submit"));
