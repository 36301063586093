import {
  fetchBasicInfoByUserIdApi,
  inviteSecondaryApplicantApi,
  setLenderApi,
  updateBasicInfoByUserIdApi,
  uploadFileApi,
} from "api/basicInfo";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {OperationType} from "types/operation";
import {Operation} from "./Operation";

export const fetchBasicInfoByUserIdOperation =
  new Operation<ResidentialApplicationBasicInfoResponse>(
    OperationType.fetchBasicInfoByUserId,
    fetchBasicInfoByUserIdApi
  );

export const acceptPrivacyOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.acceptPrivacy,
  updateBasicInfoByUserIdApi
);

export const submitLoanTypeOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitLoanType,
  updateBasicInfoByUserIdApi
);

export const submitPersonalProfileOperation =
  new Operation<ResidentialApplicationBasicInfoResponse>(
    OperationType.submitPersonalProfile,
    updateBasicInfoByUserIdApi
  );

export const submitEmploymentInfoOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitEmploymentInfo,
  updateBasicInfoByUserIdApi
);

export const submitAddressInfoOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitAddressInfo,
  updateBasicInfoByUserIdApi
);

export const submitNewBankOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitNewBank,
  updateBasicInfoByUserIdApi
);

export const submitLegalNamesSinOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitLegalNamesSin,
  updateBasicInfoByUserIdApi
);

export const submitT4Operation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitT4,
  uploadFileApi
);

export const submitNoaOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitNoa,
  uploadFileApi
);

export const submitPaystubOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitPaystub,
  uploadFileApi
);

export const submitBankStatementOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitBankStatement,
  uploadFileApi
);

export const submitMlsOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitMls,
  uploadFileApi
);

export const submitClosingDocumentOperation =
  new Operation<ResidentialApplicationBasicInfoResponse>(
    OperationType.submitClosingDocument,
    uploadFileApi
  );

export const submitMortgageTypeOperation = new Operation<ResidentialApplicationBasicInfoResponse>(
  OperationType.submitMortgageType,
  updateBasicInfoByUserIdApi
);

export const submitPropertyInformationOperation =
  new Operation<ResidentialApplicationBasicInfoResponse>(
    OperationType.submitPropertyInformation,
    updateBasicInfoByUserIdApi
  );

export const submitUnresolvedAVMPropertyOperation =
  new Operation<ResidentialApplicationBasicInfoResponse>(
    OperationType.submitUnresolvedAVMProperty,
    updateBasicInfoByUserIdApi
  );

export const setLenderOperation = new Operation(OperationType.setLender, setLenderApi);

// direct lender ops
export const submitSelfReportedIncomeAssetsOperation = new Operation(
  OperationType.submitSelfReportedIncomeAssets,
  updateBasicInfoByUserIdApi
);

export const submitSelfReportedLiabilitiesOperation = new Operation(
  OperationType.submitSelfReportedLiabilities,
  updateBasicInfoByUserIdApi
);

export const submitSelfReportedCreditScoreOperation = new Operation(
  OperationType.submitSelfReportedCreditScore,
  updateBasicInfoByUserIdApi
);

export const inviteSecondaryApplicantOperation = new Operation(
  OperationType.inviteSecondaryApplicant,
  inviteSecondaryApplicantApi
);
