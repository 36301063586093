import {useEffect} from "react";
import {useNavigate, useNavigation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {initialize} from "./CalculateQualification.action";
import Page from "pages/Page/Page";
import LoadingHouseAnimation from "components/molecules/LoadingHouseAnimation/LoadingHouseAnimation";

const CalculateQualification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);
  
  return (
    <Page>
      <LoadingHouseAnimation />
    </Page>
  );
};

export default CalculateQualification;
