import {useTranslation} from "react-i18next";
import * as yup from "yup";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {
    keyPrefix: "basicInfoSteps.selfReportedAssetsLiabilities",
  });

  return yup.object({
    otherAssets: yup.array().of(
      yup.object({
        amount: yup.number().typeError(fixedT("required")!).min(0).required(fixedT("required")!),
        description: yup
          .string()
          .typeError(fixedT("required")!)
          .trim()
          .required(fixedT("required")!),
      })
    ),
    otherLiabilities: yup.array().of(
      yup.object({
        amount: yup.number().typeError(fixedT("required")!).min(0).required(fixedT("required")!),
        description: yup
          .string()
          .typeError(fixedT("required")!)
          .trim()
          .required(fixedT("required")!),
      })
    ),
  });
};
