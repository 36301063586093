import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {WithNavigate} from "types/ui";

const namespace = "CreditScorePage";

export const initialize = createAction(actionTypeOf(namespace, "initialize"));

export type SubmitCreditScorePagePayload = WithNavigate &
  Pick<ResidentialApplicationBasicInfoRequest, "creditScore" | "dateOfBirth">;

export const submit = createAction<SubmitCreditScorePagePayload>(actionTypeOf(namespace, "submit"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);

export const saveDateOfBirthAndCreditScore = createAction<SubmitCreditScorePagePayload>(
  actionTypeOf(namespace, "saveDateOfBirthAndCreditScore")
);
