import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {default as styles} from "./SelectProductProviderPage.module.scss";
import classNames from "classnames";
import LenderDisplay from "components/molecules/LenderDisplay/LenderDisplay";
import Message from "components/atoms/Message/Message";
import {useDispatch, useSelector} from "react-redux";
import {initialize, submit} from "./SelectProductProviderPage.action";
import {getHasUserAccount} from "store/selectors/user";
import {ProductSummary} from "types/dto/productSummary";
import {getIconDisplayMapper, getIsRateDisplayed} from "store/selectors/configurations";
import {guessProperRouteUrl} from "util/routeUtil";
import {ProductDisplaySourceType} from "types/ProductDisplaySourceType";
import {
  getBankProvider,
  getHasProviders,
  getLenderProvider,
  getWhitelabelId,
} from "store/selectors/whitelabel";
import {ProductProviderSelection} from "types/dto/ProductProviderSelection";
import {State} from "types/store";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {formatAsPercentage} from "util/numberUtil";
import {Typography} from "@mui/material";
import Page from "pages/Page/Page";
import {getNavigationError} from "store/selectors/navigationController";
import {getFixedT} from "util/languageUtil";
import {removeNavigationError} from "components/molecules/NavigationBar/NavigationBar.action";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import BackButton from "components/molecules/BackButton/BackButton";
import {orderProvidersByCriteria} from "./SelectProductProviderPage.util";

const fixedT = getFixedT("selectProductProviderPage");

interface Props {
  className?: () => any;
  prevPage?: () => any;
  nextPage?: () => any;
}

const SelectProductProviderPage: React.FC<Props> = ({
  className = "",
  prevPage = undefined,
  nextPage = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whitelabelId = useSelector(getWhitelabelId);
  const hasAccount = useSelector(getHasUserAccount);
  const bankProvider = useSelector<State, Partial<ProductProviderSelection> | undefined>(
    getBankProvider
  );
  const lenderProvider = useSelector<State, Partial<ProductProviderSelection> | undefined>(
    getLenderProvider
  );
  const hasProviders = useSelector(getHasProviders);
  const iconDisplayMapper = useSelector(getIconDisplayMapper);
  const isRateDisplayed = useSelector(getIsRateDisplayed);
  const navigationError = useSelector(getNavigationError);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (whitelabelId) {
      dispatch(initialize({navigate}));
    }
  }, [whitelabelId]);

  useEffect(() => {
    setError(navigationError ?? "");
  }, [navigationError]);

  const orderedProviders = orderProvidersByCriteria(
    {...bankProvider, productDisplaySourceType: ProductDisplaySourceType.BANK},
    {...lenderProvider, productDisplaySourceType: ProductDisplaySourceType.LENDER}
  );

  function SourceComponent(props: {displayedSourceType: ProductDisplaySourceType}) {
    const displayProviders =
      props.displayedSourceType === ProductDisplaySourceType.BANK ? bankProvider : lenderProvider;

    return (
      <div className={classNames(styles.displayedSourceType)}>
        <header>
          <h3 className={styles.header}>
            {fixedT("source.header", {context: props.displayedSourceType})}
          </h3>
          {isRateDisplayed && (
            <h4 className={styles.description}>
              {displayProviders?.displayRate &&
                fixedT("source.description", {
                  rate: formatAsPercentage(displayProviders.displayRate * 100),
                })}
            </h4>
          )}
        </header>
        <div className={classNames(styles.products)}>
          {displayProviders?.products?.map((product: ProductSummary, index: number) => {
            const {icon, background} = iconDisplayMapper(product);
            return (
              <LenderDisplay
                key={`${product?.productPublicId}-${index}`}
                iconPath={icon}
                backgroundColor={background}
                organizationName={product?.organizationName}
                productName={product?.productName}
                onClick={() => {
                  if (validate()) {
                    product.displaySourceType == ProductDisplaySourceType.BANK
                      ? trackEvent(TrackingEventType.providerPageSelectBank)
                      : trackEvent(TrackingEventType.providerPageSelectMortgageLender);
                    dispatch(submit(product.financialInstitutionPublicId));
                    hasAccount
                      ? guessProperRouteUrl()
                      : dispatch(removeNavigationError()) && nextPage();
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }

  const validate = () => {
    if (!hasProviders) {
      setError("No mortgage providers fetched");
      return false;
    }
    setError("");
    return true;
  };

  return (
    <Page className={styles.root}>
      <Typography className={styles.mainHeader} align="center" variant="h1">
        {fixedT("header")}
      </Typography>
      <Typography align="center" variant="h6">
        {fixedT("subheader")}
      </Typography>
      <Message message={error} className={styles.error} />
      <div className={styles.content}>
        {orderedProviders.flatMap((provider, index, array) => [
          <SourceComponent
            key={provider.productDisplaySourceType}
            displayedSourceType={provider.productDisplaySourceType!}
          />,
          index === 0 && array.length > 1 ? <h3 key="or">{fixedT("or")}</h3> : null,
        ])}
      </div>
      {prevPage && (
        <NavigationSection>
          <BackButton onClick={prevPage()} />
        </NavigationSection>
      )}
    </Page>
  );
};

export default SelectProductProviderPage;
