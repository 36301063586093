import {TextField, TextFieldProps} from "@mui/material";
import {BaseSyntheticEvent} from "react";
import {Controller, FieldValues, UseFormReturn} from "react-hook-form";
import {TrackingEventType} from "types/enums/trackingEventType";
import {WithChildren} from "types/ui";
import {trackEventMemoized} from "util/eventUtil";

interface TextFieldControlledProps<T extends FieldValues = any, C = any>
  extends Pick<
      TextFieldProps,
      | "name"
      | "type"
      | "required"
      | "disabled"
      | "label"
      | "select"
      | "placeholder"
      | "defaultValue"
      | "fullWidth"
      | "autoComplete"
    >,
    WithChildren {
  formHook: UseFormReturn<T, C>;
  className?: string;
  name: string;
  valueAsFloat?: boolean;
  triggerOnChange?: boolean; // React-hook-form workaround for dropdown change not updating the validation/UI
  trackingEvent?: TrackingEventType;
  // money?: boolean;
  number?: boolean;
  inputProps?: any; // for <input>.
  InputProps?: any; // for MUI's <Input>. Both props are used by MUI TextField
}

const TextFieldControlled = ({
  formHook,
  triggerOnChange = false,
  valueAsFloat = false,
  className,
  // MUI props
  name,
  type = "text",
  select,
  // money = false,
  label,
  placeholder,
  defaultValue = "",
  disabled = false,
  required = false,
  fullWidth = false,
  children,
  trackingEvent,
  inputProps,
  InputProps,
  autoComplete,
}: TextFieldControlledProps) => {
  const {control, trigger, getFieldState, formState} = formHook;
  const {error, invalid, isTouched} = getFieldState(name);

  const shouldDisplayError =
    (formState.submitCount > 0 && Boolean(error?.message)) ||
    (isTouched && invalid && formState.dirtyFields);
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({field}) => (
        <TextField
          {...field}
          className={className}
          type={type === "number" ? "text" : type} // temporary workaround until MUI NumberInput is released
          value={
            (field.value ?? defaultValue) || (valueAsFloat && field.value === 0 ? "" : field.value)
          }
          autoComplete={autoComplete}
          select={select}
          label={label}
          inputProps={inputProps}
          InputProps={InputProps}
          fullWidth={fullWidth}
          placeholder={placeholder}
          helperText={shouldDisplayError ? error?.message : ""}
          error={!!shouldDisplayError}
          onChange={(event: BaseSyntheticEvent) => {
            let value: string | any = event.target.value;
            if (trackingEvent) trackEventMemoized(trackingEvent);
            if (type === "number") {
              if (!value.match(/^\d{0,}(\.\d{0,2})?$/)) {
                value = field.value;
              } else if (valueAsFloat) {
                value = parseFloat(value);
              } else {
                value = parseInt(value);
              }
              if (isNaN(value)) {
                value = ""; // React warns about NaN, and it's not a valid user input anyway.
              }
            }
            field.onChange(value);

            if (triggerOnChange) {
              trigger();
            }
          }}
          onBlur={() => {
            field.onBlur();
            trigger();
          }}
          disabled={disabled}
          required={required}
        >
          {children}
        </TextField>
      )}
    />
  );
};

export default TextFieldControlled;
