import {fetchPolicyApi} from "api/basicInfo";
import {getPrivacy, getTermsOfService} from "store/selectors/policy";
import {OperationType} from "types/operation";
import {Operation} from "./Operation";

export const fetchTermsOfServicePolicyOperation = new Operation<string>(
  OperationType.fetchTermsOfService,
  fetchPolicyApi,
  getTermsOfService
);

export const fetchPrivacyPolicyOperation = new Operation<string>(
  OperationType.fetchPrivacy,
  fetchPolicyApi,
  getPrivacy
);
