import Axios from "axios";
import {User} from "types/dto/user";
import {CIMODE} from "util/languageUtil";
import {WithDataError} from "types/api";
import {normalizedAxiosApi} from "./util";
import {getAccessToken} from "./auth";
import {logEmpty} from "components/utils/log";
import {UUID} from "types/uuid";

export const fetchUserApi = async (): Promise<WithDataError<User>> => {
  const token = await getAccessToken();
  logEmpty(token, "token");
  return normalizedAxiosApi(() =>
    Axios.get(
      `${(window)._env_.REACT_APP_PINCH_SERVICE_API_HOST}/users/me`,
      {
        headers: {Authorization: `Bearer ${token}`},
      }
    )
  );
};

export const changeUserLanguageApi = async (
  languagePreference: string,
  userId: UUID
): Promise<WithDataError<string>> => {
  logEmpty(userId, "userId");

  const token = await getAccessToken();

  if (languagePreference === CIMODE) {
    return {data: languagePreference};
  }

  if (token) {
    return normalizedAxiosApi(() =>
      Axios.put(
        `${
          (window)._env_.REACT_APP_PINCH_SERVICE_API_HOST
        }/users/change-language`,
        {
          userId,
          languagePreference,
        },
        {headers: {Authorization: `Bearer ${token}`}}
      )
    );
  } else {
    return normalizedAxiosApi(() =>
      Axios.put(
        `${
          (window)._env_.REACT_APP_PINCH_SERVICE_API_HOST
        }/users/change-language/unverified`,
        {
          userId,
          languagePreference,
        }
      )
    );
  }
};
