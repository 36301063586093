import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitSelfReportedCreditScoreOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {
  submit,
  SubmitCreditScorePagePayload,
  navigatePreviousStep,
  saveDateOfBirthAndCreditScore,
} from "./CreditScorePage.action";
import {WithNavigate} from "types/ui";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {ConfigurationKey} from "types/configurations";
import {calculateStepStatusChange} from "components/molecules/NavigationBar/NavigationBar.action";
import {NavStep} from "types/enums/navStep";

function* onSubmit({
  payload: {navigate, creditScore, dateOfBirth},
}: WithPayload<SubmitCreditScorePagePayload>) {
  const userId: string = yield select(getUserId);
  const isSelfReportedCreditEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
    )
  );
  const isSelectProductProvidersEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_BROKERAGE_OR_BANKING
    )
  );

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    creditScore,
    dateOfBirth,
  };

  if (!userId && isSelfReportedCreditEnabled) {
    yield put(saveDateOfBirthAndCreditScore(basicInfoRequest));
    // this should be refactored once the landing page structure is changed
    const nextRoute = isSelectProductProvidersEnabled
      ? RouteUrl.LANDING_PAGE_URL
      : RouteUrl.SIGN_UP;
    navigate(nextRoute);
  } else {
    const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
      submitSelfReportedCreditScoreOperation.saga,
      userId,
      basicInfoRequest,
      Step.SELF_REPORTED_CREDIT_SCORE
    );
    yield put(calculateStepStatusChange(NavStep.CREDIT));
    if (Operation.isSuccess(result)) {
      navigate(RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS);
    }
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  const userId: string = yield select(getUserId);
  const isSelfReportedCreditEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
    )
  );
  const isSelfReportedAssetsEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );

  if (!userId && isSelfReportedCreditEnabled) {
    // once the other tickets are done for self reported pages
    // this should be refactored
    const previousRoute = isSelfReportedAssetsEnabled
      ? RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT
      : RouteUrl.LANDING_PAGE_URL;
    navigate(previousRoute);
  } else {
    navigate(RouteUrl.BASIC_INFO_URL_BANKING_REDIRECT);
  }
}

export default function* creditScorePageSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
