import {createAction} from "@reduxjs/toolkit";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {PurchaseTimeframe} from "types/enums/purchaseTimeframe";
import {actionTypeOf} from "./util";

const namespace = "applicantMeta";

export const saveAssetsLiabilities = createAction<
  [SelfReportedAssetDto[], SelfReportedLiabilityDto[]]
>(actionTypeOf(namespace, "saveAssetsLiabilities"));

export const choosePurchaseTimeframe = createAction<PurchaseTimeframe>(
  actionTypeOf(namespace, "choosePurchaseTimeframe")
);
