import {Typography} from "@mui/material";
import {default as styles} from "./ConfirmationModal.module.scss";
import SubmitButton from "../SubmitButton/SubmitButton";
import BackButton from "components/molecules/BackButton/BackButton";
import React, {MouseEventHandler} from "react";

interface ConfirmationModalProps {
  title: string;
  subtitle: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  onClick: MouseEventHandler;
  onBack: MouseEventHandler;
}

export const ConfirmationModal = React.forwardRef(
  ({
    title,
    subtitle,
    confirmButtonText,
    cancelButtonText,
    onClick,
    onBack,
  }: ConfirmationModalProps) => (
    <div className={styles.root}>
      <div className={styles.content}>
        <Typography className={styles.title} variant="h5" fontWeight={"bold"}>
          {title}
        </Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
        <div className={styles.buttons}>
          <BackButton onClick={onClick}>{confirmButtonText}</BackButton>
          <SubmitButton onClick={onBack} text={cancelButtonText!} />
        </div>
      </div>
    </div>
  )
);
