import {nonNull} from "components/utils/booleanUtil";
import {isBlank} from "components/utils/stringUtil";
import {isEmpty} from "lodash";
import {nullable} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType} from "types/enums/loanType";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";

export const indirectSteps = [
  Step.SIGNED_UP,
  Step.ACCEPT_POLICY,
  Step.PERSONAL_PROFILE,
  Step.EMPLOYMENT_INFO,
  Step.ADDRESS_INFO,
  Step.ADD_BANK,
  Step.LEGAL_NAMES_SIN,
  Step.LOAN_TYPE,
  Step.PROPERTY_INFOR,
  Step.MORTGAGE_TYPE,
  Step.SELECT_LENDER,
  Step.REVIEW_SUBMISSION,
];

export const directSteps = indirectSteps; // TODO: ENG-4137 - Comment in the succeeding code, which was removed so CMLS shows the broker flow
// export const directSteps = [
//   Step.SIGNED_UP,
//   Step.ACCEPT_POLICY,
//   Step.PERSONAL_PROFILE,
//   Step.EMPLOYMENT_INFO,
//   Step.ADDRESS_INFO,
//   Step.SELF_REPORTED_ASSETS_LIABILITIES,
//   Step.SELF_REPORTED_DOCUMENTS,
//   Step.SELF_REPORTED_CREDIT_SCORE,
//   Step.LOAN_TYPE,
//   Step.PROPERTY_INFOR,
//   Step.MORTGAGE_TYPE,
//   Step.SELECT_LENDER,
//   Step.REVIEW_SUBMISSION,
// ];


export function guessProperRouteUrl(basicInfo?: ResidentialApplicationBasicInfoResponse): RouteUrl {
  return RouteUrl.BASIC_INFO_URL_EMPLOYMENT;
}

export function getNextStepUrl(basicInfo?: ResidentialApplicationBasicInfoResponse): RouteUrl {
  if (hasChosenProduct(basicInfo)) {
    return getUrlFromStep(Step.REVIEW_SUBMISSION);
  }
  if (
    ResidentialApplicationType.JOINT_APPLICANT === basicInfo?.applicationType &&
    isEmpty(basicInfo.secondaryUserEmail)
  ) {
    return RouteUrl.INVITE_SECONDARY_APPLICANT;
  }

  const isDirectLender = basicInfo?.isDirectLender && false; // TODO: ENG-4137 - Remove the false, which was added so CMLS shows the broker flow

  const step = isDirectLender
    ? getLastStepCompletedBankFlow(basicInfo)
    : getLastStepCompletedBrokerageFlow(basicInfo);
  const nextStep = getNextStep(step, isDirectLender ? directSteps : indirectSteps);

  if (Step.LOAN_TYPE == nextStep && !basicInfo?.isPrimaryApplicant) {
    return RouteUrl.JOINT_COMPLETED_URL;
  }
  if (
    Step.PROPERTY_INFOR == nextStep &&
    LoanType.PREAPPROVAL == basicInfo?.mortgageDetails?.loanType
  ) {
    return RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE;
  }

  return getUrlFromStep(nextStep);
}

export function getLastStepCompletedMortgageDetailsFlow(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): Step | null {
  if (hasChosenProduct(basicInfo)) {
    return Step.REVIEW_SUBMISSION;
  }
  if (hasCompletedMortgageInfo(basicInfo)) {
    return Step.MORTGAGE_TYPE;
  }
  if (hasCompletedSubjectPropertyIfRequired(basicInfo)) {
    return Step.PROPERTY_INFOR;
  }
  if (hasSelectedLoanType(basicInfo)) {
    return Step.LOAN_TYPE;
  }
  return null;
}

export function getLastStepCompletedBrokerageFlow(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): Step {
  if (hasPulledCreditData(basicInfo)) {
    return getLastStepCompletedMortgageDetailsFlow(basicInfo) ?? Step.LEGAL_NAMES_SIN;
  }
  if (hasPulledBankingData(basicInfo)) {
    return Step.ADD_BANK;
  }
  if (hasCompletedAddressInfo(basicInfo)) {
    return Step.ADDRESS_INFO;
  }
  if (hasCompletedEmploymentInfo(basicInfo)) {
    return Step.EMPLOYMENT_INFO;
  }
  if (hasCompletedPersonalInfo(basicInfo)) {
    return Step.PERSONAL_PROFILE;
  }
  if (hasAcceptedPrivacyPolicy(basicInfo)) {
    return Step.ACCEPT_POLICY;
  }
  return Step.SIGNED_UP;
}

export function getLastStepCompletedBankFlow(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): Step {
  // Check if assets/liabilities or credit have employment info as well, since
  // having employment info means the applicant is seeing the assets/liabilities or credit pages
  // for the second time. And we intentionally show it twice during bank flow... because reasons.
  if (hasSelfReportedCreditScore(basicInfo) && hasCompletedEmploymentInfo(basicInfo)) {
    return getLastStepCompletedMortgageDetailsFlow(basicInfo) ?? Step.SELF_REPORTED_CREDIT_SCORE;
  }
  if (hasUploadedRequiredDocuments(basicInfo)) {
    return Step.SELF_REPORTED_DOCUMENTS;
  }
  if (hasSelfReportedAssetsOrLiabilities(basicInfo) && hasCompletedEmploymentInfo(basicInfo)) {
    return Step.SELF_REPORTED_ASSETS_LIABILITIES;
  }
  if (hasCompletedAddressInfo(basicInfo)) {
    return Step.ADDRESS_INFO;
  }
  if (hasCompletedEmploymentInfo(basicInfo)) {
    return Step.EMPLOYMENT_INFO;
  }
  if (hasCompletedPersonalInfo(basicInfo)) {
    return Step.PERSONAL_PROFILE;
  }
  if (hasAcceptedPrivacyPolicy(basicInfo)) {
    return Step.ACCEPT_POLICY;
  }
  return Step.SIGNED_UP;
}

export function getUrlFromStep(step?: Step): RouteUrl {
  switch (step) {
    case Step.SIGNED_UP:
    case Step.ACCEPT_POLICY:
    // case Step.PERSONAL_PROFILE:
    //   return RouteUrl.BASIC_INFO_URL_PERSONAL_INFO;
    case Step.EMPLOYMENT_INFO:
      return RouteUrl.BASIC_INFO_URL_EMPLOYMENT;
    case Step.ADDRESS_INFO:
      return RouteUrl.BASIC_INFO_URL_ADDRESS;
    case Step.ADD_BANK:
      return RouteUrl.BASIC_INFO_URL_PRE_BANKING;
    case Step.LEGAL_NAMES_SIN:
      return RouteUrl.BASIC_INFO_URL_SIN_FORM;
    case Step.SELF_REPORTED_ASSETS_LIABILITIES:
      return RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT;
    case Step.SELF_REPORTED_CREDIT_SCORE:
      return RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT;
    case Step.SELF_REPORTED_DOCUMENTS:
      return RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS;
    case Step.LOAN_TYPE:
      return RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION;
    case Step.MORTGAGE_TYPE:
      return RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE;
    case Step.PROPERTY_INFOR:
      return RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO;
    case Step.UNRESOLVED_AVM_PROPERTY:
      return RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO;
    case Step.SELECT_LENDER:
      return RouteUrl.PRODUCTS_CALCULATE_QUALIFICATION_URL;
    case Step.REVIEW_SUBMISSION:
      return RouteUrl.APPLICATION_COMPLETE_URL;
    default:
      return RouteUrl.BASIC_INFO_URL_EMPLOYMENT;
  }
}

export function hasSelectedLoanType(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return nonNull(basicInfo?.mortgageDetails?.loanType);
}
export function hasSelectedMortgageType(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean | null {
  return Boolean(basicInfo?.mortgageDetails?.loanType);
}

export function hasCompletedMortgageInfo(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  switch (basicInfo?.mortgageDetails?.loanType) {
    case LoanType.PREAPPROVAL:
      return (
        nonNull(basicInfo?.mortgageDetails?.downPayment) &&
        nonNull(basicInfo?.mortgageDetails?.downPaymentPercent)
      );
    case LoanType.NEW_PURCHASE:
      return (
        nonNull(basicInfo?.mortgageDetails?.downPayment) &&
        nonNull(basicInfo?.mortgageDetails?.closingDate) &&
        nonNull(basicInfo?.mortgageDetails?.purchasePrice)
      );
    case LoanType.RENEWAL:
      return (
        nonNull(basicInfo?.mortgageDetails?.remainingAmount) &&
        nonNull(basicInfo?.mortgageDetails?.remainingMonths) &&
        nonNull(basicInfo?.mortgageDetails?.estimatedPropertyValue)
      );
    default:
      return false;
  }
}

export function hasCompletedSubjectPropertyIfRequired(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.mortgageDetails?.streetName);
}

export function hasChosenProduct(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return nonNull(basicInfo?.mortgageDetails?.productPublicId);
}

export function hasPulledCreditData(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return (
    nonNull(basicInfo?.legalFirstName) &&
    nonNull(basicInfo?.legalLastName) &&
    nonNull(basicInfo?.creditReportPublicId)
  );
}

export function hasPulledBankingData(basicInfo?: ResidentialApplicationBasicInfoResponse): boolean {
  return nonNull(basicInfo?.firstBankFinancialDataPublicId);
}

export function hasAcceptedPrivacyPolicy(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return !isBlank(basicInfo?.acceptedPrivacyPolicyAt);
}

export function hasCompletedPersonalInfo(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.dateOfBirth);
}

export function hasCompletedEmploymentInfo(basicInfo?: ResidentialApplicationBasicInfoResponse) {
  return nonNull(basicInfo?.employmentHistory);
}

export function hasCompletedAddressInfo(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.residentialAddress);
}

export function hasSelfReportedAssetsOrLiabilities(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return (
    (nonNull(basicInfo?.selfReportedAssets) && !!basicInfo?.selfReportedAssets?.length) ||
    (nonNull(basicInfo?.selfReportedLiabilities) && !!basicInfo?.selfReportedLiabilities?.length)
  );
}

export function hasSelfReportedCreditScore(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return Boolean(nonNull(basicInfo?.creditScore) && nonNull(basicInfo?.dateOfBirth));
}

export function hasSelfReportedGrossIncome(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return nonNull(basicInfo?.grossIncome);
}

export function hasUploadedRequiredDocuments(
  basicInfo?: ResidentialApplicationBasicInfoResponse
): boolean {
  return (
    (basicInfo?.uploadedT4 &&
      basicInfo?.uploadedNOA &&
      basicInfo?.uploadedPayStub &&
      basicInfo?.uploadedBankStatement) ??
    false
  );
}

export function getNextPage(currentPage: RouteUrl, pages: RouteUrl[]): RouteUrl {
  const currentIndex = pages.indexOf(currentPage);
  if (pages.length > currentIndex + 1) {
    return pages[currentIndex + 1];
  }
  return pages[0];
}

export function getPreviousPage(currentPage: RouteUrl, pages: RouteUrl[]): RouteUrl {
  const currentIndex = pages.indexOf(currentPage);
  return pages[Math.max(currentIndex - 1, 0)];
}

export function getNextStep(currentStep: Step, steps: Step[]): Step {
  const currentIndex = steps.indexOf(currentStep);
  if (steps.length > currentIndex + 1) {
    return steps[currentIndex + 1];
  }
  return steps[0];
}

export function getMortgagePageUrl(url: RouteUrl, loanType: nullable<LoanType>): RouteUrl {
  if (RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO == url && LoanType.PREAPPROVAL == loanType) {
    return RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE;
  }
  return url;
}
