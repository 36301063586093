export type NavStepKey = keyof typeof NavStep;

export enum NavStep {
  WELCOME = "WELCOME",
  PERSONAL_PROFILE = "PERSONAL_PROFILE",
  BANKING = "BANKING",
  CREDIT = "CREDIT",
  DOCUMENTS = "DOCUMENTS",
  MORTGAGE_INFORMATION = "MORTGAGE_INFORMATION",
  SELECT_PRODUCT = "SELECT_PRODUCT",
}

export const NavStepCaption = (fixedT: (arg0: NavStep) => string): Record<NavStep, string> => {
  return {
    [NavStep.WELCOME]: fixedT(NavStep.WELCOME),
    [NavStep.PERSONAL_PROFILE]: fixedT(NavStep.PERSONAL_PROFILE),
    [NavStep.BANKING]: fixedT(NavStep.BANKING),
    [NavStep.CREDIT]: fixedT(NavStep.CREDIT),
    [NavStep.DOCUMENTS]: fixedT(NavStep.DOCUMENTS),
    [NavStep.MORTGAGE_INFORMATION]: fixedT(NavStep.MORTGAGE_INFORMATION),
    [NavStep.SELECT_PRODUCT]: fixedT(NavStep.SELECT_PRODUCT),
  };
};

export type StepsWithoutSelectProductType = Exclude<NavStep, NavStep.SELECT_PRODUCT>;
