import {classNames} from "components/utils/stringUtil";
import {useState} from "react";
import {
  root,
  container,
  shape1,
  shape2,
  fallbackText as fallbackTextClass,
} from "./LogoBox.module.scss";

interface LogoBoxProps {
  iconPath?: string;
  fallbackText: string;
  backgroundColor: string;
  className?: string;
  isLenderBox?: boolean;
}

const LogoBox = ({
  fallbackText,
  iconPath,
  backgroundColor,
  className,
  isLenderBox,
}: LogoBoxProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div
      className={classNames(root, className)}
      style={{
        backgroundColor: "RGB(" + backgroundColor + ")",
      }}
    >
      {!!iconPath && (
        <img
          className={loaded ? "loaded" : "not-loaded"}
          src={iconPath}
          alt={fallbackText}
          onLoad={() => setLoaded(true)}
        />
      )}
      {/* To prevent showing a broken image icon */}
      {!loaded && !iconPath && <span className={fallbackTextClass}>{fallbackText}</span>}
      <div className={container}>
        <div className={shape1} />
        <div className={shape2} />
      </div>
    </div>
  );
};

export default LogoBox;
