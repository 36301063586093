import {OktaAuth} from "@okta/okta-auth-js";
import {RouteUrl} from "types/route";

export interface SessionInformationInterface {
  sessionToken: string;
}

const authClient = new OktaAuth({
  clientId: window._env_.REACT_APP_AUTHORIZATION_CLIENT_ID,
  issuer: `${window._env_.REACT_APP_AUTHORIZATION_SERVER_ADDRESS}/oauth2/default`,
  redirectUri: `${window.location.origin}${RouteUrl.LOGIN_CALLBACK}`,
  postLogoutRedirectUri: `${window.location.origin}${RouteUrl.SIGN_IN}`,
  tokenManager: {
    storage: {
      getItem: (key: string) => {
        return localStorage.getItem(key);
      },
      setItem: (key: string, val: string) => {
        return localStorage.setItem(key, val);
      },
    },
  },
  scopes: ["openid", "email", "profile", "offline_access"],
  cookies: {
    secure: true,
    sameSite: "None",
  },
  pkce: true,
});

authClient.tokenManager?.on("expired", (key: string, _token: string) => {
  console.warn(`${key} token has expired.`);
  authClient.revokeAccessToken();
  authClient.signOut();
  window.location.reload();
});

export async function storeTokens(): Promise<void> {
  await authClient.handleRedirect();
}

export default authClient;
