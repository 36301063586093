import LoadingAnimation from "components/atoms/LoadingAnimation/LoadingAnimation";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authorizationCodeCallback} from "store/actions/auth.action";

const AuthorizationCodeCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authorizationCodeCallback({navigate}))
  }, []);

  return (
    <LoadingAnimation />
  );
};

export default AuthorizationCodeCallback;
