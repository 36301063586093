import {
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Box,
  useTheme,
} from "@mui/material";
import {DRAWER_WIDTH} from "appConstants";
import CompanyLogo from "components/atoms/CompanyLogo/CompanyLogo";
import useOnResize from "components/hooks/useOnResize/useOnResize";
import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {
  getAcceptedPrivacyPolicy,
  getBrokerCode,
  getIsBankRequestLoading,
} from "store/selectors/basicInfo";
import {getNavigationError, getNavigationStepStatus} from "store/selectors/navigationController";
import {getFooterTextImage, getIsMortgageProviderLogoEnabled} from "store/selectors/theme";
import {getSelectedProvider} from "store/selectors/whitelabel";
import {ProductSummary} from "types/dto/productSummary";
import {NavStep, NavStepCaption} from "types/enums/navStep";
import {RouteUrl} from "types/route";
import {preventDefaultBehaviour} from "util/eventUtil";
import NavigationBarStatusIcon from "../NavigationBarStatusIcon/NavigationBarStatusIcon";
import NavigationBarTop from "../NavigationBarTop/NavigationBarTop";
import {handleNewUrl, navigateStep} from "./NavigationBar.action";
import {State} from "types/store";
import {default as styles} from "./NavigationBar.module.scss";
import {getFixedT} from "util/languageUtil";
import NavigationBarActionSection from "../NavigationBarActionSection/NavigationBarActionSection";
import NavigationBarMobile from "../NavigationBarMobile/NavigationBarMobile";
import NavigationBarDesktop from "../NavigationBarDesktop/NavigationBarDesktop";
import {useTranslation} from "react-i18next";
import {ConfigurationKey} from "types/configurations";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {isCurrentUrl, isCurrentUrlAnyOf} from "components/utils/urlUtil";
import {getHasUser} from "store/selectors/user";
import {LanguageType} from "types/enums/languageType";

const tNavBar = getFixedT("enums.navStep");

export default function NavigationBar() {
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const {t, i18n} = useTranslation("errors");
  const provider = useSelector<State, ProductSummary | undefined>(getSelectedProvider);
  const brokerCode = useSelector(getBrokerCode);
  const {image} = useSelector(getFooterTextImage)(
    i18n.language,
    provider?.organizationName,
    brokerCode
  );

  const isMortgageProviderLogoEnabled = useSelector(getIsMortgageProviderLogoEnabled);
  const navigationStepStatus = useSelector(getNavigationStepStatus);
  const navigationError = useSelector(getNavigationError);
  const hasUser = useSelector(getHasUser);
  const hasUserAcceptedPrivacyPolicy = useSelector(getAcceptedPrivacyPolicy);
  const isBankRequestLoading = useSelector(getIsBankRequestLoading);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const {isScreenMobileWidth} = useOnResize(theme.breakpoints);

  const handleDrawerToggle = () => {
    setMobileDrawerOpen(!isMobileDrawerOpen);
  };

  const welcomeStepURLs = [
    RouteUrl.LANDING_PAGE_URL,
    RouteUrl.PURCHASE_TIMEFRAME_URL,
    RouteUrl.SELECT_PRODUCT_PROVIDER_URL,
    RouteUrl.SIGN_UP,
  ];
  const assetsLiabilitiesEnabled: boolean = useSelector(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );
  const creditEnabled: boolean = useSelector(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
    )
  );

  // If the following pages are enabled, they show up before sign up and belong to the welcome step
  if (!hasUser && assetsLiabilitiesEnabled) {
    welcomeStepURLs.push(RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT);
  }
  if (!hasUser && creditEnabled) {
    welcomeStepURLs.push(RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT);
  }

  const onStepChangeHandler = (step?: NavStep) => {
    step && dispatch(handleNewUrl({url: location.pathname, step}));
  };

  const NAVBAR_LINKS = [
    {
      step: NavStep.WELCOME,
      text: NavStepCaption(tNavBar).WELCOME,
      url: RouteUrl.LANDING_PAGE_URL,
      current: isCurrentUrlAnyOf(welcomeStepURLs),
      completed: navigationStepStatus[NavStep.WELCOME].completed,
      optional: navigationStepStatus[NavStep.WELCOME].optional,
      visited: navigationStepStatus[NavStep.WELCOME].visited && !!navigationError,
      hidden: navigationStepStatus[NavStep.WELCOME].hidden,
    },
    {
      step: NavStep.PERSONAL_PROFILE,
      text: NavStepCaption(tNavBar).PERSONAL_PROFILE,
      url: RouteUrl.BASIC_INFO_URL_EMPLOYMENT,
      current: isCurrentUrlAnyOf([
        RouteUrl.BASIC_INFO_URL_EMPLOYMENT,
        RouteUrl.BASIC_INFO_URL_ADDRESS,
      ]),
      completed: navigationStepStatus[NavStep.PERSONAL_PROFILE].completed,
      optional: navigationStepStatus[NavStep.PERSONAL_PROFILE].optional,
      visited: navigationStepStatus[NavStep.PERSONAL_PROFILE].visited,
      hidden: navigationStepStatus[NavStep.PERSONAL_PROFILE].hidden,
    },
    {
      step: NavStep.BANKING,
      text: NavStepCaption(tNavBar).BANKING,
      url: RouteUrl.BASIC_INFO_URL_BANKING_REDIRECT,
      current: isCurrentUrlAnyOf(
        !hasUser && assetsLiabilitiesEnabled
          ? [
              RouteUrl.BASIC_INFO_URL_PRE_BANKING,
              RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO,
              RouteUrl.BASIC_INFO_URL_FLINKS_SCREEN,
            ]
          : [
              RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT,
              RouteUrl.BASIC_INFO_URL_PRE_BANKING,
              RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO,
              RouteUrl.BASIC_INFO_URL_FLINKS_SCREEN,
            ]
      ),
      completed: navigationStepStatus[NavStep.BANKING].completed,
      loading: isBankRequestLoading,
      optional: navigationStepStatus[NavStep.BANKING].optional,
      visited: navigationStepStatus[NavStep.BANKING].visited,
      hidden: navigationStepStatus[NavStep.BANKING].hidden,
    },
    {
      step: NavStep.CREDIT,
      text: NavStepCaption(tNavBar).CREDIT,
      url: RouteUrl.BASIC_INFO_URL_CREDIT_REDIRECT,
      current: isCurrentUrlAnyOf(
        !hasUser && creditEnabled
          ? [RouteUrl.BASIC_INFO_URL_SIN_FORM]
          : [RouteUrl.BASIC_INFO_URL_SIN_FORM, RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT]
      ),
      completed: navigationStepStatus[NavStep.CREDIT].completed,
      optional: navigationStepStatus[NavStep.CREDIT].optional,
      visited: navigationStepStatus[NavStep.CREDIT].visited,
      hidden: navigationStepStatus[NavStep.CREDIT].hidden,
    },
    {
      step: NavStep.DOCUMENTS,
      text: NavStepCaption(tNavBar).DOCUMENTS,
      url: RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
      current: isCurrentUrl(RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS),
      completed: navigationStepStatus[NavStep.DOCUMENTS].completed,
      optional: navigationStepStatus[NavStep.DOCUMENTS].optional,
      visited: navigationStepStatus[NavStep.DOCUMENTS].visited,
      hidden: navigationStepStatus[NavStep.DOCUMENTS].hidden,
    },
    {
      step: NavStep.MORTGAGE_INFORMATION,
      text: NavStepCaption(tNavBar).MORTGAGE_INFORMATION,
      url: RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION,
      current: isCurrentUrlAnyOf([
        RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION,
        RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
        RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO,
      ]),
      completed: navigationStepStatus[NavStep.MORTGAGE_INFORMATION].completed,
      optional: navigationStepStatus[NavStep.MORTGAGE_INFORMATION].optional,
      visited: navigationStepStatus[NavStep.MORTGAGE_INFORMATION].visited,
      hidden: navigationStepStatus[NavStep.MORTGAGE_INFORMATION].hidden,
    },
    {
      step: NavStep.SELECT_PRODUCT,
      text: NavStepCaption(tNavBar).SELECT_PRODUCT,
      url: RouteUrl.PRODUCTS_CALCULATE_QUALIFICATION_URL,
      current: isCurrentUrlAnyOf([
        RouteUrl.PRODUCTS_SELECT_URL,
        RouteUrl.PRODUCTS_CALCULATE_QUALIFICATION_URL,
      ]),
      completed: navigationStepStatus[NavStep.SELECT_PRODUCT].completed,
      optional: navigationStepStatus[NavStep.SELECT_PRODUCT].optional,
      visited: navigationStepStatus[NavStep.SELECT_PRODUCT].visited,
      hidden: navigationStepStatus[NavStep.SELECT_PRODUCT].hidden,
    },
  ];

  const getCurrentStep = () => NAVBAR_LINKS.find((option) => option.current)?.step;

  const providerLogoUrl = provider?.financialInstitutionLogo
    ? `${window._env_.REACT_APP_IMGIX_DOMAIN}/${provider.financialInstitutionLogo}?auto=format`
    : image;

  const navigationBarDisplay = (
    <>
      <Toolbar sx={{padding: {sm: 0}}}>
        <CompanyLogo />
      </Toolbar>
      <Divider />
      <List>
        {NAVBAR_LINKS.filter((item) => !item.hidden).map(
          ({text, current, url, visited, completed, optional, loading}, index) => {
            const isWelcomeStep = text === NavStepCaption(tNavBar).WELCOME;
            return (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  selected={current}
                  disabled={hasUserAcceptedPrivacyPolicy == isWelcomeStep}
                  onClick={(e) => {
                    preventDefaultBehaviour(e);
                    dispatch(
                      navigateStep({
                        url,
                        navigate,
                        providerNotChosenError: t("providerNotChosen"),
                        userNotCreatedError: t("userNotCreated"),
                      })
                    );
                  }}
                >
                  <ListItemText tabIndex={index} primary={text} />
                  <ListItemIcon sx={{justifyContent: "flex-end"}}>
                    <NavigationBarStatusIcon
                      completed={completed}
                      visited={visited}
                      optional={optional}
                      loading={loading}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            );
          }
        )}
      </List>
      <Divider sx={{marginTop: "auto"}} />
      <NavigationBarActionSection />
      <Divider />
      {isMortgageProviderLogoEnabled &&
        providerLogoUrl &&
        !isCurrentUrlAnyOf([RouteUrl.LANDING_PAGE_URL, RouteUrl.SELECT_PRODUCT_PROVIDER_URL]) && (
          <List className={styles.providerLogoItemContainer}>
            <ListItem className={styles.providerLogoItem}>
              <ListItemIcon>
                <img src={providerLogoUrl} aria-label="logo" alt="logo" />
              </ListItemIcon>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  i18n.language === LanguageType.EN_CA
                    ? provider?.financialInstitutionComplianceText
                    : provider?.financialInstitutionComplianceTextFr
                }
              />
            </ListItem>
          </List>
        )}
    </>
  );

  useEffect(() => {
    onStepChangeHandler(getCurrentStep());
  }, []);

  return (
    <>
      <NavigationBarTop
        isMobileView={isScreenMobileWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="nav"
        sx={{width: {sm: DRAWER_WIDTH}}}
        aria-label="navigation bar"
        className={styles.root}
      >
        {isScreenMobileWidth ? (
          <NavigationBarMobile
            handleDrawerToggle={handleDrawerToggle}
            isMobileDrawerOpen={isMobileDrawerOpen}
          >
            {navigationBarDisplay}
          </NavigationBarMobile>
        ) : (
          <NavigationBarDesktop>{navigationBarDisplay}</NavigationBarDesktop>
        )}
      </Box>
    </>
  );
}
