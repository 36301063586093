export enum SelfReportedAssetType {
  SAVINGS = "SAVINGS",
  CHEQUING = "CHEQUING",
  // Deprecated, unused for new applications, however they are present in some existing applications
  RRSP_OR_SIMILAR = "RRSP_OR_SIMILAR",
  OTHER = "OTHER",
  RRSP = "RRSP",
  TFSA = "TFSA",
  HOUSEHOLD_GOODS = "HOUSEHOLD_GOODS",
  LIFE_INSURANCE = "LIFE_INSURANCE",
  DEPOSIT_ON_PURCHASE = "DEPOSIT_ON_PURCHASE",
  GIFT = "GIFT",
  VEHICLE = "VEHICLE",
  PROPERTY = "PROPERTY",
  STOCKS_BONDS_MUTUAL_FUNDS = "STOCKS_BONDS_MUTUAL_FUNDS",
  RENTAL_PROPERTY = "RENTAL_PROPERTY",
}
