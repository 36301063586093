import axios from "axios";
import {logEmpty} from "components/utils/log";
import {WithDataError} from "types/api";
import {ResidentialApplicationQualificationsResponse} from "types/dto/residentialApplicationQualificationsResponse";
import {getAccessToken} from "./auth";
import {normalizedAxiosApi} from "./util";
import { ApplicationStatusResponse } from "types/dto/ApplicationStatusResponse";

const baseUrl = `${
  (window)._env_.REACT_APP_PINCH_SERVICE_API_HOST
}/mortgage`;

export async function fetchQualificationStatusApi(
  userId: string
): Promise<WithDataError<ApplicationStatusResponse>> {
  logEmpty(userId, "userId");
  const token = await getAccessToken();
  const url = `${baseUrl}/residential-application/qualification/userId/${userId}/app-status`;

  return normalizedAxiosApi(() =>
    axios.get(
      token ? url : `${url}/unverified`,
      token ? {headers: {Authorization: `Bearer ${token}`}} : {}
    )
  );
}

export async function fetchLendersApi(
  userId: string
): Promise<WithDataError<ResidentialApplicationQualificationsResponse[]>> {
  logEmpty(userId, "userId");
  const token = await getAccessToken();
  const url = `${baseUrl}/residential-application/qualification/userId/${userId}/get-lenders`;

  return normalizedAxiosApi(() =>
    axios.get(
      token ? url : `${url}/unverified`,
      token ? {headers: {Authorization: `Bearer ${token}`}} : {}
    )
  );
}
