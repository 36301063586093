import OktaAuth, {SessionInformationInterface} from "components/utils/OktaAuth/OktaAuth";
import {WithDataError} from "types/api";
import {AuthUser} from "types/auth";
import {MediaType, HttpHeaders, HttpMethods} from "./types";
import {writableErrorOf, normalizedFetchApi} from "./util";
import {getReferralCode, getReferrer, getSelectedProvince, getUtm, setHasVisited} from "util/localStorageUtil";
import {UserRequest} from "types/dto/userRequest";
import {logEmpty} from "components/utils/log";
import {User} from "types/dto/user";
import {PINCH_MORTGAGE_PROVIDER} from "appConstants";

export const fetchAuthorizedUserApi = async (): Promise<WithDataError<AuthUser>> => {
  try {
    const idToken = await OktaAuth.tokenManager.get("idToken");
    const accessToken = await OktaAuth.tokenManager.get("accessToken");
    const authUser = await OktaAuth.token.getUserInfo(accessToken, idToken);

    return {data: authUser};
  } catch (authError: any) {
    return {error: writableErrorOf(authError)};
  }
};

export const verifyRecoveryTokenApi = async (token: string) => {
  try {
    const authnTransaction = await OktaAuth.verifyRecoveryToken({
      recoveryToken: token,
    });
    return {data: authnTransaction};
  } catch (authError: any) {
    return {error: writableErrorOf(authError)};
  }
};

export const signInApi = async (
  username: string,
  password: string
): Promise<WithDataError<AuthUser>> => {
  try {
    const sessionInformation = await OktaAuth.signInWithCredentials({
      username: username,
      password: password,
    });

    if (sessionInformation?.status !== "SUCCESS") {
      return {error: writableErrorOf(sessionInformation?.status)};
    }

    // Redirect to Okta to exchange session token for authorization code
    await OktaAuth.signInWithRedirect({sessionToken: sessionInformation.sessionToken});
    // See the AuthorizationCallback and Auth Saga for code handling the redirect to our app
    // which exchanges the authorization code for the access/id/refresh tokens
    return {};
  } catch (e) {
    return {error: writableErrorOf(e)};
  }
};

export const setPasswordApi = async (
  whiteLabelId: string,
  password: string,
  email: string,
  stateToken: string,
  language: string
): Promise<WithDataError<SessionInformationInterface>> => {
  const {data: tokens, error} = await normalizedFetchApi<SessionInformationInterface>(() =>
    fetch(
      `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/users/set-password/${whiteLabelId}?locale=${language}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          email,
          state_token: stateToken,
        }),
      }
    )
  );

  return {data: tokens, error};
};

export const signOutApi = async (): Promise<WithDataError> => {
  try {
    setHasVisited(false);
    localStorage.removeItem(PINCH_MORTGAGE_PROVIDER);
    // okta will redirect page to `postLogoutRedirectUri`
    await OktaAuth.signOut({
      revokeAccessToken: true,
      revokeRefreshToken: true,
      clearTokensBeforeRedirect: true,
    });
    return {};
  } catch (e: any) {
    return {error: writableErrorOf(e, "Failed to signout")};
  }
};

export const getAccessToken = async (): Promise<string> =>
  ((await OktaAuth.tokenManager.get("accessToken")) || {}).accessToken;

export async function getRequestConfig(): Promise<object> {
  const token = await getAccessToken();
  return token ? {headers: {Authorization: `Bearer ${token}`}} : {};
}

export const resetPasswordApi = async (
  whiteLabelId: string,
  email: string,
  urlDomain: string,
  language: string
): Promise<WithDataError> => {
  return normalizedFetchApi(() =>
    fetch(
      `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/users/password/reset/${email}/${whiteLabelId}/${urlDomain}?locale=${language}`,
      {
        method: HttpMethods.POST,
        headers: {
          [HttpHeaders.CONTENT_TYPE]: MediaType.APPLICATION_JSON,
        },
        body: JSON.stringify("Reset Request"),
      }
    )
  );
};

export const registerPrimaryUserApi = async (
  userRequest: Partial<UserRequest>,
  whiteLabelId: string,
  urlDomain: string,
  financialInstitutionId: string
): Promise<WithDataError<User>> => {
  logEmpty(userRequest.email, "userRequest.email");
  logEmpty(userRequest.firstName, "userRequest.firstName");
  logEmpty(userRequest.lastName, "userRequest.lastName");
  logEmpty(whiteLabelId, "whiteLabelId");
  logEmpty(financialInstitutionId, "financialInstitutionId");

  const utm = getUtm();
  const referrer = getReferrer();
  const referralCode = getReferralCode();
  const selectedProvince = getSelectedProvince();
  return normalizedFetchApi(() =>
    fetch(
      `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/users/register/${whiteLabelId}/${urlDomain}/${financialInstitutionId}`, // uw call
      {
        method: HttpMethods.POST,
        headers: {
          [HttpHeaders.CONTENT_TYPE]: MediaType.APPLICATION_JSON,
        },
        body: JSON.stringify({...userRequest, ...utm, referrer, referralCode, selectedProvince}),
      }
    )
  );
};

export const registerSecondaryUserApi = async (
  appId: string,
  userRequest: Partial<UserRequest>,
  whiteLabelId: string,
  urlDomain: string
): Promise<WithDataError<User>> => {
  logEmpty(appId, "appId");
  logEmpty(userRequest.email, "userRequest.email");
  logEmpty(userRequest.firstName, "userRequest.firstName");
  logEmpty(userRequest.lastName, "userRequest.lastName");
  logEmpty(whiteLabelId, "whiteLabelId");

  const utm = getUtm();

  return normalizedFetchApi(() =>
    fetch(
      `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/users/register-secondary/appId/${appId}/whiteLabelId/${whiteLabelId}/originatingSite/${urlDomain}`, // uw call
      {
        method: HttpMethods.POST,
        headers: {
          [HttpHeaders.CONTENT_TYPE]: MediaType.APPLICATION_JSON,
        },
        body: JSON.stringify({...userRequest, ...utm}),
      }
    )
  );
};
