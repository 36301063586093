import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Routes from "pages/_routes/Routes";
import {initialize} from "./Root.action";
import {getTheme} from "store/selectors/theme";
import {applyThemeToDocument} from "./themeUtil";
import {Theme} from "types/theme";
import withTracking from "components/molecules/WithTracking/WithTracking";
import "../../util/languageUtil";
import "components/atoms/Colors";
import "components/atoms/Headings";
import "components/atoms/Sizes";
import "components/atoms/ScrollBar";
import "./Reset.module.scss";
import "./Root.module.scss";
import i18next, {hasLoadedNamespace} from "i18next";
import {getIsAppInitialized} from "store/selectors/operation";
import LoadingAnimation from "components/atoms/LoadingAnimation/LoadingAnimation";
import {withTranslation} from "react-i18next";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {PaletteColorOptions, responsiveFontSizes} from "@mui/material/styles";
import {RouteUrl} from "types/route";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    prompt: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    prompt?: React.CSSProperties;
  }
  interface CustomPalette {
    black: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    prompt: true;
  }
}

const Root = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector(getTheme) as Theme;
  const isAppInitialized = useSelector(getIsAppInitialized);

  const muiTheme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
      prompt: {
        fontSize: "18px",
        color: "black",
      },
      fontFamily: theme?.fontFamily || "Circular",
    },
    palette: {
      black: {
        main: "#000000DE",
      },
      warning: {
        main: "#ed6c02", // the same
        light: "#FFA726", // slightly different than the norm
      },
    },
  });

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  useEffect(() => {
    if (theme) {
      applyThemeToDocument(theme);
    }
  }, [theme]);

  if (
    !hasLoadedNamespace(i18next.language) ||
    (window.location.pathname !== RouteUrl.ERROR_PAGE_URL && !isAppInitialized) ||
    !theme
  ) {
    return <LoadingAnimation />;
  }

  const RoutesEnhancedWithTracking = withTracking(Routes);

  return (
    <ThemeProvider theme={responsiveFontSizes(muiTheme)}>
      <RoutesEnhancedWithTracking
        location={{
          search: "",
          pathname: "",
        }}
      />
    </ThemeProvider>
  );
};

export default withTranslation()(Root);
