import {SagaIterator} from "redux-saga";
import {call, select, takeEvery} from "redux-saga/effects";
import {
  setPasswordOperation,
  signOutOperation,
  verifyRecoveryTokenOperation,
} from "store/operation/operations";
import {getEmailFromRecovery, getStateTokenFromRecovery} from "store/selectors/auth";
import {getPlatform} from "store/selectors/theme";
import {SagaResult, WithPayload} from "types/basic";
import {initialize, SetPasswordFormPayload, submit} from "./SetPasswordForm.action";
import i18next from "i18next";
import {Operation} from "store/operation/Operation";
import {getConsolidatedMortgageProviderEmail} from "store/selectors/basicInfo";
import {getWhitelabelId} from "store/selectors/whitelabel";

export function* verifyRecoveryToken({payload: token}: WithPayload<string>): any {
  yield call(verifyRecoveryTokenOperation.saga, token);
}

export function* setPassword({
  payload: {password, navigate},
}: WithPayload<SetPasswordFormPayload>): any {
  const whiteLabelId: string = yield select(getWhitelabelId);
  const email: string = yield select(getEmailFromRecovery);
  const stateToken: string = yield select(getStateTokenFromRecovery);
  const result: SagaResult<void> = yield call(
    setPasswordOperation.saga,
    whiteLabelId,
    password,
    email,
    stateToken,
    i18next.language
  );

  if (Operation.isSuccess(result)) {
    yield call(signOutOperation.saga);
  }
}

export default function* setPasswordSaga(): SagaIterator {
  yield takeEvery(initialize, verifyRecoveryToken);
  yield takeEvery(submit, setPassword);
}
