import i18next from "i18next";
import {useNavigate} from "react-router-dom";
import {root, flinksContainer} from "./FlinksStep.module.scss";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import BackButton from "components/molecules/BackButton/BackButton";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {isProduction} from "util/envUtil";
import {toLanguageCode} from "util/languageUtil";
import {useSelector} from "react-redux";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {UUID} from "types/uuid";

const FLINKS_IFRAME_DOMAIN = "https://pinchfinancial-iframe.private.fin.ag/v2/";

export const showDemoInstitution = (whiteLabelId?: UUID) => {
  if (!isProduction()) {
    return window._env_.REACT_APP_FLINKS_DEMO;
  }
  const isWhiteLabelDemoEnabled =
    !!whiteLabelId && whiteLabelId === window._env_.REACT_APP_FLINKS_DEMO_WHITE_LABEL_ID;
  return isWhiteLabelDemoEnabled; 
};

const buildFlinksUrl = (shouldShowDemoInstitution: boolean, languageCode: string) => {
  const searchParams = new URLSearchParams();
  searchParams.append("?redirectUrl", `${window.location.origin}/basic-info/add-bank-info`);
  searchParams.append("daysOfTransactions", "Days365");
  searchParams.append("fetchAllAccounts", "true");
  searchParams.append("accountSelectorEnable", "false");
  searchParams.append("desktopLayout", "true");
  searchParams.append("institutionFilterEnable", "true");
  searchParams.append("theme", "light");
  searchParams.append("backgroundColor", "FFFFFF");
  searchParams.append("accountSelectorCurrency", "cad");
  searchParams.append("demo", `${shouldShowDemoInstitution}`);
  searchParams.append("language", languageCode);
  searchParams.append("detailsAndStatementEnable", "true");
  searchParams.append("monthsOfStatements", "Months3");
  searchParams.append("consentEnable", "false");
  searchParams.append("staticLoadingEnable", "true");
  return new URL(`${searchParams}`, FLINKS_IFRAME_DOMAIN);
};

const FlinksStep = ({}) => {
  const navigate = useNavigate();
  const whiteLabelId = useSelector(getWhitelabelId);
  const shouldShowDemoInstitution = showDemoInstitution(whiteLabelId);
  const languageCode = toLanguageCode(i18next.language);
  const url = buildFlinksUrl(shouldShowDemoInstitution, languageCode);

  return (
    <div className={root}>
      <iframe src={decodeURIComponent(url.href)} className={flinksContainer} allowFullScreen />
      <NavigationSection>
        <BackButton
          onClick={() => {
            trackEvent(TrackingEventType.flinksStepClickBackButton);
            navigate(-1);
          }}
        />
      </NavigationSection>
    </div>
  );
};

export default FlinksStep;
