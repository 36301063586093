import {NavStep} from "./enums/navStep";

export enum RouteUrl {
  ACTIVATE_ACCOUNT_URL = "/activate-account",
  RESET_PASSWORD_URL = "/reset-password",
  SIGN_UP = "/sign-up",
  SIGN_IN = "/login",
  SECONDARY_SIGNUP = "/sign-up-secondary",
  INVITE_SECONDARY_APPLICANT = "/invite-secondary-applicant",
  RESEND_EMAIL_URL = "/resend-email",
  LANDING_PAGE_URL = "/landing-page",
  PURCHASE_TIMEFRAME_URL = "/purchase-timeframe",
  SELECT_PRODUCT_PROVIDER_URL = "/select-product-provider",

  BASIC_INFO_URL = "/basic-info",
  // BASIC_INFO_URL_PERSONAL_INFO = "/basic-info/personal-info",
  BASIC_INFO_URL_EMPLOYMENT = "/basic-info/employment-info",
  BASIC_INFO_URL_ADDRESS = "/basic-info/address-info",

  BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT = "/basic-info/assets-liabilities",
  BASIC_INFO_URL_UPLOAD_DOCUMENTS = "/basic-info/upload-documents",
  BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT = "/basic-info/self-reported-credit",

  BASIC_INFO_URL_PRE_BANKING = "/basic-info/pre-banking-info",
  BASIC_INFO_URL_ADD_BANK_INFO = "/basic-info/add-bank-info",
  BASIC_INFO_URL_FLINKS_SCREEN = "/basic-info/flinks-screen",
  BASIC_INFO_URL_SIN_FORM = "/basic-info/sin-form",
  BASIC_INFO_URL_BANKING_REDIRECT = "/basic-info/banking-redirect",
  BASIC_INFO_URL_CREDIT_REDIRECT = "/basic-info/credit-redirect",

  BASIC_INFO_URL_MORTGAGE_INFO = "/basic-info/mortgage-info",

  MORTGAGE_INFO_URL = "/mortgage-info",
  MORTGAGE_INFO_URL_MORTGAGE_SELECTION = "/mortgage-info/mortgage-selection",
  MORTGAGE_INFO_URL_PROPERTY_INFO = "/mortgage-info/property-information",
  MORTGAGE_INFO_URL_MORTGAGE_TYPE = "/mortgage-info/mortgage-type",
  
  PRODUCTS_URL = "/products",
  PRODUCTS_CALCULATE_QUALIFICATION_URL = "/products/calculate-qualification",
  PRODUCTS_UNAVAILABLE_URL = "/products/unavailable",
  PRODUCTS_SELECT_URL = "/products/select",

  APPLICATION_COMPLETE_URL = "/application-complete",
  UNRESOLVED_AVM_URL = "/unresolved-avm",
  DECLINED_TERMS_URL = "/declined-terms",
  JOINT_COMPLETED_URL = "/joint-complete",
  MANULIFE_INSTRUCTIONS_URL = "/manulife-instructions",
  MANULIFE_OFFER_URL = "/manulife-offer",
  ERROR_PAGE_URL = "/error-page",
  LOGIN_CALLBACK = "/login/callback",
}

export const INIT_STEPS = [
  RouteUrl.LANDING_PAGE_URL,
  RouteUrl.PURCHASE_TIMEFRAME_URL,
  RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT,
  RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT,
  RouteUrl.SELECT_PRODUCT_PROVIDER_URL,
  RouteUrl.SIGN_UP,
];

export const MORTGAGE_PROVIDER_NOT_REQUIRED_URLS: string[] = [
  RouteUrl.LANDING_PAGE_URL,
  RouteUrl.PURCHASE_TIMEFRAME_URL,
  RouteUrl.SELECT_PRODUCT_PROVIDER_URL,
  RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT,
  RouteUrl.ACTIVATE_ACCOUNT_URL,
  RouteUrl.RESEND_EMAIL_URL,
  RouteUrl.SECONDARY_SIGNUP,
  RouteUrl.RESET_PASSWORD_URL,
  RouteUrl.SIGN_IN,
  RouteUrl.LOGIN_CALLBACK,
];

export const USER_ACCOUNT_NOT_REQUIRED_URLS: string[] = [
  RouteUrl.LANDING_PAGE_URL,
  RouteUrl.PURCHASE_TIMEFRAME_URL,
  RouteUrl.SELECT_PRODUCT_PROVIDER_URL,
  RouteUrl.ACTIVATE_ACCOUNT_URL,
  RouteUrl.RESEND_EMAIL_URL,
  RouteUrl.SECONDARY_SIGNUP,
  RouteUrl.RESET_PASSWORD_URL,
  RouteUrl.SIGN_IN,
  RouteUrl.SIGN_UP,
  RouteUrl.LOGIN_CALLBACK,
  RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT,
  RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT,
];

export const DIRECT_LENDER_BASIC_INFO_STEPS = [
  RouteUrl.SIGN_UP,
  // RouteUrl.BASIC_INFO_URL_PERSONAL_INFO,
  RouteUrl.BASIC_INFO_URL_EMPLOYMENT,
  RouteUrl.BASIC_INFO_URL_ADDRESS,
  RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT,
  RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
  RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT,
  RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION,
];

export const INDIRECT_LENDER_BASIC_INFO_STEPS = [
  RouteUrl.SIGN_UP,
  // RouteUrl.BASIC_INFO_URL_PERSONAL_INFO,
  RouteUrl.BASIC_INFO_URL_EMPLOYMENT,
  RouteUrl.BASIC_INFO_URL_ADDRESS,
  RouteUrl.BASIC_INFO_URL_PRE_BANKING,
  RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO,
  RouteUrl.BASIC_INFO_URL_FLINKS_SCREEN,
  RouteUrl.BASIC_INFO_URL_SIN_FORM,
  RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
  RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION,
];

export const MORTGAGE_INFO_STEPS = [
  RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION,
  RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO, // not preapproval
  RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
  RouteUrl.PRODUCTS_CALCULATE_QUALIFICATION_URL,
];

export const NAVBAR_LINKS = {
  [NavStep.WELCOME]: {
    url: RouteUrl.LANDING_PAGE_URL,
  },
  [NavStep.PERSONAL_PROFILE]: {
    url: RouteUrl.BASIC_INFO_URL_EMPLOYMENT,
  },
  [NavStep.BANKING]: {
    url: RouteUrl.BASIC_INFO_URL_BANKING_REDIRECT,
  },
  [NavStep.CREDIT]: {
    url: RouteUrl.BASIC_INFO_URL_CREDIT_REDIRECT,
  },
  [NavStep.DOCUMENTS]: {
    url: RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
  },
  [NavStep.MORTGAGE_INFORMATION]: {
    url: RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION,
  },
  [NavStep.SELECT_PRODUCT]: {
    url: RouteUrl.PRODUCTS_SELECT_URL,
  },
};
