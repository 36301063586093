import {PayloadActionCreator, createReducer} from "@reduxjs/toolkit";
import {
  removeNavigationError,
  initializeStatus,
  storeCurrentStep,
  storeNavigationError,
  storeUrl,
  storeStepStatusChange,
  storeNextUrl,
} from "components/molecules/NavigationBar/NavigationBar.action";
import {initialize as initializeMortgageType} from "pages/MortgageInfoSteps/MortgageType/MortgageType.action";
import {initialize as initializePropertyInformation} from "pages/MortgageInfoSteps/PropertyInformation/PropertyInformation.action";
import {
  submitMortgageTypeOperation,
  submitPropertyInformationOperation,
} from "store/operation/basicInfo";
import {signOutOperation} from "store/operation/operations";
import {NavigationState, StepStatusRecord, StepStatusState} from "types/NavigationController";
import {NavStep} from "types/enums/navStep";

const initialStepStatusState: StepStatusState = {
  optional: false,
  hidden: false,
  completed: false,
  visited: false,
};

const initialStepStatusRecord: StepStatusRecord = {
  [NavStep.WELCOME]: {...initialStepStatusState},
  [NavStep.PERSONAL_PROFILE]: {...initialStepStatusState},
  [NavStep.BANKING]: {...initialStepStatusState},
  [NavStep.CREDIT]: {...initialStepStatusState},
  [NavStep.DOCUMENTS]: {...initialStepStatusState},
  [NavStep.MORTGAGE_INFORMATION]: {...initialStepStatusState},
  [NavStep.SELECT_PRODUCT]: {...initialStepStatusState},
};

export const initialState: NavigationState = {
  history: [],
  currentStep: undefined,
  stepStatus: initialStepStatusRecord,
};

const isSubmittingMortgageTypeSuccessOps = (targetOp: PayloadActionCreator<any>): boolean => {
  return [
    submitMortgageTypeOperation.success.type, // pre-approval
    submitPropertyInformationOperation.success.type, // new_purchase | renewal
  ].includes(targetOp.type);
};

const navigationHistoryReducer = createReducer<NavigationState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.success, () => initialState)
    .addCase(initializeStatus, (state, {payload: stepsStatusRecord}) => {
      return {
        ...state,
        stepStatus: {...state.stepStatus, ...stepsStatusRecord},
      };
    })
    .addCase(storeUrl, (state, {payload: pathname}) => {
      return {
        ...state,
        history: [...state.history, pathname],
      };
    })
    .addCase(storeNextUrl, (state, {payload: pathname}) => {
      return {...state, nextUrl: pathname};
    })
    .addCase(storeStepStatusChange, (state, {payload: {step, ...stepStatus}}) => {
      const updatedStepStatus = {...state.stepStatus[step], ...stepStatus};
      return {
        ...state,
        stepStatus: {...state.stepStatus, [step]: updatedStepStatus},
      };
    })
    .addCase(storeCurrentStep, (state, {payload: step}) => {
      return {...state, currentStep: step};
    })
    .addCase(storeNavigationError, (state, {payload: error}) => {
      return {...state, error};
    })
    .addCase(removeNavigationError, (state, {}) => {
      return {...state, error: undefined};
    })
    .addMatcher(
      (action) =>
        [initializeMortgageType.type, initializePropertyInformation.type].includes(action.type),
      (state) => {
        state.stepStatus.MORTGAGE_INFORMATION.visited = true;
        return state;
      }
    )
    .addMatcher(isSubmittingMortgageTypeSuccessOps, (state) => {
      state.stepStatus.MORTGAGE_INFORMATION.completed = true;
      state.stepStatus.MORTGAGE_INFORMATION.visited = true;
      return state;
    })
);

export default navigationHistoryReducer;
