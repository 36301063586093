import {default as styles} from "./Page.module.scss";
import {BaseProps} from "types/ui";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {isMobile} from "react-device-detect";
import classNames from "classnames";
import NavigationBar from "components/molecules/NavigationBar/NavigationBar";
import Box from "@mui/material/Box/Box";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import {DRAWER_WIDTH} from "appConstants";
import {useSelector} from "react-redux";
import {getIsNavigationMenuEnabled} from "store/selectors/configurations";
import Header from "components/organisms/Header/Header";
import Footer from "components/organisms/Footer/Footer";

const Page = ({children, className}: BaseProps) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isNavigationMenuEnabled = useSelector(getIsNavigationMenuEnabled);

  if (!isNavigationMenuEnabled) {
    return (
      <div
        className={classNames(styles.sidebarHidden, className, {
          [styles.keyboardOpen]: isMobile && isKeyboardOpen,
        })}
      >
        <Header />
        <Box
          className={classNames(styles.content, className)}
          component="main"
          sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${DRAWER_WIDTH}px)`}}}
        >
          {children}
          <Footer isKeyboardOpen={isMobile && isKeyboardOpen} />
        </Box>
      </div>
    );
  }

  return (
    <Box
      sx={{display: "flex", justifyContent: "center"}}
      className={classNames(styles.root, {
        [styles.keyboardOpen]: isMobile && isKeyboardOpen,
      })}
    >
      <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
        <CssBaseline />
        <NavigationBar />
        <Box
          className={classNames(styles.content, className)}
          component="main"
          sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${DRAWER_WIDTH}px)`, padding: "0 5vw"}}}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Page;
