import {default as styles} from "./ChipControlled.module.scss";
import {Chip, ChipProps, Typography} from "@mui/material";
import {useState} from "react";
import {Controller, FieldValues, UseFormReturn} from "react-hook-form";
import {Option} from "types/option";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import {TrackingEventType} from "types/enums/trackingEventType";
import {WithChildren} from "types/ui";
import {trackEvent} from "util/eventUtil";

interface ChipControlledProps<T extends FieldValues = any, C = any>
  extends Pick<ChipProps, "sx" | "color" | "label" | "icon" | "variant">,
    WithChildren {
  formHook: UseFormReturn<T, C>;
  options: Option[];
  name: string;
  required?: boolean;
  label?: string;
  triggerOnChange?: boolean; // React-hook-form workaround for dropdown change not updating the validation/UI
  trackingEvent?: TrackingEventType;
}

const ChipControlled = ({
  formHook,
  trackingEvent,
  options,
  // MUI props
  name,
  color,
}: ChipControlledProps) => {
  const {control, trigger, getFieldState, formState} = formHook;
  const {error, invalid, isTouched} = getFieldState(name);

  const shouldDisplayError =
    (formState.submitCount > 0 && Boolean(error?.message)) ||
    (isTouched && invalid && formState.isDirty);

  const [selectedButton, setSelectedButton] = useState<ResidentialApplicationType | null>(null);

  const handleButtonClick = (field: any, value: any) => {
    const selectedOption = options.find(
      (option) => option.label === value || option.value === value
    );
    if (selectedButton === value) {
      setSelectedButton(null);
      field.onChange(null);
    } else {
      setSelectedButton(value);
      if (trackingEvent) trackEvent(trackingEvent, `Selected application type: ${value}`);
      field.onChange(selectedOption?.value || null);
    }
  };

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({field}) => (
        <>
          <div className={styles.root}>
            {options.map((option: Option) => (
              <Chip
                key={option.value}
                className={styles.button}
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    lineHeight: "1.2",
                  },
                }}
                color={shouldDisplayError ? "error" : color}
                clickable
                label={option.label}
                icon={option.icon}
                variant={selectedButton === option.value ? "filled" : "outlined"}
                onClick={() => handleButtonClick(field, option.value)}
              />
            ))}
          </div>
          {shouldDisplayError && (
            <Typography
              className={styles.error}
              sx={{margin: "-12px 0px 0px 29px"}}
              color="#d32f2f"
              variant="caption"
            >
              {error?.message || ""}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export default ChipControlled;
