import {logEmpty} from "components/utils/log";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitSelfReportedIncomeAssetsOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {
  SelfReportedAssetsLiabilitiesFormData,
  submit,
  navigatePreviousStep,
  acceptMissingDataWarning,
  openUserAcceptedDataWarningModal,
} from "./SelfReportedAssetsLiabilities.action";
import {WithNavigate} from "types/ui";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {Currency} from "types/enums/currency";
import {SelfReportedAssetType} from "types/enums/selfReportedAssetType";
import {SelfReportedLiabilityType} from "types/enums/selfReportedLiabilityType";
import {getHasUserAcceptedDataWarning} from "store/selectors/applicantMeta";
import {RouteUrlValue} from "types/routeUrlValue";
import {getNextUrl} from "store/selectors/navigationController";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {ConfigurationKey} from "types/configurations";
import {saveAssetsLiabilities} from "store/actions/applicantMeta.action";

function* onSubmit({
  payload: {navigate, ...data},
}: WithPayload<SelfReportedAssetsLiabilitiesFormData>) {
  logEmpty(navigate, "navigate");
  const userId: string = yield select(getUserId);

  let otherAssets =
    data.otherAssets?.map(
      (other) =>
        ({
          type: SelfReportedAssetType.OTHER,
          amount: {
            amount: other.amount,
            currency: Currency.CAD,
          },
          description: other.description,
        } as SelfReportedAssetDto)
    ) || [];

  let otherLiabilities =
    data.otherLiabilities?.map(
      (other) =>
        ({
          type: SelfReportedLiabilityType.OTHER,
          balance: {
            amount: other.amount,
            currency: Currency.CAD,
          },
          lender: other.description,
        } as SelfReportedLiabilityDto)
    ) || [];

  let assets: SelfReportedAssetDto[] = [
    {type: SelfReportedAssetType.SAVINGS, amount: {amount: data.savings!, currency: Currency.CAD}},
    {
      type: SelfReportedAssetType.CHEQUING,
      amount: {amount: data.chequing!, currency: Currency.CAD},
    },
    {
      type: SelfReportedAssetType.RRSP,
      amount: {amount: data.rrsp!, currency: Currency.CAD},
    },
    {
      type: SelfReportedAssetType.TFSA,
      amount: {amount: data.tfsa!, currency: Currency.CAD},
    },
    ...otherAssets,
  ].filter((asset) => asset.amount?.amount);

  let liabilities: SelfReportedLiabilityDto[] = [
    {
      type: SelfReportedLiabilityType.CREDIT_CARD,
      balance: {amount: data.creditCard!, currency: Currency.CAD},
    },
    {
      type: SelfReportedLiabilityType.CAR_PAYMENT,
      balance: {amount: data.carPayment!, currency: Currency.CAD},
    },
    {
      type: SelfReportedLiabilityType.MORTGAGE,
      balance: {amount: data.mortgage!, currency: Currency.CAD},
    },
    {type: SelfReportedLiabilityType.LOANS, balance: {amount: data.loans!, currency: Currency.CAD}},
    ...otherLiabilities,
  ].filter((liability) => liability.balance?.amount);

  const isPageEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );

  if (!userId && isPageEnabled) {
    yield put(saveAssetsLiabilities([assets, liabilities]));
    return;
  }

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    selfReportedAssets: assets,
    selfReportedLiabilities: liabilities,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitSelfReportedIncomeAssetsOperation.saga,
    userId,
    basicInfoRequest,
    Step.SELF_REPORTED_ASSETS_LIABILITIES
  );
  if (Operation.isSuccess(result)) {
    const hasUserAcceptedDataWarning: boolean = yield select(getHasUserAcceptedDataWarning);
    const nextUrl: RouteUrlValue = yield select(getNextUrl);
    if (hasUserAcceptedDataWarning === false) {
      yield put(acceptMissingDataWarning(true));
      navigate(nextUrl);
    } else {
      yield put(acceptMissingDataWarning(true));
      navigate(RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT);
    }
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;

  const userId: string = yield select(getUserId);
  const isSelfReportedAssetsLiabilitiesEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );

  if (!userId && !isSelfReportedAssetsLiabilitiesEnabled) {
    navigate(RouteUrl.LANDING_PAGE_URL);
  } else {
    navigate(RouteUrl.BASIC_INFO_URL_ADDRESS);
  }
}

function* onAcceptMissingDataWarning({payload}: WithPayload) {
  const {hasUserAcceptedDataWarning} = payload;
  if (hasUserAcceptedDataWarning) {
    yield put(acceptMissingDataWarning(hasUserAcceptedDataWarning));
  }
}
function* onOpenUserAcceptedDataWarningModal({payload}: WithPayload) {
  const {shouldUserAcceptedDataWarningModalOpen} = payload;
  if (shouldUserAcceptedDataWarningModalOpen) {
    yield put(openUserAcceptedDataWarningModal(shouldUserAcceptedDataWarningModalOpen));
  }
}

export default function* selfReportedIncomeAssetsSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
  yield takeEvery(acceptMissingDataWarning, onAcceptMissingDataWarning);
  yield takeEvery(openUserAcceptedDataWarningModal, onOpenUserAcceptedDataWarningModal);
}
