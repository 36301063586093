import {FileData} from "types/fileType";
import {getLocalOrFallback} from "util/languageUtil";

export function formatAsPercentage(number: number): string {
  if (typeof number !== "number") {
    console.error(`Not a number cannot be formatted into a percentage. Value=${number}`);
  }
  return new Intl.NumberFormat(getLocalOrFallback(), {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  }).format(number / 100);
}

export function formatFileSize(bytes: number): FileData {
  if (typeof bytes !== "number") {
    console.error(`Not a number, can't be formatted into bytes. Value=${bytes}`);
  }

  if (bytes == 0) {
    return {size: 0, unit: "bytes"};
  }

  const k = 1024;
  const sizes = ["bytes", "kb", "mb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const data = {
    size: parseFloat(((bytes || 0) / Math.pow(k, i)).toFixed(2)),
    unit: sizes[i],
  };

  return data;
}

export function isNumberPositive(number?: number): boolean {
  return !!number && number > 0;
}
