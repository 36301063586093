import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {maybe} from "types/basic";
import {WithNavigate} from "types/ui";

const namespace = "SINForm";

export interface SINFormData extends WithNavigate {
  legalFirstName?: string;
  legalLastName?: string;
  sinNumbers: maybe<string[]>; // 3 parts ["123", "456", "789"]
  dateOfBirth?: Date | string;
  sinRequired?: boolean;
}

export const submit = createAction<SINFormData>(actionTypeOf(namespace, "submit"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
