import {createSelector} from "@reduxjs/toolkit";
import {lowerAlphaNumeric} from "components/utils/stringUtil";
import {getFixedT, isFrench} from "util/languageUtil";
import {State} from "types/store";
import {Theme, ThemeState} from "types/theme";
import {getUtm} from "util/localStorageUtil";
import {nullable} from "types/basic";

const fixedT = getFixedT("regionalRedirector");

export const getTheme = (rootState: State): ThemeState => rootState.theme || null;

export const getThemeOrEmpty = createSelector(getTheme, (theme) => theme || ({} as Theme));

// platform/name in theme, is the whitelabel name or token
export const getPlatform = createSelector(getTheme, (theme) => theme?.name);

export const getPlatformId = createSelector(getTheme, (theme) => theme?.id);

export const getFeatures = createSelector(getTheme, (theme) => theme?.features);

export const getForcePinch = createSelector(getFeatures, (features) => features?.forcePinch);

export const getIsMortgageProviderLogoEnabled = createSelector(
  getFeatures,
  (features) => features?.isMortgageProviderLogoEnabled !== false
);

export const getHeaderText = createSelector(getTheme, (theme) => (language: string) => {
  if (getUtm().utmSource == "rr") {
    return fixedT("header");
  }

  if (!isFrench(language)) {
    return theme?.headerText;
  }

  return theme?.features?.headerTextFr || theme?.headerText;
});

export const getHeaderLink = createSelector(getTheme, (theme) => {
  if (getUtm().utmSource == "rr") {
    return window._env_.REACT_APP_REGIONAL_REDIRECTOR_URL;
  }

  return theme?.headerText && theme?.headerLink ? theme?.headerLink : undefined;
});

export const getDisclosures = createSelector(getTheme, (theme) => theme?.features?.disclosures);

export const getDocumentDisclaimerForDirectLender = createSelector(
  getTheme,
  (theme) => (language: string) =>
    isFrench(language)
      ? theme?.features?.disclosures?.documentUploadFr
      : theme?.features?.disclosures?.documentUpload
);

export const getFooterTextImage = createSelector(
  getTheme,
  (theme) => (language: string, providerName?: string, brokerCode?: nullable<string>) => {
    // Footer text/image needs to be brokerCode or providerName specific
    if (!providerName) {
      return {
        image: "",
        text: "",
      };
    }

    const footersCompatible = {...theme?.features?.footers};
    for (let key in footersCompatible) {
      footersCompatible[lowerAlphaNumeric(key)] = footersCompatible[key];
    }

    const providerNameCompatible = lowerAlphaNumeric(providerName);
    const footerByProvider =
      footersCompatible[providerName] || footersCompatible[providerNameCompatible];
    const footerByBrokerCode = !!brokerCode ? footersCompatible : null;

    const footerTextFr = footerByBrokerCode?.footerTextFr || footerByProvider?.footerTextFr;
    const footerTextEn = footerByBrokerCode?.footerText || footerByProvider?.footerText;
    return {
      image: (brokerCode && footerByBrokerCode?.image) || footerByProvider?.image || "",
      text: String((isFrench(language) ? footerTextFr : footerTextEn) || ""),
    };
  }
);

export const getHasFeatureSin = createSelector(
  getTheme,
  (theme) => theme?.features?.enableSin !== false // has SIN feature if either undefined/true.
);
