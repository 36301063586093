import {call, takeEvery} from "redux-saga/effects";
import {onSignOut} from "store/sagas/auth.saga";
import {submit} from "./UserProfileBadge.action";

function* onSubmit() {
  yield call(onSignOut);
}

export default function* userProfileBadgeSaga() {
  yield takeEvery(submit, onSubmit);
}
