import i18next from "i18next";
import {call, select, takeEvery} from "redux-saga/effects";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {registerSecondaryUserOperation} from "store/operation/user";
import {getBasicInfoOrEmpty} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {User} from "types/dto/user";
import {RouteUrl} from "types/route";
import {getNextStepUrl} from "util/routeUtil";
import {registerSecondaryUser, RegisterSecondaryUserPayload} from "./SecondarySignUpForm.action";

function* onRegisteringSecondary({
  payload: {navigate, appId, ...userRequest},
}: WithPayload<RegisterSecondaryUserPayload>) {
  const whiteLabelId: string = yield select(getWhitelabelId);
  const secondarySignUpResult: SagaResult<User> = yield call(
    registerSecondaryUserOperation.saga,
    appId,
    {...userRequest, languagePreference: i18next.language},
    whiteLabelId,
    location.host
  );
  if (Operation.isSuccess(secondarySignUpResult)) {
    const userId: string = yield select(getUserId);
    const basicInfoResult: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
      fetchBasicInfoByUserIdOperation.saga,
      userId
    );

    if (Operation.isSuccess(basicInfoResult)) {
      const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getBasicInfoOrEmpty);
      navigate(getNextStepUrl(basicInfo));
    }
  }
}

export default function* secondarySignUpFormSaga() {
  yield takeEvery(registerSecondaryUser, onRegisteringSecondary);
}
