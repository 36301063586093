import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {PropertyAddressDtoUI} from "types/dto/propertyAddressDto";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {WithNavigate} from "types/ui";

const namespace = "AddressInfo";

export interface AddressInfoFormData
  extends WithNavigate,
    Pick<ResidentialApplicationBasicInfoRequest, "timeAtAddress" | "ownershipStatus"> {
  residentialAddress: PropertyAddressDtoUI;
  previousResidentialAddress?: PropertyAddressDtoUI | null;
}
export const submitAddressInfo = createAction<AddressInfoFormData>(
  actionTypeOf(namespace, "submit")
);

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
