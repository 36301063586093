import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {UserRequest} from "types/dto/userRequest";
import {WithNavigate} from "types/ui";

const namespace = "SignUpForm";

export type RegisterPrimaryUserPayload = Partial<UserRequest> & WithNavigate;

export interface SignUpFormData
  extends WithNavigate,
    Pick<UserRequest, "email" | "firstName" | "lastName" | "phoneNumber" | "applicationType"> {}

export const registerPrimaryUser = createAction<RegisterPrimaryUserPayload>(
  actionTypeOf(namespace, "registerPrimaryUser")
);
