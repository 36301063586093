export enum HttpMethods {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE"
}

export enum HttpHeaders {
  CONTENT_TYPE = "Content-Type"
}

export enum MediaType {
  APPLICATION_JSON = "application/json"
}
