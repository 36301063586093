import {
  root,
  text as textClass,
  buttonIcon,
  selected as selectedClass,
} from "./IconDialogButton.module.scss";

interface IconDialogButtonProps {
  text?: string;
  icon?: any;
  onClick?: () => void;
  selected?: boolean;
  className?: string;
  textClassName?: string;
}

const IconDialogButton = ({
  text = "",
  icon,
  onClick = () => {},
  selected = false,
  className = "",
  textClassName = textClass,
  ...otherProperties
}: IconDialogButtonProps) => {
  return (
    <button
      className={`
        ${root}
        ${selected ? selectedClass : "not-selected"}
        ${className}
      `}
      onClick={onClick}
    >
      <div className={textClassName}>{text}</div>
      {icon && <img src={icon} className={buttonIcon} />}
    </button>
  );
};

export default IconDialogButton;
