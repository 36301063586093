import {ApiError} from "./ApiError";

export interface Password {
  password: string;
  confirmPassword?: string;
}

export interface EmailPassword {
  email: string;
  password: string;
}

export interface WithPayload<T = any> {
  payload: T;
}

export type SagaResult<T = any> = ApiError | T;

export type nullable<T> = null | T;

export type optional<T> = undefined | T;

export type maybe<T> = null | undefined | T;

export enum QueryParamKeys {
  BROKER_CODE = "brokerCode",
  UTM_SOURCE = "utmSource",
  UTM_MEDIUM = "utmMedium",
  UTM_CAMPAIGN = "utmCampaign",
  UTM_TERM = "utmTerm",
  UTM_CONTENT = "utmContent",
  INTEGRATION_USER_ID = "integrationUserId",
  REFERRER = "referrer",
  SELECTED_PROVINCE = "selectedProvince",
  REFERRAL_CODE = "referral_code",
}

export interface UTM {
  [QueryParamKeys.BROKER_CODE]: nullable<string>;
  [QueryParamKeys.UTM_CAMPAIGN]: nullable<string>;
  [QueryParamKeys.UTM_CONTENT]: nullable<string>;
  [QueryParamKeys.UTM_MEDIUM]: nullable<string>;
  [QueryParamKeys.UTM_SOURCE]: nullable<string>;
  [QueryParamKeys.UTM_TERM]: nullable<string>;
  [QueryParamKeys.INTEGRATION_USER_ID]: nullable<string>;
}
