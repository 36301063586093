import * as yup from "yup";
import {Currency} from "types/enums/currency";
import {LoanType} from "types/enums/loanType";
import {Money} from "types/dto/money";
import {isDateInFutureWithinSixMonths, isValidDate} from "components/utils/dateUtil";
import {useTranslation} from "react-i18next";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "mortgageInfoSteps.mortgageType"});

  return yup.object().shape({
    downPayment: yup.lazy((downPaymentValue, {parent}) => {
      if (LoanType.PREAPPROVAL === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.missing", {context: "downPayment"})!)
              .min(1, fixedT("messages.missing", {context: "downPayment"})!)
              .required(fixedT("messages.missing", {context: "downPayment"})!),
            currency: yup.mixed<Currency>().required(),
          })
          .required(fixedT("messages.missing", {context: "downPayment"})!);
      }
      if (LoanType.NEW_PURCHASE === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.missing", {context: "downPayment"})!)
              .min(1, fixedT("messages.missing", {context: "downPayment"})!)
              .lessThan(parent.purchasePrice.amount, fixedT("messages.downPaymentLargerThanLoan")!)
              .required(fixedT("messages.missing", {context: "downPayment"})!),
            currency: yup.mixed<Currency>().required(),
          })
          .required(fixedT("messages.missing", {context: "downPayment"})!);
      }
      return yup.mixed<Money>().notRequired();
    }),
    downPaymentPercentMultipled100: yup.lazy((downPaymentValue, {parent}) => {
      if (LoanType.PREAPPROVAL === parent.loanType) {
        return yup
          .number()
          .typeError(fixedT("messages.invalid", {context: "downPaymentPercentMultipled100"})!)
          .min(5, fixedT("messages.invalid", {context: "downPaymentPercentMultipled100"})!)
          .max(99, fixedT("messages.invalid", {context: "downPaymentPercentMultipled100"})!)
          .required(fixedT("messages.invalid", {context: "downPaymentPercentMultipled100"})!);
      }
      return yup.mixed<number>().notRequired();
    }),
    remainingAmount: yup.lazy((remainingAmountValue, {parent}) => {
      if (LoanType.RENEWAL === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.missing", {context: "remainingAmount"})!)
              .min(1, fixedT("messages.missing", {context: "remainingAmount"})!)
              .required(fixedT("messages.missing", {context: "remainingAmount"})!),
            currency: yup.mixed<Currency>().required(),
          })
          .required(fixedT("messages.missing", {context: "remainingAmount"})!);
      }
      return yup.mixed<Money>().notRequired();
    }),
    estimatedPropertyValue: yup.lazy((estimatedPropertyValue, {parent}) => {
      if (LoanType.RENEWAL === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.missing", {context: "estimatedPropertyValue"})!)
              .min(1, fixedT("messages.missing", {context: "estimatedPropertyValue"})!)
              .required(fixedT("messages.missing", {context: "estimatedPropertyValue"})!),
            currency: yup.mixed<Currency>().required(),
          })
          .required(fixedT("messages.missing", {context: "estimatedPropertyValue"})!);
      }
      return yup.mixed<Money>().notRequired();
    }),
    remainingMonths: yup.lazy((remainingMonthsValue, {parent}) => {
      if (LoanType.RENEWAL === parent.loanType) {
        return yup
          .number()
          .typeError(fixedT("messages.missing", {context: "remainingMonths"})!)
          .min(1, fixedT("messages.missing", {context: "remainingMonths"})!)
          .required(fixedT("messages.missing", {context: "remainingMonths"})!);
      }
      return yup.mixed<number>().notRequired();
    }),
    purchasePrice: yup.lazy((purchasePriceValue, {parent}) => {
      if (LoanType.NEW_PURCHASE === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.missing", {context: "purchasePrice"})!)
              .min(1, fixedT("messages.missing", {context: "purchasePrice"})!)
              .required(fixedT("messages.missing", {context: "purchasePrice"})!),
            currency: yup.mixed<Currency>().required(),
          })
          .required(fixedT("messages.missing", {context: "purchasePrice"})!);
      }
      return yup.mixed<Money>().notRequired();
    }),
    closingDate: yup.lazy((closingDateValue, {parent}) => {
      if (LoanType.NEW_PURCHASE === parent.loanType) {
        return yup
          .mixed<Date | string>()
          .test({
            name: "closingDate",
            test(closingDate, ctx) {
              if (!closingDate) {
                return true;
              }
              const date = new Date(closingDate!);
              if (isValidDate(date) && isDateInFutureWithinSixMonths(date)) {
                return true;
              }
              let errorMessage;
              if (!isValidDate(date)) {
                errorMessage = fixedT("messages.invalid", {context: "closingDate"});
              } else {
                errorMessage = fixedT("messages.invalid", {context: "closingDateWithin6Months"});
              }
              return ctx.createError({
                path: "closingDate",
                message: errorMessage,
              });
            },
          })
          .notRequired();
      }
      return yup.mixed<Date>().notRequired();
    }),
    renewalDate: yup.lazy((renewalDate, {parent}) => {
      if (LoanType.RENEWAL === parent.loanType) {
        return yup
          .mixed<Date | string>()
          .test({
            name: "renewalDate",
            test(renewalDate, ctx) {
              let errorMessage;
              if (!renewalDate) {
                errorMessage = fixedT("messages.required", {context: "renewalDate"});
              } else {
                const date = new Date(renewalDate);
                if (!isValidDate(date)) {
                  errorMessage = fixedT("messages.invalid", {context: "renewalDate"});
                }
                if (!isDateInFutureWithinSixMonths(date)) {
                  errorMessage = fixedT("messages.invalid", {context: "renewalDateWithin6Months"});
                }
              }

              return errorMessage
                ? ctx.createError({
                    path: "renewalDate",
                    message: errorMessage,
                  })
                : true;
            },
          })
          .notRequired();
      }
      return yup.mixed<Date>().notRequired();
    }),
  });
};
