import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {LoanType} from "types/enums/loanType";
import {WithOnSuccess} from "types/ui";
import {WithNavigate} from "types/ui";

const namespace = "MortgageSelection";

export interface SubmitLoanTypePayload extends WithOnSuccess {
  loanType: LoanType;
}

export const submitLoanType = createAction<SubmitLoanTypePayload>(
  actionTypeOf(namespace, "submitLoanType")
);

export const initialize = createAction(actionTypeOf(namespace, "initialize"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
