import moment, {MomentObjectOutput} from "moment";

export const ONE_SECOND = 1000;

export function toISODateString(
  date?: number,
  monthsBaseOne?: number,
  years?: number
): string | undefined {
  // 12, 12, 1970
  try {
    return moment({date, months: (monthsBaseOne as number) - 1, years}).toISOString(); // moment months are 0-indexed
  } catch (e) {
    console.error("Can't make ISO date string due to:", e);
    return undefined;
  }
}

export function toMomentObjectAdjusted(dateStr?: string | any): MomentObjectOutput | undefined {
  // 12-12-1970
  if (!dateStr) {
    return undefined;
  }
  try {
    const obj = moment(dateStr).toObject();
    return {...obj, months: obj.months + 1}; // moment months are 0-indexed
  } catch (e) {
    console.error("Parsing date to moment object error:", e);
    return undefined;
  }
}

export function daysBetween(today: Date, day: Date): number {
  const diff = Math.abs(today.getTime() - day.getTime());
  return diff / (1000 * 60 * 60 * 24);
}

export function isValidDate(date?: Date): boolean {
  if (!date) return false;
  return !isNaN(date.getTime());
}

export function isDateInFutureWithinSixMonths(date: Date): boolean {
  const currentDate = new Date();
  const sixMonthsFromNow = new Date();
  sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);

  return date > currentDate && date <= sixMonthsFromNow;
}
