import {ApplicationStatusResponse} from "types/dto/ApplicationStatusResponse";
import {PropertyAddressDto} from "types/dto/propertyAddressDto";
import {ApplicationStep} from "types/enums/ApplicationStep";
import {StepStatus} from "types/enums/stepStatus";
import { anyMatchCollection, anyMatch, allMatch } from "util/objectUtil";

export const MAX_TRIES = 3;

export const hasIncompleteSecondaryData = (isJointApp: boolean, statusMap: ApplicationStatusResponse) => {
  // incomplete on a joint app means both are missing  
  return isJointApp && anyMatchCollection(statusMap, [StepStatus.INCOMPLETE, StepStatus.MISSING_SECONDARY]);
};

export const hasIncompletePrimaryOrSecondaryData = (statusMap: ApplicationStatusResponse) => {
  return anyMatchCollection(statusMap, [
    StepStatus.INCOMPLETE,
    StepStatus.MISSING_PRIMARY,
    StepStatus.MISSING_SECONDARY,
  ]);
};

export const hasPendingData = (statusMap: ApplicationStatusResponse) => {
  return anyMatch(statusMap, StepStatus.PENDING);
};

export const hasUnresolvedMortgageInformationWithoutMatches = (
  statusMap: ApplicationStatusResponse,
  potentialAddressMatchesOrEmpty: PropertyAddressDto[]
) => {
  return (
    StepStatus.UNRESOLVED === statusMap[ApplicationStep.MORTGAGE_INFORMATION] &&
    potentialAddressMatchesOrEmpty.length === 0
  );
};

export const hasUnresolvedMortgageInformationWithMatches = (
  statusMap: ApplicationStatusResponse,
  potentialAddressMatchesOrEmpty: PropertyAddressDto[]
) => {
  return (
    StepStatus.UNRESOLVED === statusMap[ApplicationStep.MORTGAGE_INFORMATION] &&
    potentialAddressMatchesOrEmpty.length !== 0
  );
};

export const hasFailureStatus = (statusMap: ApplicationStatusResponse) => {
  return anyMatch(statusMap, StepStatus.FAILURE);
};

export const isAllDataPresent = (statusMap: ApplicationStatusResponse) => {
  return allMatch(statusMap, StepStatus.COMPLETE);
};
