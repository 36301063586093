import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {maybe} from "types/basic";
import {EmploymentHistoryDto} from "types/dto/employmentHistoryDto";
import {Money} from "types/dto/money";
import {WithNavigate, WithOnSuccess} from "types/ui";

const namespace = "EmploymentInfo";

export interface EmploymentInfoFormData extends EmploymentHistoryDto, WithNavigate {
  grossIncome?: maybe<Money>;
}

export const submitEmployment = createAction<EmploymentInfoFormData>(
  actionTypeOf(namespace, "submit")
);

export const acceptPrivacy = createAction<WithOnSuccess>(actionTypeOf(namespace, "acceptPrivacy"));

// export const navigatePreviousStep = createAction<WithNavigate>(
//   actionTypeOf(namespace, "navigateBack")
// );
