import {call, put, select, takeEvery} from "redux-saga/effects";
import {
  fetchThemeOperation,
  fetchAuthorizedUserOperation,
  fetchUserApiOperation,
  fetchWhiteLabelConfigurationsOperation,
  signOutOperation,
  fetchProviderConfigurationsOperation,
} from "store/operation/operations";
import {initialize} from "./Root.action";
import i18next, {t} from "i18next";
import {getHasUserAccount, getPreferredLanguage, getUserId} from "store/selectors/user";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {getIsAuthorized} from "store/selectors/auth";
import {getBrokerCode, getConsolidatedMortgageProviderId} from "store/selectors/basicInfo";
import {hasVisited, setHasVisited, storeReferrer, storeUtm} from "util/localStorageUtil";
import {QueryParamKeys, SagaResult, WithPayload} from "types/basic";
import {WithNavigate} from "types/ui";
import {
  MORTGAGE_PROVIDER_NOT_REQUIRED_URLS,
  RouteUrl,
  USER_ACCOUNT_NOT_REQUIRED_URLS,
} from "types/route";
import {Theme} from "types/theme";
import {
  fetchMortgageProvidersOperation,
  fetchWhitelabelOperation,
} from "store/operation/whitelabel";
import {getPlatform} from "store/selectors/theme";
import {
  getBrokeargeOrganizationId,
  getProviders,
  getWhitelabelId,
} from "store/selectors/whitelabel";
import {LanguageType} from "types/enums/languageType";
import {onChangeLanguage} from "components/molecules/LanguagesToggle/LanguagesToggle.saga";
import {getSearchParamLocale} from "components/utils/urlUtil";
import {getIsPurchaseTimeframePageActive, getLanguage} from "store/selectors/configurations";
import {onInitialize as initializeNavbar} from "components/molecules/NavigationBar/NavigationBar.saga";
import {WhiteLabelProductGroupDto} from "types/dto/WhiteLabelProductGroupDto";
import {ApiError} from "types/ApiError";
import {submit as submitProductProvider} from "pages/SelectProductProviderPage/SelectProductProviderPage.action";
import {Configuration, ConfigurationKey} from "types/configurations";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {ProductSummary} from "types/dto/productSummary";
import {WhiteLabelDetailsDto} from "types/dto/WhiteLabelDetailsDto";
import {FinancialInstitutionType} from "types/enums/financialInstitutionType";

export function* storeUrlParams() {
  yield call(storeUtm);

  try {
    const urlParams = new URLSearchParams(window.location.search);
    storeUrlParam(QueryParamKeys.REFERRAL_CODE);
    storeUrlParam(QueryParamKeys.SELECTED_PROVINCE)
    const referrer = urlParams.get(QueryParamKeys.REFERRER);
    storeReferrer(referrer ? decodeURIComponent(referrer) : null);
  } catch (error: any) {
    console.error("There was an error while parsing the url params", error);
  }
}

function storeUrlParam(key: string) {
  const urlParams = new URLSearchParams(window.location.search);

  const value = urlParams.get(key);
  if (value) {
    localStorage.setItem(key, value);
  }
}

export function* initConfigurations() {
  const whitelabelId: string = yield select(getWhitelabelId);
  yield call(fetchWhiteLabelConfigurationsOperation.saga, whitelabelId);
}

export function* initUser() {
  yield call(fetchAuthorizedUserOperation.saga);

  const isAuthed: boolean = yield select(getIsAuthorized);
  const userId: string = yield select(getUserId);

  if (isAuthed) {
    console.info("Authorised user: userId=" + userId);
    yield call(fetchUserApiOperation.saga);
  }

  if (userId) {
    console.info("fetch user basicInfo: userId=" + userId);
    yield call(fetchBasicInfoByUserIdOperation.saga, userId);
  }
}

export function* initLanguageAfterUserAndConfiguration(navigate: any) {
  const userLocale: LanguageType | undefined = yield select(getPreferredLanguage);
  const configDefaultLocale: LanguageType | undefined = yield select(getLanguage);

  let locale = getSearchParamLocale() || i18next.language || userLocale || LanguageType.EN_CA;

  // When the user first visits the app check the configuration default
  if (!localStorage.getItem("initLocale")) {
    localStorage.setItem("initLocale", "true");
    locale =
      getSearchParamLocale() ??
      userLocale ??
      configDefaultLocale ??
      i18next.language ??
      LanguageType.EN_CA;
  }
  yield call(onChangeLanguage, {
    payload: {navigate, locale},
  });
}

export function* onInitialize({payload}: WithPayload<Required<WithNavigate>>) {
  const navigate = payload?.navigate;

  yield call(initUser);
  yield call(storeUrlParams);
  const brokerCode: string = yield select(getBrokerCode);
  const themeResult: SagaResult<Theme> = yield call(fetchThemeOperation.saga, brokerCode);
  if (themeResult instanceof ApiError) {
    console.error("Redirect to error page, due to theme not found, or missing name", themeResult);
    return navigate(RouteUrl.ERROR_PAGE_URL, {
      state: {message: t("errors.themeFetchFailure"), status: themeResult.status},
    });
  }
  if (!themeResult.name) {
    console.error("Redirect to error page, due to theme missing name", themeResult);
    return navigate(RouteUrl.ERROR_PAGE_URL, {
      state: {message: t("errors.themeInvalid")},
    });
  }

  const whiteLabelName: string = yield select(getPlatform);

  const whiteLabelResult: SagaResult<WhiteLabelDetailsDto> = yield call(
    fetchWhitelabelOperation.saga,
    whiteLabelName
  );
  if (whiteLabelResult instanceof ApiError) {
    console.error(
      `Redirect to error page, due to whiteLabel not found by name: ${whiteLabelName}`,
      whiteLabelResult
    );
    return navigate(RouteUrl.ERROR_PAGE_URL, {
      state: {
        message: t("errors.whiteLabelFetchFailure"),
        status: whiteLabelResult.status,
      },
    });
  }

  yield call(initConfigurations);
  yield call(initLanguageAfterUserAndConfiguration, navigate);

  let mortgageProviderId: string | undefined = yield select(getConsolidatedMortgageProviderId);

  const leadAssignmentProviderId = localStorage.getItem("leadAssignmentProviderId")

  // Get mortgage providers and pass in the previously displayed brokerage id
  // if applicable, so the user can refresh the page and still see the same brokerage.
  const mortgageProvidersResult: SagaResult<WhiteLabelProductGroupDto> = yield call(
    fetchMortgageProvidersOperation.saga,
    whiteLabelResult.publicId,
    leadAssignmentProviderId
  );

  if (mortgageProvidersResult instanceof ApiError) {
    console.error(
      "Redirect to error page, due to mortgage providers error",
      mortgageProvidersResult
    );
    return navigate(RouteUrl.ERROR_PAGE_URL, {
      state: {
        message: t("errors.mortgageProviderFetchFailure"),
        status: mortgageProvidersResult.status,
      },
    });
  }

  const providers: ProductSummary[] = yield select(getProviders);
  if (!providers.length) {
    console.error(
      "Redirect to error page, due to no active mortgage providers configured for whitelabel",
      whiteLabelName
    );
    return navigate(RouteUrl.ERROR_PAGE_URL, {
      state: {message: t("errors.mortgageProviderNotFound")},
    });
  }

  // Automatically select product provider if there is only one, this should only be set if it is not already set.
  // In the event that the sole provider changes from providerA to providerB, we must stick with providerA
  // because that's what the applicant signed up for.
  const lenderProducts = mortgageProvidersResult.lender?.products;
  const directProducts = mortgageProvidersResult.bank?.products;

  // Save the lead assignment displayed brokerage, if it's not already set
  // so the user can refresh the page and still see the same brokerage.
  if (!leadAssignmentProviderId) {
    const products = (lenderProducts ?? []).concat(directProducts ?? []);

    const id = products
      .find((product) => {
        return product.financialInstitutionType === FinancialInstitutionType.BROKERAGE;
      })?.financialInstitutionPublicId
    if (id) {
      localStorage.setItem("leadAssignmentProviderId", id);
    }
  }

  // if we have both lender and bank type products, check if they're the same org before auto-selecting
  if (!mortgageProviderId && lenderProducts?.length && directProducts?.length) {
    const allSameOrganization = directProducts
      .concat(lenderProducts)
      .every((product, index, products) => {
        return product.financialInstitutionPublicId === products[0].financialInstitutionPublicId;
      });
    if (allSameOrganization) {
      const orgId = lenderProducts[0]?.financialInstitutionPublicId; // Doesn't matter if lender or direct
      console.info("Redirect with auto-selected product provider: id=" + orgId);
      yield put(submitProductProvider(orgId));
      mortgageProviderId = orgId;
    }
  }
  if (!mortgageProviderId && !directProducts?.length) {
    const orgId = lenderProducts?.[0]?.financialInstitutionPublicId;
    if (orgId) {
      console.info("Redirect with auto-selected brokerage: id=" + orgId);
      yield put(submitProductProvider(orgId));
      mortgageProviderId = orgId;
    } else {
      console.error("This shouldn't happen. No lender organization id.");
    }
  }

  // When there is more than one bank, do not auto-select.
  if (!mortgageProviderId && !lenderProducts?.length && directProducts.length === 1) {
    const orgId = directProducts?.[0]?.financialInstitutionPublicId;
    if (orgId) {
      console.info("Redirect with auto-selected bank: id=" + orgId);
      yield put(submitProductProvider(orgId));
      mortgageProviderId = orgId;
    } else {
      console.error("This shouldn't happen. No Bank organization id.");
    }
  }

  yield call(initializeNavbar);

  // When there are multiple product providers, always use the brokerage's id to fetch configurations
  // instead of the bank.
  if (providers.length > 1) {
    const brokerageId: string = yield select(getBrokeargeOrganizationId);
    mortgageProviderId = brokerageId || mortgageProviderId;
  }

  if (mortgageProviderId && !hasVisited()) {
    setHasVisited(true);
    const providerConfigResult: SagaResult<Configuration[]> = yield call(
      fetchProviderConfigurationsOperation.saga,
      mortgageProviderId
    );
    if (providerConfigResult instanceof ApiError) {
      console.error(
        "Redirect to error page, unable to load product provider configuration",
        providerConfigResult
      );
      return navigate(RouteUrl.ERROR_PAGE_URL, {
        state: {
          message: t("errors.mortgageProviderFetchFailure"),
          status: providerConfigResult.status,
        },
      });
    }

    const isPurchaseTimeframePageActive: boolean = yield select(getIsPurchaseTimeframePageActive);
    const assetsLiabilities: boolean = yield select(
      getIsProviderConfigurationValueEnabled(
        ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
      )
    );
    const credit: boolean = yield select(
      getIsProviderConfigurationValueEnabled(
        ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
      )
    );

    // When there are more than one product providers, always show the brokerage/bank page
    // allowing the user to choose who they want to work with
    const landing: boolean =
      providers.length > 1 ||
      (yield select(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_BROKERAGE_OR_BANKING
        )
      ));
    const currentUrl = window.location.pathname;
    if (
      ![RouteUrl.ACTIVATE_ACCOUNT_URL, RouteUrl.SIGN_IN, RouteUrl.SECONDARY_SIGNUP].includes(
        currentUrl as RouteUrl
      )
    ) {
      if (isPurchaseTimeframePageActive) {
        return navigate(RouteUrl.LANDING_PAGE_URL); // If PurchaseTimeframe is active, it will be the landing page.
      } else if (assetsLiabilities) {
        return navigate(RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT);
      } else if (credit) {
        return navigate(RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT);
      } else if (landing) {
        return navigate(RouteUrl.LANDING_PAGE_URL);
      }

      return navigate(RouteUrl.SIGN_IN);
    }
  }

  if (mortgageProviderId) {
    yield call(fetchProviderConfigurationsOperation.saga, mortgageProviderId);
  }

  if (
    !mortgageProviderId &&
    !MORTGAGE_PROVIDER_NOT_REQUIRED_URLS.includes(window.location.pathname)
  ) {
    console.error("Redirecting to select product provider page due to no mortgageProvider");
    return navigate(RouteUrl.SELECT_PRODUCT_PROVIDER_URL);
  }

  const hasUser: boolean = yield select(getHasUserAccount);

  if (!USER_ACCOUNT_NOT_REQUIRED_URLS.includes(window.location.pathname) && !hasUser) {
    console.info("Redirect to sign in page, due to user account not found");
    return navigate(RouteUrl.SIGN_IN);
  }
}

export default function* rootComponentSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(signOutOperation.success, onInitialize);
}
