import {logIf, logObjectEmpty} from "components/utils/log";
import {Configuration, ConfigurationValueType} from "types/configurations";

export const isConfigurationValueTrue = (config: Configuration | undefined) => {
  logObjectEmpty(config, "provider configuration");
  logIf(
    ConfigurationValueType.BOOLEAN !== config?.valueType,
    `Configuration is not a boolean type and shouldn't be checked if enabled. Name=${config?.name}`
  );
  if (config?.value) {
    return config?.value === "true";
  }
  return config?.defaultValue === "true";
};
