import {useNavigate} from "react-router-dom";
import i18next from "i18next";
import {
  root,
  animation,
  details,
  disclosure as disclosureClass,
} from "./ApplicationComplete.module.scss";
import StampAnimation from "assets/images/ApplicationComplete/stamp.gif";
import Button from "components/atoms/Button/Button";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {useSelector} from "react-redux";
import {getThemeOrEmpty} from "store/selectors/theme";
import {getWhitelabelToken} from "store/selectors/whitelabel";
import {isFrench} from "util/languageUtil";
import {Typography} from "@mui/material";
import Page from "pages/Page/Page";
import {useTranslation} from "react-i18next";

const ApplicationComplete = () => {
  const token = useSelector(getWhitelabelToken);
  const theme = useSelector(getThemeOrEmpty);
  const navigate = useNavigate();

  const {i18n} = useTranslation();

  const disclosure = isFrench(i18n.language)
    ? theme?.features?.disclosures?.applicationCompleteEmailFr
    : theme?.features?.disclosures?.applicationComplete;

  return (
    <Page className={root}>
      <FailoverImage
        src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/application_complete_screen/application_complete.svg`}
        failoverSrc={StampAnimation}
        className={animation}
      />
      <div className={details}>
        <Typography align="center" variant="h1">
          {i18next.t("applicationComplete.details1")}
        </Typography>
        <Typography align="center" variant="h1">
          {i18next.t("applicationComplete.details2")}
        </Typography>
        {disclosure && <p className={disclosureClass}>{disclosure}</p>}
        {theme?.features?.enableManulife && (
          <Button onClick={() => navigate("/manulife-instructions")}>
            <Typography align="center" variant="h1">
              {i18next.t("applicationComplete.button")}
            </Typography>
          </Button>
        )}
      </div>
    </Page>
  );
};

export default ApplicationComplete;
