import {useEffect, useState} from "react";
import {default as styles} from "./PurchaseTimeframePage.module.scss";
import Message from "components/atoms/Message/Message";
import {useDispatch, useSelector} from "react-redux";
import {getHasUserAccount} from "store/selectors/user";
import {guessProperRouteUrl} from "util/routeUtil";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {Button, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Page from "pages/Page/Page";
import {getNavigationError} from "store/selectors/navigationController";
import {getFixedT} from "util/languageUtil";
import {PurchaseTimeframe} from "types/enums/purchaseTimeframe";
import PurchaseTimeframeIcon from "components/icons/PurchaseTimeframeIcon/PurchaseTimeframeIcon";
import {getTheme} from "store/selectors/theme";
import chroma from "chroma-js";
import {choosePurchaseTimeframe} from "store/actions/applicantMeta.action";
import {useNavigate} from "react-router-dom";

const fixedT = getFixedT("purchaseTimeframePage");

interface Props {
  className?: () => any;
  prevPage?: () => any;
  nextPage?: () => any;
}

const PurchaseTimeframePage: React.FC<Props> = ({
  className = "",
  prevPage = undefined,
  nextPage = () => {},
}) => {
  const dispatch = useDispatch();
  const hasAccount = useSelector(getHasUserAccount);
  const theme = useSelector(getTheme);
  const navigate = useNavigate();
  const navigationError = useSelector(getNavigationError);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setError(navigationError ?? "");
  }, [navigationError]);

  function stringToNumberArray(inputString?: string) {
    if (!inputString) return;

    const stringArray = inputString.split(",");
    const numberArray = stringArray.map((str: string) => parseFloat(str.trim()));

    return numberArray;
  }

  return (
    <Page className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div>
            <Typography align="left" variant="h2">
              {fixedT("header")}
            </Typography>
            <Typography align="left" variant="h6">
              {fixedT("subheader")}
            </Typography>
          </div>
          <Message message={error} className={styles.error} />
          <div>
            <Typography align="left" variant="h6">
              {fixedT("subheader2")}
            </Typography>
          </div>
          <Grid className={styles.productProviders} container>
            <Grid xs={12} sm={5.8}>
              <Button
                onClick={() => {
                  dispatch(choosePurchaseTimeframe(PurchaseTimeframe.WITHIN_30_DAYS));
                  trackEvent(TrackingEventType.purchaseTimeframePage2to3Months);
                  hasAccount ? navigate(guessProperRouteUrl()) : nextPage();
                }}
                className={styles.provider}
              >
                {fixedT("timeframe.within30Days")}
              </Button>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <Button
                onClick={() => {
                  dispatch(choosePurchaseTimeframe(PurchaseTimeframe.BETWEEN_2_TO_3_MONTHS));
                  trackEvent(TrackingEventType.purchaseTimeframePage2to3Months);
                  hasAccount ? navigate(guessProperRouteUrl()) : nextPage();
                }}
                className={styles.provider}
              >
                {fixedT("timeframe.2to3Months")}
              </Button>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <Button
                onClick={() => {
                  dispatch(choosePurchaseTimeframe(PurchaseTimeframe.BETWEEN_3_TO_6_MONTHS));
                  trackEvent(TrackingEventType.purchaseTimeframePage3to6Months);
                  hasAccount ? navigate(guessProperRouteUrl()) : nextPage();
                }}
                className={styles.provider}
              >
                {fixedT("timeframe.3to6Months")}
              </Button>
            </Grid>
            <Grid xs={12} sm={5.8}>
              <Button
                onClick={() => {
                  dispatch(choosePurchaseTimeframe(PurchaseTimeframe.MORE_THAN_6_MONTHS));
                  trackEvent(TrackingEventType.purchaseTimeframePageMoreThan6Months);
                  hasAccount ? navigate(guessProperRouteUrl()) : nextPage();
                }}
                className={styles.provider}
              >
                {fixedT("timeframe.moreThan6Months")}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <PurchaseTimeframeIcon
        className={styles.logo}
        color={
          chroma.valid(stringToNumberArray(theme?.primaryColor))
            ? chroma(stringToNumberArray(theme?.primaryColor)!).hex()
            : undefined
        }
      />
    </Page>
  );
};

export default PurchaseTimeframePage;
