import {WithNavigate} from "./../../../types/ui";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {maybe} from "types/basic";
import {UserRequest} from "types/dto/userRequest";

const namespace = "SecondarySignUpForm";

export interface RegisterSecondaryUserPayload extends Partial<UserRequest>, WithNavigate {
  appId?: maybe<string>;
}

export const registerSecondaryUser = createAction<RegisterSecondaryUserPayload>(
  actionTypeOf(namespace, "registerSecondaryUser")
);
