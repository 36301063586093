import {combineReducers} from "redux";
import {Reducers} from "types/store";
import auth from "./auth";
import basicInfo from "./basicInfo";
import {
  mortgageProviderConfigurationsReducer,
  whiteLabelConfigurationsReducer,
} from "./configurations";
import operation from "./operation";
import policy from "./policy";
import qualification from "./qualification";
import theme from "./theme";
import user from "./user";
import whitelabel from "./whitelabel";
import navigationController from "./navigationController";
import applicantMetaReducer from "./applicantMeta";

export const INITIAL_STORE_STATE = {
  theme: theme.getInitialState(),
  operation: operation.getInitialState(),
  auth: auth.getInitialState(),
  user: user.getInitialState(),
  whiteLabelConfigurations: whiteLabelConfigurationsReducer.getInitialState(),
  basicInfo: basicInfo.getInitialState(),
  policy: policy.getInitialState(),
  qualification: qualification.getInitialState(),
  whitelabel: whitelabel.getInitialState(),
  providerConfigurations: mortgageProviderConfigurationsReducer.getInitialState(),
  navigationController: navigationController.getInitialState(),
  applicantMeta: applicantMetaReducer.getInitialState(),
};

const allReducers: Reducers = {
  theme,
  operation,
  auth,
  user,
  whiteLabelConfigurations: whiteLabelConfigurationsReducer,
  basicInfo,
  policy,
  qualification,
  whitelabel,
  providerConfigurations: mortgageProviderConfigurationsReducer,
  navigationController,
  applicantMeta: applicantMetaReducer,
};

const rootReducer = combineReducers(allReducers);

export default rootReducer;
