import {useState, useRef, cloneElement} from "react";
import {
  root,
  expanded as expandedClass,
  disabled as disabledClass,
  icon as iconStyle,
  dropdownBody,
  dropdownBodyDisplaySelected,
  dropdownBodyDisplayUnselected,
  dropdownContent,
} from "./Dropdown.module.scss";
import {classNames} from "../../utils/StringFormatter/StringFormatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import useOnClickOutside from "components/hooks/useOnClickOutside/useOnClickOutside";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {IconProps} from "@mui/material";

interface DropdownProps {
  body?: JSX.Element | string;
  content?: JSX.Element;
  expansionDirection?: string;
  className?: string | number;
  bodyClassName?: string;
  contentClassName?: string;
  disabled?: boolean;
  icon?: IconProps;
  isSelected?: boolean;
}

const Dropdown = ({
  body = <>Body</>,
  content = <>Content</>,
  expansionDirection = "down",
  icon = faCaretDown,
  className = "",
  bodyClassName = dropdownBody,
  contentClassName = dropdownContent,
  disabled = false,
  isSelected = true,
}: DropdownProps) => {
  const [expanded, setExpanded] = useState(false),
    elementalReference = useRef(null),
    ClonedContent = cloneElement(content, {requestDropdownClose: () => setExpanded(false)});
  function toggleExpansion() {
    if (!disabled) setExpanded((previousExpansionState) => !previousExpansionState);
  }

  useOnClickOutside(elementalReference, () => setExpanded(false));

  return (
    <div
      className={classNames(
        root,
        expanded ? expandedClass : "not-expanded",
        disabled ? disabledClass : "not-disabled",
        className as string
      )}
      ref={elementalReference}
    >
      <div
        className={bodyClassName}
        onFocus={() => {
          if (!disabled) {
            setExpanded(true);
          }
        }}
        tabIndex={0}
      >
        <div className={isSelected ? dropdownBodyDisplaySelected : dropdownBodyDisplayUnselected}>
          {body}
        </div>
        <FontAwesomeIcon icon={icon as IconProp} className={iconStyle} />
      </div>
      <div className={contentClassName}>{ClonedContent}</div>
    </div>
  );
};

export default Dropdown;
