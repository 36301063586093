import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getAuthUserEmail} from "store/selectors/auth";
import {getAppId} from "store/selectors/basicInfo";
import {getPlatform} from "store/selectors/theme";

type Location = {
  search: string;
  pathname: string;
};

type RootProps = {
  [key: string]: Object | string | number | boolean;
  location: Location;
};

const withTracking = (WrappedComponent: React.FC<RootProps>, options = {}) => {
  const HOC = (props: RootProps) => {
    const location = useLocation();
    const email = useSelector(getAuthUserEmail);
    const platform = useSelector(getPlatform);
    const appId = useSelector(getAppId);

    useEffect(() => {
      window.gtag("event", "page_view", {
        page: location.pathname + location.search,
        page_search: location.search,
        dimension1: platform || null,
        dimension2: email || null,
        dimension3: appId || null,
      });
    }, [location.pathname, email, appId]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracking;
