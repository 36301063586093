import {createReducer} from "@reduxjs/toolkit";
import {
  changeLanguageOperation,
  fetchUserApiOperation,
  signOutOperation,
} from "store/operation/operations";
import {UserState} from "types/dto/user";
import {registerPrimaryUserOperation, registerSecondaryUserOperation} from "store/operation/user";

const initialState: UserState = null as UserState;

const userReducer = createReducer<UserState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.success, () => initialState)
    .addCase(fetchUserApiOperation.success, (_state, {payload: user}) => {
      return user;
    })
    .addCase(changeLanguageOperation.success, (state, {payload: languagePreference}) => {
      return {...state, languagePreference} as UserState;
    })
    .addCase(registerPrimaryUserOperation.success, (state, {payload: userResponse}) => {
      return {...state, ...userResponse};
    })
    .addCase(registerSecondaryUserOperation.success, (state, {payload: userResponse}) => {
      return {...state, ...userResponse};
    })
);

export default userReducer;
