import {createSelector} from "@reduxjs/toolkit";
import {optional} from "types/basic";
import {
  OperationResultConsolidated,
  OperationState,
  OperationStateValue,
  OperationStatus,
  OperationType,
  TypedFailure,
  WritableError,
} from "types/operation";
import {State} from "types/store";
import {getIsAuthorized} from "./auth";
import {WithDataError} from "types/api";

type Callback<S> = (...value: any | any[]) => S;
type Selector<S> = (state: State) => Callback<S>;

const APP_BASIC_OPS = [
  // unconditional Operations, should always fetch
  OperationType.fetchTheme,
  OperationType.fetchWhitelabel,
  OperationType.fetchAuthorizedUser,
  OperationType.fetchWhitelabelConfigurations,
  OperationType.fetchMortgageProviders,
];

export const APP_WITH_AUTH_INIT_OPS = [
  ...APP_BASIC_OPS,
  OperationType.fetchUser,
  OperationType.fetchBasicInfoByUserId,
];

export const APP_WITHOUT_AUTH_INIT_OPS = [...APP_BASIC_OPS];

export const isInitialValue = (operationStateValue: OperationStateValue): boolean => {
  return operationStateValue === undefined || operationStateValue === null;
};

export const isPendingValue = (operationStateValue: OperationStateValue): boolean => {
  return operationStateValue === OperationStatus.pending;
};

export const isFailureValue = (operationStateValue: OperationStateValue): boolean => {
  return (
    !!operationStateValue &&
    operationStateValue !== OperationStatus.pending &&
    operationStateValue !== OperationStatus.success
  );
};

export const isSuccessValue = (operationStateValue: OperationStateValue): boolean => {
  return operationStateValue === OperationStatus.success;
};

export const getOperations = (rootState: State): OperationState => rootState.operation;

export const getOperation = createSelector(
  getOperations,
  (operationState) =>
    (
      operationType: OperationType
    ): OperationStateValue | WithDataError<OperationStateValue> | undefined =>
      operationState[operationType]
);

export const getIsOperationSuccess = createSelector(
  getOperations,
  (operationState) => (operationType: OperationType) =>
    operationState[operationType] === OperationStatus.success
);

export const getIsAnyOperationFailure = createSelector(
  getOperations,
  (operationState) =>
    (...operationTypes: OperationType[]): boolean => {
      return operationTypes.some((opType) => isFailureValue(operationState[opType]));
    }
);

export const getIsAllOperationsSuccess = createSelector(
  getOperations,
  (operationState) =>
    (...operationTypes: OperationType[]): boolean => {
      return !operationTypes.some((opType) => !isSuccessValue(operationState[opType]));
    }
);

export const getIsAnyOperationPending: Selector<boolean | undefined> = createSelector(
  getOperations,
  (operationState) =>
    (...operationTypes: OperationType[]): boolean => {
      return operationTypes.some((opType) => isPendingValue(operationState[opType]));
    }
);

export const getIsOperationPending = createSelector(
  getOperations,
  (operationState) => (operationType: OperationType) =>
    operationState[operationType] === OperationStatus.pending
);

export const getIsOperationFailure = createSelector(
  getOperations,
  (operationState) => (operationType: OperationType) =>
    isFailureValue(operationState[operationType])
);

export const getIsOperationNotStarted = createSelector(
  getOperations,
  (operationState) => (operationType: OperationType) =>
    isInitialValue(operationState[operationType])
);
// either success or failure
export const getIsOperationDone: Selector<boolean | undefined> = createSelector(
  getOperations,
  (operationState) => (operationType: OperationType) =>
    operationState[operationType] && operationState[operationType] !== OperationStatus.pending
);

export const getIsAllOperationsDone: Selector<boolean> = createSelector(
  getIsOperationDone,
  (isDoneFunc) =>
    (...targetOps: OperationType[]): boolean => {
      for (let targetOp of targetOps) {
        if (!isDoneFunc(targetOp)) {
          return false;
        }
      }
      return true;
    }
);

export const getOperationFailure = createSelector(
  getOperations,
  getIsOperationFailure,
  (operationState, isFailureGetter) =>
    (operationType: OperationType): optional<WritableError> => {
      const isFailure = isFailureGetter(operationType);
      if (!isFailure) {
        return undefined;
      }
      return operationState[operationType] as WritableError;
    }
);

export const getOperationResult = createSelector(
  getOperations,
  (operationState) =>
    (operationType: OperationType): OperationResultConsolidated => {
      const current = operationState[operationType];

      const isInitial = isInitialValue(current);
      const isPending = isPendingValue(current);
      const isFailure = isFailureValue(current);
      const isSuccess = isSuccessValue(current);

      const isDone = isSuccess || isFailure;
      const status = isFailure ? (current as WritableError)?.status : undefined;
      const message = isFailure ? (current as WritableError)?.message : undefined;

      return {
        isInitial,
        isPending,
        isSuccess,
        isDone,
        isFailure,
        status,
        failure: message,
      };
    }
);

export const getAllOperationFailures: Selector<any[]> = createSelector(
  getOperationFailure,
  (failureGetter) =>
    (...operationTypes: OperationType[]): TypedFailure[] => {
      if (!operationTypes?.length) {
        return [];
      }
      return operationTypes
        .map((opType) => {
          if (typeof opType !== "string") {
            console.error(
              `Dev error - operation type is expected to be string, but it is: ${typeof opType}`
            );
          }
          return {
            type: opType,
            failure: failureGetter(opType),
          };
        })
        .filter((item) => item.failure != null);
    }
);

export const getIsAppInitializing = createSelector(
  getIsAuthorized,
  getIsAnyOperationPending,
  (isAuthorized, isAnyPendingFunc) => {
    if (isAuthorized) {
      return isAnyPendingFunc(...APP_WITH_AUTH_INIT_OPS);
    }
    return isAnyPendingFunc(...APP_WITHOUT_AUTH_INIT_OPS);
  }
);

export const getIsAppInitialized = createSelector(
  getIsAuthorized,
  getIsAllOperationsDone,
  (isAuthorized, isAllDoneFunc) => {
    if (isAuthorized) {
      return isAllDoneFunc(...APP_WITH_AUTH_INIT_OPS);
    }
    return isAllDoneFunc(...APP_WITHOUT_AUTH_INIT_OPS);
  }
);
