import React, { MutableRefObject, useEffect, useRef } from "react";

const useOnClickOutside = (
  boundReference: any = useRef(null),
  callback = (clickEvent: any) => {},
) => {

  function handleClickOutside(clickEvent: any){
    if (!boundReference.current?.contains(clickEvent.target))
      callback(clickEvent);
  }

  useEffect(() => {
    if (!boundReference || !boundReference.current) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [boundReference])

};

export default useOnClickOutside;
