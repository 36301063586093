import { createSelector } from "@reduxjs/toolkit";
import {State} from "types/store";

export const get = (rootState: State) => rootState.qualification;

export const getStatus = createSelector(get, (qual) => qual.status);

export const getLenders = createSelector(get, (qual) => qual.lenders);

export const getNumLenders = createSelector(get, (qual) => qual.lenders?.length || 0);
