import {useTranslation} from "react-i18next";
import {Currency} from "types/enums/currency";
import {EmploymentLength} from "types/enums/employmentLength";
import {EmploymentType} from "types/enums/employmentType";
import {IncomeType} from "types/enums/incomeType";
import {IndustryType} from "types/enums/industryType";
import {OccupationType} from "types/enums/occupationType";
import * as yup from "yup";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "basicInfoSteps.employmentInfo"});

  const validationShape = {
    employer: yup
      .string()
      .required(fixedT("messages.missing", {context: "employer"})!)
      .max(70, fixedT("messages.characterLimit", {context: "employer"})!),
    jobTitle: yup
      .string()
      .required(fixedT("messages.missing", {context: "jobTitle"})!)
      .max(70, fixedT("messages.characterLimit", {context: "jobTitle"})!),
    industryType: yup
      .mixed<IndustryType>()
      .required(fixedT("messages.missing", {context: "industryType"})!),
    occupationType: yup
      .mixed<OccupationType>()
      .required(fixedT("messages.missing", {context: "occupationType"})!),
    incomeType: yup.mixed<IncomeType>().required(
      fixedT("messages.missing", {
        context: "incomeType",
      })!
    ),
    employmentType: yup.mixed<EmploymentType>().required(
      fixedT("messages.missing", {
        context: "employmentType",
      })!
    ),
    duration: yup.mixed<EmploymentLength>().required(
      fixedT("messages.missing", {
        context: "duration",
      })!
    ),
    previousEmployerName: yup.lazy((previousEmployerNameValue, {parent}) => {
      if (EmploymentLength.FEWER_THAN_2_YEARS === parent.duration) {
        return yup.string().required(
          fixedT("messages.missing", {
            context: "previousEmployerName",
          })!
        );
      }
      return yup.string().notRequired();
    }),
    previousEmploymentLength: yup.lazy((previousEmploymentLengthValue, {parent}) => {
      if (EmploymentLength.FEWER_THAN_2_YEARS === parent.duration) {
        return yup.mixed<EmploymentLength>().required(
          fixedT("messages.missing", {
            context: "previousEmploymentLength",
          })!
        );
      }
      return yup.mixed<EmploymentLength>().notRequired();
    }),
    grossIncome: yup.lazy(() => {
      return yup
        .object({
          amount: yup
            .number()
            .typeError(fixedT("messages.missing", {context: "grossIncome"})!)
            .min(1, fixedT("messages.missing", {context: "grossIncome"})!)
            .required(fixedT("messages.missing", {context: "grossIncome"})!),
          currency: yup.mixed<Currency>().required(),
        })
        .required(fixedT("messages.missing", {context: "grossIncome"})!);
    }),
  };

  return yup.object().shape(validationShape);
};
