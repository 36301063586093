import i18next from "i18next";
import {root, contents, instructions} from "./JointStepsComplete.module.scss";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {useSelector} from "react-redux";
import {getWhiteLabelEmailJointInviteUrl, getWhiteLabelLogoUrl} from "store/selectors/whitelabel";
import Page from "pages/Page/Page";

const JointStepsComplete = ({}) => {
  const t = i18next.getFixedT(null, null, "jointStepsComplete");
  const logoUrl = useSelector(getWhiteLabelLogoUrl);
  const jointInviteUrl = useSelector(getWhiteLabelEmailJointInviteUrl);

  return (
    <Page className={root}>
      <div className={contents}>
        <FailoverImage src={jointInviteUrl} failoverSrc={logoUrl} className={"icon"} />
        <h3>{t("header")}</h3>
        <div className={instructions}>{t("instructions")}</div>
      </div>
    </Page>
  );
};

export default JointStepsComplete;
