import React, {useState} from "react";
import i18next from "i18next";
import {root} from "./AcceptLegalInfo.module.scss";
import DisclaimerBox from "components/molecules/DisclaimerBox/DisclaimerBox";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";
import CloseIcon from "@mui/icons-material/Close";
import {Box, IconButton, Typography} from "@mui/material";

interface AcceptLegalInfoProps {
  title?: string;
  children?: React.ReactNode;
  onAgree?: () => void;
  onClose?: () => void;
  ref?: React.RefObject<HTMLDivElement>;
}

const AcceptLegalInfo = React.forwardRef(
  ({title = "", children = "", onClose = () => {}, ref}: AcceptLegalInfoProps) => {
    const t = i18next.getFixedT(null, null, "components.templates.acceptLegalInfo");

    return (
      <div className={root} ref={ref}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <DisclaimerBox
          onDisclaimerRead={() => {
            trackEvent(TrackingEventType.personalProfilePrivacyPolicyRead);
          }}
        >
          {children}
        </DisclaimerBox>
      </div>
    );
  }
);

export default AcceptLegalInfo;
