import React from "react";
import {root, icon, progressBar, caption} from "./UploadDocumentsTableRow.module.scss";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import {getFixedT} from "util/languageUtil";
import {formatFileSize} from "util/numberUtil";

const fixedT = getFixedT("basicInfoSteps.uploadDocumentsTableRow");

interface UploadDocumentsTableRowProps {
  children?: React.ReactNode;
  fileSize: number;
  fileName?: string;
  documentType?: string;
  uploadDate?: string;
  deleteCallback?: () => void;
  locked?: boolean;
  isMobile?: boolean;
  xs?: number;
  sm?: number;
}

function getFileSize(bytes: number): string {
  const {size, unit} = formatFileSize(bytes);

  if (unit === "bytes") {
    return `${size} ${fixedT("bytes")}`;
  } else if (unit === "kb") {
    return `${size} ${fixedT("kb")}`;
  } else if (unit === "mb") {
    return `${size} ${fixedT("mb")}`;
  }

  return fixedT("unknown");
}

const UploadDocumentsTableRow = ({
  children = <></>,
  fileSize,
  fileName = "",
  documentType = "",
  uploadDate = "",
  deleteCallback = () => {},
  locked = false,
  isMobile = false,
  xs = 2,
  sm = 2,
}: UploadDocumentsTableRowProps) => {
  return (
    <Grid className={root} item container spacing={2}>
      <Grid xs={xs} sm={sm} item>
        <UploadFileIcon className={icon} color="primary" />
      </Grid>
      <Grid item xs={12 - xs} sm={12 - sm} container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs={1}>
            <Typography variant="body2" component="div">
              {fileName}
            </Typography>
            <Typography className={caption} variant="caption" gutterBottom>
              {getFileSize(fileSize)} • {!!locked ? fixedT("complete") : fixedT("loading")}
              {!isMobile && fixedT("uploaded", {uploadDate: uploadDate})}
            </Typography>
          </Grid>
          <Grid item>
            <LinearProgress className={progressBar} variant="determinate" value={100} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadDocumentsTableRow;
