import {useEffect, useState} from "react";
import i18next from "i18next";
import {useNavigate} from "react-router-dom";
import AddressBox from "components/molecules/AddressBox/AddressBox";
import Message from "components/atoms/Message/Message";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import BackButton from "components/molecules/BackButton/BackButton";
import {useDispatch, useSelector} from "react-redux";
import {
  getPotentialAddressMatchesOrEmpty,
  getPurchaseOrRenewalPropertyAddress,
} from "store/selectors/basicInfo";
import {initialize, submit} from "./UnresolvedAVMPage.action";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {default as styles} from "./UnresolvedAVMPage.module.scss";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {Typography} from "@mui/material";
import Page from "pages/Page/Page";

const t = i18next.getFixedT(null, null, "unresolvedAvmPage");

const UnresolvedAVMPage = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addressNotRecognized = useSelector(getPurchaseOrRenewalPropertyAddress);
  const potentialAddressMatches = useSelector(getPotentialAddressMatchesOrEmpty);

  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitUnresolvedAVMProperty
  );

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  return (
    <Page className={styles.root}>
      <div className={styles.unresolvedAvmBody}>
        <Typography align="center" variant="h1">
          {t("header")}
        </Typography>
        <p className={styles.enteredAddress}>
          {t("enteredAddress", {
            unitNo: addressNotRecognized?.unitNo ? `${addressNotRecognized?.unitNo} - ` : "",
            streetNo: addressNotRecognized?.streetNo,
            streetName: addressNotRecognized?.streetName,
            city: addressNotRecognized?.city,
            province: t(`provinces.${addressNotRecognized?.province}`),
            postalCode: addressNotRecognized?.postCode,
          })}
        </p>
        <Message message={failure} />
        <Message className={styles.warningDivError} message={t("warningMessage")} />
        <div className={styles.addressBoxes}>
          {potentialAddressMatches?.map((address, index) => (
            <AddressBox
              address={address}
              handleClick={() => setSelectedAddressIndex(index)}
              selected={index == selectedAddressIndex}
              key={index}
            />
          ))}
        </div>
        <NavigationSection>
          <BackButton />
          <SubmitButton
            isEnabled={selectedAddressIndex > -1 && !isPending}
            onClick={() =>
              dispatch(
                submit({
                  ...potentialAddressMatches[selectedAddressIndex],
                  navigate,
                })
              )
            }
            text={t("forwardButton")}
          />
        </NavigationSection>
      </div>
    </Page>
  );
};

export default UnresolvedAVMPage;
