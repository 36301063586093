import React, {Fragment, RefObject, useEffect, useState} from "react";
import {default as styles} from "./UploadDocuments.module.scss";
import BackButton from "components/molecules/BackButton/BackButton";
import Message from "components/atoms/Message/Message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import UploadDocumentsTableRow from "components/molecules/UploadDocumentsTableRow/UploadDocumentsTableRow";
import {useDispatch, useSelector} from "react-redux";
import {getDocumentDisclaimerForDirectLender} from "store/selectors/theme";
import {getFixedT} from "util/languageUtil";
import {useNavigate} from "react-router-dom";
import {getBasicInfoOrEmpty, getIsDirectLender, getIsLocked} from "store/selectors/basicInfo";
import {DocumentType} from "types/enums/documentType";
import {
  submitFile,
  navigatePreviousStep,
  initialize,
  navigateNextStep,
} from "./UploadDocuments.action";
import {
  getAllOperationFailures,
  getIsAllOperationsDone,
  getIsAnyOperationPending,
} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {UPLOAD_FILE_SIZE_MAX_IN_BYTES, UPLOAD_FILE_SIZE_MAX_IN_MB} from "appConstants";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {Grid, Typography, LinearProgress} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {ConfigurationKey} from "types/configurations";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import classNames from "classnames";
import StepContent from "components/organisms/StepContent/StepContent";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {State} from "types/store";
import {isMobile} from "react-device-detect";
import {preventDefaultBehaviour} from "util/eventUtil";
import {useTranslation} from "react-i18next";

interface DocStateAndRequirements {
  required: boolean;
  hidden: boolean;
  uploaded?: boolean;
}

const fixedT = getFixedT("basicInfoSteps.uploadDocuments");
const defaultDisclosure = fixedT("disclosureDefault");
const operationsTypesAllDocuments = [
  OperationType.submitT4,
  OperationType.submitNoa,
  OperationType.submitPaystub,
  OperationType.submitMls,
  OperationType.submitClosingDocument,
  OperationType.submitBankStatement,
];
export function isAllDocumentsHidden(docStateAndRequirements: {
  [index: string]: DocStateAndRequirements;
}) {
  return !Object.values(docStateAndRequirements).reduce((isHidden, docRequirement) => {
    return isHidden == true || docRequirement.hidden == false;
  }, false);
}

export function isAllDocumentsOptional(docStateAndRequirements: {
  [index: string]: DocStateAndRequirements;
}) {
  return !Object.values(docStateAndRequirements).reduce((isRequired, docRequirement) => {
    return isRequired == true || docRequirement.required == true;
  }, false);
}

export function isAllRequiredDocumentsUploaded(
  docStateAndRequirements: {
    [index: string]: DocStateAndRequirements;
  },
  callback?: ((arg0: string) => void) | undefined
) {
  return !Object.values(docStateAndRequirements).reduce(
    (requiredMissing, docRequirement, index) => {
      const requirementDocMissing =
        docRequirement.required == true && docRequirement.uploaded == false;
      if (requirementDocMissing && callback) {
        callback(Object.keys(docStateAndRequirements)[index]);
      }
      return (
        requiredMissing == true ||
        (docRequirement.required == true && docRequirement.uploaded == false)
      );
    },
    false
  );
}

const UploadDocuments = ({}) => {
  const t4UploadRef: RefObject<any> = React.createRef();
  const noaUploadRef: RefObject<any> = React.createRef();
  const paystubUploadRef: RefObject<any> = React.createRef();
  const bankDocumentUploadRef: RefObject<any> = React.createRef();
  const mlsUploadRef: RefObject<any> = React.createRef();
  const closingDocumentUploadRef: RefObject<any> = React.createRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const {
    uploadedT4,
    uploadedNOA,
    uploadedPayStub,
    uploadedBankStatement,
    uploadedMLS,
    uploadedClosingDocument,
    documents,
  } = useSelector(getBasicInfoOrEmpty);
  const isDirectLender = useSelector<State, boolean>(getIsDirectLender);
  const [fieldError, setFieldError] = useState<string>();
  const hasLoaded = useSelector(getIsAllOperationsDone)(OperationType.fetchProviderConfigurations);
  const isPending = useSelector(getIsAnyOperationPending)(...operationsTypesAllDocuments);
  const failures = useSelector(getAllOperationFailures)(...operationsTypesAllDocuments);
  const {i18n} = useTranslation();
  const directLenderDisclosure = useSelector(getDocumentDisclaimerForDirectLender)(i18n.language);
  const docStateAndRequirements: {[index: DocumentType | string]: DocStateAndRequirements} = {
    [DocumentType.T4]: {
      required: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_T4)
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_T4)
      ),
      uploaded: uploadedT4,
    },
    [DocumentType.NOA]: {
      required: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_NOA)
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_NOA)
      ),
      uploaded: uploadedNOA,
    },
    [DocumentType.PAYSTUB]: {
      required: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_PAYSTUB
        )
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_PAYSTUB
        )
      ),
      uploaded: uploadedPayStub,
    },
    [DocumentType.BANK_STATEMENT]: {
      required: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_BANK_STATEMENT
        )
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_BANK_STATEMENT
        )
      ),
      uploaded: uploadedBankStatement,
    },
    [DocumentType.MLS]: {
      required: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_MLS)
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_MLS)
      ),
      uploaded: uploadedMLS,
    },
    [DocumentType.CLOSING_DOCUMENT]: {
      required: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_REQUIRED_CLOSING_DOCUMENT
        )
      ),
      hidden: useSelector(
        getIsProviderConfigurationValueEnabled(
          ConfigurationKey.ORGANIZATION_DOCUMENTS_HIDDEN_CLOSING_DOCUMENT
        )
      ),
      uploaded: uploadedClosingDocument,
    },
  };

  useEffect(() => {
    if (isAllDocumentsHidden(docStateAndRequirements)) {
      console.info("Redirecting to next page, due to all docs being hidden");
      dispatch(navigateNextStep({navigate}));
    } else {
      dispatch(initialize({navigate}));
    }
  }, [hasLoaded]);

  function nextEnabled() {
    setFieldError(undefined);
    return isAllRequiredDocumentsUploaded(docStateAndRequirements, (docType) =>
      setFieldError(fixedT("messages.missing", {context: docType})!)
    );
  }

  async function handleFileUpload(event: any, documentType: DocumentType): Promise<void> {
    preventDefaultBehaviour(event);
    if (locked) {
      setFieldError(fixedT("messages.appLocked")!);
      return;
    }
    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    if (!file) {
      return;
    }
    if (file?.size > UPLOAD_FILE_SIZE_MAX_IN_BYTES) {
      setFieldError(fixedT("messages.fileTooBig", {maxSizeInMB: UPLOAD_FILE_SIZE_MAX_IN_MB})!);
      return;
    }
    setFieldError("");
    dispatch(submitFile({file, documentType}));
  }

  const gridItemDocumentType = (
    docType: DocumentType,
    ref: RefObject<any>,
    trackingEventType: TrackingEventType
  ) => {
    const docConfig = docStateAndRequirements[docType];
    return docConfig?.hidden ? null : (
      <Grid
        className={styles.gridWrapper}
        item
        xs={12}
        sm={6}
        sx={{flex: "1 1 100%", maxWidth: "unset"}}
      >
        <div className={styles.uploadContainer}>
          <Typography variant="body1">
            <Typography variant="body1" fontStyle="italic" component="span">
              {!docConfig?.required ? fixedT("optionalPrefix") : null}
            </Typography>
            {fixedT("body", {context: docType})}
          </Typography>
          <input
            type="file"
            onInput={(event) => handleFileUpload(event, docType)}
            ref={ref}
            accept="application/pdf,image/jpeg,image/jpg,image/png"
            hidden={true}
          />
          <div
            className={styles.uploadSection}
            onDrop={(event) => handleFileUpload(event, docType)}
            onClick={(event) => {
              trackEvent(trackingEventType);
              if (!isPending) {
                preventDefaultBehaviour(event);
                ref?.current?.click();
              }
            }}
          >
            <UploadFileIcon className={styles.icon} color="primary" />
            <Typography align="center" variant="body2">
              {isMobile ? fixedT("uploadDocumentMobile") : fixedT("uploadDocument")}
            </Typography>
            <Typography className={styles.caption} align="center" variant="caption">
              {fixedT("maxUploadSize", {maxSizeInMB: UPLOAD_FILE_SIZE_MAX_IN_MB})}
            </Typography>
          </div>
          {!documents?.length ? null : (
            <Grid container rowSpacing={3}>
              {documents
                .filter((doc) => doc.documentType == docType)
                .map((doc, index) => (
                  <Fragment key={`${index}-${doc.documentType}`}>
                    <UploadDocumentsTableRow
                      key={`${index}-${doc.documentType}-${Math.random()}`}
                      fileSize={doc.fileSize}
                      fileName={doc.fileName}
                      documentType={fixedT("documentType", {context: doc.documentType})!}
                      uploadDate={doc.uploadedAt?.substring(0, 10)}
                      locked={true}
                    />
                    <Grid item>
                      <LinearProgress
                        className={styles.progressBar}
                        variant="determinate"
                        value={100}
                      />
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
          )}
        </div>
      </Grid>
    );
  };

  return (
    <>
      {hasLoaded && isAllDocumentsOptional(docStateAndRequirements) && (
        <Typography align="center" variant="h4" fontStyle={"italic"} fontWeight={"light"}>
          {fixedT("headerOptional")}
        </Typography>
      )}
      <StepContent
        className={classNames(styles.root, styles.className)}
        headerText={fixedT("header")}
        backButton={
          <BackButton
            onClick={() => {
              trackEvent(TrackingEventType.uploadDocumentsClickBackButton);
              dispatch(navigatePreviousStep({navigate}));
            }}
          />
        }
        nextButton={
          <SubmitButton
            className={styles.submitButton}
            isEnabled={!isPending}
            onClick={() => {
              trackEvent(TrackingEventType.uploadDocumentsClickNextButton);
              if (nextEnabled()) {
                dispatch(navigateNextStep({navigate}));
              }
            }}
            text={
              (!isPending ? (
                fixedT("forwardButton")
              ) : (
                <FontAwesomeIcon icon={faSyncAlt} spin={true} />
              )) as string
            }
          />
        }
      >
        <div
          className={styles.options}
          onDragOver={preventDefaultBehaviour}
          onDragEnter={preventDefaultBehaviour}
          onDragLeave={preventDefaultBehaviour}
        >
          <Typography align="center" variant="subtitle2">
            {fixedT("subheader")}
          </Typography>
          <Grid className={styles.uploadWrapper} container rowSpacing={2} columnSpacing={1}>
            {gridItemDocumentType(
              DocumentType.T4,
              t4UploadRef,
              TrackingEventType.uploadDocumentsClickT4
            )}
            {gridItemDocumentType(
              DocumentType.NOA,
              noaUploadRef,
              TrackingEventType.uploadDocumentsClickNOA
            )}
            {gridItemDocumentType(
              DocumentType.PAYSTUB,
              paystubUploadRef,
              TrackingEventType.uploadDocumentsClickPayStub
            )}
            {gridItemDocumentType(
              DocumentType.BANK_STATEMENT,
              bankDocumentUploadRef,
              TrackingEventType.uploadDocumentsClickBankStatement
            )}
            {gridItemDocumentType(
              DocumentType.MLS,
              mlsUploadRef,
              TrackingEventType.uploadDocumentsClickMLS
            )}
            {gridItemDocumentType(
              DocumentType.CLOSING_DOCUMENT,
              closingDocumentUploadRef,
              TrackingEventType.uploadDocumentsClickClosingDocument
            )}
          </Grid>

          {!locked && (
            <div className={styles.disclaimer}>
              {(isDirectLender ? directLenderDisclosure : defaultDisclosure) || defaultDisclosure}
            </div>
          )}

          <Message message={fieldError} />
          {failures?.map(({type, failure}: any, index: React.Key | null | undefined) => (
            <Message key={type} message={fixedT(`messages.backendErrors.${type}`)} />
          ))}
        </div>
      </StepContent>
    </>
  );
};

export default UploadDocuments;
