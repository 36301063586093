import i18next from "i18next";
import {root, prompt as promptClass, options} from "./LoginOptions.module.scss";
import Button from "components/atoms/Button/Button";
import BackButton from "components/molecules/BackButton/BackButton";
import CompanyLogo from "../../atoms/CompanyLogo/CompanyLogo";

interface LoginOptionsProps {
  onSignUpSelected?: () => void;
  onSignInSelected?: () => void;
}

const LoginOptions = ({
  onSignUpSelected = () => {},
  onSignInSelected = () => {},
}: LoginOptionsProps) => {
  const t = i18next.getFixedT(null, null, "components.organisms.loginOptions");

  return (
    <div className={root}>
      <CompanyLogo />
      <div className={promptClass}>{t("prompt")}</div>
      <div className={options}>
        <Button onClick={onSignUpSelected}>{t("buttonSignUp")}</Button>
        <Button
          fillColor={"primary-background-colour-100"}
          textColor={"primary-thematic-colour-100"}
          onClick={onSignInSelected}
        >
          {t("buttonSignIn")}
        </Button>
        <BackButton />
      </div>
    </div>
  );
};

export default LoginOptions;
