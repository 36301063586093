import {root} from "./TextEditor.module.scss";

import {Content, EditorContent, useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

interface Props {
  value: Content;
}

const TextEditor: React.FC<Props> = ({value}) => {
  const editor = useEditor({
    content: value,
    extensions: [StarterKit, Link],
    editable: false,
  });

  return (
    <div className={root}>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TextEditor;
