import React from "react";
import {Navigate} from "react-router";
import LoadingAnimation from "components/atoms/LoadingAnimation/LoadingAnimation";
import {root} from "./PrimaryApplicantRoute.module.scss";
import {useSelector} from "react-redux";
import {getIsPrimary, getIsSecondary} from "store/selectors/basicInfo";
import {getIsAnyOperationPending} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {RouteUrl} from "types/route";

interface PrimaryApplicantRouteProps {
  children?: React.ReactNode;
}

const PrimaryApplicantRoute = ({children, ...rest}: PrimaryApplicantRouteProps) => {
  const isPending = useSelector(getIsAnyOperationPending)(
    OperationType.fetchAuthorizedUser,
    OperationType.fetchUser,
    OperationType.fetchBasicInfoByUserId
  );
  const isPrimary = useSelector(getIsPrimary);
  const isSecondary = useSelector(getIsSecondary);

  if (isPrimary) {
    return <>{children}</>;
  }

  if (isPending) {
    return (
      <div className={root}>
        <LoadingAnimation message={"verifying permissions"} />
      </div>
    );
  }
  if (isSecondary) {
    // secondary user shouldn't go here, go back to basic-info
    return (
      <>
        <Navigate to={RouteUrl.BASIC_INFO_URL} />
      </>
    );
  }
  // not pending, no account.
  return (
    <>
      <Navigate to={RouteUrl.SIGN_UP} />
    </>
  );
};

export default PrimaryApplicantRoute;
