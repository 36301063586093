import { createReducer } from "@reduxjs/toolkit";
import { setOperationFailure, setOperationPending, setOperationSuccess } from "store/actions/operation";
import { signOutOperation } from "store/operation/operations";
import { OperationState, OperationStatus } from "types/operation";

const initialState: OperationState = {};

const operationReducer = createReducer<OperationState>(
  initialState, 
  builder =>
    builder
    .addCase(signOutOperation.success, () => initialState)
    .addCase(setOperationPending, (state, {payload: operationType}) => {
     state[operationType] = OperationStatus.pending
    })
    .addCase(setOperationSuccess, (state, {payload: operationType}) => {
      state[operationType] = OperationStatus.success
    })
    .addCase(setOperationFailure, (state, {payload: [operationType, writableError]}) => {
      state[operationType] = writableError;
    })
)

export default operationReducer;
