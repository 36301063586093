import {ProductProviderSelectionWithType} from "types/dto/ProductProviderSelection";

export function orderProvidersByCriteria(
  bankProvider: Partial<ProductProviderSelectionWithType> | undefined,
  lenderProvider: Partial<ProductProviderSelectionWithType> | undefined
): Partial<ProductProviderSelectionWithType>[] {

  const bankProductsCount = bankProvider?.products?.length ?? 0;
  const lenderProductsCount = lenderProvider?.products?.length ?? 0;
  const bankDisplayRate = bankProvider?.displayRate ?? Infinity; // Use Infinity as a fallback to ensure comparison logic works
  const lenderDisplayRate = lenderProvider?.displayRate ?? Infinity;
  let orderedProviders = [];

  // Determine the order of providers based on their display rates and, if those are equal, their product counts
  if (
    bankDisplayRate < lenderDisplayRate ||
    (bankDisplayRate === lenderDisplayRate && bankProductsCount <= lenderProductsCount)
  ) {
    // Bank's rate are lower, has fewer products, or has the same rate and number of products
    orderedProviders = [bankProvider, lenderProvider];
  } else {
    orderedProviders = [lenderProvider, bankProvider];
  }

  // Filter out undefined/null values before returning
  return orderedProviders.filter((provider) => !!provider?.displayRate) as Partial<ProductProviderSelectionWithType>[];
}
