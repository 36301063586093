import {equalIgnoreCase} from "components/utils/stringUtil";
import i18next from "i18next";
import {Currency} from "types/enums/currency";
import {LanguageType} from "types/enums/languageType";

const languageToCurrency: Record<LanguageType | string, Currency> = {
  [LanguageType.EN_CA]: Currency.CAD,
  [LanguageType.FR_CA]: Currency.CAD,
  "fr-CA": Currency.CAD,
  "en-CA": Currency.CAD,
};

function getCurrentLocaleWellknown(): "en-CA" | "fr-CA" {
  if (equalIgnoreCase("fr-CA", i18next.language) || equalIgnoreCase("FR_CA", i18next.language)) {
    return "fr-CA";
  }
  return "en-CA";
}

export const CURRENT_LOCALE_OR_DEFAULT = getCurrentLocaleWellknown();

const CURRENT_CURRENCY_OR_DEFAULT = languageToCurrency[CURRENT_LOCALE_OR_DEFAULT] || Currency.CAD;

const CURRENCY_FORMATTER = new Intl.NumberFormat(CURRENT_LOCALE_OR_DEFAULT, {
  currency: CURRENT_CURRENCY_OR_DEFAULT,
  style: "currency",
  minimumFractionDigits: 2,
});

export const formatCurrencyAmount = (amount: number): string => {
  // It won't throw error. null -> 0
  return CURRENCY_FORMATTER.format(amount);
};

export const formatCurrencyAmountOrReturn = (amount: number): string | null => {
  if (amount === null || amount === undefined) {
    return amount;
  }
  return formatCurrencyAmount(amount);
};

export const formatCurrencyAmountOrElse = (amount: number, elseValue: string = ""): string => {
  return amount == null || isNaN(amount) ? elseValue : formatCurrencyAmount(amount);
};
