import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {NavigationState, StepStatusRecord} from "types/NavigationController";
import {NavStep} from "types/enums/navStep";
import {RouteUrlValue} from "types/routeUrlValue";
import {WithNavigate} from "types/ui";

const namespace = "NavigationBar";

export interface HandleNewUrl {
  step: NavStep;
  url: RouteUrlValue;
}

export interface NavigateStep extends WithNavigate {
  url: RouteUrlValue;
  providerNotChosenError: string;
  userNotCreatedError: string;
}

export interface StoreStepStatusChange {
  step: NavStep;
  hidden: boolean;
  optional: boolean;
  completed: boolean;
  visited: boolean;
}
export const initializeStatus = createAction<StepStatusRecord>(
  actionTypeOf(namespace, "initializeStatus")
);

export const storeUrl = createAction<RouteUrlValue>(actionTypeOf(namespace, "storeUrl"));

export const storeNextUrl = createAction<RouteUrlValue>(
  actionTypeOf(namespace, "storeNextUrl")
);

export const calculateStepStatusChange = createAction<NavStep>(
  actionTypeOf(namespace, "calculateStepStatusChange")
);

export const storeStepStatusChange = createAction<StoreStepStatusChange>(
  actionTypeOf(namespace, "storeStepStatusChange")
);

export const navigateStep = createAction<NavigateStep>(actionTypeOf(namespace, "navigateStep"));

export const storeCurrentStep = createAction<NavStep>(actionTypeOf(namespace, "storeCurrentStep"));

export const handleNewUrl = createAction<HandleNewUrl>(actionTypeOf(namespace, "handleNewUrl"));

export const storeNavigationError = createAction<string>(
  actionTypeOf(namespace, "storeNavigationError")
);

export const removeNavigationError = createAction<void>(
  actionTypeOf(namespace, "removeNavigationError")
);
