import ResendEmailForm from "components/organisms/ResendEmailForm/ResendEmailForm";
import Page from "pages/Page/Page";

const ResendEmailPage = () => {
  return (
    <Page className={"resend-email-page"}>
      <ResendEmailForm />
    </Page>
  );
};

export default ResendEmailPage;
