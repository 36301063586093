import {createSelector} from "@reduxjs/toolkit";
import {WhiteLabelDetailsDto} from "types/WhiteLabelDetailsDto";
import {maybe} from "types/basic";
import {FinancialInstitutionType} from "types/enums/financialInstitutionType";
import {State} from "types/store";
import {UUID} from "types/uuid";
import {WhitelabelState} from "types/whitelabelState";

export const get = (rootState: State): WhitelabelState => rootState.whitelabel;

export const getWhitelabel = createSelector(get, (state) => state.whitelabel);

export const getWhitelabelId = createSelector(getWhitelabel, (whiteLabel) => whiteLabel?.publicId);

export const getWhitelabelToken = createSelector(getWhitelabel, (whiteLabel) => whiteLabel?.token);

export const getSelectedProviderId = createSelector(get, (state) => state.selectedProviderId);
export const getHasUserSelectedProvider = createSelector(get, (state) =>
  Boolean(state.selectedProviderId)
);

export const getProviders = createSelector(get, (state) => [
  ...(state.bank?.products ?? []),
  ...(state.lender?.products ?? []),
]);

export const getSampleProduct = createSelector(get, (state) => new Error("Deprecated"));

export const getSelectedProvider = createSelector(
  getSelectedProviderId,
  getProviders,
  (selectedProviderId, providers) => {
    if (!providers || !providers?.length) {
      if (selectedProviderId) {
        console.error(
          `Fallback to undefined, for inconsistent state - has selectedProviderId=${selectedProviderId}, but no providers`
        );
      }
      return undefined;
    }
    if (!selectedProviderId) {
      return undefined;
    }
    const selected = providers.find(
      (provider) => provider?.financialInstitutionPublicId === selectedProviderId
    );
    if (!selected && selectedProviderId) {
      console.error(
        `Fallback to undefined, for inconsistent state - has selectedProviderId=${selectedProviderId}, and providers, but just can't find related provider.`
      );
    }
    return selected;
  }
);

export const getSelectedProviderType = createSelector(
  getSelectedProvider,
  (provider) => provider?.organizationType
);

export const getSelectedProviderName = createSelector(
  getSelectedProvider,
  (provider) => provider?.organizationName
);

export const getWhitelabelName = createSelector(getWhitelabel, (whiteLabel) => whiteLabel?.name);

export const getDirectProviders = createSelector(getProviders, (providers) => {
  if (!providers) {
    return undefined;
  }
  return providers.filter((provider) => provider.financialInstitutionType === FinancialInstitutionType.BANK);
});

export const getDirectProvidersOrEmpty = createSelector(
  getDirectProviders,
  (providers) => providers || []
);

export const getBrokerProviders = createSelector(getProviders, (providers) => {
  if (!providers) {
    return undefined;
  }
  return providers.filter(
    (provider) =>
      provider.financialInstitutionType === FinancialInstitutionType.BROKERAGE
  );
});

export const getBankProvider = createSelector(get, (state: WhitelabelState) => state?.bank);
export const getLenderProvider = createSelector(get, (state: WhitelabelState) => state?.lender);
export const getBankProducts = createSelector(getBankProvider, (bank) => bank?.products);
export const getLenderProducts = createSelector(getLenderProvider, (lender) => lender?.products);

export const getBrokerProvidersOrEmpty = createSelector(
  getBrokerProviders,
  (providers) => providers || []
);

export const getBrokerageOrganizationIdsDistinct = createSelector(
  getBrokerProvidersOrEmpty,
  (brokerProviders) => {
    const brokerageIds: UUID[] = brokerProviders.map(
      (provider) => provider.financialInstitutionPublicId
    );
    const distinctBrokerageIds = new Set(brokerageIds);
    return Array.from(distinctBrokerageIds);
  }
);

export const getBrokeargeOrganizationId = createSelector(
  getBrokerageOrganizationIdsDistinct,
  (distinctBrokerageIds) => {
    if (distinctBrokerageIds.length > 1) {
      console.error(
        "Fallback to use first brokearge, due to mult brokerages organizations found from products: ",
        distinctBrokerageIds
      );
    }
    return distinctBrokerageIds.length > 0 ? distinctBrokerageIds[0] : undefined;
  }
);

export const getHasProviders = createSelector(getProviders, (providers) =>
  Boolean(providers?.length)
);

export const getWhiteLabelLogoUrl = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) =>
    wl?.logo && `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.logo}`
);

export const getWhiteLabelEmailJointInviteUrl = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>): string | undefined =>
    wl?.emailJointInviteImage &&
    `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.emailJointInviteImage}`
);

export const getWhiteLabelFaviconUrl = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) =>
    wl?.favicon && `${window._env_.REACT_APP_IMGIX_DOMAIN}/${wl.favicon}`
);

export const getWhiteLabelBackgroundColor = createSelector(
  getWhitelabel,
  (wl: maybe<WhiteLabelDetailsDto>) => wl?.logoBackgroundColor
);
