import {call, select, takeEvery} from "redux-saga/effects";
import {acceptPrivacyOperation, submitEmploymentInfoOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {PolicyType} from "types/enums/policyType";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {WithNavigate, WithOnSuccess} from "types/ui";

import {
  acceptPrivacy,
  EmploymentInfoFormData,
  submitEmployment,
  // navigatePreviousStep,
} from "./EmploymentInfo.action";

function* onSubmit({payload}: WithPayload<EmploymentInfoFormData>) {
  const {navigate, grossIncome, ...employmentHistory} = payload;
  const userId: string = yield select(getUserId);
  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    grossIncome,
    employmentHistory,
  };
  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitEmploymentInfoOperation.saga,
    userId,
    basicInfoRequest,
    Step.EMPLOYMENT_INFO
  );

  if (Operation.isSuccess(result)) {
    navigate(RouteUrl.BASIC_INFO_URL_ADDRESS);
  }
}

function* onAcceptPrivacy({payload: {onSuccess}}: WithPayload<WithOnSuccess>) {
  const userId: string = yield select(getUserId);
  const payload: Partial<ResidentialApplicationBasicInfoRequest> = {
    policyType: PolicyType.PRIVACY,
  };
  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    acceptPrivacyOperation.saga,
    userId,
    payload,
    Step.ACCEPT_POLICY
  );
  if (Operation.isSuccess(result)) {
    onSuccess && onSuccess();
  }
}

// function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
//   const {navigate} = payload;
//   navigate(RouteUrl.BASIC_INFO_URL_PERSONAL_INFO);
// }

export default function* employmentInfoSaga() {
  yield takeEvery(submitEmployment, onSubmit);
  yield takeEvery(acceptPrivacy, onAcceptPrivacy);
  // yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
