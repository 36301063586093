import {call, select, takeEvery} from "redux-saga/effects";
import {submitLoanTypeOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {fetchTermsOfServicePolicyOperation} from "store/operation/policy";
import {getUserId} from "store/selectors/user";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {PolicyType} from "types/enums/policyType";
import {Step} from "types/enums/step";
import {
  initialize,
  submitLoanType,
  SubmitLoanTypePayload,
  navigatePreviousStep,
} from "./MortgageSelection.action";
import {getIsDirectLender} from "store/selectors/basicInfo";
import {WithNavigate} from "types/ui";
import {RouteUrl} from "types/route";
import {getIsAllDocumentUploadHidden} from "store/selectors/providerconfigurations";

function* onSubmitLoanType({payload: {loanType, onSuccess}}: WithPayload<SubmitLoanTypePayload>) {
  const userId: string = yield select(getUserId);
  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    loanType,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitLoanTypeOperation.saga,
    userId,
    basicInfoRequest,
    Step.LOAN_TYPE
  );
  if (Operation.isSuccess(result)) {
    onSuccess && onSuccess();
  }
}

function* onInitialize() {
  const whitelabelId: string = yield select(getWhitelabelId);
  yield call(
    fetchTermsOfServicePolicyOperation.cacheSaga,
    whitelabelId,
    PolicyType.TERMS_OF_SERVICE
  );
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  const isAllDocumentUploadHidden: boolean = yield select(getIsAllDocumentUploadHidden);
  const isDirectLender: boolean = yield select(getIsDirectLender);

  const routeToNavigate =
    isAllDocumentUploadHidden && isDirectLender
      ? RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT
      : isAllDocumentUploadHidden
      ? RouteUrl.BASIC_INFO_URL_CREDIT_REDIRECT
      : RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS;

  navigate(routeToNavigate);
}

export default function* mortgageSelectionSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitLoanType, onSubmitLoanType);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
