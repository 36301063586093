import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames} from "components/utils/stringUtil";
import {WithClassName} from "types/ui";
import {root} from "./LoadingAnimation.module.scss";
import {useTranslation} from "react-i18next";

interface LoadingProps extends WithClassName {
  message?: string;
}

const LoadingAnimation = ({className, message}: LoadingProps) => {
  const {t} = useTranslation("", {keyPrefix: "Loading"});

  return (
    <div className={classNames(root, className)}>
      <div>{message || t("message")}</div>
      <FontAwesomeIcon icon={faSync} spin={true} />
    </div>
  )
};

export default LoadingAnimation;
