import React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import i18next from "i18next";
import {changeLanguage} from "./LanguagesToggle.action";
import {LanguageTypeCaption, allLanguageTypes} from "util/languageUtil";
import {root, notSelected} from "./LanguagesToggle.module.scss";
import {LanguageType} from "types/enums/languageType";

const LanguagesToggle: React.FC = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const availableLanguages = [...(allLanguageTypes as LanguageType[])];

  return (
    <div className={root}>
      {availableLanguages.map((language: LanguageType, index: number) => (
        <div
          key={`language-toggle-${index}`}
          className={i18next.language === language ? "selected" : notSelected}
          onClick={() => {
            dispatch(changeLanguage({locale: language, navigate}));
          }}
        >
          {LanguageTypeCaption[language]}
        </div>
      ))}
    </div>
  );
};

export default LanguagesToggle;
