export enum Step {
  SIGNED_UP = "SIGNED_UP",
  ACCEPT_POLICY = "ACCEPT_POLICY",
  PERSONAL_PROFILE = "PERSONAL_PROFILE",
  EMPLOYMENT_INFO = "EMPLOYMENT_INFO",
  ADDRESS_INFO = "ADDRESS_INFO",
  ADD_BANK = "ADD_BANK",
  LEGAL_NAMES_SIN = "LEGAL_NAMES_SIN",
  LOAN_TYPE = "LOAN_TYPE",
  MORTGAGE_TYPE = "MORTGAGE_TYPE",
  PROPERTY_INFOR = "PROPERTY_INFOR",
  UNRESOLVED_AVM_PROPERTY = "UNRESOLVED_AVM_PROPERTY",
  SELECT_LENDER = "SELECT_LENDER", // the UI is to fetch and display lender
  SET_LENDER = "SET_LENDER",
  SELF_REPORTED_ASSETS_LIABILITIES = "SELF_REPORTED_ASSETS_LIABILITIES",
  SELF_REPORTED_CREDIT_SCORE = "SELF_REPORTED_CREDIT_SCORE",
  SELF_REPORTED_DOCUMENTS = "SELF_REPORTED_DOCUMENTS",
  REVIEW_SUBMISSION = "REVIEW_SUBMISSION", // or completed application
}
