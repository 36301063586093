import HeaderWithLogo from "./HeaderWithLogo";
import UserProfileBadge from "components/organisms/UserProfileBadge/UserProfileBadge";
import LanguagesToggle from "components/molecules/LanguagesToggle/LanguagesToggle";
import {WithClassName} from "types/ui";

const Header = ({className}: WithClassName) => {
  return (
    <HeaderWithLogo className={className}>
      <LanguagesToggle />
      <UserProfileBadge />
    </HeaderWithLogo>
  );
};

export default Header;
