import {SagaIterator} from "redux-saga";
import {call, takeEvery} from "redux-saga/effects";
import {signInOperation} from "store/operation/operations";
import {WithPayload} from "types/basic";
import {login, LoginFormPayload} from "./LoginForm.action";

function* loginWithEmailPassword({
  payload: {email, password},
}: WithPayload<LoginFormPayload>): SagaIterator {
  yield call(signInOperation.saga, email, password);
}

export default function* loginFormSaga(): SagaIterator {
  yield takeEvery(login, loginWithEmailPassword);
}
