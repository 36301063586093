import { Typography } from "@mui/material";

interface Props {
  primary: string | any;
  secondary: string | any;
}

const LabelledTextTypography: React.FC<Props> = ({ primary, secondary }) => {
  return (
    <>
      <Typography variant="body2">{primary}</Typography>
      <Typography variant="body1">{secondary}</Typography>
    </>
  );
}

export default LabelledTextTypography;
