import {default as styles} from "./MissingStepsChecklist.module.scss";
import i18next from "i18next";
import {useSelector} from "react-redux";
import {Box, List, ListSubheader, ListItem, ListItemText} from "@mui/material";
import classnames from "classnames";
import {Link, useLocation} from "react-router-dom";
import {NAVBAR_LINKS} from "types/route";
import {
  getAllRequiredIncompleteSteps,
  getAllOptionalIncompleteSteps,
} from "store/selectors/navigationController";
import {getFixedT} from "util/languageUtil";
import {getContactInfo} from "store/selectors/basicInfo";

const t = getFixedT("selectLenders");

const MissingStepsChecklist = ({}) => {
  const allRequiredIncompleteSteps = useSelector(getAllRequiredIncompleteSteps);
  const allOptionalIncompleteSteps = useSelector(getAllOptionalIncompleteSteps);
  const {email} = useSelector(getContactInfo);
  const location = useLocation();
  const isMissingSecondaryApplicantData = location.state?.isMissingSecondaryApplicantData ?? false;

  return (
    <Box className={styles.root}>
      <List sx={{listStyleType: "disc"}}>
        {!!allRequiredIncompleteSteps?.length && (
          <ListSubheader disableSticky disableGutters className={styles.listSubheader}>
            {t("missingStepsMessage")}
          </ListSubheader>
        )}
        {allRequiredIncompleteSteps.map((step) => (
          <ListItem disablePadding className={styles.listItem} key={step}>
            <Link to={NAVBAR_LINKS[step].url}>
              <ListItemText primary={i18next.t(`enums.navStep.${step}`)} />
            </Link>
          </ListItem>
        ))}

        {!!allOptionalIncompleteSteps.length && (
          <ListSubheader
            disableSticky
            disableGutters
            className={classnames(styles.listSubheader, styles.italic)}
          >
            {t("optionalStepsMessage")}
          </ListSubheader>
        )}
        {allOptionalIncompleteSteps.map((step) => (
          <ListItem disablePadding className={styles.listItem} key={step}>
            <Link to={NAVBAR_LINKS[step].url}>
              <ListItemText primary={i18next.t(`enums.navStep.${step}`)} />
            </Link>
          </ListItem>
        ))}

        {isMissingSecondaryApplicantData && (
          <ListSubheader
            disableSticky
            disableGutters
            className={classnames(styles.listSubheader, styles.dualApplicantSection)}
          >
            {t("missingDualApplicantData")}
          </ListSubheader>
        )}

        <ListItemText>{`${t("lender", {email, returnObjects: true})}`}</ListItemText>
      </List>
    </Box>
  );
};

export default MissingStepsChecklist;
