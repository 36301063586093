import {useEffect} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  root,
  entry,
  title,
  details,
  options,
  textContent,
  button,
} from "./MortgageSelection.module.scss";
import Cart from "assets/images/MortgageSelection/cart.svg";
import PropertyDocument from "assets/images/MortgageSelection/property_document.svg";
import HouseRenew from "assets/images/MortgageSelection/house_renew.svg";
import BackButton from "components/molecules/BackButton/BackButton";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {useDispatch, useSelector} from "react-redux";
import {getWhitelabelToken} from "store/selectors/whitelabel";
import {toLowerCaseOrEmpty} from "components/utils/stringUtil";
import {getConsolidatedMortgageProviderName, getIsLocked} from "store/selectors/basicInfo";
import {LoanType} from "types/enums/loanType";
import {initialize, submitLoanType, navigatePreviousStep} from "./MortgageSelection.action";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import Message from "components/atoms/Message/Message";
import {useNavigate} from "react-router-dom";
import {getFixedT} from "util/languageUtil";
import StepContent from "components/organisms/StepContent/StepContent";
import {TrackingEventType} from "types/enums/trackingEventType";
import {trackEvent} from "util/eventUtil";

const fixedT = getFixedT("mortgageInfoSteps.mortgageSelection");

const MortgageSelection = ({nextPage = (loanType?: string) => {}}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getWhitelabelToken);
  const locked = useSelector(getIsLocked);

  const providerName = useSelector(getConsolidatedMortgageProviderName);
  const mortgageProviderName = toLowerCaseOrEmpty(providerName);
  const {isPending: submittingLoanType, failure: submitLoanTypeError} = useSelector(
    getOperationResult
  )(OperationType.submitLoanType);

  const pending = submittingLoanType;

  const optionsToIterate = [
    {
      image: "browsing.svg",
      failOverImage: Cart,
      title: "preapproval.title",
      details: "preapproval.details",
      loanTypeToSet: LoanType.PREAPPROVAL,
      ctaText: "preapproval.button",
      trackingEventType: TrackingEventType.mortgageSelectionClickPreApproval,
    },
    {
      image: "owned.svg",
      failOverImage: PropertyDocument,
      title: "newPurchase.title",
      details: "newPurchase.details",
      loanTypeToSet: LoanType.NEW_PURCHASE,
      ctaText: "newPurchase.button",
      trackingEventType: TrackingEventType.mortgageSelectionClickNewPurchase,
    },
    {
      image: "renewal.svg",
      failOverImage: HouseRenew,
      title: "renewal.title",
      details: "renewal.details",
      loanTypeToSet: LoanType.RENEWAL,
      ctaText: "renewal.button",
      trackingEventType: TrackingEventType.mortgageSelectionClickRenewal,
    },
  ];

  function onBack() {
    trackEvent(TrackingEventType.mortgageSelectionClickBack);
    dispatch(navigatePreviousStep({navigate}));
  }

  function onSetLoanType(loanType: LoanType, trackingEventType: TrackingEventType) {
    trackEvent(trackingEventType);
    dispatch(
      submitLoanType({
        loanType,
        onSuccess: () => {
          nextPage(loanType);
        },
      })
    );
  }

  useEffect(() => {
    dispatch(initialize());
  }, []);

  return (
    <StepContent
      className={root}
      headerText={fixedT("header")}
      backButton={<BackButton onClick={onBack} />}
      nextButton={<></>}
    >
      <Typography variant="body1">
        {fixedT("description", {context: mortgageProviderName})}
      </Typography>
      <Message message={submitLoanTypeError} />

      <div className={options}>
        {optionsToIterate.map((optionToIterate) => (
          <div key={optionToIterate.title} className={entry}>
            <FailoverImage
              src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/mortgage_info_screen/${optionToIterate.image}`}
              failoverSrc={optionToIterate.failOverImage}
            />
            <div className={textContent}>
              <Typography className={title} variant="h4">
                {fixedT(optionToIterate.title)}
              </Typography>
              <Typography className={details} variant="body1">
                {fixedT(optionToIterate.details)}
              </Typography>
            </div>
            <Button
              className={button}
              disabled={pending || locked}
              onClick={() =>
                onSetLoanType(optionToIterate.loanTypeToSet, optionToIterate.trackingEventType)
              }
              variant="contained"
            >
              {fixedT(optionToIterate.ctaText)}
            </Button>
          </div>
        ))}
      </div>
    </StepContent>
  );
};

export default MortgageSelection;
