import axios from "axios";
import {logEmpty} from "components/utils/log";
import {toUpperCase} from "components/utils/stringUtil";
import i18next from "i18next";
import {WithDataError} from "types/api";
import {InviteSecondaryUserRequest} from "types/dto/inviteSecondaryUserRequest";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {DocumentType} from "types/enums/documentType";
import {PolicyType} from "types/enums/policyType";
import {Step} from "types/enums/step";
import {UUID} from "types/uuid";
import {getUtm} from "util/localStorageUtil";
import {getAccessToken} from "./auth";
import {normalizedAxiosApi} from "./util";

export async function fetchBasicInfoByUserIdApi(
  userId: string
): Promise<WithDataError<ResidentialApplicationBasicInfoResponse>> {
  logEmpty(userId, "userId");

  const token = await getAccessToken();
  const url = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/mortgage/residential-application/basic-info/userId/${userId}`;
  if (token) {
    return normalizedAxiosApi(() => axios.get(url, {headers: {Authorization: `Bearer ${token}`}}));
  } else {
    return normalizedAxiosApi(() => axios.get(`${url}/unverified`));
  }
}

export async function updateBasicInfoByUserIdApi(
  userId: string,
  basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest>,
  step: Step
): Promise<WithDataError<ResidentialApplicationBasicInfoResponse>> {
  logEmpty(userId, "userId");
  logEmpty(step, "step");
  logEmpty(basicInfoRequest, "basicInfoRequest");

  const token = await getAccessToken();
  const url = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/mortgage/residential-application/basic-info/userId/${userId}`;
  if (token) {
    return normalizedAxiosApi(() =>
      axios.put(`${url}?step=${step}`, basicInfoRequest, {
        headers: {Authorization: `Bearer ${token}`},
      })
    );
  } else {
    return normalizedAxiosApi(() => axios.put(`${url}/unverified?step=${step}`, basicInfoRequest));
  }
}

export async function uploadFileApi(
  userId: string,
  file: File,
  documentType: DocumentType
): Promise<WithDataError<ResidentialApplicationBasicInfoResponse>> {
  logEmpty(userId, "userId");
  logEmpty(file, "File");
  logEmpty(documentType, "documentType");

  const formData = new FormData();
  formData.append("file", file);

  const token = await getAccessToken();
  const url = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/mortgage/residential-application/basic-info/userId/${userId}`;

  if (token) {
    return normalizedAxiosApi(() =>
      axios.post(`${url}?documentType=${documentType}`, formData, {
        headers: {Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data"},
      })
    );
  } else {
    return normalizedAxiosApi(() =>
      axios.post(`${url}/unverified?documentType=${documentType}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  }
}

export async function fetchPolicyApi(organizationId?: UUID): Promise<WithDataError<string>> {
  logEmpty(organizationId, "organizationId");

  const token = await getAccessToken();
  const url = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/policy/${organizationId}?language=${i18next.language}`; // uw call

  return normalizedAxiosApi(() =>
    axios.get(url, token ? {headers: {Authorization: `Bearer ${token}`}} : {})
  );
}

export async function setLenderApi(
  userId: string,
  productId: string
): Promise<WithDataError<ResidentialApplicationBasicInfoResponse>> {
  logEmpty(userId, "userId");
  logEmpty(productId, "productId");
  const token = await getAccessToken();
  logEmpty(token, "token");

  const url = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/mortgage/residential-application/qualification/userId/${userId}/set-lender/${productId}`;

  return normalizedAxiosApi(() =>
    axios.put(url, {}, {headers: {Authorization: `Bearer ${token}`}})
  );
}

export async function inviteSecondaryApplicantApi(
  primaryUserId?: string,
  userRequest?: InviteSecondaryUserRequest
): Promise<WithDataError<ResidentialApplicationBasicInfoResponse>> {
  logEmpty(primaryUserId, "primaryUserId");
  logEmpty(userRequest?.email, "userRequest?.email");

  const utm = getUtm();
  const token = await getAccessToken();
  //todo: if no accessToken, then not signed in. Use unverified endpoint
  const url = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/mortgage/residential-application/basic-info/userId/${primaryUserId}/invite-secondary`;

  return normalizedAxiosApi(() =>
    axios.put(
      token ? url : `${url}/unverified`,
      {
        ...userRequest,
        ...utm,
      },
      token ? {headers: {Authorization: `Bearer ${token}`}} : {}
    )
  );
}
