import {useState, useEffect, useRef} from "react";
import {useNavigate, Navigate, Outlet} from "react-router-dom";
import BasicInfoStep from "components/templates/BasicInfoStep/BasicInfoStep";
import LoadingAnimation from "components/atoms/LoadingAnimation/LoadingAnimation";
import {OrderedMap} from "util/OrderedMap";
import {useSelector} from "react-redux";
import {getBasicInfo, getIsDirectLender} from "store/selectors/basicInfo";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {RouteUrl} from "types/route";
import EmploymentInfo from "pages/BasicInfoSteps/EmploymentInfo/EmploymentInfo";
import AddressInfo from "pages/BasicInfoSteps/AddressInfo/AddressInfo";
import PreBankingScreen from "pages/BasicInfoSteps/PreBankingScreen/PreBankingScreen";
import AddBankStep from "pages/BasicInfoSteps/AddBankStep/AddBankStep";
import UploadDocuments from "pages/BasicInfoSteps/UploadDocuments/UploadDocuments";
import CreditScorePage from "pages/BasicInfoSteps/CreditScorePage/CreditScorePage";
import FlinksStep from "pages/BasicInfoSteps/FlinksStep/FlinksStep";
import SelfReportedIncomeAssets from "pages/BasicInfoSteps/SelfReportedAssetsLiabilities/SelfReportedAssetsLiabilities";
import SINForm from "pages/BasicInfoSteps/SINForm/SINForm";
import Page from "pages/Page/Page";

const DIRECT_LENDER_STEPS = new OrderedMap()
  .with("employment-info", EmploymentInfo)
  .with("address-info", AddressInfo)
  .with("income-assets", SelfReportedIncomeAssets)
  .with("upload-documents", UploadDocuments)
  .with("self-reported-credit", CreditScorePage)
  .with("mortgage-info", () => <Navigate to={"/mortgage-info"} />);

const BROKER_LENDER_STEPS = new OrderedMap()
  .with("employment-info", EmploymentInfo)
  .with("address-info", AddressInfo)
  .with("pre-banking-info", PreBankingScreen)
  .with("add-bank-info", AddBankStep)
  .with("flinks-screen", FlinksStep)
  .with("sin-form", SINForm)
  .with("upload-documents", UploadDocuments)
  .with("mortgage-info", () => <Navigate to={"/mortgage-info"} />);

const BasicInfoPage = () => {
  const basicInfo = useSelector(getBasicInfo);
  const isDirectLender = useSelector(getIsDirectLender);
  const {isPending, isFailure} = useSelector(getOperationResult)(
    OperationType.fetchBasicInfoByUserId
  );
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(-1);
  const [animationClass, setAnimationClass] = useState("next-page");
  const containingElementReference: React.MutableRefObject<any> = useRef();
  const stepMap = isDirectLender ? DIRECT_LENDER_STEPS : BROKER_LENDER_STEPS;

  useEffect(() => {
    if (containingElementReference.current) containingElementReference.current.scrollTop = 0;
  }, [pageIndex]);

  useEffect(() => {
    if (isFailure) {
      console.warn("Failed to fetch basic info by user id. Redirecting to sign in");
      navigate(RouteUrl.SIGN_IN);
    }
  }, [isFailure]);

  function getNavAnimationClass(targetPageIndex: number) {
    if (targetPageIndex === pageIndex) return;
    if (pageIndex < targetPageIndex) return setAnimationClass("next-page");
    return setAnimationClass("previous-page");
  }

  return (
    <Page>
      {isPending && <LoadingAnimation />}
      {!isPending && (
        <BasicInfoStep ref={containingElementReference}>
          <Outlet />
        </BasicInfoStep>
      )}
    </Page>
  );
};

export default BasicInfoPage;
