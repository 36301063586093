export function logEmpty(value: any, valueName: string = ""): void {
  if (value == null) {
    console.error(`value is null or undefined: ${valueName} - ${value}`);
  }
  if (typeof value === "number" && !Number.isFinite(value)) {
    console.error(`value is not a finite number: ${valueName} - ${value}`);
  }
  if (typeof value === "string" && !value.trim()) {
    console.error(`value is a blank string: ${valueName} - ${value}`);
  }
}

export function logNumberNegative(number: number | any, name: string = ""): void {
  if (!Number.isFinite(number) || number < 0) {
    console.error(`value is not a finite number >= 0: ${name} - ${number}`);
  }
}

export function logObjectEmpty(
  object: object | any[] | any,
  objectName = ""
): void {
  if (typeof object !== "object") {
    console.error(`object is not of "object" type: ${objectName} - ${object}`);
    return;
  }

  if (Object.keys(object).length === 0) {
    // works for both json and array
    console.error(`object is empty: ${objectName} - ${object}`);
  }
}

export function logIf(condition: boolean, message: string): void {
  if (condition) {
    console.error(`Unexpected condition: ${message || "unknown"}`);
  }
}
