import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {EmailPassword} from "types/basic";
import {WithNavigate} from "types/ui";

const namespace = "LoginForm";

export type LoginFormPayload = EmailPassword & WithNavigate;

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const login = createAction<LoginFormPayload>(actionTypeOf(namespace, "login"));
