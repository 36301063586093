import {
  verifyRecoveryTokenApi,
  fetchAuthorizedUserApi,
  setPasswordApi,
  signInApi,
  signOutApi,
} from "api/auth";
import {fetchConfigurationsApi} from "api/configurations";
import {getTheme} from "store/selectors/theme";
import {AuthUser} from "types/auth";
import {Configuration} from "types/configurations";
import {Theme} from "types/theme";
import {fetchThemeApi} from "../../api/theme";
import {changeUserLanguageApi, fetchUserApi} from "../../api/user";
import {OperationType} from "../../types/operation";
import {Operation} from "./Operation";

export const fetchThemeOperation = new Operation<Theme>(
  OperationType.fetchTheme,
  fetchThemeApi,
  getTheme
);

export const fetchWhiteLabelConfigurationsOperation = new Operation<Configuration[]>(
  OperationType.fetchWhitelabelConfigurations,
  fetchConfigurationsApi
);

export const fetchProviderConfigurationsOperation = new Operation<Configuration[]>(
  OperationType.fetchProviderConfigurations,
  fetchConfigurationsApi
);

export const fetchAuthorizedUserOperation = new Operation<AuthUser>(
  OperationType.fetchAuthorizedUser,
  fetchAuthorizedUserApi
);

export const signInOperation = new Operation(OperationType.signIn, signInApi);

export const signOutOperation = new Operation(OperationType.signOut, signOutApi);

export const setPasswordOperation = new Operation(OperationType.setPassword, setPasswordApi);

export const verifyRecoveryTokenOperation = new Operation(
  OperationType.verifyRecoveryToken,
  verifyRecoveryTokenApi
);

export const fetchUserApiOperation = new Operation(OperationType.fetchUser, fetchUserApi);

export const changeLanguageOperation = new Operation(
  OperationType.changeLanguage,
  changeUserLanguageApi
);
