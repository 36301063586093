import {registerPrimaryUserApi, registerSecondaryUserApi} from 'api/auth';
import {OperationType} from 'types/operation';
import {Operation} from './Operation';

export const registerPrimaryUserOperation = new Operation(
  OperationType.registerPrimaryUser,
  registerPrimaryUserApi
);

export const registerSecondaryUserOperation = new Operation(
  OperationType.registerSecondaryUser,
  registerSecondaryUserApi
);
