import {useEffect} from "react";
import {
  Routes as RoutesRouter,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  Outlet,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import LoginPortal, {STATE_ENUM} from "pages/LoginPortal/LoginPortal";
import BasicInfoPage from "pages/BasicInfoPage/BasicInfoPage";
import MortgageInfoPage from "pages/MortgageInfoPage/MortgageInfoPage";
import UnresolvedAVMPage from "pages/UnresolvedAVMPage/UnresolvedAVMPage";
import SelectLenders from "pages/MortgageRatesPages/SelectLenders/SelectLenders";
import ApplicationComplete from "pages/ApplicationComplete/ApplicationComplete";
import ResendEmailPage from "pages/ResendEmailPage/ResendEmailPage";
import JointStepsComplete from "pages/JointStepsComplete/JointStepsComplete";
import SelectProductProviderPage from "pages/SelectProductProviderPage/SelectProductProviderPage";
import StatusScreen from "components/templates/StatusScreen/StatusScreen";
import PrimaryApplicantRoute from "components/molecules/PrimaryApplicantRoute/PrimaryApplicantRoute";
import ErrorPage from "../ErrorPage/ErrorPage";
import {useSelector} from "react-redux";
import {getThemeOrEmpty} from "store/selectors/theme";
import SignUpPage from "pages/SignUpPage/SignUpPage";
import SignInPage from "pages/SignInPage/SignInPage";
import InviteSecondaryApplicant from "pages/BasicInfoSteps/InviteSecondaryApplicant/InviteSecondaryApplicant";
import SignUpSecondaryPage from "pages/SecondarySignUpPage/SecondarySignUpPage";
import {getHasUserAccount} from "store/selectors/user";
import {INIT_STEPS, MORTGAGE_INFO_STEPS, RouteUrl} from "types/route";
import {getConsolidatedMortgageProviderId, getIsDirectLender} from "store/selectors/basicInfo";
import NoLendersPage from "pages/MortgageRatesPages/NoLendersPage/NoLendersPage";
import {getFixedT} from "util/languageUtil";
import EmploymentInfo from "pages/BasicInfoSteps/EmploymentInfo/EmploymentInfo";
import AddressInfo from "pages/BasicInfoSteps/AddressInfo/AddressInfo";
import AddBankStep from "pages/BasicInfoSteps/AddBankStep/AddBankStep";
import CreditScorePage from "pages/BasicInfoSteps/CreditScorePage/CreditScorePage";
import UploadDocuments from "pages/BasicInfoSteps/UploadDocuments/UploadDocuments";
import FlinksStep from "pages/BasicInfoSteps/FlinksStep/FlinksStep";
import SelfReportedAssetsLiabilities from "pages/BasicInfoSteps/SelfReportedAssetsLiabilities/SelfReportedAssetsLiabilities";
import SINForm from "pages/BasicInfoSteps/SINForm/SINForm";
import MortgageSelection from "pages/MortgageInfoSteps/MortgageSelection/MortgageSelection";
import PropertyInformation from "pages/MortgageInfoSteps/PropertyInformation/PropertyInformation";
import MortgageType from "pages/MortgageInfoSteps/MortgageType/MortgageType";
import {getLoanTypeConsolated} from "store/selectors/basicInfo";
import {getMortgagePageUrl, getNextPage, getPreviousPage} from "util/routeUtil";
import {LoanType} from "types/enums/loanType";
import AuthorizationCodeCallback from "pages/AuthorizationCodeCallback/AuthorizationCodeCallback";
import {getProviders, getWhiteLabelFaviconUrl} from "store/selectors/whitelabel";
import PurchaseTimeframePage from "pages/PurchaseTimeframePage/PurchaseTimeframePage";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {ConfigurationKey} from "types/configurations";
import {getIsPurchaseTimeframePageActive} from "store/selectors/configurations";
import Page from "pages/Page/Page";
import PreBankingScreen from "pages/BasicInfoSteps/PreBankingScreen/PreBankingScreen";
import {ProductSummary} from "types/dto/productSummary";
import MissingStepsChecklist from "components/organisms/MissingStepsChecklist/MissingStepsChecklist";
import CalculateQualification from "pages/MortgageRatesPages/CalculateQualification/CalculateQualification";

const Routes = () => {
  const theme = useSelector(getThemeOrEmpty);
  const favicon = useSelector(getWhiteLabelFaviconUrl);
  const hasAccount = useSelector(getHasUserAccount);
  const mortgageProvider = useSelector(getConsolidatedMortgageProviderId);
  const location = useLocation();
  const fixedT = getFixedT("routes");
  const loanType = useSelector(getLoanTypeConsolated);
  const isPurchaseTimeframePageActive = useSelector(getIsPurchaseTimeframePageActive);
  const isSelfReportedCreditPageActive = useSelector(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
    )
  );
  const isSelfReportedAssetsLiabilitiesEnabled: boolean = useSelector(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );
  const providers: ProductSummary[] = useSelector(getProviders);
  const isSelectProductProvidersPageActive =
    providers.length > 1 ||
    useSelector(
      getIsProviderConfigurationValueEnabled(
        ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_BROKERAGE_OR_BANKING
      )
    );
  const navigate = useNavigate();
  const urlSelected = getMortgagePageUrl(
    window.location.pathname as RouteUrl,
    loanType as LoanType
  );
  const isDirectLender = useSelector(getIsDirectLender);
  const INIT_STEPS_ACTIVE = getActivePages(INIT_STEPS);

  function getActivePages(pages: RouteUrl[]): RouteUrl[] {
    let activePages = pages;
    if (!isPurchaseTimeframePageActive) {
      activePages = activePages.filter((url) => url !== RouteUrl.PURCHASE_TIMEFRAME_URL);
    }
    if (!isSelfReportedAssetsLiabilitiesEnabled) {
      activePages = activePages.filter(
        (url) => url !== RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT
      );
    }
    if (!isSelfReportedCreditPageActive) {
      activePages = activePages.filter(
        (url) => url !== RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT
      );
    }
    if (!isSelectProductProvidersPageActive) {
      activePages = activePages.filter((url) => url !== RouteUrl.SELECT_PRODUCT_PROVIDER_URL);
    }
    return activePages;
  }

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "auto"});
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{theme?.displayName}</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <RoutesRouter>
        <Route path={RouteUrl.ERROR_PAGE_URL} element={<ErrorPage />} />
        {RouteUrl.PURCHASE_TIMEFRAME_URL == INIT_STEPS_ACTIVE[1] ? (
          <Route
            path={RouteUrl.LANDING_PAGE_URL}
            element={
              <PurchaseTimeframePage
                nextPage={() =>
                  navigate(getNextPage(RouteUrl.PURCHASE_TIMEFRAME_URL, INIT_STEPS_ACTIVE))
                }
              />
            }
          />
        ) : (
          <Route
            path={RouteUrl.PURCHASE_TIMEFRAME_URL}
            element={
              <PurchaseTimeframePage
                prevPage={() => navigate(getPreviousPage(urlSelected, INIT_STEPS_ACTIVE))}
                nextPage={() => navigate(getNextPage(urlSelected, INIT_STEPS_ACTIVE))}
              />
            }
          />
        )}
        {!hasAccount && RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT == INIT_STEPS_ACTIVE[1] ? (
          // Assets and Liabilities is the first page, the url is /landing-page. Also, don't render a back button
          <Route
            path={RouteUrl.LANDING_PAGE_URL}
            element={
              <Page>
                <SelfReportedAssetsLiabilities
                  nextPage={() => {
                    navigate(
                      getNextPage(
                        RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT,
                        INIT_STEPS_ACTIVE
                      )
                    );
                  }}
                />
              </Page>
            }
          />
        ) : !hasAccount &&
          INIT_STEPS_ACTIVE.includes(RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT) ? (
          // Assets and Liabilities is before signup, the url is not changed.
          // Choose the prev and next page based on active pre-signup pages
          <Route
            path={RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT}
            element={
              <Page>
                <SelfReportedAssetsLiabilities
                  prevPage={() => navigate(getPreviousPage(urlSelected, INIT_STEPS_ACTIVE))}
                  nextPage={() => {
                    navigate(getNextPage(urlSelected, INIT_STEPS_ACTIVE));
                  }}
                />
              </Page>
            }
          />
        ) : (
          // Assets and Liabilities is post-signup, use standard behaviour
          <Route
            path={RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT}
            element={
              <Page>
                <SelfReportedAssetsLiabilities />
              </Page>
            }
          />
        )}

        {!hasAccount &&
        RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT == INIT_STEPS_ACTIVE[1] ? (
          // Credit is the first page, the url is /landing-page. Also, don't render a back button
          <Route
            path={RouteUrl.LANDING_PAGE_URL}
            element={
              <Page>
                <CreditScorePage
                  nextPage={() =>
                    navigate(
                      getNextPage(
                        RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT,
                        INIT_STEPS_ACTIVE
                      )
                    )
                  }
                />
              </Page>
            }
          />
        ) : !hasAccount &&
          INIT_STEPS_ACTIVE.includes(RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT) ? (
          // Credit is before signup, the url is not changed.
          // Choose the prev and next page based on active pre-signup pages
          <Route
            path={RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT}
            element={
              <Page>
                <CreditScorePage
                  prevPage={() => navigate(getPreviousPage(urlSelected, INIT_STEPS_ACTIVE))}
                  nextPage={() => navigate(getNextPage(urlSelected, INIT_STEPS_ACTIVE))}
                />
              </Page>
            }
          />
        ) : (
          // Credit is post-signup, use standard behaviour
          <Route
            path={RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT}
            element={
              <Page>
                <CreditScorePage />
              </Page>
            }
          />
        )}
        {RouteUrl.SELECT_PRODUCT_PROVIDER_URL == INIT_STEPS_ACTIVE[1] ? (
          <Route
            path={RouteUrl.LANDING_PAGE_URL}
            element={
              <SelectProductProviderPage
                nextPage={() =>
                  navigate(getNextPage(RouteUrl.SELECT_PRODUCT_PROVIDER_URL, INIT_STEPS_ACTIVE))
                }
              />
            }
          />
        ) : (
          <Route
            path={RouteUrl.SELECT_PRODUCT_PROVIDER_URL}
            element={
              <SelectProductProviderPage
                prevPage={() => navigate(getPreviousPage(urlSelected, INIT_STEPS_ACTIVE))}
                nextPage={() => navigate(getNextPage(urlSelected, INIT_STEPS_ACTIVE))}
              />
            }
          />
        )}
        {RouteUrl.SIGN_UP == INIT_STEPS_ACTIVE[1] ? (
          <Route path={RouteUrl.LANDING_PAGE_URL} element={<SignUpPage />} />
        ) : (
          <Route path={RouteUrl.SIGN_UP} element={<SignUpPage />} />
        )}
        <Route path={RouteUrl.SIGN_UP} element={<SignUpPage />} />
        <Route path={RouteUrl.SECONDARY_SIGNUP} element={<SignUpSecondaryPage />} />
        <Route path={RouteUrl.SIGN_IN} element={<SignInPage />} />
        <Route path={RouteUrl.LOGIN_CALLBACK} element={<AuthorizationCodeCallback />} />
        <Route path={RouteUrl.INVITE_SECONDARY_APPLICANT} element={<InviteSecondaryApplicant />} />
        <Route path={RouteUrl.BASIC_INFO_URL} element={<BasicInfoPage />}>
          <Route
            path={RouteUrl.BASIC_INFO_URL}
            element={<Navigate to={RouteUrl.BASIC_INFO_URL_EMPLOYMENT} />}
          />
          {/* <Route
            path={RouteUrl.BASIC_INFO_URL_PERSONAL_INFO}
            element={
              <PersonalProfile nextPage={() => navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT)} />
            }
          /> */}
          <Route path={RouteUrl.BASIC_INFO_URL_EMPLOYMENT} element={<EmploymentInfo />} />
          <Route path={RouteUrl.BASIC_INFO_URL_ADDRESS} element={<AddressInfo />} />
          <Route path={RouteUrl.BASIC_INFO_URL_PRE_BANKING} element={<PreBankingScreen />} />
          <Route path={RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO} element={<AddBankStep />} />
          <Route path={RouteUrl.BASIC_INFO_URL_FLINKS_SCREEN} element={<FlinksStep />} />
          <Route
            path={RouteUrl.BASIC_INFO_URL_BANKING_REDIRECT}
            element={
              <Navigate
                to={
                  isDirectLender
                    ? RouteUrl.BASIC_INFO_URL_ASSETS_LIABILITES_DIRECT
                    : RouteUrl.BASIC_INFO_URL_PRE_BANKING
                }
              />
            }
          />
          <Route
            path={RouteUrl.BASIC_INFO_URL_CREDIT_REDIRECT}
            element={
              <Navigate
                to={
                  isDirectLender
                    ? RouteUrl.BASIC_INFO_URL_SELF_REPORTED_CREDIT_DIRECT
                    : RouteUrl.BASIC_INFO_URL_SIN_FORM
                }
              />
            }
          />
          <Route path={RouteUrl.BASIC_INFO_URL_SIN_FORM} element={<SINForm />} />
          <Route path={RouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS} element={<UploadDocuments />} />
        </Route>
        <Route
          path={RouteUrl.MORTGAGE_INFO_URL}
          element={
            <PrimaryApplicantRoute>
              <MortgageInfoPage />
            </PrimaryApplicantRoute>
          }
        >
          <Route
            path={RouteUrl.MORTGAGE_INFO_URL}
            element={<Navigate to={RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION} />}
          />
          <Route
            path={RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION}
            element={
              <MortgageSelection
                nextPage={(loanType) => {
                  if (loanType === LoanType.PREAPPROVAL) {
                    navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE);
                  } else if (loanType === LoanType.NEW_PURCHASE || loanType === LoanType.RENEWAL) {
                    navigate(getNextPage(urlSelected, MORTGAGE_INFO_STEPS));
                  }
                }}
              />
            }
          />
          <Route
            path={RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO}
            element={
              <PropertyInformation
                nextPage={() => navigate(getNextPage(urlSelected, MORTGAGE_INFO_STEPS))}
              />
            }
          />
          <Route
            path={RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE}
            element={
              <MortgageType
                nextPage={() => navigate(getNextPage(urlSelected, MORTGAGE_INFO_STEPS))}
              />
            }
          />
        </Route>
        <Route
          path={RouteUrl.PRODUCTS_URL}
          element={
            <PrimaryApplicantRoute>
              <Outlet />
            </PrimaryApplicantRoute>
          }
        >
          <Route
            path={RouteUrl.PRODUCTS_CALCULATE_QUALIFICATION_URL}
            element={<CalculateQualification />}
          />
          <Route path={RouteUrl.PRODUCTS_SELECT_URL} element={<SelectLenders />} />
          <Route path={RouteUrl.PRODUCTS_UNAVAILABLE_URL} element={<NoLendersPage />} />
        </Route>

        <Route
          path={RouteUrl.UNRESOLVED_AVM_URL}
          element={
            <PrimaryApplicantRoute>
              <UnresolvedAVMPage />
            </PrimaryApplicantRoute>
          }
        />

        <Route
          path={RouteUrl.APPLICATION_COMPLETE_URL}
          element={
            <PrimaryApplicantRoute>
              <ApplicationComplete />
            </PrimaryApplicantRoute>
          }
        />
        <Route
          path={RouteUrl.ACTIVATE_ACCOUNT_URL}
          element={<LoginPortal startingState={STATE_ENUM.SET_PASSWORD} />}
        />
        <Route
          path={RouteUrl.RESET_PASSWORD_URL}
          element={<LoginPortal startingState={STATE_ENUM.SET_PASSWORD} />}
        />
        <Route path={RouteUrl.RESEND_EMAIL_URL} element={<ResendEmailPage />} />
        <Route
          path={RouteUrl.DECLINED_TERMS_URL}
          element={
            <StatusScreen
              descriptions={
                fixedT("declinedTerms", {
                  email: theme?.contactInfo?.email,
                  returnObjects: true,
                }) as string[]
              }
            />
          }
        />
        <Route path={RouteUrl.JOINT_COMPLETED_URL} element={<JointStepsComplete />} />
        {!Boolean(mortgageProvider) ? (
          <Route element={<Navigate to={RouteUrl.LANDING_PAGE_URL} />} />
        ) : hasAccount ? (
          <Route element={<Navigate to={RouteUrl.BASIC_INFO_URL_EMPLOYMENT} />} />
        ) : (
          <Route element={<Navigate to={RouteUrl.SIGN_UP} />} />
        )}
        <Route path="*" element={<Navigate to={RouteUrl.LANDING_PAGE_URL} />} />
        <Route path={"/checklist"} element={<MissingStepsChecklist />} />
      </RoutesRouter>
    </>
  );
};

export default Routes;
