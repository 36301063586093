import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {DocumentType} from "types/enums/documentType";
import {WithNavigate} from "types/ui";

const namespace = "UploadDocuments";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export interface UploadDocumentsPayload {
  file: File;
  documentType?: DocumentType;
}

export const submitFile = createAction<UploadDocumentsPayload>(actionTypeOf(namespace, "submit"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);

export const navigateNextStep = createAction<WithNavigate>(actionTypeOf(namespace, "navigateNext"));
