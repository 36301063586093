import {LANGUAGE_LOOKUP, toLanguageType} from "util/languageUtil";
import {maybe, nullable} from "types/basic";
import {LanguageType} from "types/enums/languageType";
import {useMatch} from "react-router-dom";
import {RouteUrl} from "types/route";

export function getSearchParamLocale(): maybe<LanguageType> {
  return toLanguageType(getSearchParam(LANGUAGE_LOOKUP));
}

export function getSearchParam(key: string): maybe<string> {
  const params = getSearchParamsOf(key);
  return params[key];
}

export function getSearchParamsOf(...keys: string[]): Record<string, nullable<string>> {
  const map: Record<string, nullable<string>> = {};
  const params = new URLSearchParams(window.location.search);
  for (let key of keys) {
    map[key] = params.get(key);
  }
  return map;
}

export function getURLSearchParamsExcluding(...keysExcluded: string[]): URLSearchParams {
  const params = new URLSearchParams(window.location.search);
  for (let key of keysExcluded) {
    params.delete(key);
  }
  return params;
}

export const isCurrentUrl = (url: RouteUrl) => url == location.pathname;

export const isCurrentUrlAnyOf = (urls: RouteUrl[]) =>
  Boolean(urls.find((url) => url == location.pathname));
