import {equalIgnoreCase} from "components/utils/stringUtil";
import {select, takeEvery} from "redux-saga/effects";
import {getLoanTypeConsolated} from "store/selectors/basicInfo";
import {WithPayload} from "types/basic";
import {LoanType} from "types/enums/loanType";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {edit} from "./NoLendersPage.action";

function* onEdit({payload: {navigate}}: WithPayload<WithNavigate>) {
  const loanType: LoanType = yield select(getLoanTypeConsolated);

  if (equalIgnoreCase(LoanType.PREAPPROVAL, loanType)) {
    navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE);
  } else {
    navigate(RouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO);
  }
}

export default function* NoLendersPageSaga() {
  yield takeEvery(edit, onEdit);
}
