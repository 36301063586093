import i18next from "i18next";
import {root, prompt as promptClass, icon as iconClass} from "./RegistrationComplete.module.scss";
import FailoverImage from "components/molecules/FailoverImage/FailoverImage";
import {useSelector} from "react-redux";
import {getWhitelabelToken} from "store/selectors/whitelabel";

const Icon = require("assets/images/Iconography/registration-complete.svg");

const RegistrationComplete = () => {
  const token = useSelector(getWhitelabelToken);

  return (
    <div className={root}>
      <FailoverImage
        src={`${window._env_.REACT_APP_IMGIX_DOMAIN}/${token}/registration_screen/registration_image.svg`}
        failoverSrc={Icon}
        className={iconClass}
      />
      <div className={promptClass}>
        {i18next.t("components.organisms.registrationComplete.prompt")}
      </div>
    </div>
  );
};

export default RegistrationComplete;
