import {useEffect, useState} from "react";
import {default as styles} from "./InviteSecondaryApplicant.module.scss";
import Page from "pages/Page/Page";
import Message from "components/atoms/Message/Message";
import {ApplicantRelationshipType} from "types/enums/applicantRelationshipType";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {getFixedT} from "util/languageUtil";
import {useDispatch, useSelector} from "react-redux";
import {getUserId} from "store/selectors/user";
import {useNavigate} from "react-router-dom";
import {getIsPrimaryAndHasSecondary, getIsSecondary} from "store/selectors/basicInfo";
import {
  initialize,
  submitInviteSecondary,
  SubmitInviteSecondaryPayload,
} from "./InviteSecondaryApplicant.action";
import InviteSecondaryApplicantComplete from "./InviteSecondaryApplicantComplete/InviteSecondaryApplicantComplete";
import {getOperationFailure, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {FormControl, MenuItem, Typography} from "@mui/material";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import Form from "components/atoms/Form/Form";
import {trackEventMemoized} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {useForm} from "react-hook-form";
import {getSchema} from "./InviteSecondaryApplicant.schema";
import {yupResolver} from "@hookform/resolvers/yup";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";

const fixedT = getFixedT("basicInfoSteps.InviteSecondaryApplicant");

const InviteSecondaryApplicant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const primaryUserId = useSelector(getUserId);
  const isSecondary = useSelector(getIsSecondary);
  const operationFailure = useSelector(getOperationFailure)(OperationType.inviteSecondaryApplicant);
  const isPrimaryAndHasSecondary: boolean = useSelector(getIsPrimaryAndHasSecondary);

  const formHook = useForm<SubmitInviteSecondaryPayload>({
    defaultValues: {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      relationship: undefined,
    },
    resolver: yupResolver(getSchema()),
  });

  const {isPending} = useSelector(getOperationResult)(OperationType.inviteSecondaryApplicant);
  const [otherError, setOtherError] = useState<string>(
    !primaryUserId ? fixedT("errorMessages.userIdMissing")! : ""
  );

  function validate(): boolean {
    if (isSecondary) {
      setOtherError(fixedT("errorMessages.primaryApplicantError")!);
      return false;
    }
    if (!primaryUserId) {
      setOtherError(fixedT("errorMessages.userIdMissing")!);
      return false;
    }
    return true;
  }

  let operationFailureMessage = "";
  if (operationFailure?.status && operationFailure.status != 200) {
    operationFailureMessage = fixedT("errorMessages.error", {context: operationFailure?.status});
  }

  const onSubmit = (values: SubmitInviteSecondaryPayload) => {
    if (validate()) {
      trackEventMemoized(TrackingEventType.inviteSecondaryApplicantSubmit);
      dispatch(
        submitInviteSecondary({
          navigate,
          ...values,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  return (
    <Page>
      <div className={styles.root}>
        {isPrimaryAndHasSecondary && <InviteSecondaryApplicantComplete />}
        {!isPrimaryAndHasSecondary && (
          <>
            <Typography variant="h1" align="left">
              {fixedT("header")}
            </Typography>
            <Form className={styles.fields} onEnter={formHook.handleSubmit(onSubmit)}>
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="firstName"
                type="text"
                label={fixedT("fields.firstName.label")}
                placeholder={fixedT("fields.firstName.placeholder")!}
                required
                trackingEvent={TrackingEventType.inviteSecondaryApplicantEditFirstName}
              />
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="lastName"
                type="text"
                label={fixedT("fields.lastName.label")}
                placeholder={fixedT("fields.lastName.placeholder")!}
                required
                trackingEvent={TrackingEventType.inviteSecondaryApplicantEditLastName}
              />
              <TextFieldControlled
                fullWidth
                formHook={formHook}
                name="email"
                type="email"
                label={fixedT("fields.email.label")}
                placeholder={fixedT("fields.email.placeholder")}
                required
                trackingEvent={TrackingEventType.inviteSecondaryApplicantEditEmail}
              />
              <FormControl fullWidth>
                <TextFieldControlled
                  formHook={formHook}
                  name="relationship"
                  select
                  label={fixedT("fields.relationship.label")}
                  required
                  trackingEvent={TrackingEventType.inviteSecondaryApplicantEditRelationshipType}
                >
                  {Object.values(ApplicantRelationshipType).map((typeVal) => (
                    <MenuItem value={typeVal} key={typeVal}>
                      {fixedT("fields.relationship.options", {context: typeVal})}
                    </MenuItem>
                  ))}
                </TextFieldControlled>
              </FormControl>
            </Form>
            <NavigationSection>
              <SubmitButton
                text={fixedT("forwardButton")}
                isSubmitting={isPending}
                isEnabled={!isPending}
                onClick={formHook.handleSubmit(onSubmit)}
              />
            </NavigationSection>
          </>
        )}
        <Message message={otherError} />
        <Message message={operationFailureMessage} />
      </div>
    </Page>
  );
};

export default InviteSecondaryApplicant;
