import {Badge, IconProps} from "@mui/material";
import i18next from "i18next";
import {
  root,
  badge,
  messageText,
  dropdownList,
  dropdownBody,
  headerText,
  dropdownListItem,
} from "./UserProfileBadge.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faSignInAlt, faPhone, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "components/molecules/Dropdown/Dropdown";
import DropdownList from "components/molecules/DropdownList/DropdownList";
import {useDispatch, useSelector} from "react-redux";
import {submit} from "./UserProfileBadge.action";
import {useNavigate} from "react-router-dom";
import {
  getFirstNameConsolidated,
  getHasUnverifiedUserAccount,
  getHasUserAccount,
} from "store/selectors/user";
import {getContactInfo} from "store/selectors/basicInfo";
import {TrackingEventType} from "types/enums/trackingEventType";
import {RouteUrl} from "types/route";
import {trackEvent} from "util/eventUtil";

const t = i18next.getFixedT(null, null, "components.organisms.userProfileBadge");

const UserProfileBadge = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasUserAccount = useSelector(getHasUserAccount);
  const hasUnverifiedUserAccount = useSelector(getHasUnverifiedUserAccount);
  const firstName = useSelector(getFirstNameConsolidated);
  const {email, phoneNumber} = useSelector(getContactInfo);

  return (
    <div className={root}>
      <Dropdown
        icon={faBars}
        bodyClassName={dropdownBody}
        body={
          hasUnverifiedUserAccount ? (
            <>
              <Badge
                badgeContent={"!"}
                color="warning"
                className={badge}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              ></Badge>
              <span className={headerText}>{firstName || t("defaultName")}</span>
            </>
          ) : (
            <span className={headerText}>{firstName || t("defaultName")}</span>
          )
        }
        content={
          <DropdownList className={dropdownList}>
            {hasUnverifiedUserAccount ? (
              <div className={messageText}>
                {t("verifyMessage")}
                <a
                  onClick={() => {
                    trackEvent(TrackingEventType.clickResentVerification);
                    navigate(RouteUrl.RESEND_EMAIL_URL);
                  }}
                >
                  {t("resend")}
                </a>
              </div>
            ) : (
              ""
            )}
            <div className={dropdownListItem}>
              {hasUserAccount ? (
                <span onClick={() => dispatch(submit({navigate}))}>{t("SignOut")}</span>
              ) : (
                <span
                  onClick={() => {
                    trackEvent(TrackingEventType.clickLogin);
                    navigate(RouteUrl.SIGN_IN);
                  }}
                >
                  {t("LogIn")}
                </span>
              )}
              <FontAwesomeIcon icon={faSignInAlt} />
            </div>
            <h3>{t("contactUs")}</h3>
            <div className={dropdownListItem}>
              <span>{phoneNumber}</span>
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className={dropdownListItem}>
              <span>{email}</span>
              <FontAwesomeIcon icon={faMailBulk} />
            </div>
          </DropdownList>
        }
      />
    </div>
  );
};

export default UserProfileBadge;
