import {isValidPostalCode} from "components/utils/stringUtil";
import {OwnershipStatus} from "types/enums/ownershipStatus";
import {Province} from "types/enums/province";
import {YearsAtAddressLength} from "types/enums/yearsAtAddressLength";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "basicInfoSteps.addressInfo"});

  return yup.object().shape({
    residentialAddress: yup
      .object({
        streetNo: yup
          .number()
          .typeError(
            fixedT("messages.missing", {
              context: "currentStreetNo",
            })!
          )
          .min(
            1,
            fixedT("messages.missing", {
              context: "currentStreetNo",
            })!
          )
          .required(
            fixedT("messages.missing", {
              context: "currentStreetNo",
            })!
          ),
        streetName: yup
          .string()
          .trim()
          .required(
            fixedT("messages.missing", {
              context: "currentStreetName",
            })!
          ),
        city: yup
          .string()
          .trim()
          .required(
            fixedT("messages.missing", {
              context: "currentCity",
            })!
          ),
        province: yup
          .mixed<Province>()
          .required(
            fixedT("messages.missing", {
              context: "currentProvince",
            })!
          )
          .test({
            name: "residentialAddress.province",
            test(province, ctx) {
              if (province !== Province.NULL) return true;
              return ctx.createError({
                path: "residentialAddress.province",
                message: fixedT("messages.missing", {
                  context: "currentProvince",
                })!,
              });
            },
          }),
        postCode: yup
          .string()
          .trim()
          .required(
            fixedT("messages.missing", {
              context: "currentPostCode",
            })!
          )
          .test({
            name: "residentialAddress.postCode",
            test(postCode, ctx) {
              if (isValidPostalCode(postCode)) return true;
              return ctx.createError({
                path: "residentialAddress.postCode",
                message: fixedT("messages.invalid", {
                  context: "postCode",
                })!,
              });
            },
          }),
      })
      .required(),
    ownershipStatus: yup.mixed<OwnershipStatus>().required(
      fixedT("messages.missing", {
        context: "ownershipStatus",
      })!
    ),
    timeAtAddress: yup.mixed<YearsAtAddressLength>().required(
      fixedT("messages.missing", {
        context: "timeAtAddress",
      })!
    ),
    previousResidentialAddress: yup.lazy((previousResidentialAddressValue, {parent}) => {
      if (YearsAtAddressLength.FEWER_THAN_2_YEARS === parent.timeAtAddress) {
        return yup
          .object({
            streetNo: yup
              .number()
              .typeError(
                fixedT("messages.missing", {
                  context: "previousStreetNo",
                })!
              )
              .min(
                1,
                fixedT("messages.missing", {
                  context: "previousStreetNo",
                })!
              )
              .required(
                fixedT("messages.missing", {
                  context: "previousStreetNo",
                })!
              ),
            streetName: yup.string().required(
              fixedT("messages.missing", {
                context: "previousStreetName",
              })!
            ),
            city: yup.string().required(
              fixedT("messages.missing", {
                context: "previousCity",
              })!
            ),
            province: yup
              .mixed<Province>()
              .required(
                fixedT("messages.missing", {
                  context: "previousProvince",
                })!
              )
              .test({
                name: "previousResidentialAddress.province",
                test(province, ctx) {
                  if (province !== Province.NULL) return true;
                  return ctx.createError({
                    path: "previousResidentialAddress.province",
                    message: fixedT("messages.missing", {
                      context: "previousProvince",
                    })!,
                  });
                },
              }),
            postCode: yup
              .string()
              .required(
                fixedT("messages.missing", {
                  context: "previousPostCode",
                })!
              )
              .test({
                name: "previousResidentialAddress.postCode",
                test(postCode, ctx) {
                  if (isValidPostalCode(postCode)) return true;
                  return ctx.createError({
                    path: "previousResidentialAddress.postCode",
                    message: fixedT("messages.invalid", {
                      context: "postCode",
                    })!,
                  });
                },
              }),
          })
          .required();
      }
      return yup.mixed().notRequired();
    }),
  });
};
