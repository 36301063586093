import {TextField} from "@mui/material";
import {DatePicker, DatePickerProps, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {Controller, FieldValues, UseFormReturn} from "react-hook-form";
import {TrackingEventType} from "types/enums/trackingEventType";
import {WithChildren} from "types/ui";
import {trackEventMemoized} from "util/eventUtil";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import i18next from "i18next";
import {isFrench} from "util/languageUtil";
import {nonNull} from "components/utils/booleanUtil";
import {useTranslation} from "react-i18next";
import {useState} from "react";

const t = i18next.getFixedT(null, null, "datePicker");

interface DatePickerControlledProps<T extends FieldValues = any, C = any>
  extends Pick<DatePickerProps<null, null>, "label">,
    WithChildren {
  formHook: UseFormReturn<T, C>;
  name: string;
  valueAsFloat?: boolean;
  required?: boolean;
  trackingEvent?: TrackingEventType;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
  triggerOnChange?: boolean;
}

const DatePickerControlled = ({
  formHook,
  name,
  label,
  required = false,
  disabled = false,
  triggerOnChange = false,
  trackingEvent,
  minDate,
  maxDate,
}: DatePickerControlledProps) => {
  const {control, trigger, getFieldState, formState} = formHook;
  const {error, isTouched, invalid} = getFieldState(name);
  const {i18n} = useTranslation();

  const shouldDisplayError =
    (formState.submitCount > 0 && Boolean(error?.message)) ||
    (isTouched && invalid && Object.keys(formState.dirtyFields).length > 0);

  return (
    <LocalizationProvider
      adapterLocale={isFrench(i18n.language) ? "fr" : "en"}
      dateAdapter={AdapterDayjs}
    >
      <Controller
        key={name}
        name={name}
        control={control}
        defaultValue={null}
        render={({field, fieldState: {error}}) => (
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            label={label}
            value={field.value}
            disabled={disabled}
            onChange={(value) => {
              if (trackingEvent) trackEventMemoized(trackingEvent);
              if (!value) {
                field.onChange("");
              } else {
                field.onChange(dayjs(value).toDate());
              }
              if (triggerOnChange) {
                trigger();
              }
            }}
            onError={() => {
              field.onBlur();
              trigger();
            }}
            renderInput={(params) => (
              <TextField
                name={name}
                helperText={shouldDisplayError ? error?.message : ""}
                error={!!shouldDisplayError}
                required={required}
                fullWidth
                {...params}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerControlled;
