import React from "react";
import SecondarySignUpForm from "components/organisms/SecondarySignUpForm/SecondarySignUpForm";
import Page from "pages/Page/Page";

const SignUpSecondaryPage = () => {
  return (
    <Page className="sign-up-page">
      <SecondarySignUpForm />
    </Page>
  );
};

export default SignUpSecondaryPage;
