import {createSelector} from "@reduxjs/toolkit";
import {ConfigurationsState, ConfigurationKey, Configuration} from "types/configurations";
import {State} from "types/store";
import {isConfigurationValueTrue} from "util/configUtil";

export const get = (rootState: State): ConfigurationsState => rootState.providerConfigurations;

export const getProviderConfiguration = createSelector(get, (configState) => configState);

export const getIsProviderConfigurationValueEnabled = (key: ConfigurationKey) => {
  return createSelector(
    get,
    (configState) =>
      configState?.find((configuration: Configuration) => configuration.key === key)?.value ===
      "true"
  );
};

export const getConfigurationValue = (key: ConfigurationKey) => {
  return createSelector(
    get,
    (configState) =>
      configState?.find((configuration: Configuration) => configuration?.key === key)?.value
  );
};

export const getOnlyDocumentUploadRequirements = createSelector(get, (configState) => {
  return configState?.filter((configuration: Configuration) =>
    configuration?.key?.includes("documents.required")
  );
});

export const getIsAnyDocumentUploadRequirementsEnabled = createSelector(get, (configState) => {
  const requiredConfig = configState?.filter(
    (configuration: Configuration) =>
      configuration?.key?.includes("documents.required") && isConfigurationValueTrue(configuration)
  );
  return Boolean(requiredConfig?.length);
});

export const getIsAllDocumentUploadHidden = createSelector(get, (configState) => {
  const hiddenConfig = configState?.filter(
    (configuration: Configuration) =>
      configuration?.key?.includes("documents.hidden") && isConfigurationValueTrue(configuration)
  );
  return (
    hiddenConfig?.length ==
    Object.values(ConfigurationKey).filter((key) => key?.includes("documents.hidden"))?.length
  );
});
