import {getSearchParamsOf} from "components/utils/urlUtil";
import {call, select, takeEvery} from "redux-saga/effects";
import {submitNewBankOperation} from "store/operation/basicInfo";
import {getUserId} from "store/selectors/user";
import {WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {initialize, navigatePreviousStep} from "./AddBankStep.action";

function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const {loginId: bankLoginId, institution: bankInstitution} = getSearchParamsOf(
    "loginId",
    "institution"
  );

  const userId: string = yield select(getUserId);

  if (bankLoginId) {
    const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
      bankLoginId,
      bankInstitution,
    };

    yield call(submitNewBankOperation.saga, userId, basicInfoRequest, Step.ADD_BANK);
    navigate(RouteUrl.BASIC_INFO_URL_ADD_BANK_INFO, {replace: true});
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(RouteUrl.BASIC_INFO_URL_PRE_BANKING);
}

export default function* addBankStepSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
