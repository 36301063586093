import {
  root,
  muiGridContainer as muiGridContainerClass,
  muiGridItem as muiGridItemClass,
  logoBox as logoBoxClass,
  muiGridItemButton as muiGridItemButtonClass,
  lenderCard,
} from "./LenderBox.module.scss";
import LogoBox from "components/atoms/LogoBox/LogoBox";
import {Grid, IconButton, Paper} from "@mui/material";
import LabelledTextTypography from "components/molecules/LabelledTextTypography/LabelledTextTypography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {onKeyDownHandler} from "util/eventUtil";
import {getPluralFormatRule} from "util/languageUtil";
import moment from "moment";
import {useTranslation} from "react-i18next";


interface LenderBoxProps {
  name: string;
  rate: string;
  termInYears: number;
  mortgagePrincipal: string;
  monthlyPayment: string;
  icon?: string;
  backgroundColor?: string;
  onClick?: () => void;
}

const PLACEHOLDER = "***";

const LenderBox = ({
  name,
  rate,
  termInYears,
  mortgagePrincipal,
  monthlyPayment,
  icon,
  backgroundColor = "128, 128, 128",
  onClick = () => {},
}: LenderBoxProps) => {

  const {t: fixedT, i18n} = useTranslation("", {keyPrefix: "lenderBox"});
  moment.locale(i18n.language);
  
  return (
    <div
      className={root}
      onClick={onClick}
      onKeyDown={(e) => onKeyDownHandler(e, onClick)}
      tabIndex={0}
    >
      <Paper className={lenderCard} elevation={3}>
        <Grid
          container
          className={muiGridContainerClass}
          sx={{
            borderLeft: `30px solid RGB(${backgroundColor})`,
          }}
        >
          <Grid item md={12} sm={12} xs={12}>
            <LogoBox
              className={logoBoxClass}
              backgroundColor={backgroundColor}
              iconPath={icon}
              fallbackText={name}
              isLenderBox={true}
            />
          </Grid>
          <Grid item xs={3} sm={6} className={muiGridItemClass}>
            <LabelledTextTypography primary={fixedT("rateLabel")} secondary={rate ?? PLACEHOLDER} />
          </Grid>
          <Grid item xs={3} sm={6} className={muiGridItemClass}>
            <LabelledTextTypography
              primary={fixedT("termInYearsLabel")}
              secondary={
                termInYears
                  ? fixedT("year", {count: termInYears, context: getPluralFormatRule(termInYears)})
                  : PLACEHOLDER
              }
            />
          </Grid>
          <Grid item xs={3} sm={6} md={12} className={muiGridItemClass}>
            <LabelledTextTypography
              primary={fixedT("monthlyPaymentLabel")}
              secondary={monthlyPayment ?? PLACEHOLDER}
            />
          </Grid>
          <Grid item xs={3} sm={6} md={12} className={muiGridItemClass}>
            <LabelledTextTypography
              primary={fixedT("mortgagePrincipalLabel")}
              secondary={mortgagePrincipal ?? PLACEHOLDER}
            />
          </Grid>
          <Grid item xs={12} className={muiGridItemButtonClass}>
            <IconButton disabled>
              {fixedT("seeMore")}
              <KeyboardArrowDownIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LenderBox;
