import BackButton from "components/molecules/BackButton/BackButton";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import {getFixedT} from "util/languageUtil";
import {useNavigate} from "react-router-dom";
import {RouteUrl} from "types/route";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {default as styles} from "./InviteSecondaryApplicantComplete.module.scss";
import Typography from "@mui/material/Typography/Typography";

const fixedT = getFixedT("basicInfoSteps.InviteSecondaryApplicant");

const InviteSecondaryApplicantComplete = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <Typography variant="h5">{fixedT("completionTitle")}</Typography>
      <div className={styles.fields}>{fixedT("completionDetailsPrimaryApplicant")}</div>
      <NavigationSection>
        <BackButton />
        <SubmitButton
          text={fixedT("forwardButton")}
          onClick={() => {
            navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT);
          }}
        />
      </NavigationSection>
    </div>
  );
};

export default InviteSecondaryApplicantComplete;
