import {logEmpty} from "components/utils/log";
import {equalIgnoreCase} from "components/utils/stringUtil";
import {call, select, takeEvery} from "redux-saga/effects";
import {submitUnresolvedAVMPropertyOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getHasAvm, getLoanTypeConsolated} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "types/dto/residentialApplicationBasicInfoRequest";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType} from "types/enums/loanType";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {WithNavigate} from "types/ui";
import {initialize, submit, SubmitUnresolvedAVMPagePayload} from "./UnresolvedAVMPage.action";

function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const loanType: LoanType = yield select(getLoanTypeConsolated);
  const hasAvm: boolean = yield select(getHasAvm);

  if (!equalIgnoreCase(LoanType.NEW_PURCHASE, loanType)) {
    console.error("Wrong path here - not supported for loanType=" + loanType);
    navigate && navigate(RouteUrl.MORTGAGE_INFO_URL);
  }
  if (hasAvm) {
    console.error("Wrong path here - no need to come here since avm exists.");
    navigate && navigate(RouteUrl.MORTGAGE_INFO_URL);
  }
}

function* onSubmit({
  payload: {navigate, ...selectedPropertyAddress},
}: WithPayload<SubmitUnresolvedAVMPagePayload>) {
  logEmpty(navigate, "navigate");

  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails: {
      ...selectedPropertyAddress,
    },
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitUnresolvedAVMPropertyOperation.saga,
    userId,
    basicInfoRequest,
    Step.UNRESOLVED_AVM_PROPERTY
  );

  if (Operation.isSuccess(result)) {
    navigate && navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE);
  }
}

export default function* unresolvedAVMPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submit, onSubmit);
}
