import {createSelector} from "@reduxjs/toolkit";
import {ApplicantMetaState} from "store/reducers/applicantMeta";
import {State} from "types/store";

export const getApplicantMeta = (rootState: State): ApplicantMetaState => rootState.applicantMeta;

export const getHasUserAcceptedDataWarning = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.hasUserAcceptedDataWarning
);

export const getShouldUserAcceptedDataWarningModalOpen = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.shouldUserAcceptedDataWarningModalOpen
);

export const getPurchaseTimeframe = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.purchaseTimeframe
);

export const getUserCreditScore = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.creditScore
);

export const getUserDateOfBirth = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.dateOfBirth
);

export const getSelfReportedAssets = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.selfReportedAssets
);

export const getSelfReportedLiabilities = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.selfReportedLiabilities
);
