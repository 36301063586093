import {useState} from "react";
import {root} from "./LoginPortal.module.scss";
import LoginOptions from "components/organisms/LoginOptions/LoginOptions";
import RegistrationComplete from "components/organisms/RegistrationComplete/RegistrationComplete";
import SetPasswordForm from "components/organisms/SetPasswordForm/SetPasswordForm";
import {useNavigate} from "react-router-dom";
import {RouteUrl} from "types/route";
import Page from "pages/Page/Page";

export const STATE_ENUM = {
  PORTAL: "PORTAL",
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  VERIFY: "VERIFY",
  SET_PASSWORD: "SET_PASSWORD",
};

const LoginPortal = ({startingState = ""}) => {
  const [state, setState] = useState(startingState || STATE_ENUM.PORTAL);
  const navigate = useNavigate();

  if (state === STATE_ENUM.REGISTER) {
    navigate(RouteUrl.SIGN_UP);
  } else if (state === STATE_ENUM.LOGIN) {
    navigate(RouteUrl.SIGN_IN);
  }

  return (
    <Page className={root}>
      {state === STATE_ENUM.SET_PASSWORD && <SetPasswordForm />}
      {state === STATE_ENUM.PORTAL && (
        <LoginOptions
          onSignUpSelected={() => setState(STATE_ENUM.REGISTER)}
          onSignInSelected={() => setState(STATE_ENUM.LOGIN)}
        />
      )}
      {state === STATE_ENUM.VERIFY && <RegistrationComplete />}
    </Page>
  );
};

export default LoginPortal;
