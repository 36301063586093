import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {Password} from "types/basic";
import {WithNavigate} from "types/ui";

const namespace = "SetPasswordForm";

export type SetPasswordFormPayload = Password & WithNavigate;

export const initialize = createAction<string>(actionTypeOf(namespace, "initialize"));

export const submit = createAction<SetPasswordFormPayload>(actionTypeOf(namespace, "setPasword"));
