import {fetchMortgageProvidersApi, fetchWhiteLabelApi} from "api/whiteLabel";
import {OperationType} from "types/operation";
import {Operation} from "./Operation";

export const fetchWhitelabelOperation = new Operation(
  OperationType.fetchWhitelabel,
  fetchWhiteLabelApi
);

export const fetchMortgageProvidersOperation = new Operation(
  OperationType.fetchMortgageProviders,
  fetchMortgageProvidersApi
);
