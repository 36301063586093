import {useState} from "react";
import {
  root,
  fields,
  fineprint,
  checkboxContainer,
  checkboxWrapper,
  typography,
} from "./SecondarySignUpForm.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {getFixedT} from "util/languageUtil";
import {noopPreventDefault} from "components/utils/util";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import SubmitButton from "../SubmitButton/SubmitButton";
import Message from "components/atoms/Message/Message";
import {useNavigate} from "react-router-dom";
import TwoFieldsContainer from "../Fields/TwoFieldsContainer/TwoFieldsContainer";
import {getSearchParamsOf} from "components/utils/urlUtil";
import {registerSecondaryUser, RegisterSecondaryUserPayload} from "./SecondarySignUpForm.action";
import {getSelectedProviderName, getWhitelabelId} from "store/selectors/whitelabel";
import {fetchPolicyApi} from "api/basicInfo";
import {Link, Modal, Typography} from "@mui/material";
import AcceptLegalInfo from "components/templates/AcceptLegalInfo/AcceptLegalInfo";
import NavigationSection from "components/molecules/NavigationSection/NavigationSection";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./SecondarySignUpForm.schema";
import TextFieldControlled from "../Form/TextFieldControlled/TextFieldControlled";
import {getIsAnyOperationPending} from "store/selectors/operation";
import {OperationType} from "types/operation";
import CheckboxControlled from "../Form/CheckboxControlled/CheckboxControlled";

const fixedT = getFixedT("components.organisms.SecondarySignUpForm");

const SecondarySignUpForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPending = useSelector(getIsAnyOperationPending)(OperationType.registerSecondaryUser);
  const {email, appId} = getSearchParamsOf("email", "appId");
  const isEmailLocked = !!email;
  const [error, setError] = useState<string>();
  const whitelabelId = useSelector(getWhitelabelId);
  const organizationName = useSelector(getSelectedProviderName);

  const formHook = useForm<RegisterSecondaryUserPayload>({
    defaultValues: {
      firstName: undefined,
      lastName: undefined,
      applicationType: ResidentialApplicationType.JOINT_APPLICANT,
      email: email || undefined,
      phoneNumber: undefined,
    },
    resolver: yupResolver(getSchema()),
  });

  const [policyContent, setPolicyContent] = useState<string | undefined>(undefined);
  const [modal, setModal] = useState<boolean>(false);

  const handleClose = () => setModal(false);

  async function handleOpen() {
    // These calls need to be combined once we are committing to this change
    const {data: privacyPolicy} = await fetchPolicyApi(whitelabelId);
    setPolicyContent(privacyPolicy);
    setModal(true);
  }

  const onSubmit = (values: RegisterSecondaryUserPayload) => {
    trackEvent(TrackingEventType.secondarySignUpPageSubmit);
    dispatch(
      registerSecondaryUser({
        navigate,
        appId,
        ...values,
      })
    );
  };

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;
  return (
    <form className={root} onSubmit={noopPreventDefault}>
      <Typography variant="h1" align="left">
        {fixedT("header")}
      </Typography>
      <Typography variant="subtitle1" dangerouslySetInnerHTML={{__html: fixedT("subheader")}} />
      <div className={fields}>
        <TwoFieldsContainer
          left={
            <TextFieldControlled
              formHook={formHook}
              name="firstName"
              type="text"
              label={fixedT("fields.firstName.label")}
              placeholder={fixedT("fields.firstName.placeholder")!}
              disabled={isPending}
              autoComplete="firstName"
              required
              trackingEvent={TrackingEventType.secondarySignUpPageEditFirstName}
            />
          }
          right={
            <TextFieldControlled
              formHook={formHook}
              name="lastName"
              type="text"
              label={fixedT("fields.lastName.label")}
              placeholder={fixedT("fields.lastName.placeholder")!}
              disabled={isPending}
              autoComplete="lastName"
              required
              trackingEvent={TrackingEventType.secondarySignUpPageEditLastName}
            />
          }
        />
        <TextFieldControlled
          formHook={formHook}
          name="email"
          type="email"
          label={fixedT("fields.email.label")}
          placeholder={fixedT("fields.email.placeholder")!}
          disabled={isEmailLocked || isPending}
          autoComplete="email"
          defaultValue={email}
          required
          trackingEvent={TrackingEventType.secondarySignUpPageEditEmail}
        />

        <TextFieldControlled
          formHook={formHook}
          name="phoneNumber"
          type="tel"
          label={fixedT("fields.phoneNumber.label")}
          placeholder={fixedT("fields.phoneNumber.placeholder")!}
          disabled={isPending}
          autoComplete="phoneNumber"
          trackingEvent={TrackingEventType.secondarySignUpPageEditPhoneNo}
          required
        />
        <div className={fineprint}>
          <Typography variant="body2">
            {fixedT("termsAndConditions")}{" "}
            <Link variant="body2" onClick={handleOpen}>
              {fixedT("termsAndConditionsLink")}
            </Link>
          </Typography>
        </div>
      </div>
      <div className={checkboxContainer}>
        <div className={checkboxWrapper}>
          <CheckboxControlled formHook={formHook} name="emailOptIn" />
        </div>
        <Typography variant="body2" gutterBottom className={typography}>
          {fixedT("emailOptIn", {name: organizationName})}
        </Typography>
      </div>

      <Message message={error} />

      <Modal open={modal} onClose={handleClose}>
        <AcceptLegalInfo title={fixedT("policyTitle")} onClose={handleClose}>
          {policyContent || fixedT("errorPolicy")}
        </AcceptLegalInfo>
      </Modal>
      <NavigationSection>
        <SubmitButton
          isSubmitting={isPending}
          isEnabled={!isPending}
          onClick={formHook.handleSubmit(onSubmit)}
          text={fixedT("forwardButton")}
        />
      </NavigationSection>
    </form>
  );
};

export default SecondarySignUpForm;
