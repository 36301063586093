import {AxiosError, AxiosResponse} from "axios";
import {JSONParseOrSelf} from "components/utils/objectUtil";
import {WithDataError} from "types/api";
import {AuthError} from "types/auth";
import {UNKNOWN_AUTH_ERROR, UNKNOWN_AXIOS_API_ERROR, UNKNOWN_ERROR} from "types/error";
import {ErrorResponse, WritableError} from "types/operation";
import {ApiError} from "types/ApiError";

const isAuthError = (json: any | AuthError): boolean => {
  return !!(json && typeof json === "object" && json.errorSummary && json.errorCauses);
};

const getAuthErrorMessageOrDefault = (authError: AuthError): string => {
  try {
    const causesString = authError?.errorCauses
      ?.map((cause) => Object.values(cause))
      .reduce((acc, cur) => [...acc, ...cur], [])
      .filter(Boolean)
      .join(";");
    return causesString || authError?.errorSummary || authError?.message || UNKNOWN_AUTH_ERROR;
  } catch (e) {
    console.error("Fallback to default, due to failure in parsing message from AuthError", e);
    return UNKNOWN_AUTH_ERROR;
  }
};

export const writableErrorOf = (
  error: AuthError | string | any,
  defaultMessage = UNKNOWN_ERROR
): Partial<WritableError> => {
  if (isAuthError(error)) {
    return {message: getAuthErrorMessageOrDefault(error)};
  }
  if (error instanceof ApiError) {
    return {message: error?.message || defaultMessage};
  }
  return {message: JSON.stringify(error) || defaultMessage};
};

export async function normalizedFetchApi<T>(
  fetchApiFunc: () => Promise<Response>
): Promise<WithDataError<T>> {
  try {
    const response: Response = await fetchApiFunc();

    const jsonOrTextContent = JSONParseOrSelf(await response.text());

    if (!response.ok) {
      const errorJson = writableErrorOf(jsonOrTextContent);

      return {
        error: {
          ...errorJson,
          status: response.status,
          statusText: response.statusText,
          type: response.type,
          url: response.url,
        },
      };
    }

    return {data: jsonOrTextContent};
  } catch (e: any) {
    return {error: writableErrorOf(e)};
  }
}

export async function normalizedAxiosApi<T>(
  axiosApiFunc: () => Promise<AxiosResponse<T>>
): Promise<WithDataError<T>> {
  try {
    const {data} = await axiosApiFunc();
    return {data: data};
  } catch (e) {
    const {data, status, statusText, config} = ((e as AxiosError)?.response || {}) as AxiosResponse;
    return {
      error: {
        message: data || UNKNOWN_AXIOS_API_ERROR,
        status,
        statusText,
        url: config?.url,
      },
    };
  }
}

export function parseErrorMessage<T>(error: WritableError): ErrorResponse {
  try {
    return JSON.parse(error?.message as string);
  } catch (e) {
    return {message: JSON.stringify(error?.message)};
  }
}
