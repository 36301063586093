import {logEmpty} from "components/utils/log";
import i18next from "i18next";
import {call, select, takeEvery} from "redux-saga/effects";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {registerPrimaryUserOperation} from "store/operation/user";
import {getBasicInfoOrEmpty, getConsolidatedMortgageProviderId} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {User} from "types/dto/user";
import {getNextStepUrl} from "util/routeUtil";
import {registerPrimaryUser, RegisterPrimaryUserPayload} from "./SignUpForm.action";
import {onInitialize as initializeNavbar} from "components/molecules/NavigationBar/NavigationBar.saga";
import {
  getSelfReportedAssets,
  getSelfReportedLiabilities,
  getPurchaseTimeframe,
} from "store/selectors/applicantMeta";
import {getUserCreditScore, getUserDateOfBirth} from "store/selectors/applicantMeta";
import {CreditScoreType} from "types/enums/creditScoreType";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {PurchaseTimeframe} from "types/enums/purchaseTimeframe";
import {getWhitelabelId} from "store/selectors/whitelabel";

function* onRegisteringPrimary({
  payload: {navigate, ...userRequest},
}: WithPayload<RegisterPrimaryUserPayload>) {
  const whiteLabelId: string = yield select(getWhitelabelId);
  const financialInstitutionId: string = yield select(getConsolidatedMortgageProviderId);
  const purchaseTimeframe: PurchaseTimeframe = yield select(getPurchaseTimeframe);
  const selfReportedAssets: SelfReportedAssetDto[] = yield select(getSelfReportedAssets);
  const selfReportedLiabilities: SelfReportedLiabilityDto[] = yield select(
    getSelfReportedLiabilities
  );
  const creditScore: CreditScoreType = yield select(getUserCreditScore);
  const dateOfBirth: string = yield select(getUserDateOfBirth);

  logEmpty(financialInstitutionId, "financialInstitutionId");

  const signUpResult: SagaResult<User> = yield call(
    registerPrimaryUserOperation.saga,
    {
      ...userRequest,
      languagePreference: i18next.language,
      purchaseTimeframe,
      selfReportedAssets,
      selfReportedLiabilities,
      creditScore,
      dateOfBirth,
    },
    whiteLabelId,
    location.host,
    financialInstitutionId,
    creditScore,
    dateOfBirth,
    selfReportedAssets,
    selfReportedLiabilities,
    purchaseTimeframe
  );

  if (Operation.isSuccess(signUpResult)) {
    // Registering primary user would have created app, applicant(s).
    // Need to fetch the basicInfo which is unified view of app & applicant
    const userId: string = yield select(getUserId);

    const basicInfoResult: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
      fetchBasicInfoByUserIdOperation.saga,
      userId
    );

    if (Operation.isSuccess(basicInfoResult)) {
      const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getBasicInfoOrEmpty);

      yield call(initializeNavbar); // update completeness state

      navigate(getNextStepUrl(basicInfo));
    }
  }
}

export default function* signUpFormSaga() {
  yield takeEvery(registerPrimaryUser, onRegisteringPrimary);
}
