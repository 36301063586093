import {call, select, takeEvery} from "redux-saga/effects";
import {
  submitBankStatementOperation,
  submitClosingDocumentOperation,
  submitMlsOperation,
  submitNoaOperation,
  submitPaystubOperation,
  submitT4Operation,
} from "store/operation/basicInfo";
import {getUserId} from "store/selectors/user";
import {WithPayload} from "types/basic";
import {DocumentType} from "types/enums/documentType";
import {WithNavigate} from "types/ui";
import {
  UploadDocumentsPayload,
  initialize,
  submitFile,
  navigatePreviousStep,
  navigateNextStep,
} from "./UploadDocuments.action";
import {
  DIRECT_LENDER_BASIC_INFO_STEPS,
  INDIRECT_LENDER_BASIC_INFO_STEPS,
  RouteUrl,
} from "types/route";
import {getNextPage, getPreviousPage} from "util/routeUtil";
import {getIsDirectLender, getIsSecondary} from "store/selectors/basicInfo";

function* onInitialize({payload}: WithPayload<WithNavigate>) {}

function* onSubmitFile({payload: {file, documentType}}: WithPayload<UploadDocumentsPayload>) {
  const userId: string = yield select(getUserId);

  switch (documentType) {
    case DocumentType.T4:
      yield call(submitT4Operation.saga, userId, file, documentType);
      break;
    case DocumentType.NOA:
      yield call(submitNoaOperation.saga, userId, file, documentType);
      break;
    case DocumentType.PAYSTUB:
      yield call(submitPaystubOperation.saga, userId, file, documentType);
      break;
    case DocumentType.BANK_STATEMENT:
      yield call(submitBankStatementOperation.saga, userId, file, documentType);
      break;
    case DocumentType.MLS:
      yield call(submitMlsOperation.saga, userId, file, documentType);
      break;
    case DocumentType.CLOSING_DOCUMENT:
      yield call(submitClosingDocumentOperation.saga, userId, file, documentType);
      break;
    default:
      console.error("Abort uploading file due to unexpected documentType: " + documentType);
      break;
  }
}

function* onNavigatePreviousStep({payload: {navigate}}: WithPayload<WithNavigate>) {
  navigate(RouteUrl.BASIC_INFO_URL_CREDIT_REDIRECT);
}

function* onNavigateNextStep({payload: {navigate}}: WithPayload<WithNavigate>) {
  const isSecondary: boolean = yield select(getIsSecondary);
  if (isSecondary) {
    navigate(RouteUrl.JOINT_COMPLETED_URL);
  } else {
    navigate(RouteUrl.MORTGAGE_INFO_URL_MORTGAGE_SELECTION);
  }
}

export default function* uploadDocumentsSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitFile, onSubmitFile);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
  yield takeEvery(navigateNextStep, onNavigateNextStep);
}
