import * as yup from "yup";
import {isValidPostalCode} from "components/utils/stringUtil";
import {PropertyType} from "types/enums/propertyType";
import {Currency} from "types/enums/currency";
import {LoanType} from "types/enums/loanType";
import {Money} from "types/dto/money";
import {useTranslation} from "react-i18next";
import {Province} from "types/enums/province";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "propertyInformation"});

  return yup.object().shape({
    city: yup.string().required(fixedT("messages.cityMissing")!),
    streetName: yup.string().required(fixedT("messages.streetNameMissing")!),
    streetNo: yup
      .number()
      .typeError(fixedT("messages.streetNoMissing")!)
      .min(1, fixedT("messages.streetNoMissing")!)
      .required(fixedT("messages.streetNoMissing")!),
    province: yup
      .string()
      .required(fixedT("messages.provinceMissing")!)
      .test({
        name: "province",
        test(province, ctx) {
          if (province !== Province.NULL) return true;
          return ctx.createError({
            path: "province",
            message: fixedT("messages.provinceMissing")!,
          });
        },
      }),
    propertyType: yup.mixed<PropertyType>().required(fixedT("messages.propertyTypeMissing")!),
    postCode: yup
      .string()
      .trim()
      .test({
        name: "postCode",
        test(postCode, ctx) {
          if (isValidPostalCode(postCode)) return true;
          return ctx.createError({
            path: "postCode",
            message: fixedT("messages.invalidPostalCode")!,
          });
        },
      })
      .required(fixedT("messages.postalCodeMissing")!),
    squareFootage: yup
      .number()
      .typeError(fixedT("messages.squareFootageMissing")!)
      .min(1, fixedT("messages.squareFootageMissing")!)
      .required(fixedT("messages.squareFootageMissing")!),
    condoFees: yup.lazy((condoFeesValue, {parent}) => {
      if (PropertyType.CONDO! === parent.propertyType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.condoFeeMissing")!)
              .min(1, fixedT("messages.condoFeeMissing")!)
              .required(fixedT("messages.condoFeeMissing")!),
            currency: yup.mixed<Currency>().required(),
          })
          .required();
      }
      return yup.mixed<Money>().notRequired();
    }),
    heatingCosts: yup.lazy((heatingCostsValue, {parent}) => {
      if (LoanType.RENEWAL === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.heatingCostZeroOrNegative")!)
              .min(1, fixedT("messages.heatingCostZeroOrNegative")!)
              .required(fixedT("messages.heatingCostZeroOrNegative")!),
            currency: yup.mixed<Currency>().required(),
          })
          .required();
      }
      return yup.mixed<Money>().notRequired();
    }),
    propertyTaxes: yup.lazy((propertyTaxesValue, {parent}) => {
      if (LoanType.RENEWAL === parent.loanType) {
        return yup
          .object({
            amount: yup
              .number()
              .typeError(fixedT("messages.propertyTaxZeroOrNegative")!)
              .min(1, fixedT("messages.propertyTaxZeroOrNegative")!)
              .required(fixedT("messages.propertyTaxZeroOrNegative")!),
            currency: yup.mixed<Currency>().required(),
          })
          .required();
      }
      return yup.mixed<Money>().notRequired();
    }),
  });
};
