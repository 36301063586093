import {useEffect, useState} from "react";
import {default as styles} from "./SelfReportedAssetsLiabilities.module.scss";
import BackButton from "components/molecules/BackButton/BackButton";
import Message from "components/atoms/Message/Message";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  submit,
  navigatePreviousStep,
  OtherEntry,
  SelfReportedAssetsLiabilitiesFormData,
  openUserAcceptedDataWarningModal,
  acceptMissingDataWarning,
} from "./SelfReportedAssetsLiabilities.action";
import {getBasicInfoOrEmpty, getIsLocked} from "store/selectors/basicInfo";
import {SelfReportedAssetType} from "types/enums/selfReportedAssetType";
import {safeArray} from "components/utils/listUtil";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import SubmitButton from "components/organisms/SubmitButton/SubmitButton";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "types/enums/trackingEventType";
import {FieldErrorDto} from "types/dto/fieldErrorDto";
import {Typography, IconButton, Button, Modal} from "@mui/material";
import MoneyInputControlled from "components/organisms/Form/MoneyInputControlled/MoneyInputControlled";
import {useFieldArray, useForm} from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import {SelfReportedLiabilityType} from "types/enums/selfReportedLiabilityType";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {yupResolver} from "@hookform/resolvers/yup";
import {getSchema} from "./SelfReportedAssetsLiabilities.schema";
import Form from "components/atoms/Form/Form";
import TextFieldControlled from "components/organisms/Form/TextFieldControlled/TextFieldControlled";
import StepContent from "components/organisms/StepContent/StepContent";
import {ConfirmationModal} from "components/organisms/ConfirmationModal/ConfirmationModal";
import {
  getApplicantMeta,
  getHasUserAcceptedDataWarning,
  getShouldUserAcceptedDataWarningModalOpen,
} from "store/selectors/applicantMeta";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";
import {ConfigurationKey} from "types/configurations";

const fixedT = getFixedT("basicInfoSteps.selfReportedAssetsLiabilities");

interface Props {
  className?: () => any;
  prevPage?: () => any;
  nextPage?: () => any;
}

const SelfReportedAssetsLiabilities: React.FC<Props> = ({
  prevPage = undefined,
  nextPage = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locked = useSelector(getIsLocked);
  const selfReportedLiabilities =
    useSelector(getBasicInfoOrEmpty)?.selfReportedLiabilities ||
    useSelector(getApplicantMeta)?.selfReportedLiabilities;
  const selfReportedAssets =
    useSelector(getBasicInfoOrEmpty)?.selfReportedAssets ||
    useSelector(getApplicantMeta)?.selfReportedAssets;
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitSelfReportedIncomeAssets
  );
  const [fieldError, setFieldError] = useState<string>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const hasUserAcceptedDataWarning = useSelector(getHasUserAcceptedDataWarning);
  const shouldModalOpen = useSelector(getShouldUserAcceptedDataWarningModalOpen);
  const isSelfReportedAssetsLiabilitiesEnabled = useSelector(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );

  useEffect(() => {
    if (hasUserAcceptedDataWarning === false && shouldModalOpen) {
      setModalOpen(true);
    }
  }, [hasUserAcceptedDataWarning, shouldModalOpen]);

  function getOtherAssets(assets?: SelfReportedAssetDto[]): OtherEntry[] {
    const defaultAssetTypes = [
      SelfReportedAssetType.SAVINGS,
      SelfReportedAssetType.CHEQUING,
      SelfReportedAssetType.RRSP,
      SelfReportedAssetType.TFSA,
    ];

    if (!assets) {
      return [];
    }
    return assets
      .filter((asset) => asset.type && !defaultAssetTypes.includes(asset.type))
      .map((asset) => {
        return {
          amount: asset.amount?.amount,
          description: asset.description || asset.type,
        };
      });
  }

  function getOtherLiabilities(liabilities?: SelfReportedLiabilityDto[]): OtherEntry[] {
    const defaultLiabilityTypes = [
      SelfReportedLiabilityType.CREDIT_CARD,
      SelfReportedLiabilityType.CAR_PAYMENT,
      SelfReportedLiabilityType.MORTGAGE,
      SelfReportedLiabilityType.LOANS,
    ];

    if (!liabilities) {
      return [];
    }
    return liabilities
      .filter((liability) => liability.type && !defaultLiabilityTypes.includes(liability.type))
      .map((liability) => {
        return {
          amount: liability.balance?.amount,
          description: liability.lender || liability.type,
        };
      });
  }

  const formHook = useForm<SelfReportedAssetsLiabilitiesFormData>({
    defaultValues: {
      savings: selfReportedAssets?.find((asset) => asset.type === SelfReportedAssetType.SAVINGS)
        ?.amount?.amount,
      chequing: selfReportedAssets?.find((asset) => asset.type === SelfReportedAssetType.CHEQUING)
        ?.amount?.amount,
      rrsp: selfReportedAssets?.find((asset) => asset.type === SelfReportedAssetType.RRSP)?.amount
        ?.amount,
      tfsa: selfReportedAssets?.find((asset) => asset.type === SelfReportedAssetType.TFSA)?.amount
        ?.amount,
      otherAssets: getOtherAssets(selfReportedAssets),
      creditCard: selfReportedLiabilities?.find(
        (liability) => liability.type === SelfReportedLiabilityType.CREDIT_CARD
      )?.balance?.amount,
      carPayment: selfReportedLiabilities?.find(
        (liability) => liability.type === SelfReportedLiabilityType.CAR_PAYMENT
      )?.balance?.amount,
      mortgage: selfReportedLiabilities?.find(
        (liability) => liability.type === SelfReportedLiabilityType.MORTGAGE
      )?.balance?.amount,
      loans: selfReportedLiabilities?.find(
        (liability) => liability.type === SelfReportedLiabilityType.LOANS
      )?.balance?.amount,
      otherLiabilities: getOtherLiabilities(selfReportedLiabilities),
    },
    resolver: yupResolver(getSchema()),
  });

  const {control} = formHook;

  const {
    fields: fieldsAssets,
    append: appendAssets,
    remove: assetsRemove,
  } = useFieldArray({
    control,
    name: "otherAssets",
  } as never);

  const {
    fields: fieldsLiabilities,
    append: appendLiabilities,
    remove: removeLiabilities,
  } = useFieldArray({
    control,
    name: "otherLiabilities",
  } as never);

  function onClose() {
    dispatch(openUserAcceptedDataWarningModal(false));
    setModalOpen(false);
  }

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;


  return (
    <StepContent
      className={styles.root}
      headerText={
        !isSelfReportedAssetsLiabilitiesEnabled ? fixedT("header") : fixedT("headerPreSignup")
      }
      backButton={
        !isSelfReportedAssetsLiabilitiesEnabled ? (
          // If this page is after sign up,
          //    let the component dynamically handle routing
          <BackButton
            onClick={() => {
              trackEvent(TrackingEventType.assetsLiabilitiesClickBackButton);
              dispatch(navigatePreviousStep({navigate}));
            }}
          />
        ) : isSelfReportedAssetsLiabilitiesEnabled && prevPage ? (
          // If this page is before sign up and there is an assigned previous page
          //    let the Router handle routing
          <BackButton
            onClick={() => {
              trackEvent(TrackingEventType.assetsLiabilitiesClickBackButton);
              prevPage();
            }}
          />
        ) : undefined
        // If this page is before sign up and there is no assigned previous page
        //    it is the first page and no back button is rendered
      }
      nextButton={
        <SubmitButton
          isSubmitting={isPending}
          isEnabled={!isPending}
          onClick={formHook.handleSubmit((data) => {
            trackEvent(TrackingEventType.assetsLiabilitiesClickNextButton);
            if (
              hasUserAcceptedDataWarning === false ||
              !data.savings ||
              !data.chequing ||
              !data.rrsp ||
              !data.tfsa ||
              !data.creditCard ||
              !data.carPayment ||
              !data.mortgage ||
              !data.loans
            ) {
              setModalOpen(true);
            } else {
              dispatch(
                submit({
                  ...data,
                  navigate,
                })
              ) && nextPage();
            }
          })}
          text={
            (!isPending ? (
              fixedT("forwardButton")
            ) : (
              <FontAwesomeIcon icon={faSyncAlt} spin={true} />
            )) as string
          }
        />
      }
    >
      <Typography variant="prompt">{fixedT("subtitle")}</Typography>
      <Form className={styles.form}>
        <div className={styles.contentContainer}>
          <Typography variant="subtitle1" className={styles.contentSubtitle}>
            {fixedT("assets")}
          </Typography>
          <MoneyInputControlled
            formHook={formHook}
            name={"savings"}
            label={fixedT("savings")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeAssetAmount}
            disabled={locked}
          />
          <MoneyInputControlled
            formHook={formHook}
            name={"chequing"}
            label={fixedT("chequing")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeAssetAmount}
            disabled={locked}
          />
          <MoneyInputControlled
            formHook={formHook}
            name={"rrsp"}
            label={fixedT("rrsp")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeAssetAmount}
            disabled={locked}
          />
          <MoneyInputControlled
            formHook={formHook}
            name={"tfsa"}
            label={fixedT("tfsa")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeAssetAmount}
            disabled={locked}
          />

          {fieldsAssets.map((field, index) => (
            <div className={styles.additional} key={field.id}>
              <TextFieldControlled
                className={styles.inputField}
                formHook={formHook}
                name={`otherAssets.${index}.description`}
                label={fixedT("otherAssetLabel", {index: index + 1})}
                disabled={locked}
              />
              <div className={styles.valueField}>
                <MoneyInputControlled
                  className={styles.inputField}
                  formHook={formHook}
                  name={`otherAssets.${index}.amount`}
                  trackingEvent={TrackingEventType.assetsLiabilitiesChangeAssetAmount}
                  label={fixedT("value")}
                  disabled={locked}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    trackEvent(TrackingEventType.assetsLiabilitiesRemoveAssetButton);
                    assetsRemove(index);
                  }}
                  disabled={locked}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
          ))}

          <Button
            className={styles.button}
            variant="outlined"
            color={"black"}
            endIcon={<AddIcon />}
            onClick={() => {
              trackEvent(TrackingEventType.assetsLiabilitiesAddAsset);
              appendAssets({type: SelfReportedAssetType.OTHER});
            }}
            disabled={isPending || locked}
          >
            {fixedT("addAsset")}
          </Button>
        </div>

        <div className={styles.contentContainer}>
          <Typography variant="subtitle1" className={styles.contentSubtitle}>
            {fixedT("liabilities")}
          </Typography>
          <MoneyInputControlled
            formHook={formHook}
            name={"creditCard"}
            label={fixedT("creditCard")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeLiabilityAmount}
            disabled={locked}
          />
          <MoneyInputControlled
            formHook={formHook}
            name={"carPayment"}
            label={fixedT("carPayment")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeLiabilityAmount}
            disabled={locked}
          />
          <MoneyInputControlled
            formHook={formHook}
            name={"mortgage"}
            label={fixedT("mortgage")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeLiabilityAmount}
            disabled={locked}
          />
          <MoneyInputControlled
            formHook={formHook}
            name={"loans"}
            label={fixedT("loans")}
            trackingEvent={TrackingEventType.assetsLiabilitiesChangeLiabilityAmount}
            disabled={locked}
          />

          {fieldsLiabilities.map((_field, index) => (
            <div className={styles.additional} key={`otherLiabilities.${index}`}>
              <TextFieldControlled
                className={styles.inputField}
                formHook={formHook}
                name={`otherLiabilities.${index}.description`}
                label={fixedT("otherLiabilityLabel", {index: index + 1})}
                disabled={locked}
              />
              <div className={styles.valueField}>
                <MoneyInputControlled
                  className={styles.inputField}
                  formHook={formHook}
                  name={`otherLiabilities.${index}.amount`}
                  label={fixedT("value")}
                  trackingEvent={TrackingEventType.assetsLiabilitiesChangeLiabilityAmount}
                  disabled={locked}
                />
                <IconButton
                  aria-label="delete"
                  onClick={() => removeLiabilities(index)}
                  disabled={locked}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
          ))}

          <Button
            className={styles.button}
            variant="outlined"
            color={"black"}
            endIcon={<AddIcon />}
            onClick={() => {
              trackEvent(TrackingEventType.assetsLiabilitiesAddLiability);
              appendLiabilities({type: SelfReportedLiabilityType.OTHER});
            }}
            disabled={isPending || locked}
          >
            {fixedT("addLiability")}
          </Button>
        </div>
      </Form>

      <Message message={fieldError} />
      {safeArray<FieldErrorDto>(failure?.errors).map((fieldErrorBE: FieldErrorDto, index) => (
        <Message
          key={index}
          message={fixedT([
            `messages.${fieldErrorBE?.field}.${fieldErrorBE?.reason}`,
            `messages.${fieldErrorBE?.field}.missing`,
            "messages.general",
          ])}
        />
      ))}

      <Modal open={modalOpen} onClose={onClose}>
        <ConfirmationModal
          title={fixedT("modalTitle")}
          subtitle={fixedT("modalSubtitle")}
          confirmButtonText={fixedT("modalForward")}
          cancelButtonText={fixedT("modalCancel")}
          onClick={() => {
            // When this modal is displayed, not all default assets/liabilities are filled in.
            // Submit any assets/liabilities filled in by the user.
            formHook.handleSubmit((data) => {
              trackEvent(TrackingEventType.assetsLiabilitiesClickSkipButton);
              dispatch(acceptMissingDataWarning(true));
              dispatch(
                submit({
                  ...data,
                  navigate,
                })
              ) && nextPage();
            })();
          }}
          onBack={onClose}
        />
      </Modal>
    </StepContent>
  );
};

export default SelfReportedAssetsLiabilities;
