import {isEmail} from "components/utils/stringUtil";
import {ApplicantRelationshipType} from "types/enums/applicantRelationshipType";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "basicInfoSteps.InviteSecondaryApplicant"});

  return yup.object().shape({
    firstName: yup.string().trim().required(fixedT("errorMessages.firstNameMissing")!),
    lastName: yup.string().trim().required(fixedT("errorMessages.lastNameMissing")!),
    email: yup
      .string()
      .trim()
      .required(fixedT("errorMessages.validEmailMissing")!)
      .test({
        name: "email",
        test(email, ctx) {
          if (isEmail(email)) return true;
          return ctx.createError({
            path: "email",
            message: fixedT("errorMessages.validEmailMissing")!,
          });
        },
      }),
    relationship: yup
      .mixed<ApplicantRelationshipType>()
      .required(fixedT("errorMessages.relationshipMissing")!),
  });  
}
