export enum SelfReportedLiabilityType {
  CREDIT_CARD = "CREDIT_CARD",
  CAR_PAYMENT = "CAR_PAYMENT",
  MORTGAGE = "MORTGAGE",
  LOANS = "LOANS",
  OTHER = "OTHER",
  // Deprecated, unused for new applications, however they are present in some existing applications
  PERSONAL_LOAN = "PERSONAL_LOAN",
  ALIMONY = "ALIMONY",
  CHILD_SUPPORT = "CHILD_SUPPORT",
  STUDENT_LOAN = "STUDENT_LOAN",
  WAGE_GARNISHMENT = "WAGE_GARNISHMENT",
  UNSECURED_LINE_OF_CREDIT = "UNSECURED_LINE_OF_CREDIT",
  INCOME_TAX = "INCOME_TAX",
  MORTGAGE_RENTAL = "MORTGAGE_RENTAL",
  SECURED_LINE_OF_CREDIT = "SECURED_LINE_OF_CREDIT",
  LEASE = "LEASE",
  RENT = "RENT",
  AUTO_LEASE = "AUTO_LEASE",
}
