import {Drawer} from "@mui/material";
import {DRAWER_WIDTH} from "appConstants";
import {WithChildren} from "types/ui";

interface Props extends WithChildren {}

export default function NavigationBarDesktop({children}: Props) {
  return (
    <Drawer
      PaperProps={{elevation: 10}}
      variant="permanent"
      sx={{
        display: "block",
        "& .MuiDrawer-paper": {boxSizing: "border-box", width: DRAWER_WIDTH},
      }}
      open
    >
      {children}
    </Drawer>
  );
}
