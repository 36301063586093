import {call, select, takeEvery} from "redux-saga/effects";
import {submitAddressInfoOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getIsDirectLender} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "types/enums/step";
import {RouteUrl} from "types/route";
import {AddressInfoFormData, submitAddressInfo, navigatePreviousStep} from "./AddressInfo.action";
import {WithNavigate} from "types/ui";

function* onSubmit({payload}: WithPayload<AddressInfoFormData>) {
  const userId: string = yield select(getUserId);
  const {navigate, ...requestPayload} = payload;

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitAddressInfoOperation.saga,
    userId,
    requestPayload,
    Step.ADDRESS_INFO
  );

  if (Operation.isSuccess(result)) {
    navigate(RouteUrl.BASIC_INFO_URL_BANKING_REDIRECT);
  }
}

function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(RouteUrl.BASIC_INFO_URL_EMPLOYMENT);
}

export default function* addressInfoSaga() {
  yield takeEvery(submitAddressInfo, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
