import {createReducer} from "@reduxjs/toolkit";
import {saveDateOfBirthAndCreditScore} from "pages/BasicInfoSteps/CreditScorePage/CreditScorePage.action";
import {
  acceptMissingDataWarning,
  openUserAcceptedDataWarningModal,
} from "pages/BasicInfoSteps/SelfReportedAssetsLiabilities/SelfReportedAssetsLiabilities.action";
import {choosePurchaseTimeframe} from "store/actions/applicantMeta.action";
import {PurchaseTimeframe} from "types/enums/purchaseTimeframe";
import {saveAssetsLiabilities} from "store/actions/applicantMeta.action";

import {CreditScoreType} from "types/enums/creditScoreType";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {signOutOperation} from "store/operation/operations";

export interface ApplicantMetaState {
  shouldUserAcceptedDataWarningModalOpen: any;
  hasUserAcceptedDataWarning?: boolean;
  purchaseTimeframe?: PurchaseTimeframe;
  selfReportedAssets?: SelfReportedAssetDto[];
  selfReportedLiabilities?: SelfReportedLiabilityDto[];
  dateOfBirth?: Date | string;
  creditScore?: CreditScoreType;
}

export const initialState: ApplicantMetaState = {
  shouldUserAcceptedDataWarningModalOpen: undefined,
  hasUserAcceptedDataWarning: undefined,
  purchaseTimeframe: undefined,
  selfReportedAssets: undefined,
  selfReportedLiabilities: undefined,
  dateOfBirth: undefined,
  creditScore: undefined,
};

const applicantMetaReducer = createReducer<ApplicantMetaState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.success, () => initialState)
    .addCase(acceptMissingDataWarning, (state, {payload: hasUserAcceptedWarning}) => {
      return {...state, hasUserAcceptedDataWarning: hasUserAcceptedWarning};
    })
    .addCase(
      openUserAcceptedDataWarningModal,
      (state, {payload: shouldUserAcceptedDataWarningModalOpen}) => {
        return {
          ...state,
          shouldUserAcceptedDataWarningModalOpen,
        };
      }
    )
    .addCase(choosePurchaseTimeframe, (state, {payload: purchaseTimeframe}) => {
      return {...state, purchaseTimeframe: purchaseTimeframe};
    })
    .addCase(saveDateOfBirthAndCreditScore, (state, {payload}) => {
      const {dateOfBirth, creditScore} = payload;
      return {
        ...state,
        dateOfBirth: dateOfBirth,
        creditScore: creditScore,
      };
    })
    .addCase(saveAssetsLiabilities, (state, {payload}) => {
      return {
        ...state,
        selfReportedAssets: payload[0],
        selfReportedLiabilities: payload[1],
      };
    })
);

export default applicantMetaReducer;
