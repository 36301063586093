import {Money} from "types/dto/money";
import {Currency} from "types/enums/currency";

export function moneyOf(amount: number): Money {
  return {amount, currency: Currency.CAD};
}

export function moneyOptional(amount?: number | any): Money | undefined {
  if (amount == null || amount === "") {
    return undefined;
  }
  return moneyOrZero(amount);
}

export function moneyOrZero(amount?: number | any): Money {
  amount = Number(amount);
  if (!Number.isFinite(amount)) {
    amount = 0;
  }
  return {amount, currency: Currency.CAD};
}

export function moneyZero(): Money {
  return {amount: 0, currency: Currency.CAD};
}

export function isMoneyPositive(money?: Money): boolean {
  return !!money && money.amount > 0;
}
